/* eslint-disable max-len */
import React from "react";
import { observer } from "mobx-react";
import styles from "./Applicant.scss";
import { ITopUpApplicantForm } from "netbank-shared/src/libs/models/Offer/Offer";
import { tx } from "netbank-shared/src/libs/i18n";
import { useStores } from "netbank-shared/src/hooks";
import { Dropdown, Input } from "..";
import { PurposeOfLoan } from "netbank-shared/src/libs/models/Content/Enums";

export interface IApplicantInformationLabelsProps {
  purposeOfLoanLabel?: string;
  customPurposeOfLoanPlaceholder?: string;
}

interface IApplicantInformationProps {
  data: IApplicantInformationLabelsProps;
  applicant: ITopUpApplicantForm;
  setApplicant: (applicant: ITopUpApplicantForm) => void;
  showValidationErrors: boolean;
  isMainApplicant?: boolean;
}

export const ApplicantInformation = observer(
  ({ data, applicant, setApplicant, showValidationErrors, isMainApplicant }: IApplicantInformationProps) => {
    const { offerStore } = useStores();

    const currentPurposeOfLoan = offerStore.purposeOfLoanList.find((e) => e.value === offerStore.purposeOfLoan.value);

    const emailValidator =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const phoneValidator = /^((((0{2}?)|(\+){1})46)|0)7[\d]{8}/;

    let emailErrorText = tx("error.required");

    if (applicant.email.value && !applicant.email.isValid) {
      // If email is valid but applicant.email.isValid is false, main and co-applicant are using the same email
      const isValidEmail = emailValidator.test(applicant.email.value);
      emailErrorText = isValidEmail ? tx("error.conflictingEmail") : tx("error.incorrectEmail");
    }

    let phoneErrorText = tx("error.required");

    if (applicant.phone.value && !applicant.phone.isValid) {
      // If phone is valid but applicant.phone.isValid is false, main and co-applicant are using the same phone
      const isValidPhone = phoneValidator.test(applicant.phone.value);
      if (isValidPhone) {
        phoneErrorText = tx("error.conflictingPhone");
      } else if (applicant.phone.value.length > 13) {
        phoneErrorText = tx("error.tooLongPhone");
      } else if (applicant.phone.value.length < 8) {
        phoneErrorText = tx("error.tooShortPhone");
      } else {
        phoneErrorText = tx("error.invalidPhone");
      }
    }

    return (
      <div className={styles.wrapper}>
        <Input
          wrapperStyle={styles.inputWrapper}
          label={tx("topUp.emailLabel")}
          value={applicant.email.value}
          type="email"
          onChange={(e) => {
            setApplicant({
              ...applicant,
              email: {
                value: e.target.value,
                isValid: emailValidator.test(e.target.value),
              },
            });
          }}
          error={showValidationErrors && !applicant.email.isValid ? emailErrorText : undefined}
        />
        <Input
          wrapperStyle={styles.inputWrapper}
          label={tx("topUp.phoneLabel")}
          value={applicant.phone.value}
          type="tel"
          onChange={(e) => {
            const value = e.target.value.replace(/[^0-9+]/g, "");
            setApplicant({
              ...applicant,
              phone: {
                value,
                isValid: phoneValidator.test(value) && value.length >= 8 && value.length <= 13,
              },
            });
          }}
          error={showValidationErrors && !applicant.phone.isValid ? phoneErrorText : undefined}
        />
        {isMainApplicant && (
          <div className={styles.inputWrapper}>
            <Dropdown
              label={data.purposeOfLoanLabel || tx("topUp.purposeOfLoanLabel")}
              list={offerStore.purposeOfLoanList}
              onChange={(item) => {
                offerStore.purposeOfLoan = {
                  value: item.value,
                  isValid: true,
                };
              }}
              value={offerStore.purposeOfLoan.value || `${tx("misc.select")}...`}
              innerLabel={tx("misc.select")}
              error={
                offerStore.showTopUpValidationErrors && !offerStore.purposeOfLoan.isValid
                  ? tx("error.required")
                  : undefined
              }
            />
            {currentPurposeOfLoan?.enumValue === PurposeOfLoan.Other && (
              <Input
                className={styles.customInput}
                value={offerStore.customPurposeOfLoan.value}
                placeholder={data.customPurposeOfLoanPlaceholder || tx("topUp.customPurposeOfLoanPlaceholder")}
                onChange={(e) => {
                  offerStore.customPurposeOfLoan = {
                    value: e.target.value,
                    isValid: !!e.target.value,
                  };
                }}
                error={
                  offerStore.showTopUpValidationErrors && !offerStore.customPurposeOfLoan.isValid
                    ? tx("error.required")
                    : undefined
                }
              />
            )}
          </div>
        )}
      </div>
    );
  }
);
