import React from "react";
import { observer } from "mobx-react";
import { HtmlContent } from "../HtmlContent/HtmlContent";
import { Lottie } from "../Lottie/Lottie";
import loadingAnimationData from "~assets/lottie/loading.json";

interface IProcessingSpinnerProps {
  header: string;
  text: string;
}

export const ProcessingSpinner = observer(({ header, text }: IProcessingSpinnerProps) => {
  return (
    <div>
      <HtmlContent html={`<h1>${header}</h1>`} />
      {text && <HtmlContent html={text} />}
      <Lottie
        options={{
          animationData: loadingAnimationData,
        }}
        height={100}
        width={100}
      />
    </div>
  );
});
