import { observable, computed, action, makeObservable } from "mobx";
import dayjs, { Dayjs } from "dayjs";
import { tx } from "../libs/i18n";
import { EngagementsApi } from "../libs/api";
import { IEngagementTransaction, IGetTransactionsRequest, ITransactionDocument } from "../libs/models/Engagements";
import { base64ToBlob, createAutoDownloadTempLink } from "../libs/utils";

interface IDateRangePreset {
  index: number;
  label: string;
  value: string;
}

interface IAccountTransactions {
  accountNumber: string;
  transactions: IEngagementTransaction[];
}

export class TransactionStore {
  engagementsApi: EngagementsApi;

  constructor(engagementsApi: EngagementsApi) {
    this.engagementsApi = engagementsApi;
    makeObservable(this);
  }

  @observable
  accounts: IAccountTransactions[] = [];

  @observable
  nextToken?: string = undefined;

  @observable
  prevToken?: string = undefined;

  @observable
  dateRangeLabel: string | undefined;

  @observable
  dateRangePresets: IDateRangePreset[] = [
    { index: 0, label: tx("date.last30"), value: "last-30-days" },
    { index: 1, label: tx("date.last3Months"), value: "last-3-months" },
    { index: 2, label: tx("date.thisYear"), value: "this-year" },
  ];

  @observable
  search = "";

  @observable
  startDate?: Dayjs;

  @observable
  endDate?: Dayjs;

  @observable
  amountFrom = "";

  @observable
  amountTo = "";

  // Filter observables

  @observable
  filterStart?: Date;

  @observable
  filterEnd?: Date;

  @observable
  filterFrom = "";

  @observable
  filterTo = "";

  @observable
  fetchingTransactionDocument?: boolean = false;

  @observable
  fetchingTransactions?: boolean = false;

  setSearch = (search: string) => {
    this.search = search;
  };

  setStartDate = (date: Date) => {
    const startDate = dayjs(date.toString());
    this.startDate = startDate;
  };

  setEndDate = (date: Date) => {
    const endDate = dayjs(date.toString());
    this.endDate = endDate;
  };

  setAmountFrom = (amount: string) => {
    this.amountFrom = amount;
  };

  setAmountTo = (amount: string) => {
    this.amountTo = amount;
  };

  setFilterFrom = (amount: string) => {
    this.filterFrom = amount;
  };

  setFilterTo = (amount: string) => {
    this.filterTo = amount;
  };

  setFilterStart = (date: Date) => {
    this.filterStart = date;
    this.dateRangeLabel = undefined;
  };

  setFilterEnd = (date: Date) => {
    this.filterEnd = date;
    this.dateRangeLabel = undefined;
  };

  setFilterDateFromPreset = (selected: IDateRangePreset) => {
    this.dateRangeLabel = selected.label;
    const today = new Date();

    switch (selected.index) {
      case 0: {
        const lastMonth = new Date();
        lastMonth.setMonth(lastMonth.getMonth() - 1);
        this.filterStart = lastMonth;
        this.filterEnd = today;
        break;
      }
      case 1: {
        const threeMonthsAgo = new Date();
        threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
        this.filterStart = threeMonthsAgo;
        this.filterEnd = today;
        break;
      }
      case 2: {
        const fistDayOfYear = new Date(new Date().getFullYear(), 0, 1);
        this.filterStart = fistDayOfYear;
        this.filterEnd = today;
        break;
      }
      default:
        break;
    }
  };

  clearFilter = () => {
    this.amountFrom = "";
    this.amountTo = "";
    this.startDate = undefined;
    this.endDate = undefined;
    this.filterFrom = "";
    this.filterTo = "";
    this.filterStart = undefined;
    this.endDate = undefined;
    this.filterEnd = undefined;
    this.search = "";
    this.dateRangeLabel = undefined;
  };

  @computed
  get hasFilter(): boolean {
    return (
      this.amountFrom !== "" ||
      this.amountTo !== "" ||
      this.startDate !== undefined ||
      this.endDate !== undefined ||
      this.search !== ""
    );
  }

  fetchTransactions = async (accountNumber: string, reset = false) => {
    if (this.fetchingTransactions) {
      return;
    }

    this.fetchingTransactions = true;
    if (reset) {
      this.nextToken = "";
    } else {
      this.prevToken = this.nextToken;
    }
    const data: IGetTransactionsRequest = {
      accountNumber,
      nextToken: this.nextToken || "",
      amountFrom: this.amountFrom || "",
      amountTo: this.amountTo || "",
      dateFrom: this.startDate?.toString() || "",
      dateTo: this.endDate?.toString() || "",
    };

    const response = await this.engagementsApi.getTransactions(data);
    let transactions: IEngagementTransaction[] = [];
    if (response?.ok) {
      this.nextToken = response.data?.nextToken || "";
      transactions = response.data?.transactions || [];
    }

    const index = this.accounts.findIndex((a) => a.accountNumber === accountNumber);
    if (index > -1) {
      if (reset) {
        this.accounts[index].transactions = transactions;
      } else {
        this.accounts[index].transactions = this.accounts[index].transactions.concat(transactions);
      }
    } else {
      this.accounts?.push({
        accountNumber,
        transactions,
      });
    }
    if (reset) {
      this.prevToken = "";
    }
    this.fetchingTransactions = false;
  };

  getCurrentTransactions = (accountNumber: string) => {
    return this.accounts.find((a: IAccountTransactions) => a.accountNumber === accountNumber)?.transactions;
  };

  getFilteredTransactions = (accountNumber: string) => {
    if (!this.accounts) return null;
    return this.accounts
      .find((a: IAccountTransactions) => a.accountNumber === accountNumber)
      ?.transactions?.filter((t) => {
        const searchString = t.storeName || t.transactionText || "";
        if (this.search && searchString?.toLowerCase().indexOf(this.search.toLowerCase()) === -1) {
          return false;
        }

        return true;
      });
  };

  getTransactionDocument = async (accountNumber: string): Promise<ITransactionDocument | null> => {
    this.fetchingTransactionDocument = true;
    const response = await this.engagementsApi.getTransactionDocument({
      accountNumber,
      fromDate: this.startDate?.toString() || "",
      toDate: this.endDate?.toString() || "",
    });
    this.fetchingTransactionDocument = false;
    if (response?.ok && response.data) {
      const { content, fileName, mimeType } = response.data;
      if (content && fileName && mimeType) {
        const blob = base64ToBlob(content, mimeType);
        const objectUrl = URL.createObjectURL(blob);
        createAutoDownloadTempLink(fileName, objectUrl, true);
        return response.data;
      }
    }
    return null;
  };

  @action
  resetStore = () => {
    this.accounts = [];
    this.nextToken = undefined;
    this.prevToken = undefined;
    this.fetchingTransactionDocument = false;
    this.fetchingTransactions = false;
    this.clearFilter();
  };
}
