import { observer } from "mobx-react";
import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { useStores } from "netbank-shared/src/hooks";
import { tx } from "netbank-shared/src/libs/i18n";
import { PageType } from "netbank-shared/src/libs/models/Content/Enums";
import { IPage } from "netbank-shared/src/libs/models/Content/Page";
import { OfferCategory } from "netbank-shared/src/libs/models/Offer/Offer";
import { capitalize } from "netbank-shared/src/libs/utils";
import { DynamicLink } from "../DynamicLink/DynamicLink";
import styles from "./Breadcrumbs.scss";
import { getQueryParam } from "~utils/misc";
import { OfferStore } from "netbank-shared/src/stores/OfferStore";
import { CreditStore } from "netbank-shared/src/stores/CreditStore";
import { LoanStore } from "netbank-shared/src/stores/LoanStore";

interface IBreadcrumb {
  path: string;
  title: string;
  index?: number;
}

const getParamCrumbs = (
  getProductName: (nameId: [string, unknown] | undefined) => string,
  params: {},
  location: Location
): IBreadcrumb[] | undefined => {
  if (Object.keys(params).length < 1) return undefined;
  const paramCrumbs: IBreadcrumb[] = [];
  const paramEntries = Object.entries(params);
  const pathSegments = location.pathname.split("/")?.filter((el) => el !== "");
  let path = "";

  pathSegments.forEach((segment, index) => {
    path += `/${segment}`;
    const param = paramEntries.find((el) => el[1] === segment);
    if (param?.length === 2)
      paramCrumbs.push({
        index,
        path,
        title: capitalize(getProductName(param)),
      });
  });
  return paramCrumbs;
};

const getBreadcrumbByAccountNumberAndOfferCategory = (
  accountNumber: string,
  offerCategory: OfferCategory,
  creditStore: CreditStore,
  loanStore: LoanStore
) => {
  if (!accountNumber) return undefined;
  let path = "";
  let title = "";

  switch (offerCategory) {
    case OfferCategory.PPIC:
    case OfferCategory.PFMC:
    case OfferCategory.LINC: {
      const creditAccount = creditStore.creditAccounts?.find((a) => a.accountNumber === accountNumber);
      if (!creditAccount) return undefined;
      path = `${tx("routing.lang")}/${tx("routing.card")}/${accountNumber}`;
      title = creditAccount?.nameIB ?? "";
      break;
    }
    case OfferCategory.PPIL:
    case OfferCategory.PFML:
    case OfferCategory.AOL: {
      const loanAccounts = loanStore.loanAccounts?.find((a) => a.accountNumber === accountNumber);
      if (!loanAccounts) return undefined;
      path = `${tx("routing.lang")}/${tx("routing.privateLoan")}/${accountNumber}`;
      title = loanAccounts?.nameIB ?? "";
      break;
    }
    default:
      break;
  }

  return {
    path,
    title,
  };
};

const generateOfferBreadcrumbs = (offerStore: OfferStore, creditStore: CreditStore, loanStore: LoanStore) => {
  const breadcrumbs: IBreadcrumb[] = [];
  const offerCategory = offerStore.currentOffer?.offerCategory;
  const { currentOffer } = offerStore;
  const currentAccountNumber = currentOffer?.accountNumber;
  const accountBreadcrumb =
    currentAccountNumber &&
    offerCategory &&
    getBreadcrumbByAccountNumberAndOfferCategory(
      currentAccountNumber,
      OfferCategory[offerCategory],
      creditStore,
      loanStore
    );

  const accountNumberQueryParam = getQueryParam(window.location.search, "accountNumber");

  const isPendingTopUpApplication =
    accountNumberQueryParam && offerStore.getPendingApplicationByAccountNumber(accountNumberQueryParam);

  switch (offerCategory) {
    case OfferCategory.PPIC:
    case OfferCategory.PFMC:
    case OfferCategory.LINC: {
      breadcrumbs.push({
        path: `${tx("routing.lang")}/${tx("routing.card")}`,
        title: capitalize(tx("routing.card")),
      });
      break;
    }
    case OfferCategory.PPIL:
    case OfferCategory.PFML: {
      breadcrumbs.push({
        path: `${tx("routing.lang")}/${tx("routing.privateLoan")}`,
        title: capitalize(tx("routing.privateLoan")),
      });
      break;
    }
    default:
      break;
  }
  if (accountBreadcrumb) breadcrumbs.push(accountBreadcrumb);
  if (offerCategory) {
    breadcrumbs.push({
      path: `${window.location.pathname}${window.location.search}`,
      title: tx(`offers.${offerCategory}`) ?? "",
    });
  }

  if (isPendingTopUpApplication) {
    breadcrumbs.push({
      path: `${window.location.pathname}${window.location.search}`,
      title: tx(`topUp.header`) ?? "",
    });
  }

  return breadcrumbs;
};

export const Breadcrumbs = observer(() => {
  const { contentStore, creditStore, leasingStore, loanStore, depositStore, offerStore } = useStores();
  const params = useParams();
  const location = useLocation();

  const generateBreadcrumbs = (
    getProductName: (nameId: [string, unknown] | undefined) => string,
    currentPage?: IPage
  ) => {
    const active = currentPage?.menuItems?.find((item) => item.active);
    const menuItemsHasCurrentPath = currentPage?.menuItems?.find(
      (item) =>
        item.path === window.location.pathname || item.children?.find((c) => c.path === window.location.pathname)
    );

    const isHome = (path?: string) => {
      if (typeof path === "undefined") return false;
      return path?.toLowerCase()?.match(`^(/${tx("routing.lang")}|/${tx("routing.lang")}/|/|)$`) !== null;
    };

    const home = currentPage?.menuItems?.find((item) => isHome(item.path));
    let breadcrumbs: IBreadcrumb[] = [];
    let currentItem = active;

    const paramCrumbs = getParamCrumbs(getProductName, params, location);

    if (home?.path) {
      breadcrumbs.push({
        path: home?.path ?? "",
        title: home?.name ?? "",
      });
    }

    if (currentItem !== undefined) {
      do {
        if (!isHome(currentItem.path)) {
          breadcrumbs.push({
            path: currentItem.path ?? "",
            title: currentItem.name ?? "",
          });
        }
        currentItem = currentItem?.children?.find((el) => el?.active);
      } while (currentItem?.active);

      if (
        !contentStore.isTemplatePage &&
        !menuItemsHasCurrentPath &&
        currentItem?.path !== window.location.pathname &&
        !isHome(window.location.pathname)
      ) {
        // Adds currentPage as last element in breadcrumbs
        // if current path is not in menuItems && not template page
        breadcrumbs.push({
          path: window.location.pathname ?? "",
          title: currentPage?.name ?? "",
        });
      }

      if (paramCrumbs !== undefined && paramCrumbs?.length > 0) {
        /* eslint-disable array-callback-return */
        paramCrumbs?.map((crumb) => {
          if (crumb.index !== undefined) breadcrumbs.splice(crumb.index, 0, crumb);
        });
      }
    } else if (currentPage) {
      if (
        (currentPage.contentType?.[1] === PageType.OffersPage && offerStore.currentOffer) ||
        currentPage.contentType?.[1] === PageType.LoanTopUpPage
      ) {
        breadcrumbs = breadcrumbs.concat(generateOfferBreadcrumbs(offerStore, creditStore, loanStore));
      } else {
        if (currentPage.parentLink?.url && currentPage.parentName) {
          breadcrumbs.push({
            path: currentPage.parentLink?.url ?? "",
            title: currentPage.parentName ?? "",
          });
        }
        breadcrumbs.push({
          path: window.location.pathname ?? "",
          title: currentPage.name ?? "",
        });
      }
    }

    return breadcrumbs?.filter((el, i) => breadcrumbs.findIndex((e) => el.title === e.title) === i);
  };

  const getProductName = (nameId: [string, unknown] | undefined): string => {
    const { creditAccounts } = creditStore;
    const { leasingAccounts } = leasingStore;
    const { allLoanAccounts } = loanStore;
    const { depositAccounts } = depositStore;
    let name = nameId?.[0].replace(/id$/i, "") || "";
    switch (name) {
      case "card": {
        const creditAccount = creditAccounts?.find((account) => account.accountNumber === nameId?.[1]);
        name = creditAccount?.name || name;
        break;
      }
      case "leasing":
        name = leasingAccounts?.find((account) => account.accountNumber === nameId?.[1])?.nameIB || name;
        break;
      case "loan":
        name = allLoanAccounts?.find((account) => account.accountNumber === nameId?.[1])?.nameIB || name;
        break;
      case "deposit":
        name = depositAccounts?.find((account) => account.accountNumber === nameId?.[1])?.nameIB || name;
        break;
      default:
        break;
    }
    return name;
  };

  const breadcrumbs = generateBreadcrumbs(getProductName, contentStore?.currentPage);

  return (
    <div className={styles.wrapper}>
      {breadcrumbs?.map((crumb: IBreadcrumb, i: number) => (
        <DynamicLink to={crumb.path} className={styles.link} key={`breadcrumb-${i}-${crumb.path}`}>
          {crumb.title}
        </DynamicLink>
      ))}
    </div>
  );
});
