import dayjs from "dayjs";
import { IOfferProjectionResponse } from "libs/models/Offer/Offer";

export const mockPpilProjection: IOfferProjectionResponse = {
  status: 200,
  ppilProjection: {
    premiumAmount: 100,
    startDate: dayjs().endOf("month").toDate(),
  },
};

export const mockPfmlProjection: IOfferProjectionResponse = {
  status: 200,
  pfmlProjection: {
    newMonthlyAmount: 500,
    newNumberOfTerms: 144,
    loanBalanceAmount: 68141,
    modificationDate: dayjs().endOf("month").toDate(),
  },
};
