import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import styles from "./SavedAccountForm.scss";
import rootStyles from "~views/pages/Root.scss";
import { Button, Input } from "~views/shared";
import { tx } from "netbank-shared/src/libs/i18n";
import {
  clearingStartsWithEight,
  getClearingNumberMaxLength,
  getClearingNumberMinLength,
  getValidAccountNumberLength,
} from "netbank-shared/src/libs/utils/accountNumber";
import { Colors, ForeignAccountValidationError } from "netbank-shared/src/libs/models/Content/Enums";
import { ITransferAccountFields } from "netbank-shared/src/libs/models/Transfer/Transfer";
import errorIcon from "~assets/error.svg";
import { TrackingAction, TrackingCategory } from "netbank-shared/src/libs/models/Tracking";
import { useStores } from "netbank-shared/src/hooks";
import { IAccountNumberValidation } from "netbank-shared/src/libs/models/Content/AccountNumberValidation";

interface ISavedAccountFormProps {
  handleSavedAccount: () => void; // Either create or update function
  account: ITransferAccountFields | undefined;
  setAccount: (account: ITransferAccountFields) => void;
  loading: boolean;
  clearingNumberLabel?: string;
  accountNumberLabel?: string;
  accountNameLabel?: string;
  clearingNumberInformation?: string;
  accountNumberInformation?: string;
  clearingStartsWithEightThenFiveDigitsError?: string;
  saveLabel?: string;
  extraClasses?: string[];
  updating?: boolean;
  error?: string;
  header?: string;
}

export const SavedAccountForm = observer(
  ({
    handleSavedAccount,
    account,
    setAccount,
    loading,
    clearingNumberLabel,
    accountNumberLabel,
    accountNameLabel,
    clearingNumberInformation,
    clearingStartsWithEightThenFiveDigitsError,
    accountNumberInformation,
    saveLabel,
    extraClasses,
    updating,
    error,
    header,
  }: ISavedAccountFormProps) => {
    const [invalidClearingNumber, setInvalidClearingNumber] = useState(false);
    const [invalidAccountNumber, setInvalidAccountNumber] = useState(false);
    const [invalidDisplayName, setInvalidDisplayName] = useState(false);
    const { contentStore, transferStore } = useStores();
    const { createSavedToAccountValidationError } = transferStore;
    const accountValidationData: IAccountNumberValidation = contentStore.accountValidationData;
    useEffect(() => {
      if (createSavedToAccountValidationError === ForeignAccountValidationError.InvalidClearing ||
        createSavedToAccountValidationError === ForeignAccountValidationError.UnrecognizedBank
      ) {
        setInvalidClearingNumber(true);
      }

      if (createSavedToAccountValidationError === ForeignAccountValidationError.InvalidAccountNumber) {
        setInvalidAccountNumber(true);
      }
    }, [createSavedToAccountValidationError])

    const onClick = async () => {
      if (account) {
        const checkDisplayName = account.displayName.length > 0 && account.displayName.length <= 25;
        setInvalidDisplayName(!checkDisplayName);

        if (!checkDisplayName) {
          return;
        }

        if (contentStore.strictAccountValidationEnabled) {
          handleSavedAccount();
          return;
        }

        const checkClearingNumber =
          account.clearingNumber.match(/^\d{0,}$/) &&
          account.clearingNumber.length <= getClearingNumberMaxLength(account.clearingNumber) &&
          account.clearingNumber.length >= getClearingNumberMinLength(account.clearingNumber);

        const checkAccountNumber = account.accountNumber.length === getValidAccountNumberLength(account.clearingNumber);

        setInvalidClearingNumber(!checkClearingNumber);
        setInvalidAccountNumber(!checkAccountNumber);

        if (!checkClearingNumber || !checkAccountNumber) {
          return;
        }
        handleSavedAccount();
      }
    };

    let invalidClearingText = "";
    if (clearingStartsWithEight(account?.clearingNumber) && account?.clearingNumber.length !== 5) {
      invalidClearingText = clearingStartsWithEightThenFiveDigitsError || tx("transfer.clearingStartsWithEightThenFiveDigitsError");
    } else {
      invalidClearingText = accountValidationData?.invalidClearingNumber || tx("transfer.clearingNumberError");
    }

    let classes = [styles.newAccount, styles.bottomPadding];

    if (extraClasses) {
      classes = classes.concat(extraClasses);
    }
    if (!account) {
      return null;
    }
    const formatNumber = (number: string) => number.replace(/\D+/g, "");
    const setClearing = (clearing: string) => {
      setInvalidClearingNumber(false);
      transferStore.createSavedToAccountValidationError = undefined;
      setAccount({
        ...account,
        clearingNumber: formatNumber(clearing),
      });
    };

    return (
      <div>
        {header && <h2>{header}</h2>}
        <div className={classes.join(" ")}>
          <div className={styles.flexContainer}>
            <Input
              label={clearingNumberLabel || tx("transfer.clearingNumber")}
              infoPopover={{
                popoverTitle: clearingNumberLabel || tx("transfer.clearingNumber"),
                content: clearingNumberInformation,
              }}
              value={account.clearingNumber}
              minLength={getClearingNumberMinLength(account.clearingNumber)}
              maxLength={getClearingNumberMaxLength(account.clearingNumber)}
              onPaste={(event) => {
                //Prevents pasted Swedbank clearing from being clipped
                const clearing = formatNumber((event.clipboardData || window.Clipboard).getData("text"));
                const maxLength = getClearingNumberMaxLength(clearing);
                const slicedClearing = clearing.substring(0, maxLength);

                setClearing(slicedClearing);
              }}
              onChange={(e) => {
                setClearing(e.target.value);
              }}
              wrapperStyle={styles.input}
              disabled={updating}
              error={(invalidClearingNumber && invalidClearingText) || undefined}
            />
            <Input
              label={accountNumberLabel || tx("transfer.accountNumber")}
              infoPopover={{
                popoverTitle: accountNumberLabel || tx("transfer.accountNumber"),
                content: accountNumberInformation,
              }}
              value={account.accountNumber}
              maxLength={12}
              onChange={(e) => {
                setInvalidAccountNumber(false);
                transferStore.createSavedToAccountValidationError = undefined;
                setAccount({
                  ...account,
                  accountNumber: formatNumber(e.target.value),
                });
              }}
              wrapperStyle={styles.input}
              disabled={updating}
              error={(invalidAccountNumber && (accountValidationData?.invalidAccountNumber || tx("transfer.accountNumberError"))) || undefined}
            />
          </div>
          <div className={[styles.flexContainer, styles.noMargin].join(" ")}>
            <Input
              label={accountNameLabel || tx("misc.name")}
              value={account.displayName}
              onChange={(e) => {
                setInvalidDisplayName(false);
                transferStore.createSavedToAccountValidationError = undefined;
                setAccount({
                  ...account,
                  displayName: e.target.value.replace(/[^a-öA-Ö\s]/gi, ""),
                });
              }}
              wrapperStyle={styles.input}
              error={(invalidDisplayName && tx("transfer.displayNameError")) || undefined}
            />
          </div>
          {error && (
            <div className={[rootStyles.error, styles.error].join(" ")}>
              <img src={errorIcon} alt="error-icon" />
              <span>{error}</span>
            </div>
          )}
          <div className={styles.flexContainer}>
            <div className={styles.emptyCell} />
            <div className={styles.buttonWrapper}>
              <Button
                loading={loading}
                fullWidth
                title={saveLabel || tx("misc.save")}
                onClick={onClick}
                color={Colors.White}
                bordered
                borderColor={Colors.Tundora}
                trackingCategory={TrackingCategory.Transfers}
                trackingAction={TrackingAction.ChangeRecipient}
                centered
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
);
