import { IMultiSelectOption } from "./MultiSelect/MultiSelectOption";

export const citizenshipsList: IMultiSelectOption[] = [
  {
    label: "Afghanistan",
    value: "Afghanistan",
  },
  {
    label: "Åland",
    value: "Åland Islands",
  },
  {
    label: "Albanien",
    value: "Albania",
  },
  {
    label: "Algeriet",
    value: "Algeria",
  },
  {
    label: "Amerikanska Samoa",
    value: "American Samoa",
  },
  {
    label: "Andorra",
    value: "Andorra",
  },
  {
    label: "Angola",
    value: "Angola",
  },
  {
    label: "Anguilla",
    value: "Anguilla",
  },
  {
    label: "Antarktis",
    value: "Antarctica",
  },
  {
    label: "Antigua och Barbuda",
    value: "Antigua and Barbuda",
  },
  {
    label: "Argentina",
    value: "Argentina",
  },
  {
    label: "Armenien",
    value: "Armenia",
  },
  {
    label: "Aruba",
    value: "Aruba",
  },
  {
    label: "Australien",
    value: "Australia",
  },
  {
    label: "Österrike",
    value: "Austria",
  },
  {
    label: "Azerbajdzjan",
    value: "Azerbaijan",
  },
  {
    label: "Bahamas",
    value: "Bahamas",
  },
  {
    label: "Bahrain",
    value: "Bahrain",
  },
  {
    label: "Bangladesh",
    value: "Bangladesh",
  },
  {
    label: "Barbados",
    value: "Barbados",
  },
  {
    label: "Vitryssland",
    value: "Belarus",
  },
  {
    label: "Belgien",
    value: "Belgium",
  },
  {
    label: "Belize",
    value: "Belize",
  },
  {
    label: "Benin",
    value: "Benin",
  },
  {
    label: "Bermuda",
    value: "Bermuda",
  },
  {
    label: "Bhutan",
    value: "Bhutan",
  },
  {
    label: "Bolivia",
    value: "Bolivia, Plurinational State of",
  },
  {
    label: "Karibiska Nederländerna",
    value: "Bonaire, Sint Eustatius and Saba",
  },
  {
    label: "Bosnien och Hercegovina",
    value: "Bosnia and Herzegovina",
  },
  {
    label: "Botswana",
    value: "Botswana",
  },
  {
    label: "Bouvetön",
    value: "Bouvet Island",
  },
  {
    label: "Brasilien",
    value: "Brazil",
  },
  {
    label: "Brittiska territoriet i Indiska Oceanen",
    value: "British Indian Ocean Territory",
  },
  {
    label: "Brunei",
    value: "Brunei Darussalam",
  },
  {
    label: "Bulgarien",
    value: "Bulgaria",
  },
  {
    label: "Burkina Faso",
    value: "Burkina Faso",
  },
  {
    label: "Burundi",
    value: "Burundi",
  },
  {
    label: "Kambodja",
    value: "Cambodia",
  },
  {
    label: "Kamerun",
    value: "Cameroon",
  },
  {
    label: "Kanada",
    value: "Canada",
  },
  {
    label: "Kap Verde",
    value: "Cape Verde",
  },
  {
    label: "Caymanöarna",
    value: "Cayman Islands",
  },
  {
    label: "Centralafrikanska republiken",
    value: "Central African Republic",
  },
  {
    label: "Tchad",
    value: "Chad",
  },
  {
    label: "Chile",
    value: "Chile",
  },
  {
    label: "Kina",
    value: "China",
  },
  {
    label: "Julön",
    value: "Christmas Island",
  },
  {
    label: "Kokosöarna",
    value: "Cocos (Keeling) Islands",
  },
  {
    label: "Colombia",
    value: "Colombia",
  },
  {
    label: "Komorerna",
    value: "Comoros",
  },
  {
    label: "Kongo-Brazzaville",
    value: "Congo",
  },
  {
    label: "Demokratiska republiken Kongo",
    value: "Congo, the Democratic Republic of the",
  },
  {
    label: "Cooköarna",
    value: "Cook Islands",
  },
  {
    label: "Costa Rica",
    value: "Costa Rica",
  },
  {
    label: "Elfenbenskusten",
    value: "Cote d’Ivoire",
  },
  {
    label: "Kroatien",
    value: "Croatia",
  },
  {
    label: "Kuba",
    value: "Cuba",
  },
  {
    label: "Curaçao",
    value: "Curacao",
  },
  {
    label: "Cypern",
    value: "Cyprus",
  },
  {
    label: "Tjeckien",
    value: "Czech Republic",
  },
  {
    label: "Danmark",
    value: "Denmark",
    priority: 1,
  },
  {
    label: "Djibouti",
    value: "Djibouti",
  },
  {
    label: "Dominica",
    value: "Dominica",
  },
  {
    label: "Dominikanska republiken",
    value: "Dominican Republic",
  },
  {
    label: "Ecuador",
    value: "Ecuador",
  },
  {
    label: "Egypten",
    value: "Egypt",
  },
  {
    label: "El Salvador",
    value: "El Salvador",
  },
  {
    label: "Ekvatorialguinea",
    value: "Equatorial Guinea",
  },
  {
    label: "Eritrea",
    value: "Eritrea",
  },
  {
    label: "Estland",
    value: "Estonia",
  },
  {
    label: "Etiopien",
    value: "Ethiopia",
  },
  {
    label: "Falklandsöarna",
    value: "Falkland Islands (Malvinas)",
  },
  {
    label: "Färöarna",
    value: "Faroe Islands",
  },
  {
    label: "Fiji",
    value: "Fiji",
  },
  {
    label: "Finland",
    value: "Finland",
    priority: 1,
  },
  {
    label: "Frankrike",
    value: "France",
  },
  {
    label: "France métropolitaine (Frankrike; europeiska delen)",
    value: "France, Metropolitan",
  },
  {
    label: "Franska Guyana",
    value: "French Guiana",
  },
  {
    label: "Franska Polynesien",
    value: "French Polynesia",
  },
  {
    label: "Franska södra territorierna",
    value: "French Southern Territories",
  },
  {
    label: "Gabon",
    value: "Gabon",
  },
  {
    label: "Gambia",
    value: "Gambia",
  },
  {
    label: "Georgien",
    value: "Georgia",
  },
  {
    label: "Tyskland",
    value: "Germany",
  },
  {
    label: "Ghana",
    value: "Ghana",
  },
  {
    label: "Gibraltar",
    value: "Gibraltar",
  },
  {
    label: "Grekland",
    value: "Greece",
  },
  {
    label: "Grönland",
    value: "Greenland",
  },
  {
    label: "Grenada",
    value: "Grenada",
  },
  {
    label: "Guadeloupe",
    value: "Guadeloupe",
  },
  {
    label: "Guam",
    value: "Guam",
  },
  {
    label: "Guatemala",
    value: "Guatemala",
  },
  {
    label: "Guernsey",
    value: "Guernsey",
  },
  {
    label: "Guinea",
    value: "Guinea",
  },
  {
    label: "Guinea Bissau",
    value: "Guinea-Bissau",
  },
  {
    label: "Guyana",
    value: "Guyana",
  },
  {
    label: "Haiti",
    value: "Haiti",
  },
  {
    label: "Heard- och McDonaldöarna",
    value: "Heard Island and McDonald Islands",
  },
  {
    label: "Vatikanstaten",
    value: "Holy See (Vatican City State)",
  },
  {
    label: "Honduras",
    value: "Honduras",
  },
  {
    label: "Hongkong",
    value: "Hong Kong",
  },
  {
    label: "Ungern",
    value: "Hungary",
  },
  {
    label: "Island",
    value: "Iceland",
  },
  {
    label: "Indien",
    value: "India",
  },
  {
    label: "Indonesien",
    value: "Indonesia",
  },
  {
    label: "Iran",
    value: "Iran, Islamic Republic of",
  },
  {
    label: "Irak",
    value: "Iraq",
  },
  {
    label: "Irland",
    value: "Ireland",
  },
  {
    label: "Isle of Man",
    value: "Isle of Man",
  },
  {
    label: "Israel",
    value: "Israel",
  },
  {
    label: "Italien",
    value: "Italy",
  },
  {
    label: "Jamaica",
    value: "Jamaica",
  },
  {
    label: "Japan",
    value: "Japan",
  },
  {
    label: "Jersey",
    value: "Jersey",
  },
  {
    label: "Jordanien",
    value: "Jordan",
  },
  {
    label: "Kazakstan",
    value: "Kazakhstan",
  },
  {
    label: "Kenya",
    value: "Kenya",
  },
  {
    label: "Kiribati",
    value: "Kiribati",
  },
  {
    label: "Nordkorea",
    value: "Korea, Democratic People’s Republic of",
  },
  {
    label: "Sydkorea",
    value: "Korea, Republic of",
  },
  {
    label: "Kuwait",
    value: "Kuwait",
  },
  {
    label: "Kirgizistan",
    value: "Kyrgyzstan",
  },
  {
    label: "Laos",
    value: "Lao People’s Democratic Republic",
  },
  {
    label: "Lettland",
    value: "Latvia",
  },
  {
    label: "Libanon",
    value: "Lebanon",
  },
  {
    label: "Lesotho",
    value: "Lesotho",
  },
  {
    label: "Liberia",
    value: "Liberia",
  },
  {
    label: "Libyen",
    value: "Libyan Arab Jamahiriya",
  },
  {
    label: "Liechtenstein",
    value: "Liechtenstein",
  },
  {
    label: "Litauen",
    value: "Lithuania",
  },
  {
    label: "Luxemburg",
    value: "Luxembourg",
  },
  {
    label: "Macau",
    value: "Macao",
  },
  {
    label: "Makedonien",
    value: "Macedonia, the former Yugoslav Republic of",
  },
  {
    label: "Madagaskar",
    value: "Madagascar",
  },
  {
    label: "Malawi",
    value: "Malawi",
  },
  {
    label: "Malaysia",
    value: "Malaysia",
  },
  {
    label: "Maldiverna",
    value: "Maldives",
  },
  {
    label: "Mali",
    value: "Mali",
  },
  {
    label: "Malta",
    value: "Malta",
  },
  {
    label: "Marshallöarna",
    value: "Marshall Islands",
  },
  {
    label: "Martinique",
    value: "Martinique",
  },
  {
    label: "Mauretanien",
    value: "Mauritania",
  },
  {
    label: "Mauritius",
    value: "Mauritius",
  },
  {
    label: "Mayotte",
    value: "Mayotte",
  },
  {
    label: "Mexiko",
    value: "Mexico",
  },
  {
    label: "Mikronesiska federationen",
    value: "Micronesia, Federated States of",
  },
  {
    label: "Moldavien",
    value: "Moldova, Republic of",
  },
  {
    label: "Monaco",
    value: "Monaco",
  },
  {
    label: "Mongoliet",
    value: "Mongolia",
  },
  {
    label: "Montenegro",
    value: "Montenegro",
  },
  {
    label: "Montserrat",
    value: "Montserrat",
  },
  {
    label: "Marocko",
    value: "Morocco",
  },
  {
    label: "Moçambique",
    value: "Mozambique",
  },
  {
    label: "Burma",
    value: "Myanmar",
  },
  {
    label: "Namibia",
    value: "Namibia",
  },
  {
    label: "Nauru",
    value: "Nauru",
  },
  {
    label: "Nepal",
    value: "Nepal",
  },
  {
    label: "Nederländerna",
    value: "Netherlands",
  },
  {
    label: "Nederländska Antillerna",
    value: "Netherlands Antilles",
  },
  {
    label: "Nya Kaledonien",
    value: "New Caledonia",
  },
  {
    label: "Nya Zeeland",
    value: "New Zealand",
  },
  {
    label: "Nicaragua",
    value: "Nicaragua",
  },
  {
    label: "Niger",
    value: "Niger",
  },
  {
    label: "Nigeria",
    value: "Nigeria",
  },
  {
    label: "Niue",
    value: "Niue",
  },
  {
    label: "Norfolkön",
    value: "Norfolk Island",
  },
  {
    label: "Nordmarianerna",
    value: "Northern Mariana Islands",
  },
  {
    label: "Norge",
    value: "Norway",
    priority: 1,
  },
  {
    label: "Oman",
    value: "Oman",
  },
  {
    label: "Pakistan",
    value: "Pakistan",
  },
  {
    label: "Palau",
    value: "Palau",
  },
  {
    label: "Palestina",
    value: "Palestine",
  },
  {
    label: "Panama",
    value: "Panama",
  },
  {
    label: "Papua Nya Guinea",
    value: "Papua New Guinea",
  },
  {
    label: "Paraguay",
    value: "Paraguay",
  },
  {
    label: "Peru",
    value: "Peru",
  },
  {
    label: "Filippinerna",
    value: "Philippines",
  },
  {
    label: "Pitcairnöarna",
    value: "Pitcairn",
  },
  {
    label: "Polen",
    value: "Poland",
  },
  {
    label: "Portugal",
    value: "Portugal",
  },
  {
    label: "Puerto Rico",
    value: "Puerto Rico",
  },
  {
    label: "Qatar",
    value: "Qatar",
  },
  {
    label: "Réunion",
    value: "Reunion",
  },
  {
    label: "Rumänien",
    value: "Romania",
  },
  {
    label: "Ryssland",
    value: "Russian Federation",
  },
  {
    label: "Rwanda",
    value: "Rwanda",
  },
  {
    label: "Saint-Barthélemy",
    value: "Saint Barthélemy",
  },
  {
    label: "Sankta Helena",
    value: "Saint Helena, Ascension and Tristan da Cunha",
  },
  {
    label: "Saint Kitts och Nevis",
    value: "Saint Kitts and Nevis",
  },
  {
    label: "Saint Lucia",
    value: "Saint Lucia",
  },
  {
    label: "Saint Martin",
    value: "Saint Martin (French part)",
  },
  {
    label: "Saint-Pierre och Miquelon",
    value: "Saint Pierre and Miquelon",
  },
  {
    label: "Saint Vincent och Grenadinerna",
    value: "Saint Vincent and the Grenadines",
  },
  {
    label: "Samoa",
    value: "Samoa",
  },
  {
    label: "San Marino",
    value: "San Marino",
  },
  {
    label: "São Tomé och Príncipe",
    value: "Sao Tome and Principe",
  },
  {
    label: "Saudiarabien",
    value: "Saudi Arabia",
  },
  {
    label: "Senegal",
    value: "Senegal",
  },
  {
    label: "Serbien",
    value: "Serbia",
  },
  {
    label: "Seychellerna",
    value: "Seychelles",
  },
  {
    label: "Sierra Leone",
    value: "Sierra Leone",
  },
  {
    label: "Singapore",
    value: "Singapore",
  },
  {
    label: "Sint Maarten",
    value: "Sint Maarten (Dutch part)",
  },
  {
    label: "Slovakien",
    value: "Slovakia",
  },
  {
    label: "Slovenien",
    value: "Slovenia",
  },
  {
    label: "Salomonöarna",
    value: "Solomon Islands",
  },
  {
    label: "Somalia",
    value: "Somalia",
  },
  {
    label: "Sydafrika",
    value: "South Africa",
  },
  {
    label: "Sydgeorgien och Sydsandwichöarna",
    value: "South Georgia and the South Sandwich Islands",
  },
  {
    label: "Sydsudan",
    value: "South Sudan",
  },
  {
    label: "Spanien",
    value: "Spain",
  },
  {
    label: "Sri Lanka",
    value: "Sri Lanka",
  },
  {
    label: "Sudan",
    value: "Sudan",
  },
  {
    label: "Surinam",
    value: "Suriname",
  },
  {
    label: "Svalbard och Jan Mayen",
    value: "Svalbard and Jan Mayen",
  },
  {
    label: "Swaziland",
    value: "Swaziland",
  },
  {
    label: "Sverige",
    value: "Sweden",
    priority: 1,
  },
  {
    label: "Schweiz",
    value: "Switzerland",
  },
  {
    label: "Syrien",
    value: "Syrian Arab Republic",
  },
  {
    label: "Taiwan",
    value: "Taiwan",
  },
  {
    label: "Tadzjikistan",
    value: "Tajikistan",
  },
  {
    label: "Tanzania",
    value: "Tanzania, United Republic of",
  },
  {
    label: "Thailand",
    value: "Thailand",
  },
  {
    label: "Östtimor a.k.a. Timor-Leste",
    value: "Timor-Leste",
  },
  {
    label: "Togo",
    value: "Togo",
  },
  {
    label: "Tokelauöarna",
    value: "Tokelau",
  },
  {
    label: "Tonga",
    value: "Tonga",
  },
  {
    label: "Trinidad och Tobago",
    value: "Trinidad and Tobago",
  },
  {
    label: "Tunisien",
    value: "Tunisia",
  },
  {
    label: "Turkiet",
    value: "Turkey",
  },
  {
    label: "Turkmenistan",
    value: "Turkmenistan",
  },
  {
    label: "Turks- och Caicosöarna",
    value: "Turks and Caicos Islands",
  },
  {
    label: "Tuvalu",
    value: "Tuvalu",
  },
  {
    label: "Uganda",
    value: "Uganda",
  },
  {
    label: "Ukraina",
    value: "Ukraine",
  },
  {
    label: "Förenade Arabemiraten",
    value: "United Arab Emirates",
  },
  {
    label: "Storbritannien",
    value: "United Kingdom",
  },
  {
    label: "United Nations Mission in Kosovo",
    value: "United Nations Interim Administration Mission in Kosovo",
  },
  {
    label: "USA",
    value: "United States",
  },
  {
    label: "USA:s yttre öar",
    value: "United States Minor Outlying Islands",
  },
  {
    label: "Uruguay",
    value: "Uruguay",
  },
  {
    label: "Uzbekistan",
    value: "Uzbekistan",
  },
  {
    label: "Vanuatu",
    value: "Vanuatu",
  },
  {
    label: "Venezuela",
    value: "Venezuela, Bolivarian Republic of",
  },
  {
    label: "Vietnam",
    value: "Vietnam",
  },
  {
    label: "Brittiska Jungfruöarna",
    value: "Virgin Islands, British",
  },
  {
    label: "Amerikanska Jungfruöarna",
    value: "Virgin Islands, U.S.",
  },
  {
    label: "Wallis- och Futunaöarna",
    value: "Wallis and Futuna",
  },
  {
    label: "Västsahara",
    value: "Western Sahara",
  },
  {
    label: "Jemen",
    value: "Yemen",
  },
  {
    label: "Zambia",
    value: "Zambia",
  },
  {
    label: "Zimbabwe",
    value: "Zimbabwe",
  },
];
