import { IAddTopUpResponse, IUpdateApplicationResponse } from "../../../models/Offer/Offer";

export const mockAddTopUpSuccess: IAddTopUpResponse = {
  applicationId: "335da88f-61bd-40c7-bd13-5abbdf409b74",
  title: "",
};

export const mockUpdateApplicationSuccess: IUpdateApplicationResponse = {
  applicationId: "335da88f-61bd-40c7-bd13-5abbdf409b74",
};
