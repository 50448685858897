export function getLangInUrl(url: string) {
  // Assumes url structure of /lang/page/*
  return url.split("/")[1];
}

export function setLangInUrl(url: string, lang: string) {
  // Assumes url structure of /lang/page/*
  const urlArray = url.split("/");
  urlArray[1] = lang;
  return urlArray.join("/");
}
