import { ValidatorFuncProps, ValidatorOptionProps } from "./ValidatorProps";
import { tx } from "../i18n";

export const AccountNameValidator: ValidatorFuncProps = (value: string, { errorName }: ValidatorOptionProps) => {
  let valid = true;
  let error = "";

  const minLength = 1;
  const maxLength = 20;

  const regexp = /^[A-Za-zäöåÄÖÅæøåÆØÅ0-9 _]*[A-Za-zäöåÄÖÅæøåÆØÅ0-9][A-Za-zäöåÄÖÅæøåÆØÅ0-9 _]*$/;

  if (!value) {
    valid = false;
    error = tx("validators.required", {
      name: errorName,
    });
  } else if (value.length < minLength || value.length > maxLength) {
    valid = false;
    error = tx("validators.accountName", {
      name: errorName,
    });
  } else if (!regexp.test(value)) {
    valid = false;
    error = tx("validators.accountName", {
      name: errorName,
    });
  }

  return { valid, error };
};
