import { ValidatorFuncProps, ValidatorOptionProps } from "./ValidatorProps";
import { tx } from "../i18n";

export interface INumberValidatorOptions extends ValidatorOptionProps {
  minValue?: number;
  maxValue?: number;
  minDigits?: number;
  maxDigits?: number;
  minWarningValue?: number;
  maxWarningValue?: number;
  errorName?: string;
  errorText?: string;
}

export const NumberValidator: ValidatorFuncProps = (
  value: string,
  {
    minValue,
    maxValue,
    minDigits,
    maxDigits,
    minWarningValue,
    maxWarningValue,
    errorName,
    errorText,
    key,
  }: INumberValidatorOptions
) => {
  const normStrValue = (value || "").replace(/\D/g, "");
  const digits = normStrValue.length;
  const normIntValue = parseInt(normStrValue, 10);

  let valid = true;
  let error = "";
  let warning = "";

  if (Number.isNaN(normIntValue)) {
    valid = false;
    error =
      errorText ||
      tx("validators.missing", {
        name: errorName,
      });
  } else if (minValue && normIntValue < minValue) {
    valid = false;
    error =
      errorText ||
      tx("validators.number_low", {
        value: Number(minValue).toLocaleString("sv"),
        name: errorName,
      });
  } else if (maxValue && normIntValue > maxValue) {
    valid = false;
    error =
      errorText ||
      tx("validators.number_high", {
        value: Number(maxValue).toLocaleString("sv"),
        name: errorName,
      });
  } else if (minDigits && minDigits > digits) {
    error =
      errorText ||
      tx("validators.digit", {
        count: minDigits - digits,
        name: errorName,
      });
    valid = false;
  } else if (maxDigits && maxDigits < digits) {
    error =
      errorText ||
      tx("validators.digit_to_many", {
        count: digits - maxDigits,
        name: errorName,
      });
    valid = false;
  } else if (typeof minWarningValue !== "undefined" && normIntValue <= minWarningValue) {
    warning = tx("validators.correct_input_warning", { name: errorName });
  } else if (typeof maxWarningValue !== "undefined" && normIntValue >= maxWarningValue) {
    warning = tx("validators.correct_input_warning", { name: errorName });
  }

  return { valid, error, warning, key };
};
