import { CommonService } from "netbank-shared/src/libs/models/CommonService";
import { NavigateFunction, RouteComponentProps } from "react-router-dom";
import { getQueryParam } from "../utils/misc";
import { jsPDF } from "jspdf";
import { tx } from "netbank-shared/src/libs/i18n";

export class WebCommonService extends CommonService {
  async redirectToBankId(bankIdUrl: string): Promise<void> {
    window.location.href = bankIdUrl;
  }
  getUserAgentHeader(): string {
    return navigator.userAgent;
  }

  constructor(private navigate: NavigateFunction) {
    super();
  }

  handleLogout = (url?: string, isInternal?: boolean) => {
    if (isInternal || !url) {
      this.redirectToLogin();
      return;
    }

    window.location.href = url;
  };

  getQueryParam = (key: string): string => {
    return getQueryParam(window.location.search, key);
  };

  redirect = (url: string) => {
    if (!url) return;

    this.navigate?.(url);
  };

  redirectToLogin = () => {
    this.redirect(`/${tx("routing.lang")}/login`);
  };

  createPdf = () => {
    /* eslint-disable new-cap */
    const doc = new jsPDF();
    doc.text("Mock file", 10, 10);
    const blobPart = doc.output("arraybuffer");
    return blobPart;
  };
}
