import { action, computed, makeObservable, observable } from "mobx";
import { EngagementsApi } from "../libs/api";
import { IDocument } from "../libs/models/Engagements";
import { DocumentType } from "../libs/models/Content/Enums";

import { IDepositAccount } from "../libs/models/Engagements/DepositAccount";
import { Store } from "./Store";
import { AccountNameValidator } from "../libs/validators/AccountNameValidator";
import { AccountStatus } from "../libs/models/Engagements/Account";

export class DepositStore {
  engagementsApi: EngagementsApi;

  rootStore: Store;

  constructor(engagementsApi: EngagementsApi, rootStore: Store) {
    this.engagementsApi = engagementsApi;
    this.rootStore = rootStore;
    makeObservable(this);
  }

  @observable
  depositAccounts: IDepositAccount[] = [];

  @observable
  depositAccountsError?: boolean;

  @computed
  get currentAccountNumber(): string {
    return this.rootStore.currentAccountNumber;
  }

  set currentAccountNumber(accountNumber: string) {
    this.rootStore.currentAccountNumber = accountNumber;
  }

  @observable
  depositAccountDocuments?: IDocument[];

  @observable
  loadingDocuments: boolean = false;

  @observable
  isUpdatingAccountName: boolean = false;

  @observable
  hasUpdateAccountNameError?: boolean = false;

  @observable
  hasValidationAccountNameError?: boolean = false;

  @computed
  get currentAccount(): IDepositAccount | undefined {
    return this.depositAccounts?.find((acc) => acc.accountNumber === this.currentAccountNumber);
  }

  @computed
  get hasAccounts(): boolean {
    return this.depositAccounts !== undefined && this.notClosedAccounts.length > 0;
  }

  @computed
  get notClosedAccounts(): IDepositAccount[] {
    return this.depositAccounts?.filter((a) => a.accountStatus !== AccountStatus.AccountClosed);
  }

  @observable
  getDepositAccountsByStatus(statuses: AccountStatus[], allowUndefined: boolean = true): IDepositAccount[] {
    return this.depositAccounts?.filter((acc) =>
      allowUndefined
        ? acc.accountStatus === undefined || statuses.indexOf(acc.accountStatus) > -1
        : acc.accountStatus !== undefined && statuses.indexOf(acc.accountStatus) > -1
    );
  }

  getDepositDocument = async (accountNumber: string) => {
    this.depositAccountDocuments = [];
    this.loadingDocuments = true;
    const response = await this.engagementsApi.getDocuments(accountNumber, DocumentType.Contract);
    if (response?.ok && response?.data?.documents) {
      const { documents } = response.data;
      this.depositAccountDocuments = documents;
    }
    this.loadingDocuments = false;
  };

  setCurrentAccount(accountNumber: string): void {
    this.currentAccountNumber = accountNumber;
  }

  generateClosingDepositAccountParams = () => {
    return `accountNumber=${this.currentAccountNumber}`;
  };

  generateTransferDepositAccountParams = () => {
    return `accountNumber=${this.currentAccountNumber}`;
  };

  @action
  resetDepositAccountDocuments = () => {
    this.depositAccountDocuments = undefined;
  };

  @action
  saveAccountName = async (accName: string) => {
    if (!this.currentAccount) return false;

    this.isUpdatingAccountName = true;
    this.hasValidationAccountNameError = !AccountNameValidator(accName || "", {}).valid;

    if (this.hasValidationAccountNameError) {
      this.isUpdatingAccountName = false;
      return false;
    }

    const updatedData = {
      accountName: accName,
      accountNumber: this.currentAccount.displayNumber,
    };

    const response = await this.engagementsApi.saveAccountName(updatedData);

    if (response?.ok) {
      this.currentAccount.accountPersonalName = accName;
      await this.rootStore.getEngagements(true);
    } else {
      this.hasUpdateAccountNameError = true;
    }
    this.isUpdatingAccountName = false;
    return response?.ok;
  };

  @action
  invalidateCachedAccounts = async () => {
    await this.rootStore.getEngagements(true);
  };

  @action
  resetStore = () => {
    this.depositAccounts = [];
    this.depositAccountDocuments = undefined;
    this.loadingDocuments = false;
  };
}
