import React from "react";
import { observer } from "mobx-react";
import styles from "../Page.scss";
import skeletonStyles from "./RootSkeleton.scss";
import { SkeletonWrapper } from "~views/shared";
import { SelfServiceButtonSkeleton } from "./SelfServiceButtonSkeleton";

export const HomeSkeleton = observer(() => {
  return (
    <div className={styles.wrapper}>
      <section>
        <SkeletonWrapper width={30} />
        <SkeletonWrapper width={300} height={40} />
      </section>
      <section>
        <div className={skeletonStyles.selfServiceWrapper}>
          <SelfServiceButtonSkeleton />
          <SelfServiceButtonSkeleton />
          <SelfServiceButtonSkeleton />
        </div>
      </section>
      <section>
        <SkeletonWrapper height={150} />
      </section>
    </div>
  );
});
