import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import { useStores } from "netbank-shared/src/hooks";
import { tx } from "netbank-shared/src/libs/i18n";
import { IMessageInboxPage } from "netbank-shared/src/libs/models/Content/Page";
import { IMessage, IThread, ThreadStatus } from "netbank-shared/src/libs/models/Message/Threads";
import { getQueryParam } from "../../../utils/misc";
import { MessageSender } from "netbank-shared/src/libs/models/Content/Enums";
import { Button, Lottie } from "~views/shared";
import styles from "./Message.scss";
import { TextArea } from "~views/shared/TextArea/TextArea";
import santanderLogo from "~assets/logo-icon.svg";
import rootStyles from "../Root.scss";
import undoIcon from "~assets/undo.svg";
import { MessageEmptyState } from "./MessageEmptyState";
import { InitialsIcon } from "./InitialsIcon";
import bookmarkRedIcon from "~assets/bookmark-red.svg";
import loadingAnimationData from "~assets/lottie/loading.json";

interface IMessageProps {
  data?: IThread;
}

export const Message = observer(({ data }: IMessageProps) => {
  const { uiStore, messageStore, contentStore } = useStores();
  const page = contentStore.currentPage as IMessageInboxPage;
  const navigate = useNavigate();
  const [invalidMessage, setInvalidMessage] = useState(false);
  const threadId = getQueryParam(window.location.search, "threadId");

  useEffect(() => {
    messageStore.markAsRead(threadId);
    messageStore.fetchThreads(false);
    return () => {
      messageStore.resetStore();
    };
  }, []);

  const handleClear = () => {
    messageStore.setReplyMessageBody("");
  };

  const handleSubmit = async () => {
    if (!messageStore.replyMessageBody) {
      setInvalidMessage(true);
    } else {
      await messageStore.reply(threadId);
    }
  };

  const handleBack = () => {
    navigate(page?.messageInboxPage ?? `/${tx("routing.lang")}`);
    messageStore.resetStore();
  };

  const renderPreviousMessage = (dark: boolean, prevMessageData: IMessage) => {
    const classes = [styles.prevMessageWrapper];
    if (dark) {
      classes.push(styles.dark);
    }

    return (
      <div key={prevMessageData.messageId} className={classes.join(" ")}>
        <div className={styles.messageHeader}>
          <div className={styles.from}>
            {prevMessageData.creatorReference === MessageSender.santander ? (
              <img src={santanderLogo} alt="santander-logo" />
            ) : (
              <InitialsIcon name={prevMessageData.creatorReference} />
            )}
            <span>{prevMessageData.creatorReference}</span>
          </div>
          <div className={styles.date}>
            <span>{prevMessageData.created.split("T")[0]}</span>
          </div>
        </div>
        <div
          className={styles.messageContent}
          dangerouslySetInnerHTML={{
            __html: prevMessageData.body.split("\n").join("<br/>"),
          }}
        />
        <div className={styles.attachments}>
          {prevMessageData.attachments?.map((a) => {
            return (
              <a
                href={`data:${a.mimeType};base64,${a.attachment}`}
                target="_blank"
                rel="noopener noreferrer"
                download={a.fileName}
                key={a.id}
              >
                <img src={bookmarkRedIcon} alt="red attachment icon" />
                {a.fileName}
              </a>
            );
          })}
        </div>
      </div>
    );
  };

  const renderPreviousMessages = (messages: IMessage[]) => {
    messages.shift();
    return messages.map((message, index) => {
      let dark = true;
      if (index % 2 === 0) {
        dark = false;
      }
      return renderPreviousMessage(dark, message);
    });
  };

  return (
    <>
      {messageStore.replyMessageSuccess && <MessageEmptyState data={page.happyCaseEmptyState} />}
      {messageStore.replyMessageSuccess === false && <MessageEmptyState data={page.errorCaseEmptyState} />}
      {messageStore.replyMessageSuccess === undefined && (
        <section className={uiStore.isMobile ? rootStyles.noPadding : ""}>
          <div className={styles.backButtonWrapper}>
            <Button className={styles.backButton} onClick={() => handleBack()}>
              <div className={styles.backButtonContent}>
                <img className={styles.backIcon} src={undoIcon} alt="undo icon" />
                <span className={styles.backButtonTitle}>{tx("message.goBack")}</span>
              </div>
            </Button>
          </div>
          <div className={styles.messageWrapper}>
            {messageStore.replyMessageSuccess === undefined && !data && (
              <Lottie
                options={{
                  animationData: loadingAnimationData,
                }}
                height={100}
                width={100}
              />
            )}
            {messageStore.replyMessageSuccess === undefined && data && (
              <>
                <div className={styles.messageTitle}>{data.title}</div>
                <div className={styles.message}>
                  <div className={styles.messageHeader}>
                    <div className={styles.from}>
                      {data.messages[0].creatorReference === MessageSender.santander ? (
                        <img src={santanderLogo} alt="santander-logo" />
                      ) : (
                        <InitialsIcon name={data.messages[0].creatorReference} />
                      )}
                      <span>{data.messages[0].creatorReference}</span>
                    </div>
                    <div className={styles.date}>
                      <span>{data.messages[0].created.split("T")[0]}</span>
                    </div>
                  </div>
                  <div
                    className={styles.messageContent}
                    dangerouslySetInnerHTML={{
                      __html: data.messages[0].body.split("\n").join("<br/>"),
                    }}
                  />
                  <div className={styles.attachments}>
                    {data.messages[0].attachments?.map((a, i) => (
                      <a
                        href={`data:${a.mimeType};base64,${a.attachment}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        download={a.fileName}
                        key={i}
                      >
                        <img src={bookmarkRedIcon} alt="red attachment icon" />
                        {a.fileName}
                      </a>
                    ))}
                  </div>
                </div>
                {data.status !== ThreadStatus.closed && (
                  <>
                    <div className={styles.replyFormWrapper}>
                      <TextArea
                        value={messageStore.replyMessageBody}
                        label={tx("message.reply")}
                        placeholder={tx("message.textareaReplyPlaceholder")}
                        onChange={(e) => {
                          messageStore.setReplyMessageBody(e.target.value);
                          setInvalidMessage(!e.target.value);
                        }}
                        error={(invalidMessage && tx("message.messageError")) || undefined}
                      />
                      <div className={styles.buttonWrapper}>
                        <div className={styles.buttonActionsWrapper}>
                          <Button
                            title={tx("misc.clear")}
                            bordered
                            centered
                            borderColor="grey"
                            onClick={() => handleClear()}
                          />
                          <Button
                            title={tx("misc.send")}
                            bordered
                            centered
                            color="red"
                            borderColor="grey"
                            onClick={() => handleSubmit()}
                            loading={messageStore.sendingMessage}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={styles.replyLabel}>{tx("message.prevDialog")}</div>
                  </>
                )}
                <div className={styles.prevMessages}>{renderPreviousMessages([...data.messages])}</div>
              </>
            )}
          </div>
        </section>
      )}
    </>
  );
});
