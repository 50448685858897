import { ILoanAccount } from "libs/models/Engagements";
import { action, computed, observable, makeObservable } from "mobx";
import { EpiserverApi } from "../libs/api";
import { changeI18nLanguage, tx } from "../libs/i18n";
import { Lang, PageType } from "../libs/models/Content/Enums";
import { IPage } from "../libs/models/Content/Page";
import {
  ICardTemplate,
  IDepositTemplate,
  ILeasingTemplate,
  IPrivateLoanTemplate,
  ISecuredLoanTemplate,
} from "../libs/models/Content/templates";

import { Store } from "./Store";
import { getLangInUrl } from "../libs/utils/url";
import { ILoanTemplate } from "libs/models/Content/templates/LoanTemplate";
import { IBankAccountControl } from "libs/models/Content/IKreditzVerification";
import { IGenericContentLink } from "libs/models/Content/ContentLink";
import { CustomerPersistentStore } from "./CustomerPersistentStore";
import { IAccountNumberValidation } from "libs/models/Content/AccountNumberValidation";

export class ContentStore {
  rootStore: Store;

  customerPersistentStore: CustomerPersistentStore;

  episerverApi: EpiserverApi;

  constructor(rootStore: Store, customerPersistentStore: CustomerPersistentStore, episerverApi: EpiserverApi) {
    this.rootStore = rootStore;
    this.customerPersistentStore = customerPersistentStore;
    this.episerverApi = episerverApi;
    makeObservable(this);
  }

  @observable
  siteUrl?: string;

  @observable
  pageCache: IPage[] = [];

  @observable
  currentPage?: IPage = undefined;

  @observable
  is404: boolean = false;

  @observable
  isRetryPage: boolean = false;

  @observable
  fetchingHomePage: boolean = false;

  @observable
  fetchingCmsPage: boolean = false;

  @observable
  fetchingTemplatePage: boolean = false;

  @observable
  loanTemplatePages?: ILoanTemplate[] = [];

  @observable
  privateLoanTemplatePages?: IPrivateLoanTemplate[] = [];

  @observable
  securedLoanTemplatePages?: ISecuredLoanTemplate[] = [];

  @observable
  loanEmptyStatePage?: IPage = undefined;

  @observable
  leasingTemplatePages?: ILeasingTemplate[] = [];

  @observable
  leasingEmptyStatePage?: IPage = undefined;

  @observable
  cardTemplatePages?: ICardTemplate[] = [];

  @observable
  cardEmptyStatePage?: IPage = undefined;

  @observable
  depositTemplatePages?: IDepositTemplate[] = [];

  @observable
  depositEmptyStatePage?: IPage = undefined;

  @observable
  cardSettingsEmptyStatePage?: IPage = undefined;

  // Should always be synced with tx("routing.lang"). It is used
  // to make the i18n lang value reactive.
  @observable
  i18nLang = tx("routing.lang");

  getHomePageFromCache = (lang?: string) => {
    return this.pageCache.find(
      (page) =>
        page?.url === "" ||
        page?.url === "/" ||
        page?.url === `/${lang || tx("routing.lang")}` ||
        page?.url === `/${lang || tx("routing.lang")}/`
    );
  };

  @computed
  get homePage(): IPage | undefined {
    return this.getHomePageFromCache();
  }

  @computed
  get svHomePage(): IPage | undefined {
    return this.getHomePageFromCache(Lang.sv);
  }

  @computed
  get enHomePage(): IPage | undefined {
    return this.getHomePageFromCache(Lang.en);
  }

  getHomePage(lang?: string): IPage | undefined {
    switch (lang) {
      case Lang.en:
        return this?.enHomePage;
      case Lang.sv:
        return this?.svHomePage;
      default:
        return this?.homePage;
    }
  }

  @computed
  get fetchingPage(): boolean {
    return this.fetchingTemplatePage || this.fetchingCmsPage || this.fetchingHomePage;
  }

  @computed
  get isTemplatePage(): boolean {
    const currentPageType = this.currentPage?.contentType?.[1];
    return this.currentPage
      ? currentPageType === PageType.CardTemplatePage ||
      currentPageType === PageType.DepositTemplatePage ||
      currentPageType === PageType.LeasingTemplatePage ||
      currentPageType === PageType.PrivateLoanTemplatePage ||
      currentPageType === PageType.SecuredLoanTemplatePage
      : false;
  }

  @computed
  get isSetAccountNameEnabled(): boolean {
    const currentPageType = this.currentPage?.contentType?.[1];
    switch (currentPageType) {
      case PageType.CardTemplatePage:
        return this.currentCardTemplatePage?.setAccountNameModalContent?.[0] != null;
      case PageType.DepositTemplatePage:
        return this.currentDepositTemplatePage?.setAccountNameModalContent?.[0] != null;
      case PageType.LeasingTemplatePage:
        return this.currentLeasingTemplatePage?.setAccountNameModalContent?.[0] != null;
      case PageType.LoanTemplatePage:
        return this.currentLoanTemplatePage?.setAccountNameModalContent?.[0] != null;
      case PageType.PrivateLoanTemplatePage:
        return this.currentPrivateLoanTemplatePage?.setAccountNameModalContent?.[0] != null;
      case PageType.SecuredLoanTemplatePage:
        return this.currentSecuredLoanTemplatePage?.setAccountNameModalContent?.[0] != null;
      default:
        return false;
    }
  }

  @computed
  get currentLoanTemplatePage(): ILoanTemplate | undefined {
    return this.loanTemplatePages?.find((page) => page.currentAccountNumber === this.rootStore.currentAccountNumber);
  }

  @computed
  get currentPrivateLoanTemplatePage(): IPrivateLoanTemplate | undefined {
    return this.privateLoanTemplatePages?.find(
      (page) =>
        page.currentAccountNumber === this.rootStore.currentAccountNumber && getLangInUrl(page.url) === this.i18nLang
    );
  }

  @computed
  get currentSecuredLoanTemplatePage(): ISecuredLoanTemplate | undefined {
    return this.securedLoanTemplatePages?.find(
      (page) =>
        page.currentAccountNumber === this.rootStore.currentAccountNumber && getLangInUrl(page.url) === this.i18nLang
    );
  }

  @computed
  get currentCardTemplatePage(): ICardTemplate | undefined {
    return this.cardTemplatePages?.find(
      (page) =>
        page.currentAccountNumber === this.rootStore.currentAccountNumber && getLangInUrl(page.url) === this.i18nLang
    );
  }

  @computed
  get currentDepositTemplatePage(): IDepositTemplate | undefined {
    return this.depositTemplatePages?.find(
      (page) =>
        page.currentAccountNumber === this.rootStore.currentAccountNumber && getLangInUrl(page.url) === this.i18nLang
    );
  }

  @computed
  get currentLeasingTemplatePage(): ILeasingTemplate | undefined {
    return this.leasingTemplatePages?.find(
      (page) =>
        page.currentAccountNumber === this.rootStore.currentAccountNumber && getLangInUrl(page.url) === this.i18nLang
    );
  }

  @computed
  get availableLanguages(): Lang[] {
    return (this.homePage?.languagePicker?.supportedLanguages?.split(",").filter((s) => s !== "") as Lang[]) || [];
  }

  @action
  getIsCorporateCustomerContent = () => {
    return (
      this.customerPersistentStore.isLoggedInToCorporateAccount ||
      this.rootStore.customerStore.isOnlyCorporateCustomer()
    );
  };

  @computed
  get strictAccountValidationEnabled(): boolean {
    return this.homePage?.accountNumberValidation?.length === 1 || false;
  }

  @computed
  get accountValidationData(): IGenericContentLink<IAccountNumberValidation> | undefined {
    return this.homePage?.accountNumberValidation?.[0] || undefined;
  }

  @computed
  get bankAccountControl(): IGenericContentLink<IBankAccountControl> | undefined {
    return this.homePage?.bankAccountControl[0] || undefined;
  }

  setHomePage = async (lang?: string) => {
    if (this.getHomePage(lang)) {
      this.currentPage = this.getHomePage(lang);
    }
  };

  updateFetchingPage = (isHomePage: boolean, value: boolean) => {
    if (isHomePage) {
      this.fetchingHomePage = value;
    } else {
      this.fetchingCmsPage = value;
    }
  };

  updateOrAddToPageCache = (pageToCache: IPage): void => {
    const pageIndex = this.pageCache.findIndex((cachedPage) => pageToCache.url === cachedPage.url);
    if (pageIndex === -1) {
      this.pageCache.push(pageToCache);
    } else {
      this.pageCache[pageIndex] = pageToCache;
    }
  };

  @action
  setI18nLang = async (lang: string) => {
    changeI18nLanguage(lang);
    this.i18nLang = lang;
  };

  @action
  changeLanguage = async (lang: string) => {
    this.setI18nLang(lang);
  };

  @action
  getLoanTemplateFromAccount(currentAccount: ILoanAccount): IPrivateLoanTemplate | ISecuredLoanTemplate | undefined {
    if (!currentAccount) return undefined;

    const loanTemplate = this.loanTemplatePages?.find(
      (page) => page.currentAccountNumber === currentAccount.accountNumber
    );

    if (loanTemplate) {
      return loanTemplate;
    }

    switch (currentAccount.accountType) {
      case "PrivateLoan":
        return this.currentPrivateLoanTemplatePage;
      case "SecuredLoan":
        return this.currentSecuredLoanTemplatePage;
      default:
        return this.currentLoanTemplatePage;
    }
  }

  // TODO: This is a dirty temporary solution for supporting both new and old loan menu structures
  @action
  getLoanWebRoutingPrefixFromCurrentAccount = (currentAccount: ILoanAccount) => {
    if (!currentAccount) return "";
    const loanTemplate = this.loanTemplatePages?.find(
      (page) => page.currentAccountNumber === currentAccount.accountNumber
    );

    if (loanTemplate) {
      return tx("routing.loan");
    }

    const privateLoanTemplate = this.svHomePage?.privateLoanTemplatePage;
    const securedLoanTemplate = this.svHomePage?.privateLoanTemplatePage;

    switch (currentAccount.accountType) {
      case "PrivateLoan":
        return privateLoanTemplate ? tx("routing.privateLoan") : tx("routing.loan");
      case "SecuredLoan":
        return securedLoanTemplate ? tx("routing.securedLoan") : tx("routing.loan");
      default:
        return tx("routing.loan");
    }
  };

  // TODO: This is a dirty temporary solution for supporting both new and old loan menu structures
  @action
  getLoanAppRoutingPrefixFromCurrentAccount = (currentAccount: ILoanAccount) => {
    if (!currentAccount) return "";
    const loanTemplate = this.loanTemplatePages?.find(
      (page) => page.currentAccountNumber === currentAccount.accountNumber
    );

    if (loanTemplate) {
      return "loan";
    }

    const privateLoanTemplate = this.svHomePage?.privateLoanTemplatePage;
    const securedLoanTemplate = this.svHomePage?.privateLoanTemplatePage;

    switch (currentAccount.accountType) {
      case "PrivateLoan":
        return privateLoanTemplate ? "privateLoan" : "loan";
      case "SecuredLoan":
        return securedLoanTemplate ? "securedLoan" : "loan";
      default:
        return "loan";
    }
  };

  @action
  getPage = async (path: string, saveToCache = true, skipCache = false, setAsCurrentPage = true) => {
    const isHomePage =
      path === "" || path === "/" || path === `/${tx("routing.lang")}` || path === `/${tx("routing.lang")}/`;

    this.updateFetchingPage(isHomePage, true);
    this.isRetryPage = false;

    this.is404 = false;
    if (!skipCache && this.pageCache.findIndex((page) => page.url === path) !== -1) {
      const currentPage = this.pageCache.find((page) => page.url === path);
      if (setAsCurrentPage) this.currentPage = currentPage;
      this.updateFetchingPage(isHomePage, false);
      return currentPage;
    }

    const response = await this.episerverApi.getData<IPage>(path);
    if (response?.ok && response.data) {
      const currentPage = response.data;
      if (setAsCurrentPage) this.currentPage = currentPage;
      if (saveToCache) {
        this.updateOrAddToPageCache(currentPage);
      }
      this.updateFetchingPage(isHomePage, false);
      return response.data;
    }

    if (response?.status === 404) {
      this.is404 = true;
      this.updateFetchingPage(isHomePage, false);
      const cachedHomePage = this.getHomePage(tx("routing.lang"));

      if (cachedHomePage) {
        if (setAsCurrentPage) this.currentPage = cachedHomePage;
        return cachedHomePage;
      }
    }
    this.rootStore.uiStore.setLoading(false);
    this.isRetryPage = true;
    this.updateFetchingPage(isHomePage, false);
    return undefined;
  };

  @action
  getLoanTemplate = async () => {
    this.fetchingTemplatePage = true;

    const templatePage = this.loanTemplatePages?.find(
      (page) =>
        page.currentAccountNumber === this.rootStore.currentAccountNumber && getLangInUrl(page.url) === this.i18nLang
    );

    if (templatePage) {
      this.currentPage = templatePage;
      this.fetchingTemplatePage = false;
      return;
    }
    let page = this.currentPage;
    if (!page || getLangInUrl(page.url) !== tx("routing.lang")) {
      page = await this.getPage(`/${tx("routing.lang")}`);
    }
    const response = await this.episerverApi.getData<ILoanTemplate>(page?.loanTemplatePage);
    if (response?.ok && response.data) {
      this.loanTemplatePages?.push({
        ...response.data,
        currentAccountNumber: this.rootStore.currentAccountNumber,
      });
      this.currentPage = response.data;
    }
    this.fetchingTemplatePage = false;
  };

  @action
  getPrivateLoanTemplate = async () => {
    this.fetchingTemplatePage = true;

    const templatePage = this.privateLoanTemplatePages?.find(
      (page) =>
        page.currentAccountNumber === this.rootStore.currentAccountNumber && getLangInUrl(page.url) === this.i18nLang
    );

    if (templatePage) {
      this.currentPage = templatePage;
      this.fetchingTemplatePage = false;
      return;
    }
    let page = this.currentPage;
    if (!page || getLangInUrl(page.url) !== tx("routing.lang")) {
      page = await this.getPage(`/${tx("routing.lang")}`);
    }
    const response = await this.episerverApi.getData<IPrivateLoanTemplate>(page?.privateLoanTemplatePage);
    if (response?.ok && response.data) {
      this.privateLoanTemplatePages?.push({
        ...response.data,
        currentAccountNumber: this.rootStore.currentAccountNumber,
      });
      this.currentPage = response.data;
    }
    this.fetchingTemplatePage = false;
  };

  @action
  getSecuredLoanTemplate = async () => {
    this.fetchingTemplatePage = true;

    const templatePage = this.securedLoanTemplatePages?.find(
      (page) =>
        page.currentAccountNumber === this.rootStore.currentAccountNumber && getLangInUrl(page.url) === this.i18nLang
    );

    if (templatePage) {
      this.currentPage = templatePage;
      this.fetchingTemplatePage = false;
      return;
    }
    let page = this.currentPage;
    if (!page || getLangInUrl(page.url) !== tx("routing.lang")) {
      page = await this.getPage(`/${tx("routing.lang")}`);
    }
    const response = await this.episerverApi.getData<ISecuredLoanTemplate>(page?.securedLoanTemplatePage);
    if (response?.ok && response.data) {
      this.securedLoanTemplatePages?.push({
        ...response.data,
        currentAccountNumber: this.rootStore.currentAccountNumber,
      });
      this.currentPage = response.data;
    }
    this.fetchingTemplatePage = false;
  };

  @action
  getLeasingTemplate = async () => {
    this.fetchingTemplatePage = true;
    const templatePage = this.leasingTemplatePages?.find(
      (page) =>
        page.currentAccountNumber === this.rootStore.currentAccountNumber && getLangInUrl(page.url) === this.i18nLang
    );
    if (templatePage) {
      this.currentPage = templatePage;
      this.fetchingTemplatePage = false;
      return;
    }
    let page = this.currentPage;
    if (!page || getLangInUrl(page.url) !== tx("routing.lang")) {
      page = await this.getPage(`/${tx("routing.lang")}`);
    }
    const response = await this.episerverApi.getData<ILeasingTemplate>(page?.leasingTemplatePage);
    if (response?.ok && response.data) {
      this.leasingTemplatePages?.push({
        ...response.data,
        currentAccountNumber: this.rootStore.currentAccountNumber,
      });
      this.currentPage = response.data;
    }
    this.fetchingTemplatePage = false;
  };

  @action
  getCardTemplate = async () => {
    this.fetchingTemplatePage = true;
    const templatePage = this.cardTemplatePages?.find(
      (page) =>
        page.currentAccountNumber === this.rootStore.currentAccountNumber && getLangInUrl(page.url) === this.i18nLang
    );
    if (templatePage) {
      this.currentPage = templatePage;
      this.fetchingTemplatePage = false;
      return;
    }

    let page = this.currentPage;
    if (!page || getLangInUrl(page.url) !== tx("routing.lang")) {
      page = await this.getPage(`/${tx("routing.lang")}`);
    }
    const response = await this.episerverApi.getData<ICardTemplate>(page?.cardTemplatePage);
    if (response?.ok && response.data) {
      this.cardTemplatePages?.push({
        ...response.data,
        currentAccountNumber: this.rootStore.currentAccountNumber,
      });
      this.currentPage = response.data;
    }
    this.fetchingTemplatePage = false;
  };

  @action
  getDepositTemplate = async () => {
    this.fetchingTemplatePage = true;
    const templatePage = this.depositTemplatePages?.find(
      (page) =>
        page.currentAccountNumber === this.rootStore.currentAccountNumber && getLangInUrl(page.url) === this.i18nLang
    );
    if (templatePage) {
      this.currentPage = templatePage;
      this.fetchingTemplatePage = false;
      return;
    }

    let page = this.currentPage;
    if (!page || getLangInUrl(page.url) !== tx("routing.lang")) {
      page = await this.getPage(`/${tx("routing.lang")}`);
    }
    const response = await this.episerverApi.getData<IDepositTemplate>(page?.depositTemplatePage);
    if (response?.ok && response.data) {
      this.depositTemplatePages?.push({
        ...response.data,
        currentAccountNumber: this.rootStore.currentAccountNumber,
      });
      this.currentPage = response.data;
    }
    this.fetchingTemplatePage = false;
  };

  @action
  getEmptyStateContent = async (pageType?: string) => {
    let page;
    if (this.homePage) {
      page = this.homePage;
    } else {
      const path = `/${tx("routing.lang")}`;
      const response = await this.episerverApi.getData<IPage>(path);
      if (response?.ok && response.data) {
        page = response.data;
        this.updateOrAddToPageCache(page);
      }
    }

    const { cardOrderingDisabledForAllAccounts } = this.rootStore.creditStore;

    switch (pageType) {
      case PageType.CardTransitPage:
        return page?.cardEmptyStateContent;
      case PageType.CardSettingsPage:
        return cardOrderingDisabledForAllAccounts
          ? page?.cardSettingsBlockedAccountsEmptyState
          : page?.cardSettingsEmptyStateContent;
      case PageType.LoanTransitPage:
        return page?.loanEmptyStateContent;
      case PageType.PrivateLoanTransitPage:
        return page?.privateLoanEmptyStateContent;
      case PageType.SecuredLoanTransitPage:
        return page?.securedLoanEmptyStateContent;
      case PageType.DepositTransitPage:
        return page?.depositEmptyStateContent;
      case PageType.LeasingTransitPage:
        return page?.leasingEmptyStateContent;
      default:
        return undefined;
    }
  };

  translateTemplatePageUrl = (targetLang: string, currentLang: string) => {
    // We toggle i18n language to translate the tx strings to target language
    let translatedUrl;
    changeI18nLanguage(targetLang);
    if (this.isTemplatePage && this.currentPrivateLoanTemplatePage)
      translatedUrl = `/${tx("routing.lang")}/${tx("routing.privateLoan")}/${this.currentPrivateLoanTemplatePage.currentAccountNumber
        }`;
    if (this.isTemplatePage && this.currentSecuredLoanTemplatePage)
      translatedUrl = `/${tx("routing.lang")}/${tx("routing.securedLoan")}/${this.currentSecuredLoanTemplatePage.currentAccountNumber
        }`;
    if (this.isTemplatePage && this.currentCardTemplatePage)
      translatedUrl = `/${tx("routing.lang")}/${tx("routing.card")}/${this.currentCardTemplatePage.currentAccountNumber
        }`;
    if (this.isTemplatePage && this.currentDepositTemplatePage)
      translatedUrl = `/${tx("routing.lang")}/${tx("routing.deposit")}/${this.currentDepositTemplatePage.currentAccountNumber
        }`;
    if (this.isTemplatePage && this.currentLeasingTemplatePage)
      translatedUrl = `/${tx("routing.lang")}/${tx("routing.leasing")}/${this.currentLeasingTemplatePage.currentAccountNumber
        }`;
    changeI18nLanguage(currentLang);
    return translatedUrl;
  };

  translateUrl = async (currentUrl: string, targetLang: string, currentLang: string) => {
    let translatedUrl;

    // Is homepage
    if (
      currentUrl === "" ||
      currentUrl === "/" ||
      currentUrl === `/${tx("routing.lang")}` ||
      currentUrl === `/${tx("routing.lang")}/`
    ) {
      translatedUrl = `/${targetLang}/`;
    }

    // Is login page
    if (
      currentUrl === "/login" ||
      currentUrl === "/login/" ||
      currentUrl === `/${tx("routing.lang")}/login` ||
      currentUrl === `/${tx("routing.lang")}/login/`
    ) {
      translatedUrl = `/${targetLang}/login`;
    }

    // Template pages
    if (this.isTemplatePage) translatedUrl = this.translateTemplatePageUrl(targetLang, currentLang);

    // Get homepages
    let targetHomePage = this.getHomePage(targetLang);
    let currentHomePage = this.getHomePage(currentLang);
    if (!targetHomePage) {
      targetHomePage = await this.getPage(targetLang, true, false, false);
    }
    if (!currentHomePage) {
      currentHomePage = await this.getPage(currentLang, true, false, false);
    }

    // Menu items
    currentHomePage?.menuItems.forEach((currentMenuItem) => {
      if (currentUrl === currentMenuItem.path) {
        translatedUrl = targetHomePage?.menuItems.find(
          (targetMenuItem) => targetMenuItem.contentGuid === currentMenuItem.contentGuid
        )?.path;
      } else {
        // Nested menu items
        currentMenuItem.children?.forEach((currentChild) => {
          if (currentUrl === currentChild.path && targetHomePage) {
            for (let i = 0; i < targetHomePage.menuItems.length; i += 1) {
              const targetMenuItem = targetHomePage.menuItems[i];
              const targetChild = targetMenuItem.children?.find(
                (child) => child.contentGuid === currentChild.contentGuid
              );
              if (targetChild) {
                translatedUrl = targetChild?.path;
                break;
              }
            }
          }
        });
      }
    });

    // Other pages
    if (targetHomePage && currentHomePage && !translatedUrl) {
      switch (currentUrl) {
        case currentHomePage.newMessagePage:
          translatedUrl = targetHomePage.newMessagePage;
          break;
        case currentHomePage.messageInboxPage:
          translatedUrl = targetHomePage.messageInboxPage;
          break;
        case currentHomePage.personalSettingsPage:
          translatedUrl = targetHomePage.personalSettingsPage;
          break;
        case currentHomePage.termsPage:
          translatedUrl = targetHomePage.termsPage;
          break;
        case currentHomePage.transferPage:
          translatedUrl = targetHomePage.transferPage;
          break;
        default:
      }
    }

    if (!translatedUrl) {
      translatedUrl = `/${targetLang}/`;
    }

    return translatedUrl;
  };

  @action
  resetStore = () => {
    this.pageCache = [];
    this.currentPage = undefined;
    this.is404 = false;
    this.fetchingHomePage = false;
    this.fetchingCmsPage = false;
    this.fetchingTemplatePage = false;
    this.loanTemplatePages = [];
    this.privateLoanTemplatePages = [];
    this.securedLoanTemplatePages = [];
    this.loanEmptyStatePage = undefined;
    this.leasingTemplatePages = [];
    this.leasingEmptyStatePage = undefined;
    this.cardTemplatePages = [];
    this.cardEmptyStatePage = undefined;
    this.depositTemplatePages = [];
    this.depositEmptyStatePage = undefined;
    this.cardSettingsEmptyStatePage = undefined;
  };
}
