import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { IPlatformStore } from "netbank-shared/src/libs/models/PlatformStore";
import { v4 as uuidv4 } from "uuid";

interface IBrowser {
  name: string;
  version: number;
}

export default class WebPlatformStore implements IPlatformStore {
  unsupportedBrowsers: { [key: string]: number };

  browser: IBrowser;

  constructor() {
    this.browser = { name: "", version: 0 };
    // Browser versions or -1 for all versions
    this.unsupportedBrowsers = {
      Chrome: 70,
      Safari: 12,
      Firefox: 62,
      Edge: 14,
      IE: -1,
      Opera: 60,
      Samsung: 8,
      Yandex: -1,
      UCBrowser: -1,
    };

    this.detectBrowser();
  }

  getTelemetryProvider = (connectionString: string): ApplicationInsights => {
    const appInsights = new ApplicationInsights({
      config: {
        connectionString: connectionString,
        isStorageUseDisabled: true,
        loggingLevelConsole: 2,
        excludeRequestFromAutoTrackingPatterns: [
          new RegExp("o2.mouseflow.com"),
          new RegExp("service.giosg.com"),
          new RegExp("google-analytics.com"),
          new RegExp("appspot.com")
        ]
      },
    });

    return appInsights;
  }

  getVersion = () => {
    return `${this.browser.version}`;
  };

  detectBrowser = (): void => {
    let tem: RegExpMatchArray | null | [] = null;
    const ua = navigator.userAgent;
    let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      this.browser = { name: "IE", version: parseInt(tem[1]) || -1 };
      return;
    }

    if (M[1] === "Chrome") {
      tem = ua.match(/\b(OPR|edge?)\/(\d+)/i);
      if (tem != null) {
        this.browser = {
          name: tem[1].replace("OPR", "Opera").replace(/edge?/i, "Edge"),
          version: parseInt(tem[2]),
        };
        return;
      }
    }

    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];

    tem = ua.match(/version\/(\d+)/i);
    if (tem != null) {
      M.splice(1, 1, tem[1]);
    }

    this.browser = { name: M[0], version: parseInt(M[1]) };
  };

  isApplication = (): boolean => false;

  isWeb = (): boolean => true;

  getPlatformSpecificHeaders = () => {
    const headers: { [key: string]: string | number } = {};
    headers["X-Platform"] = `${this.getOS()}:${this.browser.name}(${this.browser.version})`;
    return Object.keys(headers) ? headers : undefined;
  };

  isIE = (): boolean => this.browser.name === "IE";

  isEdge = (): boolean => this.browser.name === "Edge";

  isMicrosoft = (): boolean => this.isIE() || this.isEdge();

  isFirefox = (): boolean => this.browser.name === "Firefox";

  isChrome = (): boolean => this.browser.name === "Chrome";

  isSafari = (): boolean => this.browser.name === "Safari";

  isAndroidDevice = (): boolean => /Android/i.test(navigator.userAgent);

  isOtherDevice = (): boolean => !this.isiOSDevice() && !this.isAndroidDevice();

  isWindows = (): boolean => /Win32|Win64|Windows|WinCE/i.test(navigator.userAgent);

  isMac = (): boolean => /Macintosh|MacIntel|MacPPC|Mac68K/i.test(navigator.userAgent);

  isLinux = (): boolean => /Linux/i.test(navigator.userAgent);

  isBlackBerry = (): boolean => /BlackBerry/i.test(navigator.userAgent);

  isWindowsMobile = () => /IEMobile/i.test(navigator.userAgent);

  isIpad = () => /Macintosh/i.test(navigator.userAgent) && !!navigator.maxTouchPoints && navigator.maxTouchPoints > 1;

  isiOSDevice = (): boolean => /iPhone|iPad|iPod/i.test(navigator.userAgent) || this.isIpad();

  isIOSSafari = (): boolean => this.isiOSDevice() && navigator.userAgent.indexOf("Safari") > -1;

  isIOSChrome = (): boolean => this.isiOSDevice() && /CriOS/i.test(navigator.userAgent);

  isIOSFirefox = (): boolean => this.isiOSDevice() && /FxiOS/i.test(navigator.userAgent);

  isIOSEdge = (): boolean => this.isiOSDevice() && /EdgiOS/i.test(navigator.userAgent);

  isMobile = (): boolean => {
    if (this.isAndroidDevice() || this.isBlackBerry() || this.isWindowsMobile() || this.isiOSDevice()) {
      return true;
    }

    const isTouch = "ontouchstart" in window || navigator.maxTouchPoints;
    if (window.screen.width < 992 && isTouch) {
      return true;
    }

    const pointerCapability = window.matchMedia("(pointer: coarse) and (hover: none)");
    if (pointerCapability.matches) {
      return true;
    }

    return false;
  }


  isOutdated = (): boolean => {
    if (this.unsupportedBrowsers[this.browser.name]) {
      if (
        this.unsupportedBrowsers[this.browser.name] === -1 ||
        this.browser.version <= this.unsupportedBrowsers[this.browser.name]
      ) {
        return true;
      }
    }

    return false;
  };

  getOS = (): string => {
    if (this.isiOSDevice()) return "IOS";
    if (this.isAndroidDevice()) return "Android";
    if (this.isWindows()) return "Windows";
    if (this.isMac()) return "Mac";
    if (this.isLinux()) return "Linux";
    return "";
  };

  getSessionId = () => {
    const correlationKey = "correlationId";
    let currentSessionId = sessionStorage.getItem(correlationKey)
    if (currentSessionId == null) {
      currentSessionId = uuidv4();
      sessionStorage.setItem(correlationKey, currentSessionId);
    }
    return currentSessionId;
  }
}
