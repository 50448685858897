import { useTracking } from "netbank-shared/src/hooks";
import { TrackingAction, TrackingCategory } from "netbank-shared/src/libs/models/Tracking";
import { tx } from "netbank-shared/src/libs/i18n";
import React, { CSSProperties, forwardRef, ReactNode } from "react";
import { Link } from "react-router-dom";

interface IDynamicLinkProps {
  to: string;
  target?: string;
  children?: ReactNode;
  className?: string;
  trackingCategory?: TrackingCategory;
  trackingAction?: TrackingAction;
  trackingPrefix?: string;
  style?: CSSProperties;
  onClick?: () => void;
  download?: string;
  tabIndex?: 0 | -1 | undefined;
}

export const DynamicLink = forwardRef<HTMLAnchorElement, IDynamicLinkProps>(
  (
    {
      to,
      children,
      onClick,
      trackingCategory,
      trackingAction,
      trackingPrefix,
      download,
      tabIndex = undefined,
      ...rest
    },
    ref
  ) => {
    const dataLayer = useTracking((window as any).dataLayer);

    if (typeof to !== "string") {
      return null;
    }

    const clickHandler = () => {
      if (trackingCategory && trackingAction) {
        dataLayer.pushInteraction(trackingCategory, trackingAction, trackingPrefix);
      }

      onClick?.();
    };

    const href = to === "" ? `/${tx("routing.lang")}` : to;

    if (href.startsWith("/")) {
      return (
        <Link ref={ref} to={href} {...rest} onClick={clickHandler} tabIndex={tabIndex}>
          {children}
        </Link>
      );
    }
    return (
      <a ref={ref} href={href} download={download} {...rest} onClick={clickHandler} tabIndex={tabIndex}>
        {children}
      </a>
    );
  }
);
