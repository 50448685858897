import { ValidatorFuncProps, ValidatorOptionProps } from "./ValidatorProps";
import { tx } from "../i18n";

export const PhoneValidator: ValidatorFuncProps = (value: string, { errorName }: ValidatorOptionProps) => {
  let valid = true;
  let error = "";

  const regexp = /^((?<countrycode>(([\+]46)|(0046)))|(?<nationalprefix>(0)))((?<mobile>7[02369]\d{7})|(?<landline>[1-9]\d{1,2}\d{5,8}))$/;

  if (!value) {
    valid = false;
    error = tx("validators.required", {
      name: errorName,
    });
  } else if (!regexp.test(value)) {
    valid = false;
    error = tx("validators.phone", {
      name: errorName,
    });
  }

  return { valid, error };
};
