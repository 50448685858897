import dayjs from "dayjs";
import { action, computed, makeObservable, observable } from "mobx";
import { EngagementsApi } from "../libs/api";
import { DocumentType, LoanType } from "../libs/models/Content/Enums";
import { IDocument } from "../libs/models/Engagements";
import { ILoanAccount } from "../libs/models/Engagements/LoanAccounts";
import { IGetTerminationAmountRequest } from "../libs/models/Engagements/TerminationAmount";
import { Store } from "./Store";
import { AccountNameValidator } from "../libs/validators/AccountNameValidator";
import { AccountStatus } from "../libs/models/Engagements/Account";

export class LoanStore {
  engagementsApi: EngagementsApi;

  rootStore: Store;

  constructor(engagementsApi: EngagementsApi, rootStore: Store) {
    this.engagementsApi = engagementsApi;
    this.rootStore = rootStore;
    makeObservable(this);
  }

  @observable
  privateLoanAccountsError?: boolean;

  @observable
  securedLoanAccountsError?: boolean;

  @observable
  blockedLoanAccounts: ILoanAccount[] = [];

  @observable
  blockedPrivateLoanAccounts: ILoanAccount[] = [];

  @observable
  blockedSecuredLoanAccounts: ILoanAccount[] = [];

  @observable
  loanAccounts: ILoanAccount[] = [];

  @observable
  securedLoanAccounts: ILoanAccount[] = [];

  @observable
  privateLoanAccounts: ILoanAccount[] = [];

  @observable
  isUpdatingAccountName: boolean = false;

  @observable
  hasUpdateAccountNameError?: boolean = false;

  @observable
  hasValidationAccountNameError?: boolean = false;

  @computed
  get closedPrivateLoanAccounts(): ILoanAccount[] {
    return this.privateLoanAccounts.filter((a) => a.accountStatus === AccountStatus.AccountClosed);
  }

  @computed
  get allClosedPrivateLoanAccounts(): ILoanAccount[] {
    return [
      ...this.privateLoanAccounts.filter((a) => a.accountStatus === AccountStatus.AccountClosed),
      ...this.blockedPrivateLoanAccounts.filter((a) => a.accountStatus === AccountStatus.AccountClosed),
    ];
  }

  @computed
  get activePrivateLoanAccounts(): ILoanAccount[] {
    return this.privateLoanAccounts.filter((a) => a.accountStatus !== AccountStatus.AccountClosed);
  }

  @computed
  get closedSecuredLoanAccounts(): ILoanAccount[] {
    return this.securedLoanAccounts.filter((a) => a.accountStatus === AccountStatus.AccountClosed);
  }

  @computed
  get allClosedSecuredLoanAccounts(): ILoanAccount[] {
    return [
      ...this.securedLoanAccounts.filter((a) => a.accountStatus === AccountStatus.AccountClosed),
      ...this.blockedSecuredLoanAccounts.filter((a) => a.accountStatus === AccountStatus.AccountClosed),
    ];
  }

  @computed
  get activeSecuredLoanAccounts(): ILoanAccount[] {
    return this.securedLoanAccounts.filter((a) => a.accountStatus !== AccountStatus.AccountClosed);
  }

  @computed
  get activeLoanAccounts(): ILoanAccount[] {
    return [...this.activePrivateLoanAccounts, ...this.activeSecuredLoanAccounts];
  }

  @computed
  get allActiveLoanAccounts(): ILoanAccount[] {
    return [...this.allPrivateLoanAccounts, ...this.allSecuredLoanAccounts];
  }

  @computed
  get allLoanAccounts(): ILoanAccount[] {
    return [...this.loanAccounts, ...this.blockedLoanAccounts];
  }

  @computed
  get allPrivateLoanAccounts(): ILoanAccount[] {
    return [
      ...this.activePrivateLoanAccounts,
      ...this.blockedLoanAccounts.filter(
        (a) => a.accountType === LoanType.PrivateLoan && a.accountStatus !== AccountStatus.AccountClosed
      ),
    ];
  }

  @computed
  get allSecuredLoanAccounts(): ILoanAccount[] {
    return [
      ...this.activeSecuredLoanAccounts,
      ...this.blockedLoanAccounts.filter(
        (a) => a.accountType === LoanType.SecuredLoan && a.accountStatus !== AccountStatus.AccountClosed
      ),
    ];
  }

  @observable
  terminationDate?: Date;

  @observable
  paymentReference?: string;

  @observable
  paymentAccount?: string;

  @observable
  terminationAmount?: number;

  @observable
  fetchingTerminationAmount: boolean = false;

  @observable
  terminationAmountError: boolean = false;

  @computed
  get templateData() {
    return {
      terminationAmountDate: dayjs(this.terminationDate).format("YYYY-MM-DD"),
    };
  }

  @computed
  get currentAccountNumber(): string {
    return this.rootStore.currentAccountNumber;
  }

  set currentAccountNumber(accountNumber: string) {
    this.rootStore.currentAccountNumber = accountNumber;
  }

  @observable
  loanAccountDocuments?: IDocument[];

  @observable
  loadingDocuments: boolean = false;

  @computed
  get currentAccount(): ILoanAccount | undefined {
    return this.allLoanAccounts?.find((acc) => acc.accountNumber === this.currentAccountNumber);
  }

  @computed
  get hasAccounts(): boolean {
    return this.allLoanAccounts !== undefined && this.allLoanAccounts.length > 0;
  }

  @computed
  get hasPrivateLoanAccounts(): boolean {
    return this.allPrivateLoanAccounts !== undefined && this.allPrivateLoanAccounts.length > 0;
  }

  @computed
  get hasSecuredLoanAccounts(): boolean {
    return this.allSecuredLoanAccounts !== undefined && this.allSecuredLoanAccounts.length > 0;
  }

  setCurrentAccount(accountNumber: string): void {
    this.currentAccountNumber = accountNumber;
  }

  getLoanDocument = async (accountNumber: string) => {
    this.loanAccountDocuments = [];
    this.loadingDocuments = true;
    const response = await this.engagementsApi.getDocuments(accountNumber, DocumentType.Contract);
    if (response?.ok && response?.data?.documents) {
      const { documents } = response.data;
      this.loanAccountDocuments = documents;
    }
    this.loadingDocuments = false;
  };

  @action
  setTerminationDate = (date: Date) => {
    this.terminationDate = date;
  };

  @action
  getTerminationAmount = async () => {
    if (this.fetchingTerminationAmount || !this.terminationDate) {
      return;
    }
    this.terminationAmountError = false;
    this.fetchingTerminationAmount = true;

    const data: IGetTerminationAmountRequest = {
      accountNumber: this.currentAccountNumber,
      terminationDate: dayjs(this.terminationDate).format("YYYY-MM-DD") || "",
    };
    const response = await this.engagementsApi.getTerminationAmount(data);
    if (response?.ok && response?.data) {
      this.terminationAmount = response.data.amount;
      this.paymentReference = response.data.paymentReference;
      this.paymentAccount = response.data.paymentAccount;
    } else {
      this.terminationAmountError = true;
    }
    this.fetchingTerminationAmount = false;
  };

  @action
  saveAccountName = async (accName: string) => {
    if (!this.currentAccount) return false;

    this.isUpdatingAccountName = true;
    this.hasValidationAccountNameError = !AccountNameValidator(accName || "", {}).valid;

    if (this.hasValidationAccountNameError) {
      this.isUpdatingAccountName = false;
      return false;
    }

    const updatedData = {
      accountName: accName,
      accountNumber: this.currentAccount.displayNumber,
    };

    const response = await this.engagementsApi.saveAccountName(updatedData);

    if (response?.ok) {
      this.currentAccount.accountPersonalName = accName;
      await this.rootStore.getEngagements(true);
    } else {
      this.hasUpdateAccountNameError = true;
    }
    this.isUpdatingAccountName = false;
    return response?.ok;
  };

  @action
  resetLoanAccountDocuments = () => {
    this.loanAccountDocuments = undefined;
  };

  @action
  resetTerminationAmountState = () => {
    this.terminationAmount = undefined;
    this.terminationAmountError = false;
    this.fetchingTerminationAmount = false;
    this.terminationDate = undefined;
    this.paymentReference = undefined;
    this.paymentAccount = undefined;
  };

  @action
  resetStore = () => {
    this.loanAccounts = [];
    this.securedLoanAccounts = [];
    this.privateLoanAccounts = [];
    this.blockedLoanAccounts = [];
    this.blockedPrivateLoanAccounts = [];
    this.blockedSecuredLoanAccounts = [];
    this.resetTerminationAmountState();
  };
}
