import { IEngagementsCustomerResponse } from "../../../../models/Customer/EngagementsCustomer";

export const mockCustomerData: IEngagementsCustomerResponse = {
  customer: {
    customerId: "24325123",
    name: "Test",
    surName: "Testsson",
    address: {
      addressLine1: "Testvägen 1",
      addressLine2: "",
      city: "STOCKHOLM",
      country: "SWEDEN",
      zipCode: "12648",
    },
    email: "test@santanderconsumer.se",
    mobile: "+46700000000",
    phone: "46700000000",
    isPartnerCustomer: false,
    parties: [],
  },
};

export const mockBusinessCustomerData: IEngagementsCustomerResponse = {
  customer: {
    customerId: "707011",
    name: "Xos Tpqli",
    surName: "Knväöo",
    address: {
      addressLine1: "",
      addressLine2: "Qksboqrjnzk 85 S",
      city: "NYKÖPING",
      country: "SWEDEN",
      zipCode: "61131",
    },
    email: "test@santanderconsumer.se",
    mobile: "+46700000000",
    phone: "46700000000",
    isPartnerCustomer: true,
    parties: [
      {
        displayName: "Xos Tpqli Knväöo",
        identityNumber: "197902201234",
        isOrganization: false,
        displayIdentityNumber: "19790220-****",
      },
      {
        displayName: "Företagsnamn 1",
        identityNumber: "5562203967",
        isOrganization: true,
        displayIdentityNumber: "556220-3967",
      },
      {
        displayName: "Företagsnamn 2",
        identityNumber: "1234567890",
        isOrganization: true,
        displayIdentityNumber: "123456-7890",
      },
      {
        displayName: "Företagsnamn 3",
        identityNumber: "8936514931",
        isOrganization: true,
        displayIdentityNumber: "893651-4931",
      },
    ],
  },
};
