import React from "react";
import { observer } from "mobx-react";
import styles from "./DividerBlock.scss";
import rootStyles from "~views/pages/Root.scss";
import { IDivider } from "netbank-shared/src/libs/models/Content/Divider";
import { IGenericContentLink } from "netbank-shared/src/libs/models/Content/ContentLink";

interface IDividerBlockProps {
  data: IGenericContentLink<IDivider>;
  accordion?: boolean;
}

export const DividerBlock = observer(({ data, accordion }: IDividerBlockProps) => {
  const classes = [styles.wrapper];
  const sectionClasses = [rootStyles.noBorder];
  if (data.borderColor) {
    classes.push(styles[data.borderColor]);
  }

  if (accordion) {
    sectionClasses.push(rootStyles.noPadding);
  }

  return (
    <section className={sectionClasses.join(" ")}>
      <div className={classes.join(" ")}>
        {data.icon?.url && (
          <img className={styles.icon} src={data.icon.url} alt={data.icon.altText || data.icon.name} />
        )}
      </div>
    </section>
  );
});
