/* eslint-disable max-len */
import { ValidatorFuncProps, ValidatorOptionProps } from "./ValidatorProps";
import { tx } from "../i18n";

const regexp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;

export const EmailValidator: ValidatorFuncProps = (value: string, { errorName }: ValidatorOptionProps) => {
  let valid = true;
  let error = "";

  if (!value) {
    valid = false;
    error = tx("validators.required", {
      name: errorName,
    });
  } else if (!regexp.test(value)) {
    valid = false;
    error = tx("validators.email");
  }

  return { valid, error };
};
