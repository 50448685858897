import { observer } from "mobx-react";
import React from "react";
import styles from "./Login.scss";
import qrBorder from "~assets/qr-border.svg";

export interface IQRProps {
    token: string;
    isMobile?: boolean;
}

export const ImageQrBlock = observer(({ token }: IQRProps) => {
    return (
        <div
            className={styles.qrcode}
            style={{
                width: "250px",
                height: "250px",
            }}
        >
            <img
                src={qrBorder}
                alt="qr-border"
                style={{
                    width: "100%",
                    height: "100%",
                }}
            />
            <img width={220} style={{
                height: "220px",
                width: "220px"
            }} className={[styles.qr, styles.qrImg].join(" ")} src={`data:image/png;base64, ${token}`} />
        </div>
    );
});