import { ApplicationInsights, ICustomProperties, IEventTelemetry } from "@microsoft/applicationinsights-web";
import { makeObservable } from "mobx";
import { IPlatformStore } from "libs/models/PlatformStore";
import { GlobalPersistentStore } from "./GlobalPersistentStore";
import { IConfig } from "../libs/models/Config";

export class TelemetryStore {
    private telemetry: ApplicationInsights | undefined;

    private globalPersistentStore: GlobalPersistentStore;

    private platformStore: IPlatformStore;

    constructor(
        globalPersistentStore: GlobalPersistentStore,
        platformStore: IPlatformStore
    ) {
        this.globalPersistentStore = globalPersistentStore;
        this.platformStore = platformStore;
        makeObservable(this);
    }

    init = (config: IConfig | undefined) => {
        if (config?.INSTRUMENTATION_KEY) {
            this.telemetry = this.platformStore.getTelemetryProvider(config?.INSTRUMENTATION_KEY)
            this.telemetry?.addTelemetryInitializer(item => {
                if (item.data) {
                    item.data["correlation-id"] = this.platformStore.getSessionId();
                    item.data["customerId"] = this.globalPersistentStore.customerId;
                }
            })
            this.telemetry.loadAppInsights();
        }
    }

    trackEvent = (eventName: string, customProperties: ICustomProperties) => {
        try {
            this.telemetry?.trackEvent({
                name: eventName,
                properties: customProperties
            });
        }
        catch (ex) { }
    }

    trackSimpleEvent = (eventName: string, customProperty: string) => {
        try {
            this.telemetry?.trackEvent({
                name: eventName,
                properties: {
                    customProperty: customProperty
                }
            });
        }
        catch (ex) { }
    }
}