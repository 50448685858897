export const mockInsuranceLetters = {
  documents: [
    {
      id: "201",
      created: "2021-09-05T15:09:30",
      fileName: "SELT128 test-insurance-letter 1.pdf",
      mimeType: "application/pdf",
      documentType: "Insurance_letter",
      accountNumber: "1",
    },
    {
      id: "202",
      created: "2019-03-04T15:09:30",
      fileName: "SELT128 test-insurance-letter 2.pdf",
      mimeType: "application/pdf",
      documentType: "Insurance_letter",
    },
  ],
};
