import React from "react";
import { observer } from "mobx-react";
import { useStores } from "netbank-shared/src/hooks";
import { toLocaleString } from "netbank-shared/src/libs/utils";
import { IImage } from "netbank-shared/src/libs/models/Content/Image";
import { IFAQItem } from "netbank-shared/src/libs/models/Content/accordions/FAQItem";
import { IGenericContentLink } from "netbank-shared/src/libs/models/Content/ContentLink";
import { tx } from "netbank-shared/src/libs/i18n";
import styles from "../../LoanTopUpFlow.scss";
import offerStyles from "~views/pages/Offers/Offers.scss";
import { HtmlContent } from "~views/shared/HtmlContent/HtmlContent";
import { Button } from "~views/shared";
import { AccordionFaqBlock } from "~views/shared/ContentArea/Blocks/Accordions/AccordionFaqBlock";

export interface LoanTopUpDecisionSuccessProps {
  image?: IGenericContentLink<IImage>;
  header?: string;
  topText?: string;
  monthlyCostLabel?: string;
  newLoanAmountLabel?: string;
  repaymentTimeLabel?: string;
  interestLabel?: string;
  cancelButtonText?: string;
  cancelButtonAction?: () => void;
  continueButtonText?: string;
  continueButtonAction?: () => void;
  continueButtonLoading?: boolean;
  faqs?: IGenericContentLink<IFAQItem>[];
}

export const LoanTopUpDecisionSuccess = observer(
  ({
    image,
    header,
    topText,
    monthlyCostLabel,
    newLoanAmountLabel,
    repaymentTimeLabel,
    interestLabel,
    cancelButtonText,
    cancelButtonAction,
    continueButtonText,
    continueButtonAction,
    continueButtonLoading,
    faqs,
  }: LoanTopUpDecisionSuccessProps) => {
    const { offerStore, uiStore } = useStores();
    const { currency, locale } = uiStore;

    if (!offerStore.currentAccount) return null;

    let newLoanAmount = Math.abs(offerStore.currentAccount.bookedBalance || 0);

    if (offerStore.currentPendingApplication) {
      newLoanAmount += offerStore.currentPendingApplication.approvedAmount;
    } else {
      newLoanAmount += offerStore.topUpAmount;
    }

    const interestRate = offerStore.currentPendingApplication
      ? offerStore.currentPendingApplication.interestRate
      : offerStore.currentAccount.accountInterest;

    const headerStyles = [styles.headerText];

    if (!image?.url) {
      headerStyles.push(styles.noMarginLeft);
    }

    return (
      <div>
        <div className={styles.decisionHeader}>
          {image && <img src={image.url} alt={image.altText} />}
          <div className={headerStyles.join(" ")}>
            {header && <HtmlContent html={`<h1>${header}</h1>`} />}
            {topText && <HtmlContent html={topText} />}
          </div>
        </div>
        <div className={styles.successSummary}>
          <div className={styles.summaryCol}>
            <p>{monthlyCostLabel || tx("topUp.monthlyCostLabel")}</p>
            <div className={styles.summaryValue}>
              {toLocaleString(offerStore.topUpMonthlyCost, currency, locale, 0)}
            </div>
          </div>
          {interestRate && (
            <div className={styles.summaryCol}>
              <p>{interestLabel || tx("misc.interest")}</p>
              <div className={styles.summaryValue}>{`${toLocaleString(interestRate, undefined, locale, 2)} %`}</div>
            </div>
          )}
          <div className={[styles.summaryCol, styles.small].join(" ")}>
            <p>{newLoanAmountLabel || tx("topUp.newLoanAmountLabel")}</p>
            <div className={styles.summaryValue}>{toLocaleString(newLoanAmount, currency, locale)}</div>
          </div>
          <div className={[styles.summaryCol, styles.small].join(" ")}>
            <p>{repaymentTimeLabel || tx("topUp.repaymentTimeLabel")}</p>
            <div className={styles.summaryValue}>{`${offerStore.topUpRepaymentTime} ${tx("misc.years")}`}</div>
          </div>
        </div>
        {(cancelButtonAction || continueButtonAction) && (
          <div className={offerStyles.submit}>
            {cancelButtonAction && (
              <Button
                title={cancelButtonText || tx("misc.cancel")}
                onClick={cancelButtonAction}
                borderColor="black"
                bordered
                large
              />
            )}
            {continueButtonAction && (
              <Button
                title={continueButtonText || tx("topUp.choosePayoutAccount")}
                color="red"
                onClick={continueButtonAction}
                loading={continueButtonLoading}
                large
              />
            )}
          </div>
        )}
        {faqs && faqs.length > 0 && (
          <div className={styles.decisionFaqs}>
            {faqs.map((faqBlock) => (
              <AccordionFaqBlock key={faqBlock.contentLink.guidValue} data={faqBlock} />
            ))}
          </div>
        )}
      </div>
    );
  }
);
