import { observer } from "mobx-react";
import styles from "../BankAccountControlFlow.scss";
import React from "react";
import art from "~assets/Art-side-computer2.svg";
import { Button } from "~views/shared";
import { useStores } from "netbank-shared/src/hooks";
import { BankAccountControlFlowState } from "netbank-shared/src/libs/models/Content/Enums";

export const BankAccountControlFinalStep = observer(() => {
    const { uiStore } = useStores();

    const handleClose = () => {
        uiStore.setBankAccountControlFlowState(BankAccountControlFlowState.Completed);
    };

    const { header, text, buttonText } = uiStore.bankAccountControlFinalStepVariant;

    return (
        <div className={styles.finalStepContainer}>
            <div>
                <img src={art} alt="art" />
            </div>
            <div className={styles.textContainer}>
                <span className={styles.header}>{header}</span>
                <span className={styles.description}>{text}</span>
            </div>
            <div className={styles.buttonContainer}>
                <Button title={buttonText} color="white" onClick={handleClose} bordered fullWidth />
            </div>
        </div>
    );
}
);
