import { action, makeObservable, observable } from "mobx";
import { v4 as uuidv4 } from "uuid";
import { IAttachment } from "../libs/models/Attachment/Attachment";
import { InboxTabs } from "../libs/models/Message/Inbox";
import { ListItem } from "../libs/models/Content/ListItem";
import { EngagementsApi } from "../libs/api";
import { IThread } from "../libs/models/Message/Threads";
import {
  INewMessageRequest,
  IReplyRequest,
  ISelectedAccount,
  ISelectedArea,
  ISelectedSubject,
} from "../libs/models/Message/NewMessage";
import { AccountTypeAsProductType, MessageSender } from "../libs/models/Content/Enums";
import { CommonService } from "../libs/models/CommonService";

export class MessageStore {
  engagementsApi: EngagementsApi;

  commonService: CommonService;

  constructor(engagementsApi: EngagementsApi, commonService: CommonService) {
    this.engagementsApi = engagementsApi;
    this.commonService = commonService;
    makeObservable(this);
  }

  @observable attachments: IAttachment[] = [];

  @observable newMessageBody: string = "";

  @observable replyMessageBody: string = "";

  @observable subjectDropdownDisabled = true;

  @observable threads?: IThread[] = undefined;

  @observable inboxThreads?: IThread[] = undefined;

  @observable archivedThreads?: IThread[] = undefined;

  @observable sentThreads?: IThread[] = undefined;

  @observable unreadAmount: number = 0;

  @observable pageSize: number = 20;

  @observable hasNextPage: boolean = false;

  @observable account?: ISelectedAccount = undefined;

  @observable subject?: ISelectedSubject = undefined;

  @observable area?: ISelectedArea = undefined;

  @observable activeTab: InboxTabs = InboxTabs.inbox;

  @observable threadData?: IThread = undefined;

  @observable fetchingThreads: boolean = false;

  @observable fetchingThread: boolean = false;

  @observable sendingMessage: boolean = false;

  @observable newMessageSuccess?: boolean = undefined;

  @observable replyMessageSuccess?: boolean = undefined;

  @observable maxFilesPerMessage: number = 5;

  @observable maxFileSize: number = 15728640; // in bytes

  @observable invalidFileSize: boolean = false;

  @observable invalidAttachments: boolean = false;

  @observable messageViewContent?: IThread = undefined;

  @action setMessageViewContent = (content?: IThread) => {
    this.messageViewContent = content;
  };

  @action removeMessageViewContent = () => {
    this.messageViewContent = undefined;
  };

  @action
  setActiveTab = (tab: InboxTabs) => {
    this.activeTab = tab;

    if (tab === InboxTabs.inbox) {
      this.threads = this.inboxThreads;
    }

    if (tab === InboxTabs.archive) {
      this.threads = this.archivedThreads;
    }

    if (tab === InboxTabs.sent) {
      this.threads = this.sentThreads;
    }
  };

  @action
  setNewMessageBody = (value: string) => {
    this.newMessageBody = value;
  };

  @action
  setReplyMessageBody = (value: string) => {
    this.replyMessageBody = value;
  };

  @action
  setAccount = (listItem: ListItem) => {
    if (listItem.value) {
      this.account = {
        label: listItem.label,
        accountNumber: listItem.value,
        tag: AccountTypeAsProductType[listItem.extra.key as keyof typeof AccountTypeAsProductType],
      };
      this.subjectDropdownDisabled = false;
    }
    if (!listItem.value) {
      this.account = undefined;
      this.subjectDropdownDisabled = true;
    }
    this.subject = undefined;
    this.area = undefined;
  };

  @action
  setSubject = (listItem: ListItem) => {
    this.area = undefined;
    if (listItem.value) {
      this.subject = {
        label: listItem.label,
        tag: listItem.value,
        areas: listItem.extra.tags,
        showTextarea: listItem.extra.showTextarea,
        sendable: listItem.extra.sendable,
      };

      if (listItem.extra.tags?.length === 1) {
        const areaItem = listItem.extra.tags[0];
        const area: ISelectedArea = {
          label: areaItem.text,
          tag: areaItem.key,
          showTextarea: areaItem.showTextarea,
          sendable: areaItem.sendable,
        };
        this.area = area;
      }
    }
    if (!listItem.value) {
      this.subject = undefined;
    }
  };

  @action
  setArea = (listItem: ListItem) => {
    if (listItem.value) {
      this.area = {
        label: listItem.label,
        tag: listItem.value,
        showTextarea: listItem.extra.showTextarea,
        sendable: listItem.extra.sendable,
      };
    }
    if (!listItem.value) {
      this.area = undefined;
    }
  };

  @observable newMessageRequest: INewMessageRequest = {
    title: "",
    body: "",
    accountNumber: "",
    subjectTag: "",
    areaTag: "",
    file: undefined,
  };

  @observable newReplyMessageRequest: IReplyRequest = {
    threadId: "",
    body: "",
    file: undefined,
  };

  @action
  addAttachment = (file: FileList) => {
    this.invalidFileSize = false;
    this.invalidAttachments = false;
    if (!this.validateAttachment(file)) return;
    const attachment: IAttachment = {
      name: file.item(0)?.name ?? "",
      file: file.item(0) ?? undefined,
      id: uuidv4(),
    };
    this.attachments.push(attachment);
  };

  @action
  removeAttachment = (id: string) => {
    this.invalidAttachments = false;
    this.invalidFileSize = false;
    this.attachments = this.attachments.filter((attachment) => attachment.id !== id);
  };

  @action
  validateAttachment = (file: FileList) => {
    let isValid = true;
    if (file.length === 0) isValid = false;
    if (file.length + this.attachments.length > this.maxFilesPerMessage) {
      isValid = false;
      this.invalidAttachments = true;
    }
    for (let i = 0; i < file.length; i += 1) {
      const fileItem = file.item(i);
      if (fileItem && fileItem.size > this.maxFileSize) {
        isValid = false;
        this.invalidFileSize = true;
      }
    }
    return isValid;
  };

  hasResponseFromCustomerService = (thread: IThread) => {
    return thread.messages.some((message) => message.creatorReference === MessageSender.santander);
  };

  @action
  fetchThreads = async (loading: boolean = true) => {
    this.fetchingThreads = loading && true;
    const response = await this.engagementsApi.getThreads();

    if (response?.ok && response.data?.threads) {
      const threadList = response.data.threads
        .filter((t) => t.messages.length > 0)
        .sort((a, b) => {
          const messagesA = a.messages.sort((messageA, messageB) => {
            return new Date(messageB.created).getTime() - new Date(messageA.created).getTime();
          });
          const messagesB = b.messages.sort((messageA, messageB) => {
            return new Date(messageB.created).getTime() - new Date(messageA.created).getTime();
          });

          const createdA = messagesA[0].created;
          const createdB = messagesB[0].created;
          const dateA = new Date(createdA).getTime();
          const dateB = new Date(createdB).getTime();
          return dateB - dateA;
        });

      const threadListFiltered = threadList.filter((thread) => this.hasResponseFromCustomerService(thread));

      this.unreadAmount =
        threadListFiltered.filter((thread) => !thread.customerAccessed && !thread.archivedByCustomer).length || 0;

      this.archivedThreads = threadList.filter((t) => t.archivedByCustomer);
      this.inboxThreads = threadListFiltered.filter((t) => !t.archivedByCustomer);
      this.sentThreads = threadList.filter(
        (t) => t.messages.find((m) => m.creatorReference !== MessageSender.santander) && !t.archivedByCustomer
      );

      if (this.activeTab === InboxTabs.inbox) {
        this.threads = this.inboxThreads;
      }
      if (this.activeTab === InboxTabs.archive) {
        this.threads = this.archivedThreads;
      }
      if (this.activeTab === InboxTabs.sent) {
        this.threads = this.sentThreads;
      }
    }
    this.fetchingThreads = false;
  };

  @action
  fetchThread = async (threadId: string) => {
    this.fetchingThread = true;
    const response = await this.engagementsApi.getThread(threadId);

    if (response?.ok && response.data) {
      // Messages with multiple attachments are split into one message with body
      // and one or more messages without a body. They are here grouped back
      // together into messages with multiple attachments.

      const sortedMessages = response.data.messages.sort((a, b) => {
        const dateA = new Date(a.created).getTime();
        const dateB = new Date(b.created).getTime();
        return dateA - dateB;
      });

      const messagesWithAttachments = [];
      let bodyMessage;
      for (let i = 0; i < sortedMessages.length; i += 1) {
        const message = sortedMessages[i];
        const lastLoop = i === response.data.messages.length - 1;
        let attachmentResponse;

        if (message.attachmentId) {
          /* eslint-disable no-await-in-loop */
          attachmentResponse = await this.engagementsApi.getAttachment(threadId, message.attachmentId);

          if (attachmentResponse?.ok && attachmentResponse.data) {
            if (message.body) {
              if (bodyMessage) {
                messagesWithAttachments.push(bodyMessage);
              }
              bodyMessage = {
                attachments: [attachmentResponse.data],
                ...message,
              };
            }
            if (bodyMessage && !message.body) {
              bodyMessage.attachments.push(attachmentResponse.data);
            }
          } else {
            messagesWithAttachments.push(message);
          }
          if (lastLoop && bodyMessage) {
            messagesWithAttachments.push(bodyMessage);
          }
        } else {
          if (bodyMessage) {
            messagesWithAttachments.push(bodyMessage);
          }
          bodyMessage = undefined;
          messagesWithAttachments.push(message);
        }
      }
      const newThreadData = response.data;
      newThreadData.messages = messagesWithAttachments.sort((a, b) => {
        const dateA = new Date(a.created).getTime();
        const dateB = new Date(b.created).getTime();
        return dateB - dateA;
      });
      this.setMessageViewContent(newThreadData);
      const threadIdFromUrl = this.commonService.getQueryParam("threadId");
      if (threadIdFromUrl === newThreadData.threadId) {
        this.threadData = newThreadData;
      }
    }
    this.fetchingThread = false;
  };

  @action
  submit = async () => {
    this.sendingMessage = true;
    if (this.account && this.subject) {
      this.newMessageRequest = {
        accountNumber: this.account.accountNumber,
        subjectTag: this.subject.tag,
        areaTag: this.subject.areas?.length > 0 && this.area ? this.area.tag : "",
        file: this.attachments[0]?.file,
        body: this.newMessageBody || `${this.account.label} ${this.subject.label} ${this.area ? this.area.label : ""}`,
        title: `${this.account.label} ${this.subject.label}`,
      };

      const response = await this.engagementsApi.createThread(this.newMessageRequest);

      if (response?.ok) {
        this.newMessageSuccess = true;
      } else {
        this.newMessageSuccess = false;
      }
    }
    this.sendingMessage = false;
  };

  @action
  reply = async (threadId: string) => {
    this.sendingMessage = true;
    if (this.replyMessageBody) {
      this.newReplyMessageRequest = {
        threadId,
        body: this.replyMessageBody,
        file: this.attachments[0]?.file,
      };

      const response = await this.engagementsApi.createReplyMessage(this.newReplyMessageRequest);

      if (response?.ok) {
        this.replyMessageSuccess = true;
      } else {
        this.replyMessageSuccess = false;
      }
    }
    this.sendingMessage = false;
  };

  @action
  markAsRead = async (threadId: string) => this.engagementsApi.markThreadAsRead(threadId);

  @action
  archive = async (threadId: string) => this.engagementsApi.archiveThread(threadId);

  @action
  resetThreadData = () => {
    this.threadData = undefined;
  };

  @action
  resetStore = (resetThreads = false) => {
    this.account = undefined;
    this.subject = undefined;
    this.area = undefined;
    this.subjectDropdownDisabled = true;
    this.attachments = [];
    this.newMessageBody = "";
    this.replyMessageBody = "";
    this.newMessageRequest = {
      accountNumber: "",
      subjectTag: "",
      areaTag: "",
      body: "",
      file: undefined,
      title: "",
    };
    this.newReplyMessageRequest = {
      threadId: "",
      body: "",
      file: undefined,
    };
    this.activeTab = InboxTabs.inbox;
    this.threadData = undefined;
    this.fetchingThreads = false;
    this.fetchingThread = false;
    this.newMessageSuccess = undefined;
    this.replyMessageSuccess = undefined;
    this.invalidFileSize = false;
    this.invalidAttachments = false;
    this.sendingMessage = false;
    this.messageViewContent = undefined;

    if (resetThreads) {
      this.inboxThreads = undefined;
      this.threads = undefined;
      this.archivedThreads = undefined;
      this.sentThreads = undefined;
      this.unreadAmount = 0;
      this.hasNextPage = false;
    } else {
      this.threads = this.inboxThreads;
    }
  };
}
