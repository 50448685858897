import React, { useState } from "react";
import { observer } from "mobx-react";
import styles from "./Applicant.scss";
import { ITopUpApplicantForm } from "netbank-shared/src/libs/models/Offer/Offer";
import { tx } from "netbank-shared/src/libs/i18n";
import { useStores } from "netbank-shared/src/hooks";
import { Dropdown, Input, RadioButtons } from "..";
import { toLocaleString } from "netbank-shared/src/libs/utils";

export interface IApplicantLivingSituationLabelsProps {
  housingTypeLabel: string;
  housingCostLabel: string;
  housingCostInformation: string;
  totalSizeOfAllDebtLabel: string;
  totalSizeOfAllDebtInformation: string;
  totalCostOfAllDebtLabel: string;
  totalCostOfAllDebtInformation: string;
  numberOfChildrenLabel: string;
  numberOfChildrenInformation: string;
  sharedAccomodationLabel: string;
}

interface IApplicantLivingSituationProps {
  data: IApplicantLivingSituationLabelsProps;
  applicant: ITopUpApplicantForm;
  setApplicant: (applicant: ITopUpApplicantForm) => void;
  showValidationErrors: boolean;
  isMainApplicant?: boolean;
}

export const ApplicantLivingSituation = observer(
  ({ data, applicant, setApplicant, showValidationErrors, isMainApplicant }: IApplicantLivingSituationProps) => {
    const { offerStore, uiStore } = useStores();
    const { currencySymbol, locale } = uiStore;
    const [housingCostBlured, setHousingCostBlured] = useState(true);
    const [totalSizeOfAllDebtBlured, setTotalSizeOfAllDebtBlured] = useState(true);
    const [totalCostOfAllDebtBlured, setTotalCostOfAllDebtBlured] = useState(true);

    return (
      <div className={styles.wrapper}>
        {!isMainApplicant && (
          <div className={styles.inputWrapper}>
            <RadioButtons
              label={data.sharedAccomodationLabel || tx("topUp.sharedAccomodationLabel")}
              list={[
                {
                  label: tx("misc.yes"),
                  value: "yes",
                  index: 0,
                },
                {
                  label: tx("misc.no"),
                  value: "no",
                  index: 1,
                },
              ]}
              value={offerStore.applicantsShareLivingCost.value ? "yes" : "no"}
              onChange={(item) => {
                const value = item.value === "yes";
                offerStore.applicantsShareLivingCost.value = value;
              }}
              error={
                offerStore.showTopUpValidationErrors && !offerStore.applicantsShareLivingCost.isValid
                  ? tx("error.required")
                  : undefined
              }
            />
          </div>
        )}
        {(isMainApplicant || !offerStore.applicantsShareLivingCost.value) && (
          <>
            <div className={styles.inputWrapper}>
              <Dropdown
                label={data.housingTypeLabel || tx("topUp.housingTypeLabel")}
                list={offerStore.housingTypeList}
                onChange={(item) => {
                  setApplicant({
                    ...applicant,
                    housingType: { value: item.value, isValid: true },
                  });
                }}
                value={applicant.housingType.value || `${tx("misc.select")}...`}
                innerLabel={tx("misc.select")}
                error={
                  offerStore.showTopUpValidationErrors && !applicant.housingType.isValid
                    ? tx("error.required")
                    : undefined
                }
              />
            </div>
            <Input
              wrapperStyle={styles.inputWrapper}
              label={data.housingCostLabel || tx("topUp.housingCostLabel")}
              infoPopover={{
                popoverTitle: data.housingCostLabel || tx("topUp.housingCostLabel"),
                content: data.housingCostInformation,
              }}
              onBlur={() => {
                if (!applicant.housingCost)
                  setApplicant({
                    ...applicant,
                    housingCost: { value: 0, isValid: false },
                  });
                setHousingCostBlured(true);
              }}
              onFocus={() => {
                setHousingCostBlured(false);
              }}
              valueSuffix={` ${currencySymbol}`}
              value={
                housingCostBlured
                  ? toLocaleString(applicant.housingCost.value || 0, undefined, locale, 0)
                  : (applicant.housingCost.value || "").toString()
              }
              onChange={(e) => {
                const newAmount = parseInt(e.target.value.replace(/[^\d.,]/g, ""));
                setApplicant({
                  ...applicant,
                  housingCost: { value: newAmount, isValid: newAmount > 0 },
                });
              }}
              error={showValidationErrors && !applicant.housingCost.isValid ? tx("error.required") : undefined}
            />
            <Input
              wrapperStyle={styles.inputWrapper}
              label={data.totalSizeOfAllDebtLabel || tx("topUp.totalSizeOfAllDebtLabel")}
              infoPopover={{
                popoverTitle: data.totalSizeOfAllDebtLabel || tx("topUp.totalSizeOfAllDebtLabel"),
                content: data.totalSizeOfAllDebtInformation,
              }}
              onBlur={() => {
                if (!applicant.totalSizeOfAllDebt)
                  setApplicant({
                    ...applicant,
                    totalSizeOfAllDebt: { value: 0, isValid: true },
                  });
                setTotalSizeOfAllDebtBlured(true);
              }}
              onFocus={() => {
                setTotalSizeOfAllDebtBlured(false);
              }}
              valueSuffix={` ${currencySymbol}`}
              value={
                totalSizeOfAllDebtBlured
                  ? toLocaleString(applicant.totalSizeOfAllDebt.value || 0, undefined, locale, 0)
                  : (applicant.totalSizeOfAllDebt.value || "").toString()
              }
              onChange={(e) => {
                const newAmount = parseInt(e.target.value.replace(/[^\d.,]/g, ""));
                setApplicant({
                  ...applicant,
                  totalSizeOfAllDebt: { value: newAmount || 0, isValid: true },
                });
              }}
              error={showValidationErrors && !applicant.totalSizeOfAllDebt.isValid ? tx("error.required") : undefined}
            />
            <Input
              wrapperStyle={styles.inputWrapper}
              label={data.totalCostOfAllDebtLabel || tx("topUp.totalCostOfAllDebtLabel")}
              infoPopover={{
                popoverTitle: data.totalCostOfAllDebtLabel || tx("topUp.totalCostOfAllDebtLabel"),
                content: data.totalCostOfAllDebtInformation,
              }}
              onBlur={() => {
                if (!applicant.totalCostOfAllDebt)
                  setApplicant({
                    ...applicant,
                    totalCostOfAllDebt: { value: 0, isValid: true },
                  });
                setTotalCostOfAllDebtBlured(true);
              }}
              onFocus={() => {
                setTotalCostOfAllDebtBlured(false);
              }}
              valueSuffix={` ${currencySymbol}`}
              value={
                totalCostOfAllDebtBlured
                  ? toLocaleString(applicant.totalCostOfAllDebt.value || 0, undefined, locale, 0)
                  : (applicant.totalCostOfAllDebt.value || "").toString()
              }
              onChange={(e) => {
                const newAmount = parseInt(e.target.value.replace(/[^\d.,]/g, ""));
                setApplicant({
                  ...applicant,
                  totalCostOfAllDebt: { value: newAmount || 0, isValid: true },
                });
              }}
              error={showValidationErrors && !applicant.totalCostOfAllDebt.isValid ? tx("error.required") : undefined}
            />
            <Input
              wrapperStyle={styles.inputWrapper}
              label={data.numberOfChildrenLabel || tx("topUp.numberOfChildrenLabel")}
              infoPopover={{
                popoverTitle: data.numberOfChildrenLabel || tx("topUp.numberOfChildrenLabel"),
                content: data.numberOfChildrenInformation,
              }}
              value={(applicant.numberOfChildren.value || 0).toString()}
              onChange={(e) => {
                const newAmount = parseInt(e.target.value.replace(/[^\d.,]/g, ""));
                setApplicant({
                  ...applicant,
                  numberOfChildren: {
                    value: newAmount,
                    isValid: newAmount >= 0,
                  },
                });
              }}
              error={showValidationErrors && !applicant.numberOfChildren ? tx("error.required") : undefined}
            />
          </>
        )}
      </div>
    );
  }
);
