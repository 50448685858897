/* eslint-disable @typescript-eslint/no-unused-vars */
import i18next from "i18next";
import sv from "./translations/sv";
import en from "./translations/en";
import { getLangInUrl } from "../utils/url";
import { Lang } from "../models/Content/Enums";
import { supportedLangs } from "./translate";

const langInUrl = window?.location?.pathname ? getLangInUrl(window.location.pathname) : "";

i18next.init({
  lng: supportedLangs.includes(langInUrl) ? langInUrl : Lang.sv,
  debug: false,
  resources: {
    sv,
    en,
  },
  interpolation: {
    format: (value: string, format, _) => {
      switch (format) {
        /**
         * All letters to uppercase
         */
        case "upper":
          return value?.toUpperCase() || "";

        /**
         * All letters to lowercase
         */
        case "lower":
          return value?.toLowerCase() || "";

        /**
         * The first letter to uppercase and the rest lowercase
         */
        case "capital":
          return `${value?.slice(0, 1).toUpperCase() || ""}${value?.slice(1).toLowerCase() || ""}`;

        /**
         * Format an array into a comma-separated sentence where the two last values are
         * separated with "and" in a localized manner
         */
        case "to_sentence": {
          if (!Array.isArray(value)) {
            return value;
          }
          const array = value as string[];
          return array?.length > 1
            ? [array.slice(0, -1).join(", "), array.slice(-1)].join(` ${i18next.t(`misc.and`)} `)
            : array[0] || "";
        }
        default:
          return value || "";
      }
    },
  },
});
