import { SantanderAuthApi } from "libs/api";
import { TransferApi } from "libs/api/TransferApi";
import { BankIdSignStatus } from "../libs/models/Content/Enums";
import { makeObservable, observable } from "mobx";
import { IInitializationResult } from "libs/models/BankIdSignStore/InitializationResult";
import { TelemetryStore } from "./TelemetryStore";
import { LoginEvent } from "../libs/models/Telemetry/LoginEvents";

type AuthSource = "TRANSFER_API" | "AUTH_API";

export class BankIdSignStore {
    authApi: SantanderAuthApi;

    transferApi: TransferApi;

    telemetry: TelemetryStore;

    constructor(
        authApi: SantanderAuthApi,
        transferApi: TransferApi,
        telemetryStore: TelemetryStore,
    ) {
        this.telemetry = telemetryStore;
        this.authApi = authApi;
        this.transferApi = transferApi;
        makeObservable(this);
    }

    @observable
    status: BankIdSignStatus = BankIdSignStatus.Inactive;

    @observable
    authSource: AuthSource = "AUTH_API";

    @observable
    qrCodeRefresher?: NodeJS.Timeout;

    @observable
    collectRefresher?: NodeJS.Timeout;

    @observable
    bankIdStartState: string = "";

    @observable
    bankIdAutostartToken: string = "";

    @observable
    nonce: string = "";

    @observable
    qrCode: string = "";

    @observable
    isPaused: boolean = false;

    @observable
    isCollectPending: boolean = false;

    @observable
    onQrCodeRefreshFailed?: () => void

    @observable
    onCollectSuccess?: () => void

    @observable
    onCollectFailed?: (reason: "Timeout" | "Unknown") => void

    @observable
    onAutoStartTokenFetched?: (autoStartToken: string) => void;

    startLogin = async (returnUrl: string, sameDevice: boolean): Promise<IInitializationResult> => {
        this.authSource = "AUTH_API";
        this.status = BankIdSignStatus.Initializing;
        const result = await this.authApi.getStartState(sameDevice, returnUrl);
        if (!result?.ok) {
            this.telemetry.trackEvent(LoginEvent.Failed, result?.data ?? {});
            return {
                initialized: false
            };
        }

        this.nonce = result?.data?.nounce ?? "";
        this.bankIdAutostartToken = result?.data?.autoStartToken ?? "";

        await this.startSign(result?.data?.startState ?? "", sameDevice, "AUTH_API");

        return {
            initialized: true,
        };
    }

    startSign = async (bankIdStartState: string, isSameDevice: boolean, authSource: AuthSource) => {
        this.authSource = authSource;
        this.bankIdStartState = bankIdStartState;

        if (this.onAutoStartTokenFetched) {
            this.onAutoStartTokenFetched(this.bankIdAutostartToken);
        }

        if (!isSameDevice) {
            await this.getQrCode();
            this.startQrCodeRefresh();
        }

        this.startCollectingResult();

        this.status = BankIdSignStatus.Polling;
    }

    getQrCode = async () => {
        if (this.onQrCodeRefreshFailed == null) {
            throw "Qr callbacks are not set"
        };

        const result = this.authSource === "AUTH_API"
            ? await this.authApi.getQrCode(this.bankIdStartState)
            : await this.transferApi.getSignQrCode(this.bankIdStartState)

        if (!result?.ok) {
            if (this.authSource === "AUTH_API") {
                this.telemetry.trackEvent(LoginEvent.FailedQrCode, result?.data ?? {});
            }
            this.onQrCodeRefreshFailed();
            return;
        }

        this.qrCode = result.data?.qrCode ?? "";
    }

    collectResult = async () => {
        if (this.onCollectFailed == null || this.onCollectSuccess == null) {
            throw "Collect callbacks are not set"
        };

        const result = this.authSource === "AUTH_API"
            ? await this.authApi.collectResult(this.bankIdStartState, "")
            : await this.transferApi.pollSigning(this.bankIdStartState);

        if (!result?.ok) {
            if (this.authSource === "AUTH_API") {
                this.telemetry.trackEvent(LoginEvent.FailedCollect, result?.data ?? {});
            }

            this.onCollectFailed("Unknown");
        }

        if (result?.data?.status === "Ok") {
            this.onCollectSuccess();
        }
    }

    onePassCollect = async () => {
        this.status = BankIdSignStatus.Polling;
        const result = this.authSource === "AUTH_API"
            ? await this.authApi.collectResult(this.bankIdStartState, "")
            : await this.transferApi.pollSigning(this.bankIdStartState);

        const success = result?.data?.status === "Ok";

        this.status = BankIdSignStatus.Inactive;
        return success;
    }

    startQrCodeRefresh = async () => {
        //BankId requires polling every 1s, by accounting response time in scheduling we provide user with more consistent UI
        const scheduleQrCodeRefresh = (time: number) => setTimeout(async () => {
            if (this.status !== BankIdSignStatus.Polling) {
                this.clear();
                return;
            }

            const startTime = (new Date()).getTime();
            await this.getQrCode();
            const endTime = (new Date()).getTime();

            const delayForNextRefresh = 1000 - (endTime - startTime);
            this.qrCodeRefresher = scheduleQrCodeRefresh(delayForNextRefresh)
        }, time);

        scheduleQrCodeRefresh(1000);
    }

    startCollectingResult = async () => {
        let statusCheckCounter: number = 0;
        this.collectRefresher = setInterval(async () => {
            if (this.status !== BankIdSignStatus.Polling) {
                this.clear();
                return;
            }

            if (!this.isPaused && !this.isCollectPending) {
                this.isCollectPending = true;
                await this.collectResult();
                this.isCollectPending = false;
            }

            statusCheckCounter += 1;
            if (statusCheckCounter > 15 && this.onCollectFailed) {
                this.telemetry.trackSimpleEvent(LoginEvent.Failed, "Timeout");
                this.onCollectFailed("Timeout");
            }
        }, 2000);
    }

    clear = () => {
        clearTimeout(this.qrCodeRefresher);
        this.qrCodeRefresher = undefined;

        clearInterval(this.collectRefresher);
        this.collectRefresher = undefined;

        this.onAutoStartTokenFetched = undefined;
        this.onCollectFailed = undefined;
        this.onCollectSuccess = undefined;
        this.onQrCodeRefreshFailed = undefined;

        this.nonce = "";
        this.bankIdStartState = "";
        this.bankIdAutostartToken = "";
        this.isPaused = false;
        this.isCollectPending = false;
        this.status = BankIdSignStatus.Inactive;
    }
}