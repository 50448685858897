import dayjs from "dayjs";
import { action, computed, makeObservable, observable } from "mobx";
import { EngagementsApi, OffersApi } from "../libs/api";
import { tx } from "../libs/i18n";
import { CardActionBlockTypes, DocumentType } from "../libs/models/Content/Enums";
import {
  BlockCardReason,
  CardStatus,
  ICard,
  ICreditAccount,
  IDocument,
  OrderCardReason,
} from "../libs/models/Engagements";
import { Store } from "./Store";
import { AccountNameValidator } from "../libs/validators/AccountNameValidator";

export enum CardActionType {
  Block = "Block",
  Activate = "Activate",
  Order = "Order",
}

export interface ICardAction {
  timestamp?: number;
  accountNumber?: string;
  cardId?: string;
  actionType?: CardActionType;
}

export class CreditStore {
  offersApi: OffersApi;

  engagementsApi: EngagementsApi;

  rootStore: Store;

  constructor(offersApi: OffersApi, engagementsApi: EngagementsApi, rootStore: Store) {
    this.offersApi = offersApi;
    this.engagementsApi = engagementsApi;
    this.rootStore = rootStore;
    makeObservable(this);
  }

  cardSelectBlocks = [
    CardActionBlockTypes.AccordionActivateCardBlock,
    CardActionBlockTypes.AccordionBlockCardBlock,
    CardActionBlockTypes.AccordionEvryBlock,
    CardActionBlockTypes.AccordionOrderCardBlock,
  ];

  @observable
  creditAccounts: ICreditAccount[] = [];

  @observable
  creditAccountsError?: boolean;

  @observable
  blockedCreditAccounts: ICreditAccount[] = [];

  @observable
  isUpdatingAccountName: boolean = false;

  @observable
  hasUpdateAccountNameError?: boolean = false;

  @observable
  hasValidationAccountNameError?: boolean = false;

  @computed
  get allCreditAccounts(): ICreditAccount[] {
    return [...this.creditAccounts, ...this.blockedCreditAccounts];
  }

  @computed
  get currentAccountNumber(): string {
    return this.rootStore.currentAccountNumber;
  }

  set currentAccountNumber(accountNumber: string) {
    this.rootStore.currentAccountNumber = accountNumber;
  }

  @observable
  previousCardAction?: ICardAction;

  @observable
  creditAccountDocuments?: IDocument[];

  @observable
  loadingDocuments: boolean = false;

  @action
  unsetPreviousCardAction = () => {
    this.previousCardAction = undefined;
  };

  @computed
  get currentAccountPath() {
    if (!this.currentAccount) return undefined;
    const accountPath = `${tx("routing.lang")}/${tx("routing.card")}`;
    const accountNumber = `${this.currentAccount?.accountNumber.replace(/^0+/, "")}`;
    const returnPath = `${accountPath}/${accountNumber}`;
    return returnPath;
  }

  @computed
  get hasAccounts(): boolean {
    return this.allCreditAccounts !== undefined && this.allCreditAccounts.length > 0;
  }

  @computed
  get currentAccount(): ICreditAccount | undefined {
    return this.creditAccounts?.find((acc) => acc.accountNumber === this.currentAccountNumber);
  }

  set currentAccount(account: ICreditAccount | undefined) {
    this.currentAccountNumber = account?.accountNumber ?? "";
  }

  @observable
  currentCardId?: string;

  @computed
  get currentCard(): ICard | undefined {
    return this.cards?.find((card) => card?.cardId === this.currentCardId);
  }

  set currentCard(card: ICard | undefined) {
    this.currentCardId = card?.cardId;
  }

  @computed
  get cards(): ICard[] | undefined {
    const cardsFromAccounts: ICard[] = [];
    this.creditAccounts?.forEach((account) => {
      if (account.cards && account.cards.length > 0) {
        cardsFromAccounts.push(...account.cards);
      }
    });
    return cardsFromAccounts.length ? cardsFromAccounts : undefined;
  }

  @computed
  get currentAccountIsWay4Account() {
    return !!this.currentCard?.accountNumber?.match(/^9353\d+$/);
  }

  @computed
  get canShowEveryBlockForCurrentAccount() {
    return this.blockedCreditAccounts.length === 0 && this.currentAccountIsWay4Account;
  }

  @computed
  get currentCardContactCUS() {
    return this.currentAccountNumber?.match(/^2\d+$/) ? !this.currentAccount?.applicationReceived : false;
  }

  @computed
  get hasInactiveCards() {
    return (
      !!this.cards &&
      this.cards.findIndex(
        (c) =>
          c.status === CardStatus.Inactive ||
          (this.previousCardAction &&
            this.previousCardAction?.cardId === c.cardId &&
            this.previousCardAction?.actionType === CardActionType.Activate)
      ) >= 0
    );
  }

  @computed
  get hasActiveCards() {
    return (
      !!this.cards &&
      this.cards.findIndex(
        (c) =>
          c.status === CardStatus.Active ||
          (this.previousCardAction &&
            c.cardId === this.previousCardAction?.cardId &&
            this.previousCardAction.actionType === CardActionType.Activate)
      ) >= 0
    );
  }

  @computed
  get hasBlockableCards() {
    return (
      !!this.cards &&
      this.cards.findIndex((c) => {
        const isMarked = !c?.isVirtual && c.isActive === null;
        const hasBlockableStatus = c.status === CardStatus.Active || c.status === CardStatus.Inactive;
        const accountActedOnPreviously =
          this.previousCardAction &&
          c.accountNumber === this.previousCardAction?.accountNumber &&
          this.previousCardAction.actionType !== CardActionType.Order;

        return !isMarked && (hasBlockableStatus || accountActedOnPreviously);
      }) >= 0
    );
  }

  @computed
  get canBlockCurrentCard() {
    let canBlock = false;

    if (
      this.previousCardAction &&
      this.currentAccountNumber === this.previousCardAction?.accountNumber &&
      this.previousCardAction.actionType !== CardActionType.Order
    ) {
      canBlock = true;
    } else if (this.currentCard) {
      const isMarked = !this.currentCard?.isVirtual && this.currentCard.isActive === null;
      const hasBlockableStatus =
        this.currentCard.status === CardStatus.Active || this.currentCard.status === CardStatus.Inactive;

      canBlock = !!this.currentCard.cardId && !isMarked && hasBlockableStatus;
    }
    return canBlock;
  }

  @computed
  get cardOrderingDisabledForAllAccounts() {
    return (
      this.creditAccounts.length > 0 &&
      this.creditAccounts.reduce((accumulator, account) => accumulator && !account?.canOrderCard, true)
    );
  }

  @computed
  get canOrderCard() {
    let canOrder = false;
    this.creditAccounts?.forEach((account) => {
      const physicalCards = account.cards?.findIndex((c) => !c?.isVirtual);
      const hasPhysicalCards = typeof physicalCards !== "undefined" && physicalCards > -1;

      canOrder =
        canOrder ||
        (!hasPhysicalCards && account?.canOrderCard) ||
        (!!this.previousCardAction &&
          account.accountNumber === this.previousCardAction?.accountNumber &&
          this.previousCardAction?.actionType !== CardActionType.Activate);
    });
    return canOrder;
  }

  @computed
  get canOrderCardForCurrentAccount() {
    let canOrder = false;
    if (this.currentAccount) {
      if (
        this.previousCardAction &&
        this.currentAccountNumber === this.previousCardAction?.accountNumber &&
        this.previousCardAction?.actionType !== CardActionType.Activate
      ) {
        canOrder = true;
      } else {
        const physicalCards = this.currentAccount.cards?.findIndex((c) => !c?.isVirtual);
        const hasPhysicalCards = typeof physicalCards !== "undefined" && physicalCards > -1;
        canOrder = !hasPhysicalCards && !!this.currentAccount?.canOrderCard;
      }
    }
    return canOrder;
  }

  @computed
  get canActivateCurrentCard() {
    let canActivate = false;
    if (
      this.previousCardAction &&
      this.previousCardAction?.cardId === this.currentCardId &&
      this.previousCardAction?.actionType === CardActionType.Activate
    ) {
      canActivate = true;
    } else if (this.currentCard && !this.currentCard?.isVirtual) {
      canActivate =
        this.currentCard.isActive === false &&
        this.currentCard.status === CardStatus.Inactive &&
        (this.currentCard.isManufactured === undefined || this.currentCard.isManufactured === true);
    }

    return canActivate;
  }

  generateTransferCreditAccountParams = () => {
    return `accountNumber=${this.currentAccountNumber}`;
  };

  orderCard = async (accountNumber: string, reason?: OrderCardReason) => {
    const response = {
      blockCardSuccess: false,
      orderCardSuccess: false,
    };
    const orderResponse = await this.engagementsApi.orderCard(accountNumber, reason);
    if (orderResponse?.ok) {
      response.blockCardSuccess = true;
      response.orderCardSuccess = true;

      this.previousCardAction = {
        timestamp: dayjs().unix(),
        accountNumber: this.currentAccountNumber,
        cardId: undefined,
        actionType: CardActionType.Order,
      };
      await this.rootStore.getEngagements(true);
      this.currentCard = this.currentAccount?.cards?.find((card) => card.status === CardStatus.Inactive);
      // response code 400 if TFS block call failed, 408 if block TFS success and order tfs failed.
    } else if (orderResponse?.status === 408 && !this.currentAccountIsWay4Account) {
      response.blockCardSuccess = true;
    }
    return response;
  };

  activateCurrentCard = async (offerId?: string) => {
    let response;

    if (offerId) {
      response = await this.offersApi.consumeOffer(offerId);
    } else if (this.currentCard) {
      response = await this.engagementsApi.activateCard(this.currentCard.cardId);
    }

    if (response?.ok) {
      this.previousCardAction = {
        timestamp: dayjs().unix(),
        accountNumber: this.currentAccountNumber,
        cardId: this.currentCardId,
        actionType: CardActionType.Activate,
      };
      await this.rootStore.getEngagements(true);
    }

    return response;
  };

  blockCurrentCard = async (blockReason: BlockCardReason) => {
    let blockResponse;
    let response = false;

    if (this.currentCard && this.currentCard.cardId) {
      const currentCardId = this.currentCard.cardId;
      const currentCardAccountNumber = this.currentCard.accountNumber;
      blockResponse = await this.engagementsApi.hardblockCard(currentCardId, blockReason);
      if (blockResponse?.ok) {
        response = true;
        this.previousCardAction = {
          timestamp: dayjs().unix(),
          accountNumber: currentCardAccountNumber,
          cardId: currentCardId,
          actionType: CardActionType.Block,
        };

        await this.rootStore.getEngagements(true);
      }
    }
    return response;
  };

  getCreditDocument = async (accountNumber: string) => {
    this.creditAccountDocuments = [];
    this.loadingDocuments = true;
    const response = await this.engagementsApi.getDocuments(accountNumber, DocumentType.Contract);
    if (response?.ok && response?.data?.documents) {
      const { documents } = response.data;
      this.creditAccountDocuments = documents;
    }
    this.loadingDocuments = false;
  };

  getCard(id: string): ICard | undefined {
    return this.cards?.find((a) => a.cardId === id);
  }

  setCurrentCard(card?: ICard): void {
    this.currentCard = card;
    this.rootStore.currentAccountNumber = card?.accountNumber ?? "";
  }

  setCurrentAccount(account?: ICreditAccount): void {
    this.rootStore.currentAccount = account;
    if (account?.cards?.length) {
      const activeCard = account?.cards?.find((c) => c.status === CardStatus.Active);
      const inactiveCard = account?.cards?.find((c) => c.status === CardStatus.Inactive);

      this.currentCard = activeCard || inactiveCard;
    } else {
      this.currentCard = undefined;
    }
  }

  @action
  saveAccountName = async (accName: string) => {
    if (!this.currentAccount) return false;

    this.isUpdatingAccountName = true;
    this.hasValidationAccountNameError = !AccountNameValidator(accName || "", {}).valid;

    if (this.hasValidationAccountNameError) {
      this.isUpdatingAccountName = false;
      return false;
    }

    const updatedData = {
      accountName: accName,
      accountNumber: this.currentAccount.displayNumber,
    };

    const response = await this.engagementsApi.saveAccountName(updatedData);

    if (response?.ok) {
      this.currentAccount.accountPersonalName = accName;
      await this.rootStore.getEngagements(true);
    } else {
      this.hasUpdateAccountNameError = true;
    }
    this.isUpdatingAccountName = false;
    return response?.ok;
  };

  @action
  updateAccountName = (value: string) => {
    if (!this.currentAccount) return;
    this.currentAccount.name = value;
  };

  @action
  resetCreditAccountDocuments = () => {
    this.creditAccountDocuments = undefined;
  };

  @action
  resetStore = () => {
    this.creditAccounts = [];
    this.blockedCreditAccounts = [];
    this.loadingDocuments = false;
    this.unmountCurrentAccount();
  };

  @action
  unmountCurrentAccount = () => {
    this.creditAccountDocuments = undefined;
    this.currentCardId = undefined;
    this.previousCardAction = undefined;
  };
}
