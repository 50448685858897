import React from "react";
import { observer } from "mobx-react";
import styles from "./LinkItemCollectionBlock.scss";
import rootStyles from "~views/pages/Root.scss";
import { ILink, ILinkItemBlock, ILinkItemCollection } from "netbank-shared/src/libs/models/Content/LinkItem";
import { IGenericContentLink } from "netbank-shared/src/libs/models/Content/ContentLink";
import { getUrlType } from "netbank-shared/src/libs/utils";
import { UrlType } from "netbank-shared/src/libs/models/Content/Enums";
import file from "~assets/file.svg";
import pdfIcon from "~assets/pdf-icon.svg";
import mail from "~assets/mail.svg";
import phone from "~assets/phone.svg";
import externalLink from "~assets/external-link.svg";
import internalLink from "~assets/internal-link.svg";
import { ExpandableText } from "~views/shared/ExpandableText/ExpandableText";
import { DynamicLink } from "~views/shared";

interface ILinkItemCollectionBlockProps {
  data: IGenericContentLink<ILinkItemCollection>;
  accordion?: boolean;
  noBorder?: boolean;
  noBottomPadding?: boolean;
  noTopPadding?: boolean;
}

const renderUrlTypeIcon = (urlType: UrlType) => {
  switch (urlType) {
    case UrlType.MailTo:
      return <img src={mail} alt="mail-icon" />;
    case UrlType.Tel:
      return <img src={phone} alt="phone-icon" />;
    case UrlType.File:
      return <img src={file} alt="file-icon" />;

    case UrlType.PDFFile:
      return <img className={[rootStyles.pdfIcon, styles.pdfIcon].join(" ")} src={pdfIcon} alt="pdf-icon" />;

    case UrlType.Internal:
      // TODO: Update with correct icon when design is ready
      return <img src={internalLink} alt="internal-link-icon" />;

    case UrlType.External:
      // TODO: Update with correct icon when design is ready
      return <img src={externalLink} alt="external-link-icon" />;

    default:
      // TODO: Update with correct icon when design is ready
      return <img src={internalLink} alt="internal-link-icon" />;
  }
};

export const LinkItemCollectionBlock = observer(
  ({ data, accordion, noBorder, noBottomPadding, noTopPadding }: ILinkItemCollectionBlockProps) => {
    const classes = [];
    if (accordion) {
      classes.push(rootStyles.noPadding, rootStyles.noBorder);
    }

    if (noBorder) {
      classes.push(rootStyles.noBorder);
    }

    if (noBottomPadding) {
      classes.push(rootStyles.noBottomPadding);
    }

    if (noTopPadding) {
      classes.push(rootStyles.noTopPadding);
    }

    const renderLinks = () => {
      const links: ILinkItemBlock[] | ILink[] = data?.linkItems || data?.links;

      return (
        links?.length > 0 && (
          <ul className={styles.links}>
            {links.map((linkItem: ILinkItemBlock | ILink) => {
              const link: ILink = (linkItem as ILinkItemBlock)?.link?.[0] || linkItem;
              const urlType = getUrlType(link.href);
              const isPdf = link.href.endsWith(".pdf");
              return (
                <li key={link.contentLink?.guidValue || link.href}>
                  <DynamicLink
                    to={link.href}
                    target={link.target}
                    download={urlType === UrlType.File ? "file" : undefined}
                    trackingAction={(linkItem as ILinkItemBlock)?.trackingAction}
                    trackingCategory={(linkItem as ILinkItemBlock)?.trackingCategory}
                  >
                    <span className={styles.link}>
                      {renderUrlTypeIcon(isPdf ? UrlType.PDFFile : urlType)}
                      {link.text || link.title}
                    </span>
                  </DynamicLink>
                </li>
              );
            })}
          </ul>
        )
      );
    };

    return (
      <section className={classes.join(" ")}>
        {data.isExpandable ? (
          <ExpandableText header={data.header || ""}>{renderLinks()}</ExpandableText>
        ) : (
          <>
            {data.header && <h3 className={rootStyles.bold}>{data.header}</h3>}
            {renderLinks()}
          </>
        )}
      </section>
    );
  }
);
