import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useStores } from "netbank-shared/src/hooks";
import { IMessageInboxPage } from "netbank-shared/src/libs/models/Content/Page";
import { tx } from "netbank-shared/src/libs/i18n";
import { InboxTabs } from "netbank-shared/src/libs/models/Message/Inbox";
import { getQueryParam } from "../../../utils/misc";
import { Button, Lottie } from "~views/shared";
import { DynamicLink } from "~views/shared/DynamicLink/DynamicLink";
import styles from "./MessageInbox.scss";
import rootStyles from "../Root.scss";
import { MessageRow } from "./MessageRow";
import { Message } from "./Message";
import { ContentArea } from "~views/shared/ContentArea/ContentArea";
import { HtmlContent } from "~views/shared/HtmlContent/HtmlContent";
import loadingAnimationData from "~assets/lottie/loading.json";
import writeIcon from "~assets/write.svg";
import { TrackingAction, TrackingCategory } from "netbank-shared/src/libs/models/Tracking";

export const MessageInbox = observer(() => {
  const { contentStore, uiStore, messageStore } = useStores();
  const page = contentStore.currentPage as IMessageInboxPage;

  const [frontendIndex, setFrontendIndex] = useState<number>(messageStore.pageSize);
  const [fetchingMore, setFetchingMore] = useState(false);

  const hasNextPage = messageStore.threads && messageStore.threads.length > frontendIndex;
  const threadIdFromUrl = getQueryParam(window.location.search, "threadId");

  const fetchMore = async () => {
    setFetchingMore(true);
    setFrontendIndex(frontendIndex + messageStore.pageSize);
    setFetchingMore(false);
  };

  const spinnerClasses = [rootStyles.spinner, styles.spinner];
  const messageClasses = [rootStyles.noBorder, rootStyles.accordion];

  if (uiStore.isMobile) {
    messageClasses.push(rootStyles.noPadding);
  }

  if (fetchingMore) {
    spinnerClasses.push(rootStyles.loading);
  }

  useEffect(() => {
    const fetchData = async () => {
      if (threadIdFromUrl) {
        await messageStore.fetchThread(threadIdFromUrl);
      }
      if (!threadIdFromUrl && !messageStore.threads) {
        await messageStore.fetchThreads();
      }
    };
    fetchData();

    return () => messageStore.resetStore();
  }, []);

  const renderTabs = () => {
    return (
      <div className={styles.tabs}>
        <Button
          className={`${styles.tab} ${messageStore.activeTab === InboxTabs.inbox ? styles.active : ""}`}
          onClick={() => messageStore.setActiveTab(InboxTabs.inbox)}
        >
          <span className={styles.title}>{tx("message.inbox")}</span>
        </Button>
        <Button
          className={`${styles.tab} ${messageStore.activeTab === InboxTabs.sent ? styles.active : ""}`}
          onClick={() => messageStore.setActiveTab(InboxTabs.sent)}
        >
          <span className={styles.title}>{tx("message.sent")}</span>
        </Button>
        <Button
          className={`${styles.tab} ${messageStore.activeTab === InboxTabs.archive ? styles.active : ""}`}
          onClick={() => messageStore.setActiveTab(InboxTabs.archive)}
        >
          <span className={styles.title}>{tx("message.archive")}</span>
        </Button>
      </div>
    );
  };

  return (
    <>
      {page.pageDescription && (
        <section className={styles.pageDescription}>
          <HtmlContent html={page.pageDescription} />
        </section>
      )}
      {threadIdFromUrl && <Message data={messageStore.threadData} />}
      {!threadIdFromUrl && (
        <section className={messageClasses.join(" ")}>
          {uiStore.isSm && <div className={styles.outsideTabsContainer}>{renderTabs()}</div>}
          <div className={styles.inboxWrapper}>
            <div className={styles.inboxHeader}>
              {!uiStore.isSm && renderTabs()}
              {page?.newMessagePage && (
                <DynamicLink
                  to={page?.newMessagePage}
                  trackingCategory={TrackingCategory.Messages}
                  trackingAction={TrackingAction.NewMessage}
                  tabIndex={-1}
                >
                  <Button
                    title={page.newMessageButtonText ?? tx("message.composeNewMessage")}
                    color="red"
                    fullWidth={uiStore.isSm}
                    iconPrefix={uiStore.isSm && writeIcon}
                    centered
                  />
                </DynamicLink>
              )}
            </div>
            <div className={styles.messageHeader}>
              <div className={styles.dateTitle}>{tx("misc.date")}</div>
              <div className={styles.messageTitle}>{tx("misc.message")}</div>
              <div className={styles.offset} />
            </div>
            <div className={styles.messages}>
              {(!messageStore.threads || messageStore.threads.length === 0) && !messageStore.fetchingThreads && (
                <div className={styles.emptyInboxWrapper}>
                  <span className={styles.emptyInboxHeader}>
                    {page.inboxEmptyStateHeader || tx("message.inboxEmpty")}
                  </span>
                  <HtmlContent className={styles.emptyInboxText} html={page.inboxEmptyStateText} />
                </div>
              )}
              {messageStore.fetchingThreads && (
                <div className={styles.loadingRow}>
                  <Lottie
                    options={{
                      animationData: loadingAnimationData,
                    }}
                    height={100}
                    width={100}
                  />
                </div>
              )}
              {!messageStore.fetchingThreads &&
                messageStore.threads
                  ?.slice(0, frontendIndex)
                  .map((thread) => (
                    <MessageRow
                      displayLastSent={messageStore.activeTab === InboxTabs.sent}
                      data={thread}
                      key={`${thread.threadId}`}
                    />
                  ))}
              {hasNextPage && (
                <div onClick={fetchMore} className={[rootStyles.loadMoreRow, styles.loadMoreWrapper].join(" ")}>
                  <span>{page.loadMoreButtonText || tx("message.loadMore")}</span>
                  <span className={spinnerClasses.join(" ")} />
                </div>
              )}
            </div>
          </div>
        </section>
      )}
      <ContentArea />
    </>
  );
});
