import { IGenericContentLink } from "./ContentLink";
import { ILink, ILinkItemBlock } from "./LinkItem";

export interface INotification {
  level: NotificationLevel;
  header: string;
  content?: string;
  mobileContent?: string;
  link?: ILink[]; // deprecated
  linkItem?: IGenericContentLink<ILinkItemBlock[]>;
  hideLinkOnDesktop: boolean;
}

export enum NotificationLevel {
  error = "error",
  important = "important",
  information = "information",
}
