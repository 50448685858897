export const mockYearlyStatements = {
  documents: [
    {
      id: "101",
      created: "2021-01-01T15:09:30",
      fileName: "Årsrapport 2021.pdf",
      mimeType: "application/pdf",
      documentType: "YEARLY_STATEMENT",
      accountNumber: "",
    },
    {
      id: "102",
      created: "2020-04-01T15:09:30",
      fileName: "Årsrapport 2020.pdf",
      mimeType: "application/pdf",
      documentType: "YEARLY_STATEMENT_FATCA",
      accountNumber: "",
    },
    {
      id: "103",
      created: "2019-01-01T15:09:30",
      fileName: "Årsrapport 2019.pdf",
      mimeType: "application/pdf",
      documentType: "YEARLY_STATEMENT",
      accountNumber: "",
    },
    {
      id: "104",
      created: "2018-04-01T15:09:30",
      fileName: "Årsrapport 2018.pdf",
      mimeType: "application/pdf",
      documentType: "YEARLY_STATEMENT_CRS",
      accountNumber: "",
    },
    {
      id: "104",
      created: "2017-01-01T15:09:30",
      fileName: "Årsrapport 2018.pdf",
      mimeType: "application/pdf",
      documentType: "YEARLY_STATEMENT",
      accountNumber: "",
    },
  ],
};
