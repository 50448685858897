import React from "react";
import { observer } from "mobx-react";
import styles from "./Summary.scss";
import rootStyles from "~views/pages/Root.scss";
import { Colors } from "netbank-shared/src/libs/models/Content/Enums";
import { capitalize } from "netbank-shared/src/libs/utils";

interface ISummaryCol {
  label: string;
  value: string;
  key: string;
}

export interface ISummaryRow {
  cols: ISummaryCol[];
  key: string;
}

interface ISummaryProps {
  rows: ISummaryRow[];
  color?: Colors;
  label?: string;
}

export const Summary = observer(({ label, rows, color }: ISummaryProps) => {
  const summaryClasses = [styles.summary];

  if (color) {
    summaryClasses.push(styles[`bg${capitalize(color)}`]);
  }
  return (
    <div>
      {label && (
        <div>
          <span className={rootStyles.label}>{label}</span>
        </div>
      )}
      <div className={summaryClasses.join(" ")}>
        {rows.map((row) => {
          const singleCol = row.cols?.length === 1;
          const classes = [styles.summaryRow];

          if (singleCol) {
            classes.push(styles.singleCol);
          }
          return (
            <div className={classes.join(" ")} key={row.key}>
              {row.cols.map((col) => (
                <div className={styles.summaryCol} key={col.key}>
                  <div className={rootStyles.bold}>{col.label}</div>
                  <div>{col.value}</div>
                </div>
              ))}
            </div>
          );
        })}
      </div>
    </div>
  );
});
