import React from "react";
import styles from "./ProgressBar.scss";
import { tx } from "netbank-shared/src/libs/i18n";

interface IProgressBarProps {
  current: number;
  max: number;
  displayAmountHeader?: boolean;
  amountHeaderSuffix?: string;
  percentageCompletedSuffix?: string;
}

export const ProgressBar = ({
  current,
  max,
  displayAmountHeader,
  amountHeaderSuffix,
  percentageCompletedSuffix,
}: IProgressBarProps) => {
  const percentage = max === 0 ? 100 : (current / max) * 100;
  return (
    <div className={styles.wrapper}>
      <div className={styles.progressWrapper}>
        {!!displayAmountHeader && <p className={styles.progressState}>{`${current}/${max} ${amountHeaderSuffix}`}</p>}
        <div className={styles.progressBar}>
          <div
            style={{
              width: `${percentage}%`,
            }}
          />
        </div>
        <span className={styles.completedPercentage}>{`${Math.round(percentage)}% ${
          percentageCompletedSuffix || tx("misc.completed")
        }`}</span>
      </div>
    </div>
  );
};
