import { useTracking } from "netbank-shared/src/hooks";
import { Colors } from "netbank-shared/src/libs/models/Content/Enums";
import { TrackingAction, TrackingCategory } from "netbank-shared/src/libs/models/Tracking";
import { capitalize } from "netbank-shared/src/libs/utils";
import React, { forwardRef, ReactNode, useState } from "react";
import styles from "./Button.scss";

interface IButtonProps {
  active?: boolean;
  borderColor?: string;
  bordered?: boolean;
  centered?: boolean;
  children?: ReactNode;
  className?: string;
  color?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  iconPrefix?: string;
  iconSuffix?: string;
  justifySpaceBetween?: boolean;
  justifyStart?: boolean;
  large?: boolean;
  loading?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => Promise<boolean> | boolean | Promise<void> | void;
  successText?: string;
  title?: string;
  trackingAction?: TrackingAction;
  trackingCategory?: TrackingCategory;
  trackingPrefix?: string;
  trackingValue?: number;
  buttonHeight?: "regular" | "large";
  type?: "button" | "submit" | "reset";
  tabIndex?: 0 | -1 | undefined;
}

/* eslint-disable react/display-name */
export const Button = forwardRef<HTMLButtonElement, IButtonProps>(
  (
    {
      active,
      borderColor,
      bordered,
      centered,
      className,
      color,
      disabled,
      fullWidth,
      iconPrefix,
      iconSuffix,
      justifySpaceBetween,
      justifyStart,
      large,
      loading,
      onClick,
      successText,
      title,
      trackingCategory,
      trackingAction,
      trackingPrefix,
      trackingValue,
      buttonHeight = "regular",
      type = "button",
      tabIndex = undefined,
      children,
    },
    ref
  ) => {
    const [success, setSuccess] = useState(false);

    const dataLayer = useTracking((window as any).dataLayer);

    const clickHandler = async (e: React.MouseEvent<HTMLButtonElement>) => {
      if (loading || success || !onClick || disabled) return;
      if (trackingCategory && trackingAction) {
        dataLayer.pushInteraction(trackingCategory, trackingAction, trackingPrefix, trackingValue);
      }

      const isValid = await onClick(e);
      if (successText && isValid) {
        // Successful status is only shown if the onClick function returns true and successText is set.
        // onClick can by definition also return void. In this case, the success message will not being shown
        // regardless if successText is set or not.
        setSuccess(true);
        setTimeout(() => setSuccess(false), 5000);
      }
    };

    const classes = [styles.wrapper];
    const loaderClasses = [styles.loader];
    const contentClasses = [styles.content];
    const childrenClasses = [styles.children];

    if (className) {
      classes.push(className);
    }

    if (bordered) {
      classes.push(styles.bordered);
    }

    if (color) {
      classes.push(styles[color]);

      if (color === Colors.White) {
        loaderClasses.push(styles.red);
      }
    }

    if (borderColor) {
      classes.push(styles[`border${capitalize(borderColor)}`]);
    }

    if (buttonHeight) {
      classes.push(styles[`height${capitalize(buttonHeight)}`]);
    }

    if (fullWidth) {
      classes.push(styles.fullWidth);
    }

    if (active) {
      classes.push(styles.active);
    }

    if (large) {
      classes.push(styles.large);
    }

    if (success) {
      classes.push(styles.success);
      contentClasses.push(styles.success);
    }

    if (loading) {
      contentClasses.push(styles.loading);
      loaderClasses.push(styles.loading);
      childrenClasses.push(styles.loading);
    }

    if (disabled) {
      classes.push(styles.disabled);
    }

    if (centered) {
      contentClasses.push(styles.centered);
    }

    if (justifyStart) {
      contentClasses.push(styles.justifyStart);
    }

    if (justifySpaceBetween) {
      contentClasses.push(styles.spaceBetween);
    }

    return (
      <button
        ref={ref}
        className={classes.join(" ")}
        onClick={clickHandler}
        // eslint-disable-next-line react/button-has-type
        type={type}
        tabIndex={tabIndex}
      >
        {children && (
          <>
            <div className={loaderClasses.join(" ")} />
            <div className={childrenClasses.join(" ")}>{children}</div>
          </>
        )}
        {!children && (
          <>
            <div className={loaderClasses.join(" ")} />
            <div className={contentClasses.join(" ")}>
              <div className={styles.inner}>
                {iconPrefix && <img className={styles.iconPrefix} src={iconPrefix} alt="icon" />}
                {title && <span>{title}</span>}
                {iconSuffix && <img className={styles.iconSuffix} src={iconSuffix} alt="icon" />}
              </div>
              {successText && <span className={styles.successText}>{successText}</span>}
            </div>
          </>
        )}
      </button>
    );
  }
);
