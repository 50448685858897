import { observer } from "mobx-react";
import React, { CSSProperties, RefObject, useEffect, useState } from "react";
import { IImage } from "netbank-shared/src/libs/models/Content/Image";
import styles from "./Image.scss";

interface IImageProps {
  image: IImage;
  hoverImage?: IImage;
  activeImage?: IImage;
  active?: boolean;
  className?: string;
  style?: CSSProperties;
  wrapperStyle?: CSSProperties;
  hoverReference?: RefObject<HTMLElement>;
  aspectRatio?: number | string;
  maxWidth?: number;
}

function getAspectRatio(aspectRatio?: number | string) {
  const aspectRatioMatch = typeof aspectRatio === "string" && aspectRatio.match(/(\d+)[/|:](\d+)/);
  let ratio = 100;
  if (typeof aspectRatio === "number") {
    ratio = 100 / aspectRatio;
  } else if (aspectRatioMatch) {
    ratio = (parseFloat(aspectRatioMatch?.[2]) / parseFloat(aspectRatioMatch?.[1])) * 100;
  }
  return ratio;
}

export const Image = observer(
  ({
    image,
    hoverImage,
    activeImage,
    active = false,
    className,
    style,
    wrapperStyle,
    hoverReference,
    aspectRatio,
    maxWidth,
    ...rest
  }: IImageProps) => {
    const { name, url, altText } = image;

    const hoverName = hoverImage?.name;
    const hoverUrl = hoverImage?.url;
    const hoverAltText = hoverImage?.altText;

    const ratio = getAspectRatio(aspectRatio);

    const [hovering, setHovering] = useState(false);

    useEffect(() => {
      const events = ["mouseenter", "mouseleave"];
      if (hoverReference?.current) {
        events.forEach((e) => hoverReference.current?.addEventListener(e, () => setHovering(e === "mouseenter")));
      }
      return () => {
        if (hoverReference?.current) {
          events.forEach((e) => {
            hoverReference.current?.removeEventListener(e, () => setHovering(e === "mouseenter"));
          });
        }
      };
    }, []);

    const activeName = activeImage?.name;
    const activeUrl = activeImage?.url;
    const activeAltText = activeImage?.altText;

    const customStyle =
      style ||
      ({
        backgroundPosition: "center",
        backgroundSize: "contain",
      } as CSSProperties);
    const wrapperStyles = [styles.imagesWrapper];

    if (active && activeUrl) wrapperStyles.push(styles.active);
    if (hoverUrl) wrapperStyles.push(styles.hoverEnabled);
    if (hovering && hoverUrl) wrapperStyles.push(styles.hover);
    if (className) wrapperStyles.push(className);

    if (typeof url === "undefined" || url === "") {
      return null;
    }

    return (
      <div className={wrapperStyles.join(" ")} style={{ ...wrapperStyle }}>
        {url && (
          <img
            src={url}
            alt={altText || name}
            style={{
              backgroundImage: `url("${url}")`,
              paddingBottom: `${ratio}%`,
              maxWidth: `${maxWidth}px`,
              ...customStyle,
            }}
            className={styles.image}
            {...rest}
          />
        )}
        {hoverUrl && (
          <img
            src={hoverUrl}
            alt={hoverAltText || hoverName}
            style={{
              backgroundImage: `url("${hoverUrl}")`,
              paddingBottom: `${ratio}%`,
              maxWidth: `${maxWidth}px`,
              ...customStyle,
            }}
            className={styles.hoverImage}
            {...rest}
          />
        )}
        {activeUrl && (
          <img
            src={activeUrl}
            alt={activeAltText || activeName}
            style={{
              backgroundImage: `url("${activeUrl}")`,
              paddingBottom: `${ratio}%`,
              maxWidth: `${maxWidth}px`,
              ...customStyle,
            }}
            className={styles.activeImage}
            {...rest}
          />
        )}
      </div>
    );
  }
);
