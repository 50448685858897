import { IGetDocumentsReponse } from "../../../../models/Engagements";

export const mockDocuments: IGetDocumentsReponse = {
  documents: [
    {
      id: "56861069",
      created: "2021-05-05T15:09:29",
      fileName: "Test me-Contract 1.pdf",
      mimeType: "application/pdf",
      documentType: "Contract",
      accountNumber: "93534592612233",
    },
  ],
};
