import React from "react";
import { observer } from "mobx-react";
import { SkeletonWrapper } from "~views/shared";
import { useStores } from "netbank-shared/src/hooks";

export const ClosingDepositSkeleton = observer(() => {
  const { uiStore } = useStores();

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ paddingBottom: 20 }}>
        <SkeletonWrapper height={50} />
      </div>
      <div style={{ paddingBottom: 20 }}>
        <SkeletonWrapper height={125} />
      </div>
      {uiStore.isMobile ? (
        <div>
          <SkeletonWrapper height={50} />
          <SkeletonWrapper height={50} />
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignSelf: "flex-end",
          }}
        >
          <div style={{ paddingRight: 20 }}>
            <SkeletonWrapper height={50} width={150} />
          </div>
          <SkeletonWrapper height={50} width={150} />
        </div>
      )}
    </div>
  );
});
