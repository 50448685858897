import { observer } from "mobx-react";
import { useStores } from "netbank-shared/src/hooks";
import { BankAccountControlFlowState, QueryStringKeys } from "netbank-shared/src/libs/models/Content/Enums";
import React, { useEffect, useState } from "react";
import { KreditzInitialLoadingStep } from "./BankAccountControlFlow/BankAccountControlInitialLoadingStep";
import { BankAccountControlKreditzInControlStep } from "./BankAccountControlFlow/BankAccountControlKreditzInControlStep";
import { BankAccountControlPollingResultStep } from "./BankAccountControlFlow/BankAccountControlPollingResultStep";
import { BankAccountControlErrorStep } from "./BankAccountControlFlow/BankAccountControlErrorStep";
import {
  displayInitialLoading,
  displayKreditzInControlStep,
  displayErrorStep,
  displayPollingResultStep,
  displayFinalStep,
} from "netbank-shared/src/libs/utils/bankAccountControlFlow";
import { BankAccountControlLegalInformationStep } from "./BankAccountControlFlow/BankAccountControlLegalInformationStep";
import styles from "./BankAccountControlFlow.scss";
import { BankAccountControlFinalStep } from "./BankAccountControlFlow/BankAccountControlFinalStep";
import { BACEvents } from "netbank-shared/src/libs/models/Telemetry/BACEvents";
import { getQueryParam } from "~utils/misc";
import { IBankAccountControl } from "netbank-shared/src/libs/models/Content/IKreditzVerification";

export const BankAccountControlFlow = observer(() => {
  const { uiStore, transferStore, contentStore, telemetryStore } = useStores();
  const currentState = uiStore.bankAccountControlflowState;
  const [legalAcknowleged, setLegalAcknowleged] = useState<boolean>(false);
  const bankControlData: IBankAccountControl = contentStore.bankAccountControl;
  const [overflowHidden, setOverflowHidden] = useState<boolean>(false);

  useEffect(() => {
    if (uiStore.bankAccountControlflowState === BankAccountControlFlowState.Started) {
      transferStore.initializeKreditzFlow();
    }

    if (uiStore.bankAccountControlflowState === BankAccountControlFlowState.Completed) {
      uiStore.setBankAccountControlFlowState(BankAccountControlFlowState.Inactive);
    }
  }, [uiStore.bankAccountControlflowState]);

  useEffect(() => {
    if (bankControlData == null) {
      return;
    }

    const finalStepContentId = getQueryParam(location.search, QueryStringKeys.BankAccountControlFinalStepContentId);

    if (finalStepContentId && bankControlData.finalStepVariant) {
      const finalStepVariant = bankControlData.finalStepVariant.find(x => `${x.contentLink.id}` === finalStepContentId);
      if (finalStepVariant) {
        telemetryStore.trackSimpleEvent(BACEvents.Started, "Initialized with final step variant");
        uiStore.setBankAccountControlFinalStepVariant(finalStepVariant);
      }
    }
    else {
      uiStore.setBankAccountControlFinalStepVariant(undefined);
    }
  }, [bankControlData])


  if (bankControlData === undefined) {
    return null
  }
  return (
    <div
      className={styles.popupContentContainer}
      style={{
        overflow: overflowHidden || !legalAcknowleged ? "hidden" : "auto",
      }}
    >
      {!legalAcknowleged && (
        <BankAccountControlLegalInformationStep
          body={bankControlData?.legalInformation}
          title={bankControlData.legalInformationHeader}
          continueLabel={bankControlData.legalInformationAcknowledgeButton}
          handleContinue={() => setLegalAcknowleged(true)}
        />
      )}
      {displayInitialLoading(currentState) && <KreditzInitialLoadingStep />}
      {displayKreditzInControlStep(currentState) && (
        <BankAccountControlKreditzInControlStep setOverflowHidden={setOverflowHidden} />
      )}
      {displayPollingResultStep(currentState) && (
        <BankAccountControlPollingResultStep
          waitingForResultBody={bankControlData.waitingForResultBody}
          waitingForResultHeader={bankControlData.waitingForResultHeader}
        />
      )}
      {displayErrorStep(currentState) && (
        <BankAccountControlErrorStep
          tryAgainButton={bankControlData.tryAgainButton}
          cancelButton={bankControlData.cancelButton}
          errorHeader={bankControlData.errorHeader}
          errorMessage={bankControlData.errorMessage}
        />
      )}
      {displayFinalStep(currentState) && (
        <BankAccountControlFinalStep />
      )}
    </div>
  );
});
