export const mockLetters = {
  documents: [
    {
      id: "1",
      created: "2021-09-15T15:09:30",
      fileName: "SELT101 test-block-card 1.pdf",
      mimeType: "application/pdf",
      documentType: "Letter",
      accountNumber: "1",
    },
    {
      id: "2",
      created: "2019-03-14T15:09:30",
      fileName: "SELT101 test-block-card 2.pdf",
      mimeType: "application/pdf",
      documentType: "Letter",
    },
    {
      id: "3",
      created: "2018-09-12T15:09:30",
      fileName: "SELT102 test-termination 1.pdf",
      mimeType: "application/pdf",
      documentType: "Letter",
    },
    {
      id: "4",
      created: "2021-09-13T15:09:30",
      fileName: "SELT101 test-block-card 3.pdf",
      mimeType: "application/pdf",
      documentType: "Letter",
    },
  ],
};
