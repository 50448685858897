import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Variants, Transition, motion } from "framer-motion";

interface IPageLayoutProps {
    children: React.ReactNode;
}

const PageLayout = ({ children }: IPageLayoutProps) => children;

export const RoutingAnimationLayout = () => {
    const { pathname } = useLocation();

    const pageVariants: Variants = {
        initial: {
            opacity: 0,
        },
        in: {
            opacity: 1,
        },
        out: {
            opacity: 0,
        },
    };

    const pageTransition: Transition = {
        type: "tween",
        ease: "linear",
        duration: 0.3,
    };

    return (
        <PageLayout>
            <motion.div key={pathname} initial="initial" animate="in" variants={pageVariants} transition={pageTransition}>
                <Outlet />
            </motion.div>
        </PageLayout>
    );
};
