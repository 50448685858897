/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useRef, useState, RefObject } from "react";
import { observer } from "mobx-react";
import chevronRight from "~assets/chevron-right.svg";
import styles from "./Dropdown.scss";
import rootStyles from "~views/pages/Root.scss";
import { useStores } from "netbank-shared/src/hooks";
import { capitalize } from "netbank-shared/src/libs/utils";
import { IInfoPopoverProps, InfoPopover } from "../InfoPopover/InfoPopover";
import errorIcon from "~assets/error.svg";
import { ListItem } from "netbank-shared/src/libs/models/Content/ListItem";

interface IDropdownProps {
  list: ListItem[];
  value?: string;
  label?: string;
  innerLabel?: string;
  icon?: string;
  disabled?: boolean;
  fontWeight?: string;
  suffix?: string;
  suffixLabel?: string;
  infoPopover?: IInfoPopoverProps;
  error?: string;
  borderless?: boolean;
  smallChevron?: boolean;
  onChange: (item: ListItem) => void;
  onOpen?: () => void;
  onClose?: () => void;
}

export const Dropdown = observer(
  ({
    list,
    value,
    label,
    innerLabel,
    icon,
    disabled,
    fontWeight,
    suffix,
    suffixLabel,
    infoPopover,
    error,
    borderless,
    smallChevron,
    onChange,
    onOpen,
    onClose,
  }: IDropdownProps): JSX.Element => {
    const contentRef: RefObject<HTMLDivElement> = useRef(null);
    const { transactionStore, uiStore } = useStores();
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
      setOpen(true);
      onOpen?.();
    };

    const handleClose = () => {
      setOpen(false);
      onClose?.();
    };

    const toggleOpen = () => {
      if (open) handleClose();
      else handleOpen();
    };

    const handleClickOutside = (e: MouseEvent) => {
      if (contentRef && e.target instanceof Node && !contentRef.current?.contains(e.target)) {
        handleClose();
      }
    };

    const onClick = (item: ListItem) => {
      onChange(item);
      handleClose();
    };

    React.useEffect(() => {
      window.addEventListener("mousedown", handleClickOutside);

      return () => {
        window.removeEventListener("mousedown", handleClickOutside);
      };
    });

    const wrapperClasses = [styles.wrapper];
    const headerClasses = [styles.header];
    const dropdownListClasses = [styles.dropdownListWrapper];
    const expandIconClasses = [styles.expandIcon];

    if (open) {
      headerClasses.push(styles.open);
      dropdownListClasses.push(styles.open);
    }

    if (disabled) {
      headerClasses.push(styles.disabled);
      wrapperClasses.push(styles.faded);
    }

    if (fontWeight === "regular") {
      headerClasses.push(styles[`font${capitalize(fontWeight)}`]);
    }

    if (borderless) {
      headerClasses.push(styles.borderless);
    }

    if (smallChevron) {
      expandIconClasses.push(styles.smallChevron);
    }

    const onClickHeader = () => {
      if (disabled) return;
      if (!open && typeof onOpen !== "undefined") onOpen();
      toggleOpen();
    };
    return (
      <>
        <div className={wrapperClasses.join(" ")} ref={contentRef}>
          {label && (
            <div className={styles.labelRow}>
              <span className={styles.label}>{label}</span>
              {infoPopover?.content && <InfoPopover className={styles.popover} {...infoPopover} />}
            </div>
          )}
          <div className={styles.dropdownWrapper}>
            <div
              className={headerClasses.join(" ")}
              onClick={onClickHeader}
              // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
              tabIndex={0}
              onKeyPress={(e) => {
                if (e.key === "Enter") onClickHeader();
              }}
            >
              <div className={styles.title}>
                <div className={[styles.itemValue, rootStyles.textEllipsis].join(" ")}>
                  {icon && value && <img src={icon} alt="icon" />}
                  <span>{value}</span>
                </div>
                {suffix && !uiStore.isMobile && <div className={styles.suffix}>{suffix}</div>}
              </div>
              {!disabled && <img className={expandIconClasses.join(" ")} src={chevronRight} alt="" />}
            </div>
            <div className={dropdownListClasses.join(" ")}>
              <div className={styles.dropdownList}>
                <div className={styles.dropdownInnerHeader}>
                  <div className={styles.headerSeparator} />
                  {innerLabel && <span className={styles.innerLabel}>{innerLabel}</span>}
                </div>
                <ul>
                  {list.map((item) => {
                    const itemClasses = [styles.listItem];
                    const suffixClasses = [];
                    const iconClasses = [];
                    const isActive =
                      `${item.label}${item.valueSuffix}` === value || item.label === value || item.value === value;
                    if (item.label === transactionStore.dateRangeLabel || isActive) {
                      itemClasses.push(styles.active);
                    }
                    if (item.suffix) {
                      itemClasses.push(styles.suffixWrapper);
                    }
                    if (item.disabled) {
                      itemClasses.push(styles.disabled);
                      suffixClasses.push(styles.disabledSuffix);
                      iconClasses.push(styles.disabledIcon);
                    }
                    return (
                      <li className={itemClasses.join(" ")} key={`dropdown-${item.value}`}>
                        <div
                          className={styles.itemValueWrapper}
                          onClick={() => {
                            if (!item.disabled) onClick(item);
                          }}
                          onKeyPress={(e) => {
                            if (e.key === "Enter" && !item.disabled) onClick(item);
                          }}
                          role="button"
                          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                          tabIndex={open ? 0 : -1}
                        >
                          {item.icon && <img src={item.icon} alt="icon" className={iconClasses.join(" ")} />}
                          <div>
                            <div className={styles.itemValue}>
                              {item.label}
                              {item.valueSuffix}
                            </div>
                            {item.disabled && item.disabledText && (
                              <div className={styles.disabledText}>{item.disabledText}</div>
                            )}
                          </div>
                        </div>
                        {item.suffix && !uiStore.isMobile && (
                          <div className={suffixClasses.join(" ")}>{item.suffix}</div>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
          {uiStore.isMobile && suffix && suffixLabel && (
            <div className={styles.mobileSuffix}>
              <span>{suffixLabel}</span>
              <span>{suffix}</span>
            </div>
          )}
        </div>
        {error && (
          <div className={rootStyles.error}>
            <img src={errorIcon} alt="error-icon" />
            <span>{error}</span>
          </div>
        )}
      </>
    );
  }
);
