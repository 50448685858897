import { observer } from "mobx-react";
import React, { createRef, useRef } from "react";
import { Link } from "react-router-dom";
import logoutIconFallback from "~assets/logout.svg";
import mailIcon from "~assets/mail.svg";
import mailIconFull from "~assets/mail-full.svg";
import userIcon from "~assets/user-red.svg";
import userIconFull from "~assets/user-full.svg";
import { useStores } from "netbank-shared/src/hooks";
import { tx } from "netbank-shared/src/libs/i18n";
import { IImage } from "netbank-shared/src/libs/models/Content/Image";
import { TrackingAction, TrackingCategory } from "netbank-shared/src/libs/models/Tracking";
import { Button, DynamicLink, HamburgerNav, Image } from "..";
import styles from "./Header.scss";

export interface IHeaderProps {
  logoOnly?: boolean;
}

export const Header = observer(({ logoOnly = false }: IHeaderProps) => {
  const { contentStore, uiStore, messageStore, rootStore, securePersistentStore } = useStores();
  const { currentPage } = contentStore;
  const menuReferences = useRef([createRef(), createRef(), createRef()]);

  const logotype = uiStore.isMobile ? currentPage?.mobileLogotype?.url : currentPage?.logotype?.url;
  const { isMobile } = uiStore;
  const customerProfileButtonTitle = currentPage?.customerProfileButtonTitle;

  const logoutTitle = currentPage?.logoutLink?.[0]?.title || currentPage?.logoutLink?.[0]?.text || tx("misc.logout");
  const logoutIcon = currentPage?.logoutIcon?.url || logoutIconFallback;
  const logoutIconActive = currentPage?.logoutIconActive?.url || logoutIconFallback;
  const corporateCustomerContent = contentStore.getIsCorporateCustomerContent();

  return (
    <div className={styles.wrapper}>
      <div className={styles.contentWrapper}>
        {logotype &&
          (logoOnly ? (
            <img className={styles.logotype} src={logotype} alt="Santander" />
          ) : (
            <Link to={`/${tx("routing.lang")}`}>
              <img className={styles.logotype} src={logotype} alt="Santander" />
            </Link>
          ))}
        {securePersistentStore.accessToken && !uiStore.hasOutdatedBrowser && !logoOnly && (
          <>
            {!isMobile && currentPage && (
              <div className={styles.userIcons}>
                {currentPage.messageInboxPage && !corporateCustomerContent && !isMobile && (
                  <div className={styles.userIcon}>
                    <DynamicLink
                      ref={menuReferences.current[0]}
                      to={currentPage.messageInboxPage}
                      trackingAction={TrackingAction.Messages}
                      trackingCategory={TrackingCategory.Header}
                    >
                      <Image
                        hoverReference={menuReferences.current[0]}
                        image={
                          {
                            name: tx("misc.messages"),
                            url: mailIcon,
                            altText: tx("misc.messages"),
                          } as IImage
                        }
                        hoverImage={
                          {
                            name: tx("misc.messages"),
                            url: mailIconFull,
                            altText: tx("misc.messages"),
                          } as IImage
                        }
                        activeImage={
                          {
                            name: tx("misc.messages"),
                            url: mailIconFull,
                            altText: tx("misc.messages"),
                          } as IImage
                        }
                      />
                      <span data-text={tx("misc.messages")}>{tx("misc.messages")}</span>
                      {messageStore.unreadAmount > 0 && (
                        <span className={styles.badge}>{messageStore.unreadAmount}</span>
                      )}
                    </DynamicLink>
                  </div>
                )}
                {currentPage?.personalSettingsPage && (
                  <div className={styles.userIcon}>
                    <DynamicLink
                      ref={menuReferences.current[1]}
                      to={currentPage.personalSettingsPage}
                      trackingAction={TrackingAction.MyProfile}
                      trackingCategory={TrackingCategory.Header}
                    >
                      <Image
                        hoverReference={menuReferences.current[1]}
                        image={
                          {
                            name: tx("misc.customer"),
                            url: currentPage.personalSettingsIcon?.url || userIcon,
                            altText: tx("misc.customer"),
                          } as IImage
                        }
                        hoverImage={
                          {
                            name: tx("misc.customer"),
                            url: currentPage.personalSettingsIconActive?.url || userIconFull,
                            altText: tx("misc.customer"),
                          } as IImage
                        }
                        activeImage={
                          {
                            name: tx("misc.customer"),
                            url: currentPage.personalSettingsIconActive?.url || userIconFull,
                            altText: tx("misc.customer"),
                          } as IImage
                        }
                      />
                      <span data-text={customerProfileButtonTitle}>{customerProfileButtonTitle}</span>
                    </DynamicLink>
                  </div>
                )}
                <div className={styles.userIcon}>
                  <Button
                    ref={menuReferences.current[2]}
                    onClick={() => rootStore.logout(false, "User initiated")}
                    trackingAction={TrackingAction.LogOut}
                    trackingCategory={TrackingCategory.Header}
                  >
                    <Image
                      hoverReference={menuReferences.current[2]}
                      image={
                        {
                          name: logoutTitle,
                          url: logoutIcon,
                          altText: logoutTitle,
                        } as IImage
                      }
                      hoverImage={
                        {
                          name: logoutTitle,
                          url: logoutIconActive,
                          altText: logoutTitle,
                        } as IImage
                      }
                      activeImage={
                        {
                          name: logoutTitle,
                          url: logoutIconActive,
                          altText: logoutTitle,
                        } as IImage
                      }
                    />
                    <span data-text={logoutTitle}>{logoutTitle}</span>
                  </Button>
                </div>
              </div>
            )}
            {isMobile && (
              <div className={styles.buttonGroup}>
                {!corporateCustomerContent && (
                  <DynamicLink
                    to={currentPage?.messageInboxPage || `/${tx("routing.lang")}`}
                    className={styles.mailWithBadge}
                    trackingAction={TrackingAction.Messages}
                    trackingCategory={TrackingCategory.Header}
                  >
                    <img className={styles.mailIcon} src={mailIcon} alt="mail icon" />
                    {messageStore.unreadAmount > 0 && <span className={styles.badge}>{messageStore.unreadAmount}</span>}
                  </DynamicLink>
                )}
                <HamburgerNav />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
});
