import { observable, action, computed, makeObservable } from "mobx";
import dayjs from "dayjs";
import { onAnimationFrame } from "../libs/utils";
import { screenBreakpoints, TScreenKeys } from "../libs/constants";
import { BankAccountControlFlowState, Lang, Locale } from "../libs/models/Content/Enums";
import { IPopup } from "../libs/models/Content/Popup";
import { IModal } from "../libs/models/Content/Modal";
import { IPopover } from "../libs/models/Content/Popover";
import { IConfirmationPopup } from "../libs/models/Content/ConfirmationPopup";
import { Store } from "./Store";
import { IAccordionView } from "../libs/models/Content/AccordionView";
import { IPlatformStore } from "../libs/models/PlatformStore";
import { tx } from "../libs/i18n";
import { getLangInUrl } from "../libs/utils/url";
import { IBankAccountControlPopup } from "libs/models/Content/BankAccountControlPopup";
import { IBankAccountControlFinalStepBlock } from "libs/models/Content/IKreditzVerification";

export class UIStore {
  platformStore: IPlatformStore;

  rootStore: Store;

  window: Window | undefined;

  events = [
    "mousemove",
    "mousedown",
    "mousewheel",
    "keypress",
    "keydown",
    "wheel",
    "DOMMouseScroll",
    "touchstart",
    "touchmove",
    "MSPointerDown",
    "MSPointerMove",
    "visibilitychange",
  ];

  logoutWarningTime: number = 1000 * 60 * 19;

  logoutTime: number = 1000 * 60 * 20;

  @observable
  breakpoint: TScreenKeys = "xl";

  @observable
  windowWidth: number = 0;

  @observable
  windowHeight: number = 0;

  @observable
  windowPageYOffset: number = 0;

  @observable
  windowPageXOffset: number = 0;

  @observable
  locale: Locale = Locale.sv;

  @observable
  lang: Lang = Lang.sv;

  @observable
  currency: string = "SEK";

  @observable
  currencySymbol: string = (0)
    .toLocaleString(this.locale, {
      currency: this.currency,
      style: "currency",
    })
    .replace(/\d/g, "")
    .replace(/,/g, "")
    .trim();

  @observable
  popups: IPopup[] = [];

  @observable
  popover?: IPopover = undefined;

  @observable
  modal?: IModal = undefined;

  @observable
  hideAccordionBlockTitle?: boolean = false;

  @observable
  bankAccountControlflowState: BankAccountControlFlowState = BankAccountControlFlowState.Inactive;

  @observable
  bankAccountControlFinalStepVariant?: IBankAccountControlFinalStepBlock;

  @observable
  accordionView?: IAccordionView = undefined;

  @observable
  modalAccordionView?: IAccordionView = undefined;

  @observable
  menuAccordionView?: IAccordionView = undefined;

  @observable
  confirmationPopup?: IConfirmationPopup = undefined;

  @observable
  bankAccountControlPopup?: IBankAccountControlPopup = undefined;

  @observable
  loading: boolean = false;

  @observable
  logoAnimationLoaded: boolean = false;

  @observable
  isMounted: boolean = false;

  @observable
  hasOutdatedBrowser: boolean = false;

  @observable
  logoutWarningTimeout?: NodeJS.Timeout;

  @observable
  logoutTimeout?: NodeJS.Timeout;

  @observable
  tokenCheckTimeout?: NodeJS.Timeout;

  @observable
  isFirstPageview: boolean = true;

  @computed
  get isMobile() {
    return this.windowWidth < 992;
  }

  @computed
  get isTablet() {
    return this.windowWidth < 1300;
  }

  @computed
  get isSm() {
    return this.windowWidth < 540;
  }

  @computed
  get langInUrl() {
    return this.window && getLangInUrl(this.window.location.pathname);
  }

  constructor(rootStore: Store, platformStore: IPlatformStore) {
    this.platformStore = platformStore;
    this.hasOutdatedBrowser = !!this.platformStore?.isOutdated();
    this.rootStore = rootStore;
    makeObservable(this);
  }

  onMount = async (window: any) => {
    if (!this.isMounted) {
      if (window) {
        this.window = window;
        this.window?.addEventListener("resize", this.onResize);
        this.onResize();

        this.window?.addEventListener("scroll", this.onScroll);
        this.onScroll();
      }

      // Rather short interval time to make sure the token doesn't expire
      // if the customer refreshes the page.
      this.tokenCheckTimeout = setInterval(() => {
        this.handleRefreshToken();
      }, 10 * 1000);

      // Check refresh token on load as well.
      this.handleRefreshToken();
    }

    // Sync language setting in URL with i18n
    if (this.langInUrl && this.langInUrl !== tx("routing.lang")) {
      if (this.rootStore.contentStore.availableLanguages?.includes(this.langInUrl as Lang)) {
        this.rootStore.contentStore.changeLanguage(this.langInUrl);
      } else {
        this.rootStore.commonService.redirect(`/${tx("routing.lang")}`);
      }
    }

    this.isMounted = true;

    if (this.window) {
      this.window.addEventListener("beforeunload", () => {
        this.onUnmount();
      });
    }
  };

  onUnmount = () => {
    if (this.isMounted) {
      if (this.window) {
        this.window.removeEventListener("resize", this.onResize);
        this.window.removeEventListener("scroll", this.onScroll);
        this.events.forEach((e) => this.window?.removeEventListener(e, this.resetTimers));
        if (this.tokenCheckTimeout) {
          clearInterval(this.tokenCheckTimeout);
        }
      }
      this.isMounted = false;
    }
  };

  // Inactivity helpers

  initEvents = () => {
    if (window && this.platformStore.isWeb()) {
      this.resetTimers();
      this.events.forEach((e) => window.addEventListener(e, this.resetTimers));
    }
    if (this.platformStore.isApplication()) {
      this.resetTimers();
    }
  };

  initTimeouts = () => {
    if (this.platformStore.isWeb()) {
      this.logoutWarningTimeout = setTimeout(this.handleWarning, this.logoutWarningTime);
    }
    this.logoutTimeout = setTimeout(() => this.handleLogout("Interaction timeout"), this.logoutTime);
  };

  resetTimers = () => {
    this.clearTimeoutFunc();
    this.initTimeouts();
  };

  clearTimeoutFunc = () => {
    if (this.logoutWarningTimeout) clearTimeout(this.logoutWarningTimeout);
    if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
  };

  setBankAccountControlFinalStepVariant = (variant: IBankAccountControlFinalStepBlock) => {
    this.bankAccountControlFinalStepVariant = variant;
  }

  resetActivityTimers = () => {
    this.clearTimeoutFunc();
    this.initTimeouts();
  };

  handleWarning = () => {
    this.rootStore.setIdleWarning();
  };

  handleLogout = async (reason: string) => {
    if (this.platformStore?.isApplication()) {
      await this.rootStore.logout(true, reason);
    } else {
      await this.rootStore.logout(false, reason);
    }
  };

  // actions
  handleRefreshToken = async () => {
    const { expiresIn, tokenTimestamp } = this.rootStore.securePersistentStore;
    if (tokenTimestamp && expiresIn) {
      const tokenTimestampDate = dayjs(tokenTimestamp).add(expiresIn, "milliseconds");

      if (tokenTimestampDate.isBefore(dayjs())) {
        this.rootStore.refreshToken();
      }
    }
  };

  @action
  onResize = () => {
    onAnimationFrame(() => {
      this.windowWidth = this.window?.innerWidth || 0;
      this.windowHeight = this.window?.innerHeight || 0;
      this.breakpoint = Object.keys(screenBreakpoints).find((key: any) => {
        return this.isBreakpoint(key);
      }) as TScreenKeys;
    });
  };

  @action
  onScroll = () => {
    onAnimationFrame(() => {
      this.windowPageYOffset = this.window?.scrollY || 0;
      this.windowPageXOffset = this.window?.scrollX || 0;
    });
  };

  isBreakpoint = (breakpoint: TScreenKeys) => {
    return screenBreakpoints[breakpoint] >= this.windowWidth;
  };

  @action
  setPopup = (popup: IPopup): number => {
    return this.popups.push(popup) - 1;
  };

  removePopup = (index: number) => {
    this.popups.splice(index, 1);
  };

  @action
  setPopover = (popover: IPopover) => {
    this.popover = popover;
  };

  removePopover = () => {
    this.popover = undefined;
  };

  setModal = (modal: IModal) => {
    this.modal = modal;
  };

  setHideAccordionBlockTitle = (hide: boolean) => {
    this.hideAccordionBlockTitle = hide;
  };

  removeModal = () => {
    this.modal = undefined;
    this.modalAccordionView = undefined;
  };

  setAccordionView = (accordionView: IAccordionView) => {
    this.accordionView = accordionView;
  };

  setModalAccordionView = (modalAccordionView: IAccordionView) => {
    this.modalAccordionView = modalAccordionView;
  };

  setMenuAccordionView = (menuAccordionView: IAccordionView) => {
    this.menuAccordionView = menuAccordionView;
  };

  removeAccordionView = () => {
    this.accordionView = undefined;
  };

  removeModalAccordionView = () => {
    this.modalAccordionView = undefined;
  };

  removeMenuAccordionView = () => {
    this.menuAccordionView = undefined;
  };

  setConfirmationPopup = (confirmationPopup: IConfirmationPopup) => {
    this.confirmationPopup = confirmationPopup;
  };

  removeConfirmationPopup = () => {
    this.confirmationPopup = undefined;
  };

  setBankAccountControlPopup = (bankAccountControlPopup: IBankAccountControlPopup) => {
    this.bankAccountControlPopup = bankAccountControlPopup;
  };

  setBankAccountControlFlowState(state: BankAccountControlFlowState) {
    if (
      this.bankAccountControlflowState === BankAccountControlFlowState.Inactive &&
      state !== BankAccountControlFlowState.Started
    ) {
      return;
    }

    if (state === BankAccountControlFlowState.Inactive) {
      this.bankAccountControlFinalStepVariant = undefined;
    }

    this.bankAccountControlflowState = state;
  }

  setLoading = (loading: boolean) => {
    this.loading = loading;
  };

  setLogoAnimationLoaded = (logoAnimationLoaded: boolean) => {
    this.logoAnimationLoaded = logoAnimationLoaded;
  };

  setIsFirstPageview = (value: boolean) => {
    this.isFirstPageview = value;
  };
}
