export const clearingStartsWithEight = (clearingNumber?: string) => {
  return !!clearingNumber?.match(/^8\d{0,4}$/);
};
export const getClearingNumberMinLength = (clearingNumber: string) => {
  return clearingStartsWithEight(clearingNumber) ? 5 : 4;
};
export const getClearingNumberMaxLength = (clearingNumber: string) => {
  return clearingStartsWithEight(clearingNumber) ? 5 : 4;
};

export const getValidAccountNumberLength = (clearingNumber: string) => {
  const clearing = parseInt(clearingNumber);
  if (
    (clearing >= 80000 && clearing <= 89999) || // Swedbank
    (clearing >= 9570 && clearing <= 9579) || // Sparbanken Syd
    (clearing >= 9300 && clearing <= 9349) || // Swedbank
    (clearing >= 9180 && clearing <= 9189) || // Danske Bank A/S
    clearing === 3300 || // Nordea Personkonto
    clearing === 3782 || // Nordea
    clearing === 9960 // Nordea Plusgirot
  ) {
    return 10;
  }
  if (clearing >= 6000 && clearing <= 6999) {
    // Handelsbanken
    return 9;
  }
  return 7;
};
