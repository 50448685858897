import React from "react";
import { observer } from "mobx-react";
import { useStores } from "netbank-shared/src/hooks";
import { toLocaleString } from "netbank-shared/src/libs/utils";
import { ILoanTopUpPage } from "netbank-shared/src/libs/models/Content/Page";
import offerStyles from "~views/pages/Offers/Offers.scss";
import rootStyles from "~views/pages/Root.scss";
import styles from "../LoanTopUpFlow.scss";
import { HtmlContent } from "~views/shared/HtmlContent/HtmlContent";
import { InputSlider, InputSliderType } from "~views/shared/InputSlider/InputSlider";
import { tx } from "netbank-shared/src/libs/i18n";
import { Button } from "~views/shared";
import { TrackingAction, TrackingCategory } from "netbank-shared/src/libs/models/Tracking";
import { usePushInteraction } from "~hooks";

export interface ILoanTopUpAmountStepProps {
  data: ILoanTopUpPage;
  setNextTopUpStep: () => void;
}

export const LoanTopUpAmountStep = observer(({ data, setNextTopUpStep }: ILoanTopUpAmountStepProps) => {
  const { offerStore, uiStore } = useStores();

  const { locale, currency } = uiStore;

  usePushInteraction({
    eventCategory: TrackingCategory.ProductLoan,
    eventAction: TrackingAction.LoanRaise1,
    requirement: offerStore.topUpAmount > 0,
  });

  const { loanCalculationData } = offerStore;

  const { loanAmountStep, sharedContent } = data;

  if (!loanCalculationData || !offerStore.currentAccount || !offerStore.currentOffer) return null;

  const minRepaymentTime = 2;
  const maxRepaymentTime = offerStore.isLowTotalLoanAmount
    ? offerStore.lowAmountPeriodLimit
    : offerStore.highAmountPeriodLimit;

  const renderLoanSummaryRow = (label: string, value: number) => {
    return (
      <div className={styles.loanSummaryRow}>
        <span>{label}</span>
        <span>{toLocaleString(value, currency, locale, 0)}</span>
      </div>
    );
  };

  // TODO: higherAmountTopText is deprecated and should be removed as soon as prod content is migrated to topText
  const topText = loanAmountStep.topText || loanAmountStep.higherAmountTopText;

  return (
    <div>
      <HtmlContent html={`<h1>${loanAmountStep.header || tx("topUp.header")}</h1>`} />
      {topText && <HtmlContent className={styles.topText} html={topText} />}
      <InputSlider
        min={offerStore.minimumTopUpAmount}
        max={offerStore.maxLoanAmount}
        step={1000}
        value={offerStore.topUpAmount}
        setValue={(amount: number) => {
          offerStore.setTopUpAmount(amount);
        }}
        debounceAction={() => offerStore.updateLoanCalculations()}
        label={loanAmountStep.loanAmountLabel || tx("topUp.loanAmountLabel")}
        sliderType={InputSliderType.Moneytary}
      />
      <InputSlider
        min={minRepaymentTime}
        max={maxRepaymentTime}
        step={1}
        value={offerStore.topUpRepaymentTime}
        setValue={(years: number) => {
          offerStore.setTopUpRepaymentTime(years);
        }}
        debounceAction={() => offerStore.updateLoanCalculations()}
        label={sharedContent.repaymentTimeLabel || tx("topUp.repaymentTimeLabel")}
        sliderType={InputSliderType.Years}
      />
      <span className={rootStyles.bold}>{sharedContent.monthlyCostLabel || tx("topUp.monthlyCostLabel")}</span>

      <div className={styles.calculatedCost}>
        <div className={styles.monthlyCost}>
          <span className={styles.monthlyCostAmount}>
            {toLocaleString(offerStore.topUpMonthlyCost, currency, locale, 0)}
          </span>
        </div>
        <span className={styles.monthLabel}>{` /${tx("misc.monthShort")}`}</span>
        <span className={styles.interest}>
          {` ${tx("misc.with")} ${toLocaleString(
            offerStore.currentAccount.accountInterest || 0,
            undefined,
            locale,
            2
          )} % ${tx("misc.interest").toLowerCase()}`}
        </span>
      </div>
      {loanAmountStep.monthlyCostExample && (
        <HtmlContent className={styles.monthlyCostExample} html={loanAmountStep.monthlyCostExample} />
      )}
      <div className={styles.loanIncreaseSummary}>
        {renderLoanSummaryRow(
          loanAmountStep.currentLoanAmountLabel || tx("topUp.currentLoanAmountLabel"),
          Math.abs(offerStore.currentAccount.bookedBalance || 0)
        )}
        {renderLoanSummaryRow(
          loanAmountStep.loanAmountIncreaseLabel || tx("topUp.loanAmountIncreaseLabel"),
          offerStore.topUpAmount
        )}
        {renderLoanSummaryRow(
          sharedContent.newLoanAmountLabel || tx("topUp.newLoanAmountLabel"),
          Math.abs(offerStore.currentAccount.bookedBalance || 0) + offerStore.topUpAmount
        )}
      </div>
      <div>
        <div className={offerStyles.submit}>
          <Button
            title={loanAmountStep.continueButtonText || tx("misc.continue")}
            color="red"
            onClick={setNextTopUpStep}
            large
          />
        </div>
      </div>
    </div>
  );
});
