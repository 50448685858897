import { ApisauceInstance, create } from "apisauce";
import { IConfig } from "libs/models/Config";
import { Environment } from "../models/Content/Enums";
import { CustomerPersistentStore, Store } from "stores";
import MockAdapter from "axios-mock-adapter";
import { AxiosAdapter, AxiosInstance } from "axios";
import { MockChatApi } from "./mock/MockChatApi";

export class ChatApi {
  rootStore: Store;

  customerPersistentStore: CustomerPersistentStore;

  client?: ApisauceInstance;

  mock?: MockAdapter;

  mockAdapter?: AxiosAdapter;

  actualAdapter?: AxiosAdapter;

  constructor(rootStore: Store, customerPersistentStore: CustomerPersistentStore) {
    this.rootStore = rootStore;
    this.customerPersistentStore = customerPersistentStore;
  }

  init = (config: IConfig | undefined) => {
    if (config) {
      const { platformStore } = this.rootStore;
      const platformSpecificHeaders = platformStore?.getPlatformSpecificHeaders();

      const headers: { [key: string]: string | number } = {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": config.CHAT_OCP_APIM_SUBSCRIPTION_KEY,
      };

      if (platformSpecificHeaders) Object.assign(headers, platformSpecificHeaders);

      this.client = create({
        baseURL: config.CHAT_URL,
        headers,
        timeout: 30000,
      });

      if (config.OCTOPUS_ENV !== Environment.Production) this.setupMockAdapter();
    }
  };

  getHeaders = (headers?: any) => ({
    headers: {
      ...headers,
      Authorization: `Bearer ${this.rootStore.securePersistentStore.accessToken}`,
    },
  });

  initiateChat = async (visitorId: string, rooms: string[]) => {
    const { currentOrganizationNumber } = this.customerPersistentStore;
    return this.client?.post("", { visitorId, rooms, identityNumber: currentOrganizationNumber }, this.getHeaders());
  };

  endChat = async (visitorId: string, rooms: string[]) => {
    const { currentOrganizationNumber } = this.customerPersistentStore;
    return this.client?.delete("", { visitorId, rooms, identityNumber: currentOrganizationNumber }, this.getHeaders());
  };

  setupMockAdapter = () => {
    this.mock = new MockAdapter(this.client?.axiosInstance as AxiosInstance, {
      delayResponse: process.env.NODE_ENV === "test" ? 0 : 2000,
    });

    this.mockAdapter = this.client?.axiosInstance.defaults.adapter as AxiosAdapter;

    // Defaults to real apis
    this.mock.restore();

    this.actualAdapter = this.client?.axiosInstance.defaults.adapter as AxiosAdapter;

    MockChatApi(this.mock);
  };

  setMock = (isMock: boolean) => {
    if (this.client) {
      if (isMock) {
        this.client.axiosInstance.defaults.adapter = this.mockAdapter;
      } else {
        this.client.axiosInstance.defaults.adapter = this.actualAdapter;
      }
    }
  };
}
