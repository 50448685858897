import React from "react";
import { Navigate, Route, useLocation, Routes } from "react-router-dom";
import { tx } from "netbank-shared/src/libs/i18n";
import { Home, Cms, Leasing, Card, Deposit, Login, SecuredLoan, PrivateLoan, Loan } from "./pages";
import { RoutingAnimationLayout } from "./RoutingAnimationLayout";

export const StripTrailingSlash = ({ ...rest }) => {
  const location = useLocation();

  // If the last character of the url is '/'
  if (location.pathname.match("/.*/$")) {
    return (
      <Navigate
        replace
        {...rest}
        to={{
          pathname: location.pathname.replace(/\/+$/, ""),
          hash: location.hash,
          search: location.search,
        }}
      />
    );
  }

  return null;
};

export const RouterWrapper = () => {

  return (
    <div className="route-section">
      <StripTrailingSlash />
      <Routes>
        <Route element={<RoutingAnimationLayout />}>
          <Route path={`/${tx("routing.lang")}/login`} element={<Login />} />
          <Route path={`/${tx("routing.lang")}`} element={<Home />} />
          <Route path={`/${tx("routing.lang")}/${tx("routing.loan")}/:loanId`} element={<Loan />} />
          <Route path={`/${tx("routing.lang")}/${tx("routing.securedLoan")}/:loanId`} element={<SecuredLoan />} />
          <Route path={`/${tx("routing.lang")}/${tx("routing.privateLoan")}/:loanId`} element={<PrivateLoan />} />
          <Route path={`/${tx("routing.lang")}/${tx("routing.leasing")}/:leasingId`} element={<Leasing />} />
          <Route path={`/${tx("routing.lang")}/${tx("routing.card")}/:cardId`} element={<Card />} />
          <Route path={`/${tx("routing.lang")}/${tx("routing.deposit")}/:depositId`} element={<Deposit />} />
          <Route path="*" element={<Cms />} />
        </Route>
      </Routes>
    </div>
  );
};

export default RouterWrapper;
