import { observer } from "mobx-react";
import React from "react";
import { IOfferEmptyState } from "netbank-shared/src/libs/models/Content/EmptyState";
import { EmptyState } from "~views/shared";
import styles from "./Offers.scss";

interface IEmptyStateProps {
  data: IOfferEmptyState;
  accountPath?: string;
}

export const OfferEmptyState = observer(({ data, accountPath }: IEmptyStateProps) => {
  return (
    <section className={styles.emptyState}>
      <EmptyState data={data} returnLink={accountPath} wrapperClassName={styles.emptyState} />
    </section>
  );
});
