import styles from "../BankAccountControlFlow.scss";
import React from "react";
import { Lottie } from "~views/shared/Lottie/Lottie";
import loadingAnimationData from "~assets/lottie/loading.json";

export const KreditzInitialLoadingStep = () => {
  return (
    <div className={styles.loaderContainer}>
      <Lottie
        options={{
          animationData: loadingAnimationData,
        }}
        height={100}
        width={100}
      />
    </div>
  );
};
