import { action, computed, makeObservable, observable } from "mobx";
import { EngagementsApi } from "../libs/api";
import { DocumentType } from "../libs/models/Content/Enums";
import { IDocument, ILeaseAccount } from "../libs/models/Engagements";
import { Store } from "./Store";
import { AccountNameValidator } from "../libs/validators/AccountNameValidator";
import { AccountStatus } from "../libs/models/Engagements/Account";

export class LeasingStore {
  engagementsApi: EngagementsApi;

  rootStore: Store;

  constructor(engagementsApi: EngagementsApi, rootStore: Store) {
    this.engagementsApi = engagementsApi;
    this.rootStore = rootStore;
    makeObservable(this);
  }

  @observable
  leasingAccounts: ILeaseAccount[] = [];

  @observable
  leasingAccountsError?: boolean;

  @computed
  get currentAccountNumber(): string {
    return this.rootStore.currentAccountNumber;
  }

  set currentAccountNumber(accountNumber: string) {
    this.rootStore.currentAccountNumber = accountNumber;
  }

  @observable
  leasingAccountDocuments?: IDocument[];

  @observable
  loadingDocuments: boolean = false;

  @observable
  isUpdatingAccountName: boolean = false;

  @observable
  hasUpdateAccountNameError?: boolean = false;

  @observable
  hasValidationAccountNameError?: boolean = false;

  @computed
  get currentAccount(): ILeaseAccount | undefined {
    return this.leasingAccounts?.find((acc) => acc.accountNumber === this.currentAccountNumber);
  }

  @computed
  get hasAccounts(): boolean {
    return this.activeLeasingAccounts !== undefined && this.activeLeasingAccounts.length > 0;
  }

  @computed
  get activeLeasingAccounts(): ILeaseAccount[] {
    return this.leasingAccounts.filter((a) => a.accountStatus !== AccountStatus.AccountClosed);
  }

  @computed
  get closedLeasingAccounts(): ILeaseAccount[] {
    return this.leasingAccounts.filter((a) => a.accountStatus === AccountStatus.AccountClosed);
  }

  setCurrentAccount(accountNumber: string): void {
    this.currentAccountNumber = accountNumber;
  }

  getLeasingDocument = async (accountNumber: string) => {
    this.leasingAccountDocuments = [];
    this.loadingDocuments = true;
    const response = await this.engagementsApi.getDocuments(accountNumber, DocumentType.Contract);
    if (response?.ok && response?.data?.documents) {
      const { documents } = response.data;
      this.leasingAccountDocuments = documents;
    }
    this.loadingDocuments = false;
  };

  @action
  saveAccountName = async (accName: string) => {
    if (!this.currentAccount) return false;

    this.isUpdatingAccountName = true;
    this.hasValidationAccountNameError = !AccountNameValidator(accName || "", {}).valid;

    if (this.hasValidationAccountNameError) {
      this.isUpdatingAccountName = false;
      return false;
    }

    const updatedData = {
      accountName: accName,
      accountNumber: this.currentAccount.displayNumber,
    };

    const response = await this.engagementsApi.saveAccountName(updatedData);

    if (response?.ok) {
      this.currentAccount.accountPersonalName = accName;
      await this.rootStore.getEngagements(true);
    } else {
      this.hasUpdateAccountNameError = true;
    }
    this.isUpdatingAccountName = false;
    return response?.ok;
  };

  @action
  resetLeasingAccountDocuments = () => {
    this.leasingAccountDocuments = undefined;
  };

  @action
  resetStore = () => {
    this.leasingAccounts = [];
    this.leasingAccountDocuments = undefined;
    this.loadingDocuments = false;
  };
}
