import { useStores } from ".";
import { useEffect } from "react";

export const useLogoAnimation = (deps: any[]) => {
  const { uiStore } = useStores();
  useEffect(() => {
    if (uiStore.logoAnimationLoaded) {
      uiStore.setLoading(false);
    }
  }, deps);
};
