import { IAccountInvoices } from "../../../../models/Engagements/Invoice";

export const mockInvoicesIndex: IAccountInvoices = {
  invoices: [
    {
      documentId: "",
      maturityDate: "2021-03-06T22:00:00Z",
      amount: 450,
      ocrReference: "0000415183556237",
      paymentAccount: "123-456",
      status: undefined,
      documentTypeId: "",
      isPartOfCommonInvoice: true,
    },
    {
      documentId: "",
      maturityDate: "2021-02-21T22:00:00Z",
      amount: 543,
      ocrReference: "0000414287506227",
      paymentAccount: "123-456",
      status: undefined,
      documentTypeId: "",
      isPartOfCommonInvoice: false,
    },
    {
      documentId: "",
      maturityDate: "2021-01-25T22:00:00Z",
      amount: 2343,
      ocrReference: "0000579417605504",
      paymentAccount: "123-456",
      status: undefined,
      documentTypeId: "",
      isPartOfCommonInvoice: false,
    },
    {
      documentId: "",
      maturityDate: "2020-12-28T22:00:00Z",
      amount: 123,
      ocrReference: "0000197590068894",
      paymentAccount: "123-456",
      status: undefined,
      documentTypeId: "",
      isPartOfCommonInvoice: false,
    },
    {
      documentId: "",
      maturityDate: "2020-11-28T22:00:00Z",
      amount: 6546,
      ocrReference: "0000217077444800",
      paymentAccount: "123-456",
      status: undefined,
      documentTypeId: "",
      isPartOfCommonInvoice: false,
    },
    {
      documentId: "",
      maturityDate: "2020-10-28T22:00:00Z",
      amount: 7653,
      ocrReference: "0000380211247004",
      paymentAccount: "123-456",
      status: undefined,
      documentTypeId: "",
      isPartOfCommonInvoice: false,
    },
    {
      documentId: "",
      maturityDate: "2020-09-28T22:00:00Z",
      amount: 543,
      ocrReference: "0000848009322520",
      paymentAccount: "123-456",
      status: undefined,
      documentTypeId: "",
      isPartOfCommonInvoice: false,
    },
    {
      documentId: "",
      maturityDate: "2020-08-28T22:00:00Z",
      amount: 673,
      ocrReference: "0000193538786942",
      paymentAccount: "123-456",
      status: undefined,
      documentTypeId: "",
      isPartOfCommonInvoice: false,
    },
  ],
};

export const mockInvoicesSecond: IAccountInvoices = {
  invoices: [
    {
      documentId: "",
      maturityDate: "2019-06-28T22:00:00Z",
      amount: 1500,
      ocrReference: "0000736910424452",
      paymentAccount: "123-456",
      status: undefined,
      documentTypeId: "",
      isPartOfCommonInvoice: false,
    },
    {
      documentId: "",
      maturityDate: "2019-05-28T22:00:00Z",
      amount: 2000,
      ocrReference: "0000401794324928",
      paymentAccount: "123-456",
      status: undefined,
      documentTypeId: "",
      isPartOfCommonInvoice: false,
    },
    {
      documentId: "",
      maturityDate: "2019-04-28T22:00:00Z",
      amount: 200,
      ocrReference: "0000355895101198",
      paymentAccount: "123-456",
      status: undefined,
      documentTypeId: "",
      isPartOfCommonInvoice: false,
    },
    {
      documentId: "",
      maturityDate: "2019-03-28T22:00:00Z",
      amount: 1524,
      ocrReference: "0000738307805723",
      paymentAccount: "123-456",
      status: undefined,
      documentTypeId: "",
      isPartOfCommonInvoice: false,
    },
    {
      documentId: "",
      maturityDate: "2019-02-30T22:00:00Z",
      amount: 1024,
      ocrReference: "0000681801220055",
      paymentAccount: "123-456",
      status: undefined,
      documentTypeId: "",
      isPartOfCommonInvoice: false,
    },
    {
      documentId: "",
      maturityDate: "2019-01-25T22:00:00Z",
      amount: 1024,
      ocrReference: "0000478337865856",
      paymentAccount: "123-456",
      status: undefined,
      documentTypeId: "",
      isPartOfCommonInvoice: false,
    },
    {
      documentId: "",
      maturityDate: "2018-10-29T22:00:00Z",
      amount: 3377,
      ocrReference: "00001039454992000",
      paymentAccount: "123-456",
      status: undefined,
      documentTypeId: "",
      isPartOfCommonInvoice: false,
    },
    {
      documentId: "",
      maturityDate: "2018-07-29T22:00:00Z",
      amount: 372,
      ocrReference: "000055124440003020",
      paymentAccount: "123-456",
      status: undefined,
      documentTypeId: "",
      isPartOfCommonInvoice: false,
    },
    {
      documentId: "",
      maturityDate: "2018-07-25T22:00:00Z",
      amount: 1274,
      ocrReference: "000098591894944",
      paymentAccount: "123-456",
      status: undefined,
      documentTypeId: "",
      isPartOfCommonInvoice: false,
    },
    {
      documentId: "",
      maturityDate: "2018-03-22T22:00:00Z",
      amount: 554,
      ocrReference: "0000299599995",
      paymentAccount: "123-456",
      status: undefined,
      documentTypeId: "",
      isPartOfCommonInvoice: false,
    },
  ],
};
