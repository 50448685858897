import React from "react";
import { observer } from "mobx-react";
import styles from "./Banner.scss";
import { IContentReference } from "netbank-shared/src/libs/models/Content/ContentReference";
import { Button, DynamicLink } from "..";
import { ILink } from "netbank-shared/src/libs/models/Content/LinkItem";
import { HtmlContent } from "../HtmlContent/HtmlContent";
import { TrackingAction, TrackingCategory } from "netbank-shared/src/libs/models/Tracking";

interface IBannerProps {
  image?: IContentReference;
  text?: string;
  link?: ILink;
  linkQuery?: string;
  trackingAction?: TrackingAction;
  trackingCategory?: TrackingCategory;
  trackingPrefix?: string;
}

export const Banner = observer(
  ({ image, text, link, linkQuery, trackingAction, trackingCategory, trackingPrefix }: IBannerProps) => {
    if (!link) return null;

    return (
      <div className={styles.wrapper}>
        <DynamicLink
          key={link.contentLink?.guidValue}
          to={`${link.href}${linkQuery ? `?${linkQuery}` : ""}`}
          trackingCategory={trackingCategory}
          trackingAction={trackingAction}
          trackingPrefix={trackingPrefix}
        >
          <div className={styles.image} style={image && { backgroundImage: `url(${image.url})` }}>
            {text && <HtmlContent className={styles.text} html={text} />}
            <div className={styles.buttonWrapper}>
              <Button className={styles.button} title={link.title || link.text} color="red" centered />
            </div>
          </div>
        </DynamicLink>
      </div>
    );
  }
);
