import { observer } from "mobx-react";
import { useStores } from "netbank-shared/src/hooks";
import React, { useState } from "react";
import { ExpandableText, Input } from "..";

export const ConfigEditor = observer(() => {
  const { rootStore } = useStores();
  const { config } = rootStore;
  const [temporaryValues, setTemporaryValues] = useState(config);

  if (!temporaryValues) return null;

  return (
    <div>
      <ExpandableText header="ConfigEditor">
        {Object.entries(temporaryValues).map((entry) => (
          <div key={entry[0]}>
            <span>{entry[0]}:</span>
            <Input
              value={entry[1]}
              onChange={(e) => setTemporaryValues({ ...temporaryValues, [entry[0]]: e.target.value })}
              onBlur={() => {
                Object.entries(rootStore)
                  .filter((possibleApi) => possibleApi[0].match(/.*api/i))
                  .forEach((api) => api[1]?.init?.(temporaryValues));
              }}
            />
          </div>
        ))}
      </ExpandableText>
    </div>
  );
});
