import React from "react";
import { observer } from "mobx-react";
import { ILoanTopUpPage } from "netbank-shared/src/libs/models/Content/Page";
import { tx } from "netbank-shared/src/libs/i18n";
import { HtmlContent } from "~views/shared/HtmlContent/HtmlContent";
import loadingAnimationData from "~assets/lottie/loading.json";
import { TrackingAction, TrackingCategory } from "netbank-shared/src/libs/models/Tracking";
import { Lottie } from "~views/shared";
import { usePushInteraction } from "~hooks";

export interface LoanTopUpProcessingProps {
  data: ILoanTopUpPage;
}

export const LoanTopUpProcessing = observer(({ data }: LoanTopUpProcessingProps) => {
  const { processingStep } = data;

  usePushInteraction({
    eventCategory: TrackingCategory.ProductLoan,
    eventAction: TrackingAction.LoanRaise3Processing,
  });

  return (
    <div>
      <HtmlContent html={`<h1>${processingStep.header || tx("topUp.processingHeader")}</h1>`} />
      <HtmlContent html={processingStep.text || tx("topUp.processingText")} />
      <Lottie
        options={{
          animationData: loadingAnimationData,
        }}
        height={100}
        width={100}
      />
    </div>
  );
});
