import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { useLogoAnimation, useStores } from "netbank-shared/src/hooks";
import { tx } from "netbank-shared/src/libs/i18n";
import { IProductRouteParams } from "netbank-shared/src/libs/models/Routing";
import { withRoot } from "../Root";
import styles from "../Page.scss";
import { ContentArea } from "~views/shared/ContentArea/ContentArea";
import { NotFoundPage } from "../NotFound/NotFound";
import { ProductPageSkeleton } from "../Skeletons/ProductPageSkeleton";
import { IModal } from "netbank-shared/src/libs/models/Content/Modal";
import { IContentAreaItem } from "netbank-shared/src/libs/models/Content/ContentAreaItem";
import editIcon from "~assets/pencil-outline.svg";
import { Button } from "~views/shared/Button/Button";
import { getAccountDisplayName } from "netbank-shared/src/libs/utils/accountName";
import { ILeaseAccount } from "netbank-shared/src/libs/models/Engagements";
import { AccountStatus } from "netbank-shared/src/libs/models/Engagements/Account";
import leasingStyles from "./Leasing.scss";
import { HtmlContent } from "~views/shared/HtmlContent/HtmlContent";

export interface LeasingPageProps {
  location: Location;
}
const renderCloseDate = (currentAccount: ILeaseAccount, closedDateTitle?: string) => {
  const { closeDate } = currentAccount;
  if (currentAccount.accountStatus !== AccountStatus.AccountClosed || !closeDate) return undefined;

  const date = new Date(closeDate).toLocaleDateString(tx("routing.locale"));
  const title = closedDateTitle || tx("leasing.closedDateTitle");

  return (
    <p>
      <span className={styles.bold}>{title}: </span>
      {date}
    </p>
  );
};

const renderClosedAccountInfo = (currentAccount: ILeaseAccount, closedAccountInfo?: string) => {
  const { accountStatus } = currentAccount;

  if (!currentAccount || accountStatus !== AccountStatus.AccountClosed) return undefined;
  const text = closedAccountInfo || tx("leasing.closedAccountInfo");
  return <HtmlContent html={text} disableTemplating className={`${leasingStyles.closedLeasingNotification}`} />;
};

const LeasingPage = observer(({ location }: LeasingPageProps) => {
  const { leasingId } = useParams<IProductRouteParams>();
  const { contentStore, leasingStore, uiStore, invoiceStore } = useStores();
  const { currentAccount } = leasingStore;

  const leasingTemplate = contentStore.currentLeasingTemplatePage;

  useEffect(() => {
    const fetchTemplate = async () => {
      leasingStore.currentAccountNumber = leasingId;
      await contentStore.getLeasingTemplate();

      if (!invoiceStore.fetchingInvoices) {
        invoiceStore.getAccountInvoices(leasingId, true);
      }
    };
    fetchTemplate();
    return () => {
      invoiceStore.resetStore();
    };
  }, [location]);

  useLogoAnimation([uiStore.logoAnimationLoaded, leasingStore.leasingAccounts]);

  useEffect(() => {
    if (leasingId) {
      leasingStore.setCurrentAccount(leasingId);
    }
  }, [currentAccount, leasingStore.leasingAccounts, leasingId, contentStore.leasingTemplatePages]);

  const renderModal = (modalContent?: IContentAreaItem[]) => {
    const modal: IModal = {
      content: modalContent || [],
    };

    uiStore.setModal(modal);
  };

  if (uiStore.loading) return null;

  if (contentStore.fetchingTemplatePage) {
    return <ProductPageSkeleton />;
  }

  if (!currentAccount) {
    return <NotFoundPage />;
  }

  return (
    <div className={styles.wrapper}>
      <section className={styles.headerWrapper}>
        {renderClosedAccountInfo(currentAccount, leasingTemplate?.closedAccountInfo)}
        <div>
          <h1
            style={{
              display: "inline-flex",
            }}
          >
            {getAccountDisplayName(leasingStore.currentAccount)}
          </h1>
          {contentStore.isSetAccountNameEnabled && currentAccount.accountStatus !== AccountStatus.AccountClosed && (
            <Button
              className={styles.boxWrapper}
              onClick={() => {
                renderModal(leasingTemplate?.setAccountNameModalContent);
              }}
            >
              <div className={styles.box}>
                <img src={editIcon} alt="edit-icon" />
              </div>
            </Button>
          )}
          <p>
            <span className={styles.bold}>{leasingTemplate?.subHeading || "N°:"}</span>
            {` ${currentAccount.displayNumber}`}
          </p>
          <p>
            <span className={styles.bold}>{tx("deposit.productType").concat(":")}</span>
            {` ${currentAccount.nameIB}`}
          </p>
          {renderCloseDate(currentAccount, leasingTemplate?.closedDateTitle)}
        </div>
      </section>
      <section>
        <div className={styles.templateHeader}>
          {!uiStore.isMobile && (
            <div
              className={styles.image}
              style={{
                backgroundImage: `url(${leasingTemplate?.image?.url})`,
              }}
            />
          )}
          <div className={styles.templateContent}>
            <div className={styles.availableBalance}>
              <div>
                <span className={styles.templateTitle}>{currentAccount.object.model}</span>
                <h3>{currentAccount.object.objectId}</h3>
              </div>
              {uiStore.isMobile && (
                <div
                  className={styles.image}
                  style={{
                    backgroundImage: `url(${leasingTemplate?.image?.url})`,
                  }}
                />
              )}
            </div>

            <div className={styles.flexBetween}>
              <div>
                <span className={styles.templateTitle}>{leasingTemplate?.remainingPeriod}</span>
                <h4>
                  {`${currentAccount.contractPeriod - currentAccount.elapsedPeriod} 
                  ${tx("date.months")}`}
                </h4>
              </div>
              <div>
                <span className={styles.templateTitle}>{leasingTemplate?.totalPeriod}</span>
                <h4>
                  {`${currentAccount.contractPeriod} 
                  ${tx("date.months")}`}
                </h4>
              </div>
            </div>
            <div className={styles.progressBar}>
              <div
                style={{
                  width: `${(currentAccount.elapsedPeriod / currentAccount.contractPeriod) * 100}%`,
                }}
              />
            </div>
          </div>
        </div>
      </section>
      <ContentArea page={leasingTemplate} />
    </div>
  );
});

export default withRoot(LeasingPage);
