import { action, makeObservable, observable } from "mobx";
import { EngagementsApi } from "../libs/api";
import { DocumentType, Limits } from "../libs/models/Content/Enums";
import { IDocument } from "../libs/models/Engagements";
import { base64ToBlob, createAutoDownloadTempLink } from "../libs/utils";

export class LetterStore {
  engagementsApi: EngagementsApi;

  constructor(engagementsApi: EngagementsApi) {
    this.engagementsApi = engagementsApi;
    makeObservable(this);
  }

  @observable letterDocuments: IDocument[] | undefined;

  @observable insuranceLetterDocuments: IDocument[] | undefined;

  @observable yearlyStatements: IDocument[] | undefined;

  @observable fetchingLetterDocuments: boolean = false;

  @observable fetchingInsuranceLetterDocuments: boolean = false;

  @observable fetchingYearlyStatements: boolean = false;

  @observable limit: number = Limits.Letters;

  @observable hasNextPage: boolean = false;

  @observable letterFrontendIndex: number = Limits.Letters;

  @action
  setLetterFrontendIndex = (value: number) => {
    this.letterFrontendIndex = value;
  };

  @action
  getLetterDocuments = async (reset: boolean = true) => {
    if (reset) {
      this.letterDocuments = [];
      this.setLetterFrontendIndex(this.limit);
      this.hasNextPage = false;
    }
    this.fetchingLetterDocuments = true;
    const response = await this.engagementsApi.getDocuments("", DocumentType.Letter);

    if (response?.ok && response.data) {
      this.letterDocuments = response.data.documents || [];
    }
    this.fetchingLetterDocuments = false;
  };

  @action
  getLetterDocument = async (letter: IDocument) => {
    if (letter.id) {
      const response = await this.engagementsApi.getDocument(letter.id);
      if (response?.ok && response.data) {
        const { fileBytes, fileName, mimeType } = response.data;
        if (fileBytes && fileName && mimeType) {
          const blob = base64ToBlob(fileBytes, mimeType);
          const objectUrl = URL.createObjectURL(blob);
          createAutoDownloadTempLink(fileName, objectUrl, true);
        }
      }
    }
  };

  @action
  getInsuranceLetterDocuments = async (reset: boolean = true) => {
    if (reset) {
      this.insuranceLetterDocuments = [];
      this.setLetterFrontendIndex(this.limit);
      this.hasNextPage = false;
    }
    this.fetchingInsuranceLetterDocuments = true;
    const response = await this.engagementsApi.getDocuments("", DocumentType.InsuranceLetter);

    if (response?.ok && response.data) {
      this.insuranceLetterDocuments = response.data.documents || [];
    }
    this.fetchingInsuranceLetterDocuments = false;
  };

  @action
  getYearlyStatements = async (reset: boolean = true) => {
    if (reset) {
      this.yearlyStatements = [];
      this.setLetterFrontendIndex(this.limit);
      this.hasNextPage = false;
    }

    this.fetchingYearlyStatements = true;
    const response = await this.engagementsApi.getDocuments("");

    if (response?.ok && response.data) {
      this.yearlyStatements =
        response.data.documents?.filter((doc) => {
          return (
            doc.documentType === DocumentType.YearlyStatement ||
            doc.documentType === DocumentType.YearlyStatementCRS ||
            doc.documentType === DocumentType.YearlyStatementFATCA
          );
        }) || [];
    }
    this.fetchingYearlyStatements = false;
  };

  getYearlyStatementDocument = async (yearlyStatement: IDocument) => {
    if (yearlyStatement.id) {
      const response = await this.engagementsApi.getDocument(yearlyStatement.id);
      if (response?.ok && response.data) {
        const { fileBytes, fileName, mimeType } = response.data;
        if (fileBytes && fileName && mimeType) {
          const blob = base64ToBlob(fileBytes, mimeType);
          const objectUrl = URL.createObjectURL(blob);
          createAutoDownloadTempLink(fileName, objectUrl, true);
        }
      }
    }
  };

  getYearlyStatementDocumentData = async (yearlyStatement: IDocument) => {
    if (yearlyStatement.id) {
      const response = await this.engagementsApi.getDocument(yearlyStatement.id);
      if (response?.ok && response.data) {
        return response.data;
      }
    }
    return null;
  };

  getLetterDocumentData = async (letter: IDocument) => {
    if (letter.id) {
      const response = await this.engagementsApi.getDocument(letter.id);
      if (response?.ok && response.data) {
        return response.data;
      }
    }
    return null;
  };

  @action
  resetStore = () => {
    this.letterDocuments = undefined;
    this.insuranceLetterDocuments = undefined;
    this.yearlyStatements = undefined;
    this.fetchingLetterDocuments = false;
    this.fetchingInsuranceLetterDocuments = false;
    this.fetchingYearlyStatements = false;
    this.hasNextPage = false;
  };
}
