import React from "react";
import { observer } from "mobx-react";
import { useStores } from "netbank-shared/src/hooks";
import styles from "./RootSkeleton.scss";
import { SkeletonWrapper } from "~views/shared";

export const SelfServiceButtonSkeleton = observer(() => {
  const { uiStore } = useStores();
  const width = uiStore.isMobile ? 70 : 120;
  return (
    <div className={styles.selfService}>
      <SkeletonWrapper width={width} height={width} circle />
      <SkeletonWrapper width={width} height={16} style={{ marginTop: "20px" }} />
    </div>
  );
});
