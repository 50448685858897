export enum TrackingCategory {
  Header = "header",
  LeftMenu = "leftMenu",
  LeftSubmenu = "leftSubmenu",
  MainSection = "mainSection",
  CardSettings = "cardSettings",
  ProductCard = "productCard",
  ProductDeposit = "productDeposit",
  ProductLoan = "productLoan",
  Transfers = "transfers",
  Messages = "messages",
  CustomerService = "customerService",
  Accordion = "accordion",
  Popup = "popup",
  Footer = "footer",
  MyProfile = "myProfile",
  Warnings = "warnings",
  Login = "login",
}

export enum TrackingAction {
  Messages = "message",
  MyProfile = "myProfile",
  LogOut = "logOut",
  Home = "home",
  Credit = "credit",
  Deposit = "deposit",
  Loan = "loan",
  Leasing = "leasing",
  Transfers = "transfers",
  CustomerService = "customerService",
  CardSettings = "cardSettings",
  AnnualReport = "annualReport",
  Faq = "faq",
  Terms = "terms",
  InterestRates = "interestRates",
  OrderCard = "orderCard",
  OrderCardCTA = "orderCardCTA",
  OrderCardOK = "orderCardOK",
  OrderCardKO = "orderCardKO",
  ActivateCard = "activateCard",
  ActivateCardCTA = "activateCardCTA",
  ActivationCardOK = "activationCardOK",
  ActivationCardKO = "activationCardKO",
  BlockCard = "blockCard",
  BlockCardCTA = "blockCardCTA",
  BlockCardOK = "blockCardOK",
  BlockCardKO = "blockCardKO",
  ShowPIN = "showPIN",
  ShowVirtual = "showVirtual",
  Transfer = "transfer",
  RaiseLimit = "raiseLimit",
  RaiseLimitCTA = "raiseLimitCTA",
  RaiseLimitOK = "raiseLimitOK",
  RaiseLimitKO = "raiseLimitKO",
  PaymentfreeMonth = "paymentfreeMonth",
  PaymentfreeMonthCTA = "paymentfreeMonthCTA",
  PaymentfreeMonthOK = "paymentfreeMonthOK",
  PaymentfreeMonthKO = "paymentfreeMonthKO",
  OrderInsurance = "orderInsurance",
  InsuranceCTA = "insuranceCTA",
  InsuranceOK = "insuranceOK",
  InsuranceKO = "insuranceKO",
  WithdrawMoney = "withdrawMoney",
  DepositMoney = "depositMoney",
  CloseAccount = "closeAccount",
  CloseAccountCTA = "closeAccountCTA",
  CloseAccountOK = "closeAccountOK",
  CloseAccountKO = "closeAccountKO",
  LoanRaise = "loanRaise",
  LoanRaise1 = "loanRaise1",
  LoanRaise2Information = "loanRaise2 information",
  LoanRaise2Employment = "loanRaise2.1 employment",
  LoanRaise2LivingSituation = "loanRaise2.2 livingSituation",
  LoanRaise2Summary = "loanRaise2.3 summary",
  LoanRaise3 = "loanRaise3",
  LoanCancel = "cancel",
  LoanRaise3Processing = "loanRaise3 processing",
  LoanRaise3Insurance = "loanRaise3 insurance",
  LoanRaise3Account = "loanRaise3 account",
  LoanRaise3EBA = "loanRaise3.1 eba",
  LoanRaise4 = "loanRaise4",
  LoanRaiseError = "loanRaiseError",
  LoanRepay = "loanRepay",
  LoanRepayCalculation = "loanRepay Calculation",
  LoanRepayCTA = "loanRepayCTA",
  LoanResume = "loanResume",
  TransferCTA = "TransferCTA",
  TransferOK = "TransferOK",
  TransferKO = "TransferKO",
  ChangeRecipient = "ChangeRecipient",
  NewMessage = "newMessage",
  MessageCTA = "messageCTA",
  Phone = "phone",
  Open = "open",
  Close = "close",
  FaqCTA = "faqCTA",
  DepositGuarantee = "depositGuarantee",
  DataProcessing = "dataProcessing",
  PSD2 = "PSD2",
  Cookies = "cookies",
  GeneralTerms = "generalTerms",
  TechRequirements = "techRequirements",
  DownloadApp = "downloadApp",
  Gdpr = "gdpr",
  Error = "error",
  AnotherDevice = "anotherDevice",
  QR = "QR",
  SameDevice = "sameDevice",
}
