import { IThreads } from "../../../models/Message/Threads";

export const mockThreads: IThreads = {
  threads: [
    {
      threadId: "1",
      created: "2021-01-21T08:30:00Z",
      archivedByCustomer: false,
      customerAccessed: true,
      title:
        "Planerat underhåll i Internetbanken och appen 20-21 april som kommer innebära att tjänsten blir otillgänglig",
      status: "",
      messages: [
        {
          threadId: "1",
          messageId: "message1",
          creatorReference: "Karl Andreas Torbjörn",
          created: "2021-01-21T08:30:00Z",
          body:
            "Hi!\n\nOrnare egestas feugiat praesent nullam netus dis viverra. " +
            "Felis sit eget phasellus lobortis sit at vestibulum. Etiam mollis " +
            "amet aliquam eu curabitur duis. Vitae quis vulputate ac eget nulla " +
            "ornare sed mattis.",
          attachmentId: "1",
        },
        {
          threadId: "1",
          messageId: "message2",
          creatorReference: "Santander",
          created: "2021-01-22T08:30:00Z",
          body:
            "Hi,\n\nOrnare egestas feugiat praesent nullam netus dis viverra. " +
            "Felis sit eget phasellus lobortis sit at vestibulum. Etiam mollis " +
            "amet aliquam eu curabitur duis. Vitae quis vulputate ac eget nulla " +
            "ornare sed mattis.",
          attachmentId: "2",
        },
        {
          threadId: "1",
          messageId: "message3",
          creatorReference: "Karl Andreas Torbjörn",
          created: "2021-01-23T08:30:00Z",
          body:
            "Hello!\n\nOrnare egestas feugiat praesent nullam netus dis viverra. " +
            "Felis sit eget phasellus lobortis sit at vestibulum. Etiam mollis " +
            "amet aliquam eu curabitur duis. Vitae quis vulputate ac eget nulla " +
            "ornare sed mattis.",
          attachmentId: "",
        },
        {
          threadId: "1",
          messageId: "message4",
          creatorReference: "Santander",
          created: "2021-01-23T08:30:00Z",
          body:
            "Hej,\n\nOrnare egestas feugiat praesent nullam netus dis viverra. " +
            "Felis sit eget phasellus lobortis sit at vestibulum. Etiam mollis amet " +
            "aliquam eu curabitur duis. Vitae quis vulputate ac eget nulla ornare " +
            "sed mattis.",
          attachmentId: "",
        },
        {
          threadId: "1",
          messageId: "message5",
          creatorReference: "Santander",
          created: "2021-01-24T08:30:00Z",
          body:
            "Amigo,\n\nOrnare egestas feugiat praesent nullam netus dis viverra. " +
            "Felis sit eget phasellus lobortis sit at vestibulum. Etiam mollis " +
            "amet aliquam eu curabitur duis. Vitae quis vulputate ac eget nulla ornare " +
            "sed mattis.",
          attachmentId: "",
        },
      ],
    },
    {
      threadId: "2",
      created: "2021-01-30T08:30:00Z",
      archivedByCustomer: false,
      customerAccessed: false,
      title: "Överföring",
      status: "",
      messages: [
        {
          threadId: "2",
          messageId: "message1",
          creatorReference: "Jack Douglas",
          created: "2021-01-30T08:30:00Z",
          body:
            "Hola!\n\nOrnare egestas feugiat praesent nullam netus dis viverra. Felis sit eget " +
            "phasellus lobortis sit at vestibulum. Etiam mollis amet aliquam eu " +
            "curabitur duis. Vitae quis vulputate ac eget nulla ornare sed mattis.",
          attachmentId: "1",
        },
        {
          threadId: "2",
          messageId: "message2",
          creatorReference: "Santander",
          created: "2021-01-30T08:30:00Z",
          body:
            "Hi,\n\nOrnare egestas feugiat praesent nullam netus dis viverra. Felis sit eget phasellus " +
            "lobortis sit at vestibulum. Etiam mollis amet aliquam eu curabitur duis. Vitae quis vulputate " +
            "ac eget nulla ornare sed mattis.",
          attachmentId: "2",
        },
      ],
    },
    {
      threadId: "3",
      created: "2021-01-30T08:30:00Z",
      archivedByCustomer: true,
      customerAccessed: true,
      title: "Nu är ditt sparkonto aktiverat",
      status: "Closed",
      messages: [
        {
          threadId: "3",
          messageId: "message1",
          creatorReference: "Karl Johan Petterson",
          created: "2021-01-30T08:30:00Z",
          body:
            "Hola!\n\nOrnare egestas feugiat praesent nullam netus dis viverra. Felis sit eget phasellus lobortis sit at vestibulum. " +
            "Etiam mollis amet aliquam eu curabitur duis. Vitae quis vulputate ac eget nulla ornare sed mattis.",
          attachmentId: "",
        },
        {
          threadId: "3",
          messageId: "message2",
          creatorReference: "Santander",
          created: "2021-01-30T08:30:00Z",
          body:
            "Hi,\n\nOrnare egestas feugiat praesent nullam netus dis viverra. Felis sit eget phasellus lobortis sit at vestibulum. Etiam mollis " +
            "amet aliquam eu curabitur duis. Vitae quis vulputate ac eget nulla ornare sed mattis.",
          attachmentId: "",
        },
      ],
    },
    {
      threadId: "4",
      created: "2021-01-30T08:30:00Z",
      archivedByCustomer: false,
      customerAccessed: false,
      title: "Attachment group test",
      status: "",
      messages: [
        {
          threadId: "4",
          messageId: "message1",
          creatorReference: "Santander",
          created: "2021-01-30T08:30:00Z",
          body: "Hola!\n\nOrnare egestas",
          attachmentId: "10",
        },
        {
          threadId: "4",
          messageId: "message2",
          creatorReference: "Santander",
          created: "2021-01-30T08:30:00Z",
          body: "",
          attachmentId: "11",
        },
        {
          threadId: "4",
          messageId: "message3",
          creatorReference: "Santander",
          created: "2021-01-30T08:30:00Z",
          body: "",
          attachmentId: "12",
        },
        {
          threadId: "4",
          messageId: "message4",
          creatorReference: "Santander",
          created: "2021-01-30T08:30:00Z",
          body: "",
          attachmentId: "13",
        },
        {
          threadId: "4",
          messageId: "message5",
          creatorReference: "Test Testson",
          created: "2021-01-30T08:30:00Z",
          body: "This is a test message.",
          attachmentId: "20",
        },
        {
          threadId: "4",
          messageId: "message6",
          creatorReference: "Test Testson",
          created: "2021-01-30T08:30:00Z",
          body: "Just a test.",
          attachmentId: "30",
        },
        {
          threadId: "4",
          messageId: "message7",
          creatorReference: "Test Testson",
          created: "2021-01-30T08:30:00Z",
          body: "",
          attachmentId: "31",
        },
      ],
    },
    {
      threadId: "5",
      created: "2021-01-30T08:30:00Z",
      archivedByCustomer: false,
      customerAccessed: false,
      title: "No response, should be hidden from inbox",
      status: "",
      messages: [
        {
          threadId: "5",
          messageId: "message1",
          creatorReference: "Test Testson",
          created: "2021-01-30T08:30:00Z",
          body: "Hola!\n\nOrnare egestas",
          attachmentId: "1",
        },
      ],
    },
    {
      threadId: "6",
      created: "2021-01-30T08:30:00Z",
      archivedByCustomer: false,
      customerAccessed: false,
      title: "One message",
      status: "",
      messages: [
        {
          threadId: "6",
          messageId: "message1",
          creatorReference: "Santander",
          created: "2021-01-30T08:30:00Z",
          body: "Hola!\n\nOrnare egestas",
          attachmentId: "1",
        },
      ],
    },
    {
      threadId: "7",
      created: "2021-01-30T08:30:00Z",
      archivedByCustomer: false,
      customerAccessed: false,
      title: "No response, should be hidden from inbox",
      status: "",
      messages: [
        {
          threadId: "7",
          messageId: "message1",
          creatorReference: "Test Testson",
          created: "2021-01-30T08:30:00Z",
          body: "Hola!\n\nOrnare egestas",
          attachmentId: "1",
        },
      ],
    },
  ],
};
