import { action, makeObservable, observable } from "mobx";
import { makePersistable, hydrateStore } from "mobx-persist-store";

export class SecurePersistentStore {
  constructor(storage: any) {
    makeObservable(this);
    makePersistable(this, {
      name: "s",
      properties: ["accessToken", "refreshToken", "expiresIn", "tokenTimestamp"],
      storage,
    });
  }

  hydrate = async () => hydrateStore(this);

  @observable
  accessToken?: string;

  @observable
  refreshToken?: string;

  @observable
  expiresIn: number = 0;

  @observable
  tokenTimestamp?: string;

  @action
  setAccessToken = (value: string) => {
    this.accessToken = value;
  };

  @action
  setRefreshToken = (value: string) => {
    this.refreshToken = value;
  };

  @action
  setExpiresIn = (value: number) => {
    this.expiresIn = value;
  };

  @action
  setTokenTimestamp = (value: string) => {
    this.tokenTimestamp = value;
  };

  @action
  resetStore = () => {
    this.accessToken = undefined;
    this.refreshToken = undefined;
    this.tokenTimestamp = undefined;
    this.expiresIn = 0;
  };
}
