import dayjs, { Dayjs } from "dayjs";
import isBetween from "dayjs/plugin/isBetween";

dayjs.extend(isBetween);

const dateIsBetween = (date: Dayjs, from: Dayjs, to: Dayjs) => date.isBetween(from, to, undefined, "[]");

export const isMorning = () => {
  const today = dayjs();
  return dateIsBetween(today, today.hour(5).minute(0), today.hour(9).minute(59));
};

export const isForenoon = () => {
  const today = dayjs();
  return dateIsBetween(today, today.hour(10).minute(0), today.hour(11).minute(59));
};

export const isNoon = () => {
  const today = dayjs();
  return dateIsBetween(today, today.hour(12).minute(0), today.hour(14).minute(59));
};

export const isAfternoon = () => {
  const today = dayjs();
  return dateIsBetween(today, today.hour(15).minute(0), today.hour(17).minute(59));
};

export const isEvening = () => {
  const today = dayjs();
  return dateIsBetween(today, today.hour(18).minute(0), today.hour(23).minute(59));
};

export const isNight = () => {
  const today = dayjs();
  return dateIsBetween(today, today.hour(0).minute(0), today.hour(4).minute(59));
};

export const getDaysLeftUntil = (targetDate: string, includeLastDay: boolean = true) => {
  const today = dayjs().startOf("day");
  const diff = dayjs(targetDate).diff(today, "days");
  return Math.max(0, diff + (includeLastDay ? 1 : 0));
};
