import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { useStores } from "netbank-shared/src/hooks";
import { withRoot } from "../Root";
import styles from "../Page.scss";
import { ContentArea } from "~views/shared/ContentArea/ContentArea";
import { HomeSkeleton } from "../Skeletons/HomeSkeleton";
import { tx } from "netbank-shared/src/libs/i18n";

export interface HomePageProps {
  location: Location;
}

const HomePage = observer(({ location }: HomePageProps) => {
  const { contentStore, uiStore, customerPersistentStore } = useStores();
  const navigate = useNavigate();
  useEffect(() => {
    const fetchPage = async () => {
      await contentStore.setHomePage(tx("routing.lang"));

      if (!customerPersistentStore.viewedTerms && contentStore.currentPage?.termsPage) {
        navigate(contentStore.currentPage.termsPage);
      }
    };
    fetchPage();
  }, [location]);

  useEffect(() => {
    if (uiStore.logoAnimationLoaded && contentStore.currentPage) {
      uiStore.setLoading(false);
    }
  }, [uiStore.logoAnimationLoaded, contentStore.currentPage]);

  if (contentStore.fetchingHomePage) {
    return <HomeSkeleton />;
  }

  return (
    <div className={styles.wrapper}>
      <ContentArea />
    </div>
  );
});

export default withRoot(HomePage);
