export interface IComplianceStatus {
  status: ComplianceStatus;
  complianceDueDate: Date;
  completedQuestions: number;
  outstaningQuestions: number;
  bookmarkedAnswerId?: string;
}

export interface IComplianceDefinition {
  title: string;
  answerId: string;
  questions: IComplianceDefinitionQuestion[];
  accountId?: string;
}

export interface IComplianceDefinitionQuestion {
  id: string;
  type: ComplianceFieldType;
  editable: boolean;
  questionText?: string;
  questionHelpText?: string;
  answers?: IComplianceFieldOption[];
  options?: IComplianceFieldOption[];
  conditions?: IComplianceCondition[];
  children?: IComplianceDefinitionQuestion[];
}

export interface IComplianceFieldOption {
  id?: string;
  text?: string;
  fileSize?: number; // Only used client side for file uploader component
}

export interface IUpdateComplianceAnswer {
  questionId: string;
  answers: IComplianceFieldOption[];
}

export interface IUploadAttachmentsResponse {
  attachments: IUploadAttachment[];
}

export interface IUploadAttachment {
  fileId: string;
  fileName: string;
}

export interface IComplianceErrors {
  [key: string]: string[];
}

export interface IComplianceUpdateResponse {
  errors?: IComplianceErrors;
  status?: number;
  title?: number;
  detail?: number;
}

export interface IComplianceSigningStatus {
  signatureLink?: string;
  status: ComplianceSigningStatus;
}

export interface IComplianceCondition {
  valueType: ComplianceConditionValueType;
  operator: ComplianceConditionOperator;
  questionId: string;
  value: string;
  conditions?: IComplianceCondition[];
}

export enum ComplianceFieldType {
  TextField = "TextField",
  TextArea = "TextArea",
  CheckBox = "CheckBox",
  CheckBoxMulti = "CheckBoxMulti",
  RadioButtons = "RadioButtons",
  RadioButtonsYesNo = "RadioButtonsYesNo",
  DropDownList = "DropDownList",
  DropDownListMulti = "DropDownListMulti",
  DropDownListYesNo = "DropDownListYesNo",
  PositiveIntegerInput = "PositiveIntegerInput",
  DecimalInput = "DecimalInput",
  DatePicker = "DatePicker",
  FileUploader = "FileUploader",
  Conditional = "Conditional",
  BeneficialOwnerTable = "BeneficialOwnerTable",
  CompanyOwnerTable = "CompanyOwnerTable",
  h3 = "h3",
  h4 = "h4",
  h5 = "h5",
  paragraph = "paragraph",
  thead = "thead",
  tbody = "tbody",
  th = "th",
  tr = "tr",
  td = "td",
}

export enum ComplianceStatus {
  NotStarted = "NotStarted",
  InProgress = "InProgress",
  Completed = "Completed",
  Signable = "Signable",
}

export enum ComplianceSigningStatus {
  Signed = "Signed",
  Signable = "Signable",
  IncompleteQuestionnaire = "IncompleteQuestionnaire",
  GeneratingSingningLink = "GeneratingSingningLink",
}

export enum ComplianceConditionValueType {
  Text = "Text",
  Number = "Number",
  Decimal = "Decimal",
  Bool = "Bool",
  Option = "Option",
  Date = "Date",
  OptionSet = "OptionSet",
}

export enum ComplianceConditionOperator {
  True = "True",
  Equals = "Equals",
  NotEquals = "NotEquals",
  GreaterThan = "GreaterThan",
  LessThan = "LessThan",
  GreaterThanOrEqualTo = "GreaterThanOrEqualTo",
  LessThanOrEqualTo = "LessThanOrEqualTo",
  In = "In",
  IsEmpty = "IsEmpty",
  IsNotEmpty = "IsNotEmpty",
  Contains = "Contains",
  ContainsAny = "ContainsAny",
  ContainsAll = "ContainsAll",
}
