import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { EmptyState, Lottie } from "~views/shared";
import styles from "./CustomerCompliancePage.scss";
import { useStores } from "netbank-shared/src/hooks";
import loadingAnimationData from "~assets/lottie/loading.json";
import { ProgressBar } from "~views/shared/ProgressBar/ProgressBar";
import { Button } from "~views/shared/Button/Button";
import { tx } from "netbank-shared/src/libs/i18n";
import { ProcessingSpinner } from "~views/shared/ProcessingSpinner/ProcessingSpinner";
import { HtmlContent } from "~views/shared/HtmlContent/HtmlContent";
import { ICustomerCompliancePage } from "netbank-shared/src/libs/models/Content/Page";
import { CustomerComplianceForm } from "~views/shared/ContentArea/Blocks/CustomerCompliance/CustomerComplianceForm";
import { IEmptyState } from "netbank-shared/src/libs/models/Content/EmptyState";

export const CustomerCompliancePage = observer(() => {
  const { complianceStore, contentStore } = useStores();

  const { currentPage } = contentStore;
  const complianceTemplate = currentPage as ICustomerCompliancePage;

  useEffect(() => {
    const fetchData = async () => {
      await complianceStore.getDefinition();
    };

    fetchData();

    return () => {
      complianceStore.resetDefinition();
    };
  }, []);

  const renderErrorEmptyState = () => {
    const fallbackErrorEmptyState: IEmptyState = {
      header: tx("misc.somethingWentWrong"),
      textContent: tx("compliance.unexpectedErrorText"),
    };

    return (
      <section>
        <div className={styles.wrapper}>
          <EmptyState data={complianceTemplate?.errorCaseEmptyState || fallbackErrorEmptyState} />
        </div>
      </section>
    );
  };

  if (complianceStore.fetchingDefinition) {
    return (
      <section>
        <div className={styles.wrapper}>
          <Lottie
            options={{
              animationData: loadingAnimationData,
            }}
            height={100}
            width={100}
          />
        </div>
      </section>
    );
  }

  if (!complianceStore.complianceStatus || !complianceTemplate) return renderErrorEmptyState();

  const {
    complianceStatus,
    complianceDefinition,
    hasPreviousStep,
    isActiveQuestionnaire,
    isSignable,
    isCompleted,
    isProcessing,
    showUnexpectedError,
    currentStep,
  } = complianceStore;
  const { completedQuestions, outstaningQuestions } = complianceStatus;

  const previousButtonText = complianceTemplate?.previousStepButtonText || tx("misc.previous");
  let submitButtonText = complianceTemplate?.nextStepButtonText || tx("misc.next");

  if (isCompleted) {
    submitButtonText = complianceTemplate?.closeButtonText || tx("misc.close");
  } else if (isSignable) {
    submitButtonText = tx("misc.sign");
  }

  const handleSubmit = async () => {
    if (isCompleted) {
      complianceStore.resetStore();
    } else if (isSignable) {
      const signingLink = await complianceStore.getSigningLink();
      if (signingLink) {
        window.open(signingLink, "_blank")?.focus();
        complianceStore.isProcessing = true;
        complianceStore.startSigningPolling();
      }
    } else {
      await complianceStore.setNextStep();

      if (!complianceStore.showValidationErrors) {
        window.scrollTo(0, 0);
      }
    }
  };

  if (isProcessing) {
    return (
      <section>
        <div className={styles.wrapper}>
          <ProcessingSpinner
            header={complianceTemplate?.processingHeader || tx("compliance.processingHeader")}
            text={complianceTemplate?.processingText || tx("compliance.processingText")}
          />
        </div>
      </section>
    );
  }

  const missingComplianceDefinition = !complianceDefinition || complianceDefinition.length === 0;
  const currentStepOutOfRange = complianceDefinition && currentStep > complianceDefinition.length;
  const unexpectedSigningError = isSignable && showUnexpectedError;

  if (missingComplianceDefinition || currentStepOutOfRange || unexpectedSigningError) {
    // Unexpected error where:
    // - questionnaire is in progress but complianceDefinition is missing
    // - currentStep is out of range
    // - Signing call is getting isIncompleteQuestionnaire status or any other unexpected error
    return renderErrorEmptyState();
  }

  return (
    <section>
      <div className={styles.wrapper}>
        <div className={styles.form}>
          {isActiveQuestionnaire && <CustomerComplianceForm template={complianceTemplate} />}
          {isSignable && (
            <div>
              {complianceTemplate?.signingHeader && (
                <HtmlContent html={`<h1>${complianceTemplate.signingHeader}</h1>`} />
              )}
              {complianceTemplate?.signingText && <HtmlContent html={complianceTemplate.signingText} />}
            </div>
          )}
          {isCompleted && <EmptyState data={complianceTemplate?.happyCaseEmptyState} />}
        </div>
        {!isCompleted && (
          <>
            <div className={styles.progressBar}>
              <div>
                <ProgressBar
                  current={completedQuestions}
                  max={completedQuestions + outstaningQuestions}
                  displayAmountHeader
                  amountHeaderSuffix={complianceTemplate?.progressBarQuestionsSuffix || tx("misc.questions")}
                  percentageCompletedSuffix={complianceTemplate?.progressBarPercentageSuffix}
                />
              </div>
            </div>
            <div className={styles.footer}>
              {isActiveQuestionnaire && hasPreviousStep && (
                <Button
                  title={previousButtonText}
                  onClick={complianceStore.setPrevStep}
                  disabled={complianceStore.updating || complianceStore.fetchingSignature}
                  borderColor="black"
                  bordered
                  large
                />
              )}
              <Button
                className={styles.alignRight}
                title={submitButtonText}
                color="surfieGreen"
                onClick={handleSubmit}
                loading={complianceStore.updating || complianceStore.fetchingSignature}
                large
              />
            </div>
          </>
        )}
      </div>
    </section>
  );
});
