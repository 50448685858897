import React, { useEffect, useState } from "react";
import { useStores } from "../hooks";
import { IEngagementsCustomer } from "../libs/models/Customer/EngagementsCustomer";

export interface IProps {
  location: Location;
}

export function authorize<Props extends IProps>(Component: (props: Props) => JSX.Element) {
  const AuthorizedComponent = (props: Props) => {
    const { customerStore, uiStore, offerStore, rootStore, securePersistentStore, complianceStore } = useStores();
    const [me, setMe] = useState<IEngagementsCustomer | null>();
    const [loginLoading, setLoginLoading] = useState(false);

    useEffect(() => {
      const fetchCustomer = async () => {
        if (securePersistentStore.accessToken) {
          setLoginLoading(true);

          const customer = customerStore.currentCustomer || (await customerStore.getCustomer());
          if (customer) {
            const engagements = customerStore.getEngagements();
            const offers = offerStore.getOffers();
            const pendingApplications = offerStore.getPendingApplications();
            const complianceStatus = complianceStore.getStatus();
            await Promise.all([engagements, offers, pendingApplications, complianceStatus]);
            setLoginLoading(false);
            setMe(customer);
          } else {
            rootStore.logout(true, "Customer data not available");
          }
        } else {
          rootStore.logout(true, "Access token not available");
        }
      };

      if (customerStore.currentCustomer && rootStore.allEngagements !== undefined) {
        setMe(customerStore.currentCustomer);
        return;
      }
      uiStore.setLoading(true);
      fetchCustomer();
    }, []);

    if (loginLoading || typeof me === "undefined") {
      return null;
    }

    return <Component {...props} />;
  };

  return AuthorizedComponent;
}
