import React, { useState } from "react";
import { observer } from "mobx-react";
import { ErrorText } from "~views/shared";
import crossIcon from "~assets/cross.svg";
import { IAttachment } from "netbank-shared/src/libs/models/Attachment/Attachment";
import styles from "./FileAttachment.scss";
import rootStyles from "~views/pages/Root.scss";
import { useStores } from "netbank-shared/src/hooks";
import { tx } from "netbank-shared/src/libs/i18n";
import { LoadingLottie } from "../Lottie/LoadingLottie";
import { bytesToSize } from "netbank-shared/src/libs/utils";
import { IInfoPopoverProps } from "../InfoPopover/InfoPopover";
import { LabelRow } from "../LabelRow/LabelRow";

interface IFileAttachmentSummaryItemProps {
  attachment: IAttachment;
  fileName: string;
  fileExtension: string;
  removeAttachment: (id: string) => Promise<void>;
  disabled?: boolean;
}

const FileAttachmentSummaryItem = ({
  attachment,
  fileName,
  fileExtension,
  removeAttachment,
  disabled,
}: IFileAttachmentSummaryItemProps) => {
  const [loading, setLoading] = useState(false);

  const onRemove = async () => {
    setLoading(true);
    await removeAttachment(attachment.id);
    setLoading(false);
  };

  const loadingClasses = [rootStyles.spinner, styles.spinner];
  if (loading) {
    loadingClasses.push(rootStyles.loading);
  }

  return (
    <div key={attachment.id} className={styles.attachment}>
      <p>{fileName}</p>
      <div className={styles.attachmentDetails}>
        <div className={styles.fileExtension}>{fileExtension.toUpperCase()}</div>
        {attachment.size && <span>{bytesToSize(attachment.size)}</span>}
      </div>
      {loading && <div className={loadingClasses.join(" ")} />}
      {!disabled && !loading && (
        <div className={styles.close} onClick={onRemove}>
          <img src={crossIcon} alt="close" />
        </div>
      )}
    </div>
  );
};

interface IFileAttachementProps {
  id: string; // unique identifier
  attachments: IAttachment[] | undefined;
  addAttachments: (files: File[]) => Promise<void>;
  removeAttachment: (id: string) => Promise<void>;
  desktopLabel?: string;
  desktopLabelSuffix?: string;
  mobileLabel?: string;
  maxFileSizeLabel?: string;
  acceptedExtensionsLabel?: string;
  // Combination if mime types & extensions used for ease of mime validation and precision of extension validation
  acceptedMimeTypes?: string[];
  acceptedExtensions?: string[];
  multiple?: boolean;
  maxAttachments?: number;
  maxAttachmentSize?: number; // In bytes
  maxCombinedAttachmentSize?: number; // Combined size of all attached files at once. In bytes
  label?: string;
  infoPopover?: IInfoPopoverProps;
  disabled?: boolean;
  errors?: string[];
}

export const FileAttachement = observer(
  ({
    id,
    attachments = [],
    addAttachments,
    removeAttachment,
    desktopLabel,
    desktopLabelSuffix,
    mobileLabel,
    maxFileSizeLabel,
    acceptedExtensionsLabel,
    acceptedMimeTypes,
    acceptedExtensions,
    multiple,
    maxAttachments,
    maxAttachmentSize = 10 * 1024 * 1024, // Default to 10MB
    maxCombinedAttachmentSize = 20 * 1024 * 1024, // Default to 20MB
    label,
    infoPopover,
    disabled,
    errors,
  }: IFileAttachementProps): JSX.Element => {
    const input: React.RefObject<HTMLInputElement> = React.useRef(null);
    const [uploading, setUploading] = useState<boolean>(false);
    const [dragFocus, setDragFocus] = useState<boolean>(false);
    const [validationError, setValidationError] = useState<string | undefined>(undefined);
    const { uiStore } = useStores();
    const dropzoneClasses = [styles.dropzone];
    if (!!attachments && attachments.length > 0) {
      dropzoneClasses.push(styles.hasAttachment);
    }

    if (dragFocus) {
      dropzoneClasses.push(styles.focus);
    }

    const onChange = async (fileList: FileList | null) => {
      if (disabled) return;

      const uploadedFiles = Array.from(fileList || []);
      if (uploadedFiles.length === 0) return;

      if (maxAttachments && uploadedFiles.length + attachments.length > maxAttachments) {
        setValidationError(tx("file.maxAttachmentsError", { count: maxAttachments }));
        return;
      }

      // Filter out files with duplicate names in current attachments, unallowed file size or unallowed mime types/file endings
      const files = uploadedFiles.filter(
        (f) =>
          !attachments?.some((a) => a.name === f.name) &&
          f.size <= maxAttachmentSize &&
          (acceptedMimeTypes === null ||
            !!acceptedMimeTypes?.find(
              (type) =>
                f.type === type &&
                (acceptedExtensions === null || !!acceptedExtensions?.find((extension) => f.name.endsWith(extension)))
            ))
      );

      // If lengths differ, show validation errors
      if (uploadedFiles.length !== files.length) {
        if (acceptedMimeTypes && acceptedExtensions) {
          setValidationError(tx("file.attachmentValidationError", { extensions: acceptedExtensions }));
        } else {
          setValidationError(tx("file.duplicateAttachmentOrSizeError"));
        }
        return;
      }

      if (files.length > 0) {
        const totalSize = files.reduce((total, { size }) => total + size, 0);
        if (totalSize > maxCombinedAttachmentSize) {
          setValidationError(tx("file.maxCombinedFileSizeLabel", { limit: bytesToSize(maxCombinedAttachmentSize) }));
          return;
        }

        setUploading(true);
        await addAttachments(files);
        setUploading(false);
      }
    };

    const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
      if (disabled || uploading) return;

      e.preventDefault();
      setDragFocus(true);
      setValidationError(undefined);
    };

    const onDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
      if (disabled || uploading) return;

      e.preventDefault();
      setDragFocus(false);
    };

    const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
      if (disabled || uploading) return;

      e.preventDefault();
      setDragFocus(false);
      onChange(e.dataTransfer.files);
    };

    return (
      <div>
        {label && <LabelRow label={label} infoPopover={infoPopover} />}
        <div className={dropzoneClasses.join(" ")} onDragOver={onDragOver} onDragLeave={onDragLeave} onDrop={onDrop}>
          {uploading ? (
            <LoadingLottie />
          ) : (
            <p>
              {!uiStore.isMobile && (desktopLabel || tx("file.desktopLabel"))}
              <span
                onClick={(e) => {
                  if (disabled) return;
                  e.preventDefault();
                  setValidationError(undefined);
                  input.current?.click();
                }}
              >
                {uiStore.isMobile
                  ? mobileLabel || tx("file.mobileLabel")
                  : desktopLabelSuffix || tx("file.desktopLabelSuffix")}
              </span>
            </p>
          )}
        </div>
        <input
          type="file"
          ref={input}
          accept={(acceptedExtensions || acceptedMimeTypes)?.join(", ")}
          onChange={(e) => {
            e.preventDefault();
            onChange(e.target.files);
          }}
          multiple={multiple}
          disabled={disabled}
          hidden
        />
        {!!attachments && attachments.length > 0 && (
          <div className={styles.attachments}>
            {attachments?.map((attachment) => {
              const splittedName = attachment.name.split(".");
              const fileName = splittedName.slice(0, -1).join(".");
              const [fileExtension] = splittedName.slice(-1);

              return (
                <FileAttachmentSummaryItem
                  key={`fileattachment-${id}-attachment-${attachment.id}`}
                  attachment={attachment}
                  fileName={fileName}
                  fileExtension={fileExtension}
                  removeAttachment={removeAttachment}
                  disabled={disabled}
                />
              );
            })}
          </div>
        )}
        <div>
          {maxAttachmentSize && (
            <span className={styles.attachmentRulesText}>
              {maxFileSizeLabel || tx("file.maxFileSizeLabel")}: {bytesToSize(maxAttachmentSize)}.
            </span>
          )}
          {acceptedExtensions && (
            <span className={styles.attachmentRulesText}>
              {`${acceptedExtensionsLabel || tx("file.acceptedExtensionsLabel")}: ${tx("file.acceptedExtensions", {
                extensions: acceptedExtensions,
              })}`}
            </span>
          )}
        </div>
        {validationError && <ErrorText error={validationError} />}
        {errors && errors.map((error, i) => <ErrorText key={`fileattachment-${id}-error-${i}`} error={error} />)}
      </div>
    );
  }
);
