import { observer } from "mobx-react";
import styles from "../BankAccountControlFlow.scss";
import React from "react";
import cloud from "~assets/cloud.svg";
import ballon from "~assets/ballon.svg";
import sky from "~assets/sky-background.svg";
import { Button } from "~views/shared";
import { useStores } from "netbank-shared/src/hooks";
import { BankAccountControlFlowState } from "netbank-shared/src/libs/models/Content/Enums";

interface BankAccountControlErrorStepProps {
  errorMessage: string;
  errorHeader: string;
  tryAgainButton: string;
  cancelButton: string;
}

export const BankAccountControlErrorStep = observer(
  ({ errorHeader, errorMessage, tryAgainButton, cancelButton }: BankAccountControlErrorStepProps) => {
    const { uiStore } = useStores();
    const handleTryAgain = () => {
      uiStore.setBankAccountControlFlowState(BankAccountControlFlowState.Started);
    };

    const handleGiveUp = () => {
      uiStore.setBankAccountControlFlowState(BankAccountControlFlowState.Inactive);
    };

    return (
      <div className={styles.errorContainer}>
        <div className={styles.errorImage}>
          <img className={styles.sky} src={sky} alt="sky" />
          <img className={styles.leftCloud} src={cloud} alt="cloud" />
          <img className={styles.ballon} src={ballon} alt="ballon" />
          <img className={styles.rightCloud} src={cloud} alt="cloud" />
        </div>
        <div className={styles.textContainer}>
          <span className={styles.header}>{errorHeader}</span>
          <span className={styles.description}>{errorMessage}</span>
        </div>
        <div className={styles.buttonContainer}>
          <Button title={tryAgainButton} onClick={handleTryAgain} color="red" fullWidth />
          <Button title={cancelButton} color="white" onClick={handleGiveUp} bordered fullWidth />
        </div>
      </div>
    );
  }
);
