import React, { ReactNode, RefObject, useRef, useState } from "react";
import { observer } from "mobx-react";
import styles from "./ExpandableText.scss";

interface IExpandableTextProps {
  header: string;
  children: ReactNode;
  expanded?: boolean;
}

export const ExpandableText = observer(({ header, children, expanded = false }: IExpandableTextProps) => {
  const textRef: RefObject<HTMLDivElement> = useRef(null);

  const [open, setOpen] = useState(expanded);
  const classes = [styles.wrapper];

  if (open) {
    classes.push(styles.open);
  }

  return (
    <div className={classes.join(" ")}>
      <button type="button" className={styles.header} onClick={() => setOpen(!open)}>
        {header}
      </button>
      <div ref={textRef} className={styles.text}>
        <span className={styles.childWrapper}>{children}</span>
      </div>
    </div>
  );
});
