export interface IAccount {
  displayNumber?: string;
  accountNumber: string;
  accountType?: string;
  accountStatus?: AccountStatus;
  bookedBalance?: number; // Interest bearing balance
  accountInterest?: number;
  effectiveInterest?: number;
  creditLimit?: number;
  chainName?: string;
  chainNumber?: number;
  storeNumber?: string;
  invoiceFee?: number;
  nameIB?: string;
  name?: string;
  coApplicantName?: string;
  hasCoApplicant?: boolean;
  hasDirectDebit?: boolean;
  paymentAccount?: string;
  hasInsurance?: boolean;
  bankTransferMinimumFee?: number;
  bankTransferPercentageFee?: number;
  availableBalance?: number;
  openDate?: string;
  accountPersonalName?: string;
  isAmlFrozen: boolean;
  closeDate?: string;
}

export enum AccountStatus {
  AccountOk = "AccountOk",
  AccountDecline = "AccountDecline",
  AccountToClose = "AccountToClose",
  Other = "Other",
  AccountClosed = "AccountClosed",
}
