import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "netbank-shared/src/hooks";
import { ILoanTopUpPage } from "netbank-shared/src/libs/models/Content/Page";
import { getQueryParam } from "../../../utils/misc";
import { OfferCategory } from "netbank-shared/src/libs/models/Offer/Offer";
import rootStyles from "../Root.scss";
import styles from "../Page.scss";
import { OfferEmptyState } from "../Offers/OfferEmptyState";
import { LoanTopUpFlow } from "~views/flows/LoanTopUpFlow/LoanTopUpFlow";
import { LoanTopUpSkeleton } from "../Skeletons/LoanTopUpSkeleton";
import { PageType } from "netbank-shared/src/libs/models/Content/Enums";

export interface ILoanTopUpProps {
  location: Location;
}

export const LoanTopUpPage = observer(({ location }: ILoanTopUpProps) => {
  const { contentStore, offerStore, transferStore } = useStores();
  const page = contentStore.currentPage as ILoanTopUpPage;

  useEffect(() => {
    const fetchOffer = async () => {
      let offer: Promise<void> | undefined;
      let pendingApplication: Promise<void> | undefined;
      const transferAccounts = transferStore.getSavedAccounts();
      const offerId = getQueryParam(location.search, "offerId");
      const applicationId = getQueryParam(location.search, "applicationId");

      if (applicationId && !offerStore.currentPendingApplication && !offerStore.loadingPendingApplication) {
        pendingApplication = offerStore.setCurrentPendingApplicationByApplicationId(applicationId);
      } else if (offerId) {
        const topUpOffer = offerStore.offers?.find(
          (o) => o.offerId === offerId && o.offerCategory === OfferCategory.AOL
        );

        if (topUpOffer && !offerStore.currentOffer) {
          offer = offerStore.setCurrentOfferById(topUpOffer.offerId);
          if (!offerStore.loanCalculationData) {
            offerStore.getLoanCalculations(topUpOffer.offerId);
          }
        }
      }

      if (!offerStore.currentAccount) {
        offerStore.setCurrentAccount();
      }

      await Promise.all([offer, pendingApplication, transferAccounts]);
      offerStore.shouldResetTopup = true;
    };

    const pageContentTypeLength = page?.contentType?.length ?? 0;
    const pageType = page?.contentType?.[pageContentTypeLength - 1];

    const isTopUpPage =
      pageType === PageType.LoanTopUpPage && location.pathname === page.loanTopUpPage.replace(/\/$/, "");

    // Weird bug caused by navigating between different CmsPages (Loan Transit Page & Loan Top Up Page)
    // Verbose check is required to avoid firing fetchOffer twice
    if (isTopUpPage) {
      fetchOffer();
    }

    return () => {
      // Weird bug caused by navigating between different CmsPages (for example Loan Transit Page & Loan Top Up Page)
      // Verbose check is required to avoid firing resetTopUp twice
      if (isTopUpPage && offerStore.shouldResetTopup) {
        offerStore.resetTopUp();
      }
    };
  }, [location]);

  if (
    (offerStore.loadingPendingApplication || transferStore.fetchingSavedAccounts) &&
    offerStore.currentTopUpStep === 1
  ) {
    return <LoanTopUpSkeleton />;
  }

  const isValidTopUp = offerStore.validateCurrentOffer() || offerStore.validateCurrentPendingApplication();

  if (!isValidTopUp) {
    return (
      <div className={styles.wrapper}>
        <section>
          <OfferEmptyState data={page.validationErrorEmptyState} />
        </section>
      </div>
    );
  }

  return (
    <section className={rootStyles.topUpFlow}>
      <LoanTopUpFlow data={page} />
    </section>
  );
});
