import { TransferStatus } from "../../../models/Content/Enums";
import { ITransfer, ITransferStatusResponse } from "../../../models/Transfer/Transfer";

export const mockTransfer: ITransfer = {
  autoStartToken: "991ff177-b6d0-4d42-ae8d-11f1cf2dabe6",
  transactionId: "3fec12ab-7ab9-4f5d-89fb-8de34d4baf10",
};

export const mockIncorrectAccountTransfer: ITransfer = {
  autoStartToken: "8995dd11-29b0-410e-81a3-450f7e96c70b",
  transactionId: "98145dd2-9a8a-4b96-a411-2c88b708d4b1",
};

export const mockTransferStatus: ITransferStatusResponse = {
  status: 200,
  transactionStatus: TransferStatus.Succeeded,
  transactionStatusCode: 0,
};

export const mockIncorrectAccountTransferStatus: ITransferStatusResponse = {
  status: 400,
  transactionStatus: TransferStatus.IllegalForeignAccount,
  transactionStatusCode: 4005,
};
