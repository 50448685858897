import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import styles from "./AmountRange.scss";
import { Button, Input } from "..";
import { useStores } from "netbank-shared/src/hooks";
import { tx } from "netbank-shared/src/libs/i18n";
import { useCloseWithEsc } from "~hooks";

interface IAmountRangeProps {
  title: string;
  updateFilter: () => void;
}

interface IAmountRangeFormProps {
  closeAction: () => void;
  applyAction: () => void;
}

const AmountRangeForm = observer(({ closeAction, applyAction }: IAmountRangeFormProps) => {
  const { transactionStore, uiStore } = useStores();
  const { filterFrom, setFilterFrom, setAmountFrom, filterTo, setFilterTo, setAmountTo } = transactionStore;

  const { currency } = uiStore;

  const apply = () => {
    if (setAmountFrom) {
      setAmountFrom(filterFrom);
    }
    if (setAmountTo) {
      setAmountTo(filterTo);
    }
    applyAction();
    closeAction();
  };
  return (
    <>
      <div className={styles.inputs}>
        <div className={styles.amountWrapper}>
          <span>{tx("misc.amountFrom")}</span>
          <Input
            value={filterFrom || ""}
            onChange={(e) =>
              e?.target?.value && parseInt(e.target.value) <= 10000000 && setFilterFrom && setFilterFrom(e.target.value)
            }
            suffix={currency}
          />
        </div>

        <div className={styles.amountWrapper}>
          <span>{tx("misc.amountTo")}</span>
          <Input
            value={filterTo || ""}
            onChange={(e) =>
              e?.target?.value && parseInt(e.target.value) <= 10000000 && setFilterTo && setFilterTo(e.target.value)
            }
            suffix={currency}
          />
        </div>
      </div>
      <div className={styles.actions}>
        <Button title={tx("misc.cancel")} onClick={closeAction} bordered color="black" borderColor="black" />
        <Button title={tx("misc.apply")} onClick={apply} color="red" />
      </div>
    </>
  );
});

export const AmountRange = observer(({ title, updateFilter }: IAmountRangeProps) => {
  const { uiStore, transactionStore } = useStores();
  const [open, setOpen] = useState(false);
  const [popupId, setPopupId] = useState(-1);
  const ref = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (open) {
      if (popupId === -1) {
        const id = uiStore.setPopup({
          children: <AmountRangeForm closeAction={() => setOpen(!open)} applyAction={updateFilter} />,
          open,
          cancelAction: () => setOpen(!open),
          element: ref,
        });
        setPopupId(id);
      }
    } else if (popupId !== -1) {
      uiStore.removePopup(popupId);
      setPopupId(-1);
    }
  }, [open]);

  useCloseWithEsc(() => setOpen(false));

  const selected = transactionStore.amountFrom !== "" || transactionStore.amountTo !== "";

  return (
    <div className={styles.wrapper}>
      <Button title={title} onClick={() => setOpen(!open)} bordered color={open || selected ? "blue" : ""} ref={ref} />
    </div>
  );
});
