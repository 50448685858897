import { Locale } from "../models/Content/Enums";

export const toLocaleString = (amount?: number, currency?: string, locale = Locale.sv, fractionDigits = 2) => {
  if (typeof amount !== "number") return "";
  if (currency) {
    return amount
      .toLocaleString(locale, {
        currency,
        style: "currency",
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
      })
      .replace(".", ",");
  }

  return amount
    .toLocaleString(locale, {
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
    })
    .replace(".", ",");
};
