import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useStores } from "netbank-shared/src/hooks";
import { tx } from "netbank-shared/src/libs/i18n";
import { ICard } from "netbank-shared/src/libs/models/Engagements";
import { Dropdown } from "~views/shared/Dropdown/Dropdown";
import { ListItem } from "netbank-shared/src/libs/models/Content/ListItem";
import styles from "./CardSelectionDropdown.scss";

interface ICardSelectionDropdownProps {
  onOpen?: () => void;
}

export const CardSelectionDropdown = observer(({ onOpen }: ICardSelectionDropdownProps) => {
  const classes = [styles.wrapper];
  const { creditStore } = useStores();
  const { creditAccounts, currentCard, currentAccount, cards } = creditStore;

  const cardList = cards?.map((card, index) => {
    const account = creditAccounts?.find((acc) => acc.accountNumber === card.accountNumber);
    const accountTitle = account ? `${account.name} - ` : "";
    return {
      index,
      value: card.cardId,
      label: `${accountTitle}${card.displayNumber}`,
    };
  });
  const hasCards = !!cardList && cardList.length > 0;

  const accountList = !hasCards
    ? creditAccounts
        ?.filter((account) => account.canOrderCard)
        ?.map((account, index) => {
          return {
            index,
            value: account.accountNumber,
            label: `${account.name} - ${account.displayNumber}`,
          };
        })
    : [];
  const hasAccounts = !!accountList && accountList.length > 0;

  useEffect(() => {
    if (hasCards && !currentAccount && cards?.length) {
      creditStore.setCurrentCard(cards[0]);
    } else if (!hasCards && hasAccounts && creditAccounts.length) {
      creditStore.setCurrentAccount(creditAccounts[0]);
    }
  }, []);

  const selectedCardTitle = currentCard
    ? cardList?.find((c) => c.value === currentCard.cardId)?.label
    : cardList?.[0]?.label;

  const selectedAccountTitle =
    accountList?.find((a) => a.value === currentAccount?.accountNumber)?.label || accountList?.[0]?.label;

  const setCurrentCard = (el: ListItem) => {
    if (creditStore.currentCard?.cardId === el?.value) return;
    const selectedCard = cards?.find((card: ICard) => el?.value === card.cardId);
    if (!selectedCard) return;
    creditStore.setCurrentCard(selectedCard);
  };

  const setCurrentAccount = (el: ListItem) => {
    if (creditStore.currentAccountNumber === el?.value) return;
    const selectedAccount = creditAccounts?.find((account) => el?.value === account.accountNumber);
    if (!selectedAccount) return;
    creditStore.setCurrentAccount(selectedAccount);
  };

  if (!hasCards && !hasAccounts) return null;

  return (
    <section className={classes.join(" ")}>
      <div className={styles.dropdownWrapper}>
        <p>{hasCards ? tx("misc.chooseACard") : tx("message.selectAccountText")}</p>
        <Dropdown
          list={hasCards ? cardList : accountList}
          onChange={hasCards ? setCurrentCard : setCurrentAccount}
          onOpen={onOpen}
          value={hasCards ? selectedCardTitle : selectedAccountTitle}
          innerLabel={hasCards ? tx("misc.chooseACard") : tx("message.selectAccountText")}
        />
      </div>
    </section>
  );
});
