import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { useStores } from "netbank-shared/src/hooks";
import { IPage } from "netbank-shared/src/libs/models/Content/Page";
import { PageType } from "netbank-shared/src/libs/models/Content/Enums";
import { Store } from "netbank-shared/src/stores";
import { tx } from "netbank-shared/src/libs/i18n";
import { withRoot } from "../Root";
import styles from "../Page.scss";
import { ContentArea } from "~views/shared/ContentArea/ContentArea";
import { MessageInbox } from "../Messages/MessageInbox";
import { OffersPage } from "../Offers/Offers";
import { TermsPage } from "../Terms/Terms";
import { NewMessage } from "../Messages/NewMessage";
import { NotFoundPage } from "../NotFound/NotFound";
import { LoanTopUpPage } from "../LoanTopUp/LoanTopUp";
import { CmsSkeleton } from "../Skeletons/CmsSkeleton";
import { ClosingOfDepositAccountPage } from "../ClosingOfDepositAccounts/ClosingOfDepositAccounts";
import { CustomerCompliancePage } from "../CustomerCompliancePage/CustomerCompliancePage";

export interface CmsPageProps {
  location: Location;
  store: Store;
}

const CmsPage = observer(({ location }: CmsPageProps) => {
  const { contentStore, uiStore, creditStore, loanStore, depositStore, leasingStore } = useStores();
  const navigate = useNavigate();
  useEffect(() => {
    const fetchPage = async () => {
      // Homepage must exist before fetching page. This useEffect will be rerun when homePage is set
      if (contentStore.getHomePage(tx("routing.lang"))) {
        await contentStore.getPage(location.pathname);
        const lastContentTypeIndex = contentStore.currentPage?.contentType?.length ?? 0;
        const pageType = contentStore.currentPage?.contentType?.[lastContentTypeIndex - 1];

        if (creditStore.creditAccounts?.length === 1 && pageType === PageType.CardTransitPage) {
          navigate(`/${tx("routing.lang")}/${tx("routing.card")}/${creditStore.creditAccounts[0].accountNumber}`);
          return;
        }
        if (!creditStore.currentCard) {
          if (pageType === PageType.CardSettingsPage) {
            const card = creditStore.cards?.[0];
            if (card) {
              creditStore.setCurrentCard(card);
            }
          }
        }
        if (loanStore.loanAccounts?.length === 1 && pageType === PageType.LoanTransitPage) {
          navigate(`/${tx("routing.lang")}/${tx("routing.loan")}/${loanStore.loanAccounts[0].accountNumber}`);
        }
        if (loanStore.activePrivateLoanAccounts?.length === 1 && pageType === PageType.PrivateLoanTransitPage) {
          navigate(
            `/${tx("routing.lang")}/${tx("routing.privateLoan")}/${
              loanStore.activePrivateLoanAccounts[0].accountNumber
            }`
          );
        }
        if (loanStore.activeSecuredLoanAccounts?.length === 1 && pageType === PageType.SecuredLoanTransitPage) {
          navigate(
            `/${tx("routing.lang")}/${tx("routing.securedLoan")}/${
              loanStore.activeSecuredLoanAccounts[0].accountNumber
            }`
          );
        }
        if (depositStore.notClosedAccounts?.length === 1 && pageType === PageType.DepositTransitPage) {
          navigate(
            `/${tx("routing.lang")}/${tx("routing.deposit")}/${depositStore.notClosedAccounts[0].accountNumber}`
          );
        }
        if (leasingStore.activeLeasingAccounts?.length === 1 && pageType === PageType.LeasingTransitPage) {
          navigate(
            `/${tx("routing.lang")}/${tx("routing.leasing")}/${leasingStore.activeLeasingAccounts[0].accountNumber}`
          );
        }
      }
    };
    fetchPage();
  }, [location, contentStore.homePage]);

  useEffect(() => {
    if (uiStore.logoAnimationLoaded && (contentStore.currentPage || contentStore.is404)) {
      uiStore.setLoading(false);
    }
  }, [uiStore.logoAnimationLoaded, contentStore.currentPage, contentStore.is404]);

  const renderCmsPage = (data?: IPage) => {
    if (contentStore.is404) return <NotFoundPage />;
    const length = data?.contentType?.length ?? 0;
    const page = data?.contentType?.[length - 1];

    switch (page) {
      case PageType.MessageInbox:
        return <MessageInbox />;
      case PageType.NewMessage:
        return <NewMessage />;
      case PageType.OffersPage:
        return <OffersPage location={location} />;
      case PageType.LoanTopUpPage:
        return <LoanTopUpPage location={location} />;
      case PageType.TermsPage:
        return <TermsPage />;
      case PageType.ClosingOfDepositAccountPage:
        return <ClosingOfDepositAccountPage location={location} />;
      case PageType.CustomerCompliancePage:
        return <CustomerCompliancePage />;
      case PageType.ContainerPage:
        return <NotFoundPage />;
      default:
        return <ContentArea />;
    }
  };

  if (contentStore.fetchingCmsPage) {
    return <CmsSkeleton />;
  }

  return <div className={styles.wrapper}>{renderCmsPage(contentStore.currentPage)}</div>;
});

export default withRoot(CmsPage);
