import { PendingApplicationApplicantRole, PendingApplicationState } from "../../../models/Content/Enums";
import { IPendingApplication, IPendingApplicationsResponse } from "../../../models/Offer/Offer";

export const mockPendingApplications: IPendingApplicationsResponse = {
  pendingApplications: [
    {
      accountNumber: "4257557",
      accountType: "L",
      requestedAmount: 25000,
      approvedAmount: 25000,
      applicationId: "335da88f-61bd-40c7-bd13-5abbdf409b74",
      customerId: 1443325,
      interestRate: 7.85,
      monthlyCost: 1130,
      paybackTime: 120,
      applicationState: PendingApplicationState.Scoring,
      isPpiEligible: true,
    },
  ],
  status: 0,
};

export const mockPendingApplication: IPendingApplication = {
  accountNumber: "4257557",
  accountType: "L",
  requestedAmount: 25000,
  approvedAmount: 25000,
  applicationId: "335da88f-61bd-40c7-bd13-5abbdf409b74",
  customerId: 1443325,
  interestRate: 7.85,
  monthlyCost: 1130,
  paybackTime: 120,
  applicationState: PendingApplicationState.Scoring,
  applicantRole: PendingApplicationApplicantRole.Customer,
  isPpiEligible: true,
};
