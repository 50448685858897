import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useStores, useTracking } from "netbank-shared/src/hooks";
import { IOffersPage } from "netbank-shared/src/libs/models/Content/Page";
import { getQueryParam } from "../../../utils/misc";
import { tx } from "netbank-shared/src/libs/i18n";
import styles from "../Page.scss";
import offerStyles from "./Offers.scss";
import { ContentArea } from "~views/shared/ContentArea/ContentArea";
import { Checkbox } from "~views/shared/Checkbox/Checkbox";
import { Button } from "~views/shared";
import { OfferEmptyState } from "./OfferEmptyState";
import { CmsSkeleton } from "../Skeletons/CmsSkeleton";

export interface IOffersPageProps {
  location: Location;
}

export const OffersPage = observer(({ location }: IOffersPageProps) => {
  const { contentStore, offerStore } = useStores();
  const dataLayer = useTracking((window as any).dataLayer);
  const page = contentStore.currentPage as IOffersPage;
  const [isConsuming, setIsConsuming] = useState(false);
  const [checked, setChecked] = useState(false);
  const [consumeOfferResponse, setConsumeOfferResponse] = useState<boolean | undefined>(undefined);

  const offerId = getQueryParam(location.search, "offerId");

  const trackingCategory = offerStore.getTrackingCategory();

  useEffect(() => {
    const fetchOffer = async () => {
      if (!offerStore.currentOffer || offerStore.currentOffer.offerId !== offerId) {
        await offerStore.setCurrentOfferById(offerId);
      }
    };
    fetchOffer();

    return () => {
      offerStore.setCurrentOffer(undefined);
    };
  }, [location]);

  const consumeOffer = async () => {
    setIsConsuming(true);
    const response = await offerStore.consumeOffer(offerId);
    window.scrollTo(0, 0);
    if (response?.ok) {
      const trackingAction = offerStore.getTrackingActionSuccess();
      const trackingEvent = offerStore.getTrackingEventPrefix();
      const trackingValue = offerStore.getTrackingValue();
      if (trackingCategory) {
        dataLayer.pushInteraction(trackingCategory, trackingAction, trackingEvent, trackingValue);
      }
      setConsumeOfferResponse(true);
    } else {
      const trackingAction = offerStore.getTrackingActionError();
      if (trackingCategory) {
        dataLayer.pushInteraction(trackingCategory, trackingAction);
      }
      setConsumeOfferResponse(false);
    }
    setIsConsuming(false);
  };

  const isValidOffer = offerStore.validateCurrentOffer();
  const accountPath = `${tx("routing.lang")}/${tx(`routing.${offerStore.getCurrentAccountTypeRoute()}`)}`;
  const accountNumber = `${offerStore.currentAccount?.accountNumber.replace(/^0+/, "")}`;

  const returnPath = `/${accountPath}/${accountNumber}`;

  if (offerStore.isLoadingOffer) {
    return <CmsSkeleton />;
  }

  if (!isValidOffer) {
    return (
      <div className={styles.wrapper}>
        <section>
          <OfferEmptyState data={page.errorCaseEmptyState} />
        </section>
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      {typeof consumeOfferResponse === "undefined" && (
        <>
          <ContentArea />
          <section>
            <Checkbox
              label={page.termsConditionText}
              checked={checked}
              onChange={(e) => setChecked(e.target.checked)}
            />
            <div className={offerStyles.submit}>
              <Button
                title={page.consumeOfferButtonText}
                color={checked ? "red" : ""}
                bordered={!checked}
                borderColor={!checked ? "black" : ""}
                onClick={() => consumeOffer()}
                loading={isConsuming}
                disabled={!checked}
                trackingCategory={trackingCategory}
                trackingAction={trackingCategory && offerStore.getTrackingCTA()}
                trackingPrefix={trackingCategory && offerStore.getTrackingEventPrefix()}
                trackingValue={trackingCategory && offerStore.getTrackingValue()}
              />
            </div>
          </section>
        </>
      )}
      {consumeOfferResponse === true && (
        <section>
          <OfferEmptyState accountPath={returnPath} data={page.happyCaseEmptyState} />
        </section>
      )}
      {consumeOfferResponse === false && (
        <section>
          <OfferEmptyState data={page.errorCaseEmptyState} />
        </section>
      )}
    </div>
  );
});
