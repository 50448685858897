import React, { RefObject, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import styles from "./InputSlider.scss";
import rootStyles from "~views/pages/Root.scss";
import { toLocaleString } from "netbank-shared/src/libs/utils";
import { Input } from "..";
import { useStores } from "netbank-shared/src/hooks";
import { tx } from "netbank-shared/src/libs/i18n";
import warningIcon from "~assets/information.svg";

export enum InputSliderType {
  Moneytary = "monetary",
  Years = "years",
}

interface IInputSliderProps {
  min: number;
  max: number;
  value: number;
  setValue: (value: number) => void;
  step?: number;
  label?: string;
  disabled?: boolean;
  sliderType: InputSliderType;
  warning?: string;
  debounceAction?: () => void;
}

export const InputSlider = observer(
  ({ min, max, value, setValue, step, label, disabled, sliderType, warning, debounceAction }: IInputSliderProps) => {
    const ref: RefObject<HTMLInputElement> = useRef(null);
    const wrapperRef: RefObject<HTMLInputElement> = useRef(null);
    const thumbRef: RefObject<HTMLDivElement> = useRef(null);
    const { uiStore } = useStores();
    const { locale, currency } = uiStore;

    const [inputValue, setInputValue] = useState(value);
    const [skipDebounceAction, setSkipDebounceAction] = useState(true);
    const [focus, setFocus] = useState(false);
    const classes = [styles.wrapper];
    const sliderClasses = [styles.slider];
    const sliderThumbClasses = [styles.sliderThumb];

    if (disabled) {
      classes.push(styles.disabled);
      sliderClasses.push(styles.disabled);
    }

    if (focus) {
      sliderClasses.push(styles.focused);
      sliderThumbClasses.push(styles.focused);
    }

    if (warning) {
      sliderClasses.push(styles.warning);
      sliderThumbClasses.push(styles.warning);
    }

    useEffect(() => {
      const delayInput = setTimeout(() => {
        let val = inputValue;
        const roundStep = step || 1000;
        val = Math.round(val / roundStep) * roundStep;

        if (val > max) {
          val = max;
        }

        if (val < min) {
          val = min;
        }

        setInputValue(val);
        setValue(val);

        if (debounceAction && !skipDebounceAction) {
          debounceAction();
        }

        setSkipDebounceAction(true);
      }, 1000);

      return () => clearTimeout(delayInput);
    }, [inputValue]);

    useEffect(() => {
      if (value !== inputValue) {
        setInputValue(value);
      }
    }, [value]);

    const setSliderBackground = () => {
      if (!ref.current?.value || !wrapperRef.current) return;
      const val = (parseInt(ref.current.value, 10) - min) / (max - min);
      const percent = val * 100;

      const color = warning ? "#fffaeb" : "#c3dee7";
      const filledColor = warning ? "#ffcc33" : "#1bb3bc";

      if (thumbRef.current) thumbRef.current.style.transform = `translate3d(${percent}%, 0, 0)`;

      wrapperRef.current.style.backgroundImage = `-webkit-gradient(
         linear, left top, right top, color-stop(${percent}%, ${filledColor}), color-stop(${percent}%, ${color}))`;

      // eslint-disable-next-line max-len
      wrapperRef.current.style.backgroundImage = `-moz-linear-gradient(left center, ${filledColor} 0%, ${filledColor} ${percent}%, ${color} ${percent}%, ${color} 100%)`;
    };

    const renderValue = (v: number) => {
      switch (sliderType) {
        case InputSliderType.Moneytary:
          return toLocaleString(v, currency, locale, 0);

        case InputSliderType.Years:
          return `${v} ${tx("misc.years")}`;

        default:
          return v.toString();
      }
    };

    useEffect(() => {
      setSliderBackground();
    }, [ref, value, min, max]);

    return (
      <div className={classes.join(" ")}>
        <div className={styles.titleValueWrapper}>
          {label && <p className={rootStyles.bold}>{label}</p>}
          <Input
            className={styles.numberInput}
            type="text"
            value={renderValue(inputValue || 0)}
            onChange={(e) => {
              const val = e.target.value.replace(/\D/g, "");
              setInputValue(parseInt(val, 10));
              // Reset skip debounce action
              setSkipDebounceAction(false);
            }}
            disabled={disabled}
            small
          />
        </div>
        <div className={styles.sliderWrapper}>
          <div className={styles.sliderInputWrapper} ref={wrapperRef}>
            <input
              ref={ref}
              className={sliderClasses.join(" ")}
              type="range"
              min={min}
              max={max}
              value={value || 0}
              onChange={(e) => {
                setValue(parseInt(e.target.value, 10));
                setInputValue(parseInt(e.target.value, 10));
              }}
              step={step || 1000}
              disabled={disabled}
              onMouseMove={setSliderBackground}
              onClick={setSliderBackground}
              onMouseDown={() => setFocus(true)}
              onTouchStart={() => setFocus(true)}
              onMouseUp={() => {
                setFocus(false);
                debounceAction?.();
              }}
              onTouchEnd={() => {
                setFocus(false);
                debounceAction?.();
              }}
            />
          </div>
          <div ref={thumbRef} className={sliderThumbClasses.join(" ")}>
            <span />
          </div>
        </div>
        <div className={styles.labels}>
          <span className={styles.minLabel}>{renderValue(min)}</span>
          <span className={styles.maxLabel}>{renderValue(max)}</span>
        </div>
        {warning && (
          <div className={rootStyles.warning}>
            <img src={warningIcon} alt="warning-icon" />
            <p>{warning}</p>
          </div>
        )}
      </div>
    );
  }
);
