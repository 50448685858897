import { toJS } from "mobx";
import { observer } from "mobx-react";
import React, { lazy, Suspense, useEffect, useState } from "react";
import { useStores } from "netbank-shared/src/hooks";
import { IAccordionContentAreaItem } from "netbank-shared/src/libs/models/Content/accordions/AccordionContentAreaItem";
import { IBasicTextBlock } from "netbank-shared/src/libs/models/Content/BasicTextBlock";
import { IContentAreaItem } from "netbank-shared/src/libs/models/Content/ContentAreaItem";
import { IGenericContentLink } from "netbank-shared/src/libs/models/Content/ContentLink";
import { ContentType, PageType } from "netbank-shared/src/libs/models/Content/Enums";
import { IPage } from "netbank-shared/src/libs/models/Content/Page";
import { CardSelectionDropdown } from "./Blocks/Accordions/CardActions/CardSelectionDropdown";

const blockCache: { [key: string]: any } = [];

interface IContentAreaProps {
  modal?: boolean;
  page?: IPage;
}

interface IContentAreaItemsProps {
  items: IContentAreaItem[];
  modal: boolean;
}

const filterContentItems = (items: IContentAreaItem[], customerCanActOn: (blockType: string) => boolean) => {
  let mappedItems = toJS(items);

  mappedItems = mappedItems.map((item) => {
    const updated = item;
    if (item.contentType[1] === ContentType.AccordionBlock && item.content && item.content.length) {
      updated.content = item.content?.filter((e) => {
        return customerCanActOn(e.contentType?.[1]);
      });
    }
    return updated;
  });

  const filteredItems = mappedItems.filter((item) =>
    item?.contentType?.[1] === ContentType.AccordionBlock
      ? (item as IGenericContentLink<IAccordionContentAreaItem>)?.content?.length > 0
      : true
  );

  return filteredItems;
};

const ContentAreaItems = observer(({ items, modal }: IContentAreaItemsProps) => {
  const { creditStore, contentStore, customerStore } = useStores();

  const { currentPage } = contentStore;
  const { cards, cardSelectBlocks, creditAccounts } = creditStore;

  const pageType = currentPage?.contentType?.[1];

  const cardSelectPage = pageType === PageType.CardSettingsPage;

  const filteredItems = filterContentItems(items, customerStore.customerCanActOn);

  const firstCardSelectionRequiredBlock = cardSelectPage
    ? items?.findIndex((item) => {
        return (
          item?.contentType?.[1] === ContentType.AccordionBlock &&
          item.content &&
          item.content.findIndex((it: any) => cardSelectBlocks.findIndex((m) => m === it?.contentType?.[1]) >= 0) >= 0
        );
      })
    : -1;

  const cardSelectIndex =
    firstCardSelectionRequiredBlock >= 0 &&
    ((cards && cards.length > 0) || (creditAccounts && creditAccounts.length > 0))
      ? firstCardSelectionRequiredBlock
      : 0;

  return filteredItems?.map((item, i) => {
    const types = item?.contentType;

    if (types && types.length > 1 && types[0] === ContentType.Block) {
      const blockType = types[1];
      const nextBlockContentType = filteredItems[i + 1]?.contentType;
      const previousBlockContentType = filteredItems[i - 1]?.contentType;

      const nextBlockType = nextBlockContentType?.[1] || null;
      const previousBlockType = previousBlockContentType?.[1] || null;

      let Comp =
        blockCache[`./Blocks/${blockType}`] ||
        (modal && item.isAccordionType && blockCache[`./Blocks/Accordions/${blockType}`]);

      if (!Comp) {
        const importPath = item.isAccordionType && modal ? `Accordions/${blockType}` : blockType;

        Comp = lazy(() => {
          return import(`./Blocks/${importPath}`).then((module) => ({
            default: module[blockType],
          }));
        });

        blockCache[`./Blocks/${importPath}`] = Comp;
      }

      const showCardSelectionDropdown = (cardSelectIndex === i || cardSelectIndex > filteredItems.length - 1) && !modal;

      let noBorder = nextBlockType === ContentType.DividerBlock || nextBlockType === ContentType.BasicInfoBlock;

      const noBottomPadding = nextBlockType === ContentType.DividerBlock;
      const noTopPadding = previousBlockType === ContentType.DividerBlock;

      if (nextBlockType === ContentType.BasicTextBlock) {
        const textBlock = item as IGenericContentLink<IBasicTextBlock>;
        if (textBlock.bgWhite) {
          noBorder = true;
        }
      }

      return (
        <React.Fragment key={item?.contentLink?.guidValue}>
          {cardSelectPage && showCardSelectionDropdown && (
            <CardSelectionDropdown key={`${item?.contentLink?.guidValue}cardselectdropdown`} />
          )}
          <Comp
            modal={modal}
            data={item}
            nextBlockType={nextBlockType}
            noBorder={noBorder}
            noBottomPadding={noBottomPadding}
            noTopPadding={noTopPadding}
          />
        </React.Fragment>
      );
    }
    return null;
  });
});

export const ContentArea = observer(({ modal, page }: IContentAreaProps) => {
  const { contentStore, customerStore, uiStore } = useStores();
  const { currentPage } = contentStore;
  const contentAreaItems = page ? page.content : contentStore.currentPage?.content;
  const modalContentAreaItems = modal && uiStore.modal?.content?.length ? uiStore.modal.content : undefined;
  const pageType = currentPage?.contentType?.[1];
  const canActOn = customerStore.customerCanActOn(pageType);
  const [emptyStateContent, setEmptyStateContent] = useState<IContentAreaItem[] | undefined>();

  useEffect(() => {
    const getEmptyStateContent = async () => {
      if (!canActOn && contentStore.currentPage) {
        const content = await contentStore.getEmptyStateContent(pageType);
        if (content) setEmptyStateContent(content);
      }
    };
    getEmptyStateContent();
  }, [currentPage]);

  if (!canActOn && (!emptyStateContent || emptyStateContent.length === 0)) return null;
  if (contentAreaItems?.length === 0) return null;
  if (modal && modalContentAreaItems?.length === 0) return null;
  return (
    <Suspense fallback={null}>
      {!!contentAreaItems && !modal && (
        <ContentAreaItems
          items={!canActOn && emptyStateContent ? emptyStateContent : contentAreaItems}
          modal={!!modal}
        />
      )}
      {!!modal && !!modalContentAreaItems && <ContentAreaItems items={modalContentAreaItems} modal={!!modal} />}
    </Suspense>
  );
});
