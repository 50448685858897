import { observer } from "mobx-react";
import React from "react";
import { IGenericContentLink } from "netbank-shared/src/libs/models/Content/ContentLink";
import { IImage } from "netbank-shared/src/libs/models/Content/Image";
import { HtmlContent } from "~views/shared/HtmlContent/HtmlContent";

interface IUspBlockProps {
  icon: IGenericContentLink<IImage>;
  content: string;
}

export const UspBlock = observer(({ icon, content }: IUspBlockProps) => {
  return (
    <div>
      <img src={icon.url} alt={icon.altText || icon.name} />
      <HtmlContent html={content} />
    </div>
  );
});
