import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import closingOfDepositAccountsStyles from "./ClosingOfDepositAccounts.scss";
import styles from "../Page.scss";

import rootStyles from "~views/pages/Root.scss";
import { useStores, useTracking } from "netbank-shared/src/hooks";
import { tx } from "netbank-shared/src/libs/i18n";
import { ListItem } from "netbank-shared/src/libs/models/Content/ListItem";
import { ITransferAccountFields } from "netbank-shared/src/libs/models/Transfer/Transfer";
import { HtmlContent } from "~views/shared/HtmlContent/HtmlContent";
import { ISummaryRow, Summary } from "~views/shared/Summary/Summary";
import { toLocaleString } from "netbank-shared/src/libs/utils";
import { Button, InfoText } from "~views/shared";
import { TrackingAction, TrackingCategory } from "netbank-shared/src/libs/models/Tracking";
import { getQueryParam } from "~utils/misc";
import { BankAccountControlFlowState, Colors } from "netbank-shared/src/libs/models/Content/Enums";
import { PendingNewTransfer } from "~views/shared/ContentArea/Blocks/Accordions/Transfer/PendingNewTransfer";
import { TransferAccountDropdown } from "~views/shared/ContentArea/Blocks/Accordions/Transfer/TransferAccountDropdown";
import { SavedAccountForm } from "~views/shared/ContentArea/Blocks/Accordions/Transfer/SavedAccountForm";
import { IClosingOfDepositAccountPage } from "netbank-shared/src/libs/models/Content/Page";
import { ClosingDepositSkeleton } from "../Skeletons/ClosingDepositSkeleton";

export interface IClosingOfDepositAccountPageProps {
  location: Location;
}

export const ClosingOfDepositAccountPage = observer(({ location }: IClosingOfDepositAccountPageProps) => {
  const accountNumber = getQueryParam(location.search, "accountNumber");

  const [canceling, setCanceling] = useState(false);
  const [createToAccount, setCreateToAccount] = useState(false);
  const [noToAccount, setNoToAccount] = useState(false);
  const [loading, setLoading] = useState(true);
  const { transferStore, contentStore, uiStore, depositStore, customerPersistentStore } = useStores();
  const dataLayer = useTracking((window as any).dataLayer);

  const { currency, locale } = uiStore;

  depositStore.currentAccountNumber = accountNumber;

  const page = contentStore.currentPage as IClosingOfDepositAccountPage;

  const { toAccount, setToAccount } = transferStore;

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      await transferStore.validateAccountRedemption();

      if (!transferStore.savedAccounts) {
        await transferStore.getSavedAccounts();
      }

      transferStore.setFromAccount(accountNumber);
      setLoading(false);
    };
    fetchData();

    return () => {
      transferStore.resetTransaction();
    };
  }, []);

  const createSavedAccount = async () => {
    const created = await transferStore.createSavedAccount(true);
    if (created) {
      setCreateToAccount(false);
      setNoToAccount(false);
    }
  };

  const cancelAction = async () => {
    setCanceling(true);
    if (transferStore.accountRedemptionStatusCode === 200) {
      await transferStore.updateEngagements();

      const currentAccountExsists =
        depositStore.depositAccounts?.findIndex((acc) => depositStore.currentAccountNumber === acc.accountNumber) > -1;
      if (!currentAccountExsists) {
        transferStore.resetAccountRedemption();
        if (depositStore.depositAccounts && depositStore.depositAccounts.length > 1) {
          navigate(`/${tx("routing.lang")}/${tx("routing.deposit")}`);
        } else {
          navigate(`/${tx("routing.lang")}`);
        }
      }
    } else {
      transferStore.resetAccountRedemption();
      navigate(`/${tx("routing.lang")}/${tx("routing.deposit")}/${accountNumber}`);
    }
    setCanceling(false);
  };

  const applyAction = async () => {
    if (!transferStore.toAccount) {
      setNoToAccount(true);
      return;
    }
    await transferStore.createAccountRedemption(customerPersistentStore.loginOnSameDevice);
  };

  const classes = [closingOfDepositAccountsStyles.content];

  const redemptionData = transferStore.accountRedemptionData;

  const summaryRows: ISummaryRow[] = [
    {
      cols: [
        {
          label: `${page?.balanceLabel || tx("misc.balance")}:`,
          value: toLocaleString(redemptionData?.balance || 0, currency, locale),
          key: uuidv4(),
        },
        {
          label: `${page?.totalTransferAmountLabel || tx("deposit.totalTransferAmount")}:`,
          value: toLocaleString(redemptionData?.payoutNet || 0, currency, locale),
          key: uuidv4(),
        },
      ],
      key: uuidv4(),
    },
    {
      cols: [
        {
          label: `${page?.accruedInterestLabel || tx("deposit.accruedInterest")}:`,
          value: toLocaleString(redemptionData?.interest || 0, currency, locale),
          key: uuidv4(),
        },
        {
          label: `${page?.closingDateLabel || tx("deposit.closingDate")}: `,
          value: redemptionData?.closesAt ? dayjs(redemptionData?.closesAt).format("YYYY-MM-DD") : "",
          key: uuidv4(),
        },
      ],
      key: uuidv4(),
    },
    {
      cols: [
        {
          label: `${page?.taxLabel || tx("deposit.tax")}:`,
          value: toLocaleString(redemptionData?.tax || 0, currency, locale),
          key: uuidv4(),
        },
        ...[
          {
            label: `${page?.feeLabel || tx("deposit.fee")}:`,
            value: toLocaleString(redemptionData?.fee || 0, currency, locale),
            key: uuidv4(),
          },
        ].filter(() => (redemptionData?.fee || 0) > 0),
      ],
      key: uuidv4(),
    },
  ];

  const renderErrorMessage = (statusCode?: number) => {
    const accountRedemptionStatus = page?.accountRedemptionStatuses?.find(
      (status) => statusCode && status.statusCode === statusCode.toString()
    );

    return (
      <div className={styles.wrapper}>
        <section>
          <div className={closingOfDepositAccountsStyles.content}>
            <div style={{ paddingTop: 20, paddingBottom: 20 }}>
              {accountRedemptionStatus ? (
                <InfoText text={accountRedemptionStatus?.text} />
              ) : (
                <InfoText
                  header={tx("deposit.accountRedemptionErrorHeader")}
                  text={tx("deposit.accountRedemptionErrorText")}
                />
              )}
            </div>
            <div className={styles.actions}>
              <Button title={tx("misc.close")} onClick={cancelAction} loading={canceling} color="red" large centered />
            </div>
          </div>
        </section>
      </div>
    );
  };

  const renderSuccess = () => {
    return (
      <div className={styles.wrapper}>
        <section>
          <div className={classes.join(" ")}>
            {page?.closingAccountSuccessTopContent && (
              <HtmlContent
                className={closingOfDepositAccountsStyles.topContent}
                html={page.closingAccountSuccessTopContent}
              />
            )}
            {toAccount && (
              <div>
                <span className={rootStyles.label}>
                  {page?.receiverSuccessLabel || tx("deposit.receiverSuccessLabel")}
                </span>

                <div className={closingOfDepositAccountsStyles.receiverSuccess}>
                  <p>
                    {`${tx("deposit.receiverAccount")}:`}
                    <span>{`${toAccount.clearingNumber}-${toAccount.accountNumber}`}</span>
                  </p>
                </div>
              </div>
            )}
            <div className={closingOfDepositAccountsStyles.accountInformationSummary}>
              <Summary
                label={page?.accountInformationLabel || tx("deposit.accountInformation")}
                rows={summaryRows}
                color={Colors.LightSuccess}
              />
            </div>
          </div>
        </section>
      </div>
    );
  };

  if (loading) {
    return (
      <div className={styles.wrapper}>
        <section>
          <div className={closingOfDepositAccountsStyles.content}>
            <ClosingDepositSkeleton />
          </div>
        </section>
      </div>
    );
  }

  // Show validation error if statusCode !== 200
  if (redemptionData?.statusCode !== 200) {
    const statusCode = redemptionData?.statusCode;
    return renderErrorMessage(statusCode);
  }

  // BankID signing step
  if (transferStore.accountRedemptionStatusCode === 201) {
    return (
      <div className={styles.wrapper}>
        <section>
          <div className={closingOfDepositAccountsStyles.content}>
            <PendingNewTransfer header={page?.pendingClosingAccountHeader} text={page?.pendingClosingAccountText} />
          </div>
        </section>
      </div>
    );
  }

  // Account redemption polling done. Show success or error content
  const showAccountRedemptionResult =
    transferStore.accountRedemptionStatusCode !== undefined &&
    transferStore.accountRedemptionStatusCode !== 201 &&
    transferStore.accountRedemptionStatusCode !== 202;

  if (showAccountRedemptionResult) {
    if (transferStore.accountRedemptionStatusCode === 200) {
      dataLayer.pushInteraction(TrackingCategory.ProductDeposit, TrackingAction.CloseAccountOK);
      return renderSuccess();
    }

    dataLayer.pushInteraction(TrackingCategory.ProductDeposit, TrackingAction.CloseAccountKO);
    return renderErrorMessage(transferStore.accountRedemptionStatusCode || 400);
  }

  return (
    <div className={styles.wrapper}>
      <section>
        {page.pageTitle && <h1>{page.pageTitle}</h1>}
        {page?.closingAccountTopContent && (
          <HtmlContent className={closingOfDepositAccountsStyles.topContent} html={page.closingAccountTopContent} />
        )}
        <div className={closingOfDepositAccountsStyles.content}>
          <div className={closingOfDepositAccountsStyles.transferAccountDropdown}>
            <TransferAccountDropdown
              infoPopover={{
                content: page?.transferAccountInformation,
                popoverTitle: page?.chooseTransferAccountLabel || tx("deposit.chooseTransferAccount"),
              }}
              label={page?.chooseTransferAccountLabel || tx("deposit.chooseTransferAccount")}
              value={
                toAccount
                  ? `${toAccount.displayName} | ${toAccount.clearingNumber}-${toAccount.accountNumber}`
                  : page?.chooseReceiverOrCreateLabel || tx("deposit.chooseReceiverOrCreate")
              }
              onChange={(item: ListItem) => {
                setNoToAccount(false);
                setToAccount(item.value);
              }}
              createToAccount={createToAccount}
              setCreateToAccount={(value: boolean) => {
                if (value) {
                  uiStore.setBankAccountControlFlowState(BankAccountControlFlowState.Started);
                  return;
                }
                if (value) {
                  setToAccount(undefined);
                }
                setCreateToAccount(value);
              }}
              error={(noToAccount && !createToAccount && tx("transfer.noToAccountError")) || undefined}
            />
            {createToAccount && (
              <SavedAccountForm
                handleSavedAccount={createSavedAccount}
                account={transferStore.newAccount}
                setAccount={(account: ITransferAccountFields) => {
                  setNoToAccount(false);
                  transferStore.setNewAccount(account);
                }}
                loading={transferStore.creatingSavedAccount}
                clearingNumberLabel={page?.clearingNumberLabel || tx("transfer.clearingNumber")}
                accountNumberLabel={page?.accountNumberLabel || tx("transfer.accountNumber")}
                clearingNumberInformation={page?.clearingNumberInformation}
                clearingStartsWithEightThenFiveDigitsError={page?.clearingStartsWithEightThenFiveDigitsError}
                accountNumberInformation={page?.accountNumberInformation}
                saveLabel={page?.saveAndUseLabel}
                error={
                  (transferStore.duplicateSavedAccountError &&
                    contentStore.currentPage?.duplicateSavedAccountError &&
                    tx("transfer.duplicateAccount")) ||
                  (noToAccount &&
                    createToAccount &&
                    transferStore.newAccount.clearingNumber &&
                    transferStore.newAccount.accountNumber &&
                    transferStore.newAccount.displayName &&
                    tx("transfer.saveToAccountError")) ||
                  undefined
                }
              />
            )}
          </div>
          <div className={closingOfDepositAccountsStyles.accountInformationSummary}>
            <Summary
              label={page?.accountInformationLabel || tx("deposit.accountInformation")}
              rows={summaryRows}
              color={Colors.LightSuccess}
            />
          </div>
          <div className={closingOfDepositAccountsStyles.actions}>
            <Button
              title={tx("misc.cancel")}
              onClick={cancelAction}
              loading={canceling}
              bordered
              centered
              large
              color="black"
              borderColor="black"
            />
            <Button
              title={page?.closeAccountButtonLabel || tx("misc.terminate")}
              onClick={applyAction}
              color="red"
              large
              centered
              loading={transferStore.loadingAccountRedemption}
              trackingCategory={TrackingCategory.ProductDeposit}
              trackingAction={TrackingAction.CloseAccountCTA}
            />
          </div>
        </div>
      </section>
    </div>
  );
});
