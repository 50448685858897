export const mockAttachments = [
  {
    id: "1",
    mimeType: "application/pdf",
    fileName: "attachment1.pdf",
    attachment: "",
  },
  {
    id: "2",
    mimeType: "application/pdf",
    fileName: "attachment2.pdf",
    attachment: "",
  },
  {
    id: "10",
    mimeType: "application/pdf",
    fileName: "attachment10.pdf",
    attachment: "",
  },
  {
    id: "11",
    mimeType: "application/pdf",
    fileName: "attachment11.pdf",
    attachment: "",
  },
  {
    id: "12",
    mimeType: "application/pdf",
    fileName: "attachment12.pdf",
    attachment: "",
  },
  {
    id: "13",
    mimeType: "application/pdf",
    fileName: "attachment13.pdf",
    attachment: "",
  },
  {
    id: "20",
    mimeType: "application/pdf",
    fileName: "attachment20.pdf",
    attachment: "",
  },
  {
    id: "30",
    mimeType: "application/pdf",
    fileName: "attachment30.pdf",
    attachment: "",
  },
  {
    id: "31",
    mimeType: "application/pdf",
    fileName: "attachment31.pdf",
    attachment: "",
  },
];
