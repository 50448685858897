import React from "react";
import { observer } from "mobx-react";
import styles from "./BankAccountControlPopup.scss";
import { useStores } from "netbank-shared/src/hooks";
import close from "~assets/close.svg";
import { BankAccountControlFlowState } from "netbank-shared/src/libs/models/Content/Enums";
import { BankAccountControlFlow } from "~views/flows/BankAccountControlFlow/BankAccountControlFlow";

export const BankAccountControlPopup = observer(() => {
  const { uiStore } = useStores();

  const handleClose = () => {
    uiStore.setBankAccountControlFlowState(BankAccountControlFlowState.Inactive);
  };

  return (
    <div className={styles.overlay}>
      <div className={styles.wrapper}>
        <div className={styles.closeContainer}>
          <div className={styles.close} onClick={handleClose}>
            <img src={close} alt="checkmark" />
          </div>
        </div>
        <BankAccountControlFlow />
      </div>
    </div>
  );
});
