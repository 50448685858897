import React from "react";
import { CaptionProps, useNavigation } from "react-day-picker";
import styles from "./Navbar.scss";
import chevronRight from "~assets/chevron-right.svg";
import { capitalize } from "netbank-shared/src/libs/utils";
import { format } from "date-fns";
import { sv } from "date-fns/locale";

type INavbarProps = CaptionProps & {
  disabled?: boolean;
};

export const Navbar = ({ displayMonth, disabled }: INavbarProps) => {
  const { goToMonth, nextMonth, previousMonth } = useNavigation();
  const locale = { locale: sv }
  const prev =
    previousMonth && capitalize(format(previousMonth, "LLLL", locale));
  const next = nextMonth && capitalize(format(nextMonth, "LLLL", locale));
  const currentMonth = capitalize(format(displayMonth, "LLLL", locale));
  const currentYear = displayMonth.getFullYear();
  return (
    <div className={styles.dateNavbar}>
      {!disabled && prev && (
        <div className={styles.prev} onClick={() => previousMonth && goToMonth(previousMonth)}>
          <img src={chevronRight} alt="prev" />
          <span>{prev.slice(0, 3)}</span>
        </div>
      )}
      <div className={styles.current}>
        {currentMonth} {currentYear}
      </div>
      {!disabled && next && (
        <div className={styles.next} onClick={() => nextMonth && goToMonth(nextMonth)}>
          <span>{next.slice(0, 3)}</span>
          <img src={chevronRight} alt="next" />
        </div>
      )}
    </div>
  );
};