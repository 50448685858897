import { IAccount } from "libs/models/Engagements/Account";

export const getAccountDisplayName = (account: IAccount) => {
  if (account?.accountPersonalName != null) {
    if (account.accountPersonalName.length > 20) {
      return account.accountPersonalName.slice(0, 20).concat("...");
    }
    return account.accountPersonalName;
  }
  return account?.name || account?.nameIB;
};
