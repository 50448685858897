import React from "react";
import { observer } from "mobx-react";
import styles from "../../flows/LoanTopUpFlow/LoanTopUpFlow.scss";
import rootStyles from "../Root.scss";
import { SkeletonWrapper } from "~views/shared";

export const LoanTopUpSkeleton = observer(() => {
  return (
    <section className={rootStyles.topUpFlow}>
      <div className={styles.wrapper}>
        <div className={styles.stepHeader}>
          <div className={styles.stepElement}>
            <div className={styles.stepNumber} />
            <SkeletonWrapper className={styles.stepName} width={65} />
          </div>
          <div className={styles.stepElement}>
            <div className={styles.stepNumber} />
            <SkeletonWrapper className={styles.stepName} width={65} />
          </div>
          <div className={styles.stepElement}>
            <div className={styles.stepNumber} />
            <SkeletonWrapper className={styles.stepName} width={65} />
          </div>
          <div className={styles.stepElement}>
            <div className={styles.stepNumber} />
            <SkeletonWrapper className={styles.stepName} width={65} />
          </div>
        </div>
        <SkeletonWrapper width={100} />
        <SkeletonWrapper height={100} />
      </div>
    </section>
  );
});
