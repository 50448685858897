import React, { useState } from "react";
import { observer } from "mobx-react";
import styles from "./Applicant.scss";
import { ITopUpApplicantForm } from "netbank-shared/src/libs/models/Offer/Offer";
import { tx } from "netbank-shared/src/libs/i18n";
import { useStores } from "netbank-shared/src/hooks";
import { Dropdown, Input, RadioButtons } from "..";
import { toLocaleString } from "netbank-shared/src/libs/utils";
import { ApplicantEmploymentType, SourceOfFunds } from "netbank-shared/src/libs/models/Content/Enums";

export interface IApplicantEmploymentLabelsProps {
  monthlyIncomeLabel: string;
  monthlyIncomeInformation: string;
  employmentStatusLabel: string;
  employmentStatusInformation: string;
  employerLabel: string;
  employerInformation: string;
  employedFromLabel: string;
  employedFromInformation: string;
  unemployedFromLabel: string;
  unemployedFromInformation: string;
  employedToLabel: string;
  employedToInformation: string;
  numberOfChildrenLabel: string;
  numberOfChildrenInformation: string;
  loansMonthlyCostLabel: string;
  loansMonthlyCostInformation: string;
  sourceOfFundsSalaryLabel: string;
  sourceOfFundsLabel: string;
  customSourceOfFundsPlaceholder: string;
}

interface IApplicantEmploymentProps {
  data: IApplicantEmploymentLabelsProps;
  applicant: ITopUpApplicantForm;
  setApplicant: (applicant: ITopUpApplicantForm) => void;
  showValidationErrors: boolean;
}

export const ApplicantEmployment = observer(
  ({ data, applicant, setApplicant, showValidationErrors }: IApplicantEmploymentProps) => {
    const { uiStore, offerStore } = useStores();
    const { currencySymbol, locale } = uiStore;

    const [amountBlured, setAmountBlured] = useState(true);

    const showEmployer = offerStore.showEmployer(applicant);

    const currentSourceOfFunds = offerStore.sourceOfFundsList.find((e) => e.value === applicant.sourceOfFunds.value);

    const setRadioButtonValue = (value: boolean | undefined) => {
      if (value === undefined) return "";
      return value ? "yes" : "no";
    };

    return (
      <div className={styles.wrapper}>
        <Input
          wrapperStyle={styles.inputWrapper}
          label={data.monthlyIncomeLabel || tx("topUp.monthlyIncomeBeforeTaxLabel")}
          infoPopover={{
            popoverTitle: data.monthlyIncomeLabel || tx("topUp.monthlyIncomeBeforeTaxLabel"),
            content: data.monthlyIncomeInformation,
          }}
          onBlur={() => {
            if (!applicant.monthlyIncome)
              setApplicant({
                ...applicant,
                monthlyIncome: { value: 0, isValid: false },
              });
            setAmountBlured(true);
          }}
          onFocus={() => {
            setAmountBlured(false);
          }}
          valueSuffix={` ${currencySymbol}`}
          value={
            amountBlured
              ? toLocaleString(applicant.monthlyIncome.value || 0, undefined, locale, 0)
              : (applicant.monthlyIncome.value || "").toString()
          }
          onChange={(e) => {
            const newAmount = parseInt(e.target.value.replace(/[^\d.,]/g, ""));
            setApplicant({
              ...applicant,
              monthlyIncome: { value: newAmount, isValid: newAmount > 0 },
            });
          }}
          error={showValidationErrors && !applicant.monthlyIncome.isValid ? tx("error.required") : undefined}
        />
        <div className={styles.inputWrapper}>
          <Dropdown
            label={data.employmentStatusLabel || tx("topUp.employmentStatusLabel")}
            infoPopover={{
              popoverTitle: data.employmentStatusLabel || tx("topUp.employmentStatusLabel"),
              content: data.employmentStatusInformation,
            }}
            list={offerStore.employmentTypeList}
            onChange={(item) =>
              setApplicant({
                ...applicant,
                employmentType: { value: item.value, isValid: true },
              })
            }
            value={applicant.employmentType.value || `${tx("misc.chooseEmploymentStatus")}...`}
            innerLabel={tx("misc.chooseEmploymentStatus")}
            error={showValidationErrors && !applicant.employmentType.isValid ? tx("error.required") : undefined}
          />
        </div>
        {applicant.employmentType.value !== ApplicantEmploymentType.None && showEmployer && (
          <Input
            wrapperStyle={styles.inputWrapper}
            label={data.employerLabel || tx("topUp.employerLabel")}
            infoPopover={{
              popoverTitle: data.employerLabel || tx("topUp.employerLabel"),
              content: data.employerInformation,
            }}
            value={applicant.employer.value}
            onChange={(e) => {
              setApplicant({
                ...applicant,
                employer: { value: e.target.value, isValid: !!e.target.value },
              });
            }}
            error={showValidationErrors && !applicant.employer.isValid ? tx("error.required") : undefined}
          />
        )}
        <div className={styles.inputWrapper}>
          <RadioButtons
            label={data.sourceOfFundsSalaryLabel || tx("topUp.sourceOfFundsSalaryLabel")}
            list={[
              {
                label: tx("misc.yes"),
                value: "yes",
                index: 0,
              },
              {
                label: tx("misc.no"),
                value: "no",
                index: 1,
              },
            ]}
            value={setRadioButtonValue(applicant.sourceOfFundsIsSalary.value)}
            onChange={(item) => {
              const value = item.value === "yes";
              setApplicant({
                ...applicant,
                sourceOfFundsIsSalary: { value, isValid: true },
              });
              if (value) {
                setApplicant({
                  ...applicant,
                  sourceOfFundsIsSalary: {
                    value: offerStore.sourceOfFundsList.find((e) => e.enumValue === SourceOfFunds.Salary)?.value || "",
                    isValid: true,
                  },
                });
              }
            }}
            error={
              offerStore.showTopUpValidationErrors && !applicant.sourceOfFundsIsSalary.isValid
                ? tx("error.required")
                : undefined
            }
          />
        </div>
        {applicant.sourceOfFundsIsSalary.value === false && (
          <div className={styles.inputWrapper}>
            <Dropdown
              label={data.sourceOfFundsLabel || tx("topUp.sourceOfFundsLabel")}
              list={offerStore.sourceOfFundsList}
              onChange={(item) => {
                setApplicant({
                  ...applicant,
                  sourceOfFunds: { value: item.value, isValid: true },
                });
              }}
              value={applicant.sourceOfFunds.value || `${tx("misc.select")}...`}
              innerLabel={tx("misc.select")}
              error={
                offerStore.showTopUpValidationErrors && !applicant.sourceOfFunds.isValid
                  ? tx("error.required")
                  : undefined
              }
            />
            {currentSourceOfFunds?.enumValue === SourceOfFunds.Other && (
              <Input
                className={styles.customInput}
                value={applicant.customSourceOfFunds.value}
                placeholder={data.customSourceOfFundsPlaceholder || tx("topUp.customSourceOfFundsPlaceholder")}
                onChange={(e) => {
                  setApplicant({
                    ...applicant,
                    customSourceOfFunds: {
                      value: e.target.value,
                      isValid: !!e.target.value,
                    },
                  });
                }}
                error={
                  offerStore.showTopUpValidationErrors && !applicant.customSourceOfFunds.isValid
                    ? tx("error.required")
                    : undefined
                }
              />
            )}
          </div>
        )}
      </div>
    );
  }
);
