import { ApiResponse, ApisauceInstance, create } from "apisauce";
import { AxiosAdapter, AxiosInstance } from "axios";
import MockAdapter from "axios-mock-adapter";
import dayjs from "dayjs";
import { IConfig } from "../models/Config";
import { Environment, AccountRedemptionStatus } from "../models/Content/Enums";
import {
  IAccountRedemptionBase,
  IAccountRedemptionResponse,
  IAccountRedemptionStatusResponse,
  IBankAccountControlResponse,
  ICreateTransferAccountResponse,
  IMakeTransferResponse,
  ITransferAccount,
  ITransferAccountsForCaseResponse,
  ITransferAccountsResponse,
  ITransferStatusResponse,
} from "../models/Transfer/Transfer";
import { Store } from "../../stores";
import {
  mockIncorrectAccountTransfer,
  mockIncorrectAccountTransferStatus,
  mockKreditz,
  mockTransfer,
  mockTransferAccounts,
  mockTransferStatus,
  mockVerifiedAccount,
} from "./mock/transfer/index";
import { ICollectResponse, IGetQrCodeResult } from "libs/models/Auth";

export class TransferApi {
  rootStore: Store;

  client?: ApisauceInstance;

  mock?: MockAdapter;

  mockAdapter?: AxiosAdapter;

  actualAdapter?: AxiosAdapter;

  constructor(rootStore: Store) {
    this.rootStore = rootStore;
  }

  init = (config: IConfig | undefined) => {
    if (config) {
      const { platformStore } = this.rootStore;
      const platformSpecificHeaders = platformStore?.getPlatformSpecificHeaders();

      const headers: { [key: string]: string | number } = {
        "Content-Type": "application/json",
        "api-version": "1.0",
        "Ocp-Apim-Subscription-Key": config.TRANSFER_OCP_APIM_SUBSCRIPTION_KEY,
      };
      if (platformSpecificHeaders) Object.assign(headers, platformSpecificHeaders);

      this.client = create({
        baseURL: config.TRANSFER_URL,
        headers,
        timeout: 60000,
      });
      if (config.OCTOPUS_ENV !== Environment.Production) this.setupMockAdapter();
    }
  };

  getHeaders = (headers?: any) => ({
    headers: {
      ...headers,
      Authorization: `Bearer ${this.rootStore.securePersistentStore.accessToken}`,
    },
  });

  makeTransfer = async (
    fromAccountNumber: string,
    toAccountNumber: string,
    amount: number,
    loggedUsingSameDevice: boolean,
    useBankId6Signing: boolean,
    toClearingNumber?: string,
  ) => {

    return this.client?.post<IMakeTransferResponse>(
      "/",
      {
        fromAccountNumber,
        toClearingNumber,
        toAccountNumber,
        isDesktopSign: this.isDesktopSign(loggedUsingSameDevice),
        useBankId6Signing: useBankId6Signing,
        amount,
        v: "1.0",
      },
      this.getHeaders()
    );
  };

  getSignQrCode = async (bankIdStartState: string) =>
    this.client?.get<IGetQrCodeResult>("transfer-signing/qr-code", {
      bankIdStartState: bankIdStartState,
      v: "1.0"
    }, this.getHeaders())

  pollSigning = async (bankIdStartState: string) =>
    this.client?.get<ICollectResponse>("transfer-signing/poll-signing", {
      bankIdStartState: bankIdStartState,
      v: "1.0"
    }, this.getHeaders())

  getTransferStatus = async (transactionId: number) =>
    this.client?.get<ITransferStatusResponse>(`/${transactionId}`, { transactionId, v: "1.0" }, this.getHeaders());

  getTransferAccounts = async () =>
    this.client?.get<ITransferAccountsResponse>("transfer-accounts", { Channel: "IB", v: "1.0" }, this.getHeaders());

  getBankAccountControlCase = async () =>
    this.client?.get<IBankAccountControlResponse>("bank-account-control", { v: "1.0" }, this.getHeaders());

  getTransferAccountForCase = async (caseId: string) =>
    this.client?.get<ITransferAccountsForCaseResponse>(
      "transfer-accounts/GetTransferAccount",
      { Channel: "IB", v: "1.0", caseId },
      this.getHeaders()
    );

  createTransferAccount = async (clearingNumber: string, accountNumber: string, displayName: string) => {
    return this.client?.post<ICreateTransferAccountResponse>(
      "transfer-accounts",
      {
        clearingNumber,
        accountNumber,
        displayName,
        creationChannel: "IB",
        v: "1.0",
        strictValidation: this.rootStore.contentStore.strictAccountValidationEnabled
      },
      this.getHeaders()
    );
  };

  updateTransferAccount = async (clearingNumber: string, accountNumber: string, displayName: string) => {
    return this.client?.put<ITransferAccount>(
      "transfer-accounts",
      {
        clearingNumber,
        accountNumber,
        displayName,
        creationChannel: "IB",
        v: "1.0",
      },
      this.getHeaders()
    );
  };

  deleteTransferAccount = async (clearingNumber: string, accountNumber: string, displayName: string) =>
    this.client?.delete<ITransferAccountsResponse>(
      "transfer-accounts",
      { clearingNumber, accountNumber, displayName, v: "1.0" },
      this.getHeaders()
    );

  createAccountRedemption = async (
    execute: boolean,
    accountNumber: string,
    payoutAccountNumber: string,
    loggedUsingSameDevice: boolean,
    useBankId6Sign: boolean,
    payoutClearingNumber?: string,
  ) => {
    return this.client?.post<IAccountRedemptionResponse>(
      "/AccountRedemptions",
      {
        execute,
        bankId6Sign: useBankId6Sign,
        accountNumber,
        payoutAccountNumber,
        payoutClearingNumber,
        isDesktopSign: this.isDesktopSign(loggedUsingSameDevice),
        v: "1.0",
      },
      this.getHeaders()
    );
  };

  getAccountRedemptionStatus = async (transactionId: string) =>
    this.client?.get<IAccountRedemptionStatusResponse>(
      `/AccountRedemptions/${transactionId}`,
      { transactionId, v: "1.0" },
      this.getHeaders()
    );

  setupMockAdapter = () => {
    this.mock = new MockAdapter(this.client?.axiosInstance as AxiosInstance, {
      delayResponse: process.env.NODE_ENV === "test" ? 0 : 2000,
    });

    this.mockAdapter = this.client?.axiosInstance.defaults.adapter;

    // Defaults to real apis
    this.mock.restore();

    this.actualAdapter = this.client?.axiosInstance.defaults.adapter;

    this.mock.onGet("transfer-accounts").reply(200, mockTransferAccounts);
    this.mock.onGet("transfer-accounts/GetTransferAccount").reply(200, mockVerifiedAccount);
    this.mock.onPost("transfer-accounts").reply((config) => {
      const data = JSON.parse(config.data);
      return [
        200,
        {
          clearingNumber: data.clearingNumber,
          accountNumber: data.accountNumber,
          displayName: data.displayName,
          bank: "Testbank",
          isAccountVerified: true,
        },
      ];
    });
    this.mock.onGet("bank-account-control").reply(200, mockKreditz);
    this.mock.onDelete("transfer-accounts").reply(204);
    this.mock.onPost("/").reply((config) => {
      const data = JSON.parse(config.data);
      const savedToAccount = mockTransferAccounts.accounts.find(
        (a) => a.clearingNumber === data.toClearingNumber && a.accountNumber === data.toAccountNumber
      );
      const isIncorrectAccount = savedToAccount?.displayName === "Felaktigt konto";
      return [200, isIncorrectAccount ? mockIncorrectAccountTransfer : mockTransfer];
    });
    this.mock.onGet(`/${mockTransfer.transactionId}`).reply(200, mockTransferStatus);

    this.mock.onGet(`/${mockIncorrectAccountTransfer.transactionId}`).reply(200, mockIncorrectAccountTransferStatus);

    const redemptionBaseData: IAccountRedemptionBase = {
      balance: 1000,
      tax: 10,
      interest: 4.35,
      closesAt: dayjs().add(1, "month").toString(),
      payoutNet: 1000,
      fee: 1000,
    };

    this.mock.onPost("/AccountRedemptions").reply((config) => {
      let result: IAccountRedemptionResponse;
      const data = JSON.parse(config.data);
      if (data?.execute) {
        const savedToAccount = mockTransferAccounts.accounts.find(
          (a) => a.clearingNumber === data.payoutClearingNumber && a.accountNumber === data.payoutAccountNumber
        );

        const isIncorrectAccount = savedToAccount?.displayName === "Felaktigt konto";

        result = {
          transactionId: isIncorrectAccount
            ? "4d29f669-ee06-46e6-abb6-0f41755c7932"
            : "59286466-9b51-4035-9fee-bcdf09bd0a57",
        };
      } else {
        result = {
          ...redemptionBaseData,
          status: AccountRedemptionStatus.Redeemable,
          statusCode: 200,
        };
      }

      return [200, result];
    });

    this.mock.onGet(`/AccountRedemptions/59286466-9b51-4035-9fee-bcdf09bd0a57`).reply(() => {
      const result: IAccountRedemptionStatusResponse = {
        ...redemptionBaseData,
        transactionStatus: AccountRedemptionStatus.RedemptionSuccessful,
        transactionStatusCode: 200,
        foreignCreditAccount: "",
      };

      return [200, result];
    });

    this.mock.onGet(`/AccountRedemptions/4d29f669-ee06-46e6-abb6-0f41755c7932`).reply(() => {
      const result: IAccountRedemptionStatusResponse = {
        ...redemptionBaseData,
        transactionStatus: AccountRedemptionStatus.RedemptionFailed,
        transactionStatusCode: 4005,
        foreignCreditAccount: "",
      };

      return [200, result];
    });
  };

  isDesktopSign = (loggedUsingSameDevice: boolean) => {
    if (this.rootStore.platformStore?.isApplication()) {
      return false;
    }

    let isCurrentDeviceMobile = false;

    if (this.rootStore.platformStore?.isMobile) {
      isCurrentDeviceMobile = this.rootStore.platformStore?.isMobile();
    }

    return loggedUsingSameDevice && !isCurrentDeviceMobile;
  }


  setMock = (isMock: boolean) => {
    if (this.client) {
      if (isMock) {
        this.client.axiosInstance.defaults.adapter = this.mockAdapter;
      } else {
        this.client.axiosInstance.defaults.adapter = this.actualAdapter;
      }
    }
  };
}
