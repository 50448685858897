import { observer } from "mobx-react";
import { useLogoAnimation, useStores } from "netbank-shared/src/hooks";
import { tx } from "netbank-shared/src/libs/i18n";
import { IEmptyState } from "netbank-shared/src/libs/models/Content/EmptyState";
import { AccountStatus } from "netbank-shared/src/libs/models/Engagements/Account";
import { IProductRouteParams } from "netbank-shared/src/libs/models/Routing";
import { toLocaleString, spaceEveryFourthLetter } from "netbank-shared/src/libs/utils";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { Button, EmptyState } from "~views/shared";
import { BasicInfoBlock } from "~views/shared/ContentArea/Blocks/BasicInfoBlock";
import { ContentArea } from "~views/shared/ContentArea/ContentArea";
import { NotFoundPage } from "../NotFound/NotFound";
import styles from "../Page.scss";
import { withRoot } from "../Root";
import accordionStyle from "~views/shared/ContentArea/Blocks/AccordionBlock.scss";
import { ProductPageSkeleton } from "../Skeletons/ProductPageSkeleton";
import { ModalButton } from "~views/shared/ModalButton/ModalButton";
import { IModal } from "netbank-shared/src/libs/models/Content/Modal";
import { IContentAreaItem } from "netbank-shared/src/libs/models/Content/ContentAreaItem";
import editIcon from "~assets/pencil-outline.svg";
import { getAccountDisplayName } from "netbank-shared/src/libs/utils/accountName";

export interface CardPageProps {
  location: Location;
}

const CardPage = observer(({ location }: CardPageProps) => {
  const { cardId } = useParams<IProductRouteParams>();
  const { contentStore, uiStore, creditStore, invoiceStore } = useStores();
  const { currency, locale } = uiStore;
  const { blockedCreditAccounts, creditAccounts } = creditStore;
  const cardTemplate = contentStore.currentCardTemplatePage;
  const currentAccount = [...creditAccounts, ...blockedCreditAccounts].find(
    (account) => account.accountNumber === creditStore.currentAccountNumber
  );

  const isBlocked = currentAccount?.cardIsBlocked;
  const isClosing = currentAccount && currentAccount.accountStatus === AccountStatus.AccountToClose;
  const closingContent =
    cardTemplate?.closingOfAccountContent && cardTemplate.closingOfAccountContent.length > 0
      ? cardTemplate.closingOfAccountContent
      : null;

  const accountBlockedState = cardTemplate?.accountBlockedEmptyState?.[0];

  const { currentCard } = creditStore;

  const classes = [styles.wrapper];

  if (contentStore.fetchingPage) {
    classes.push(styles.hidden);
  }

  useEffect(() => {
    const fetchData = async () => {
      creditStore.currentAccountNumber = cardId;
      await contentStore.getCardTemplate();

      if (!invoiceStore.fetchingInvoices) {
        invoiceStore.getAccountInvoices(cardId, true);
      }
    };
    fetchData();

    return () => {
      invoiceStore.resetStore();
    };
  }, [location]);

  useEffect(() => {
    const account = creditAccounts?.find((acc) => acc.accountNumber === cardId);
    if (!currentAccount && account) {
      creditStore.setCurrentAccount(account);
    }
  }, [currentAccount, creditAccounts, cardId, contentStore.cardTemplatePages]);

  useLogoAnimation([uiStore.logoAnimationLoaded, creditStore?.creditAccounts, invoiceStore.invoiceStatements]);

  if (uiStore.loading) return null;

  if (contentStore.fetchingTemplatePage) {
    return <ProductPageSkeleton />;
  }

  if (!currentAccount) {
    return <NotFoundPage />;
  }

  const usedCredit = creditStore.currentAccountIsWay4Account
    ? (currentAccount.creditLimit || 0) - (currentAccount.availableBalance || 0)
    : (currentAccount.bookedBalance || 0) - (currentAccount.authorizedBalance || 0);

  let usedCreditProgress = 0;

  if (creditStore.currentAccountIsWay4Account) {
    usedCreditProgress = (usedCredit / (currentAccount.creditLimit || 0)) * 100;
  } else if (usedCredit < 0) {
    usedCreditProgress = (Math.abs(usedCredit) / (currentAccount.creditLimit || 0)) * 100;
  }

  const cardIsActive = creditStore.currentCard?.isActive;

  let cardNumberText: string | JSX.Element;
  const cardManufactured =
    creditStore.currentCard?.isManufactured === undefined || creditStore.currentCard?.isManufactured;

  if (isBlocked) {
    cardNumberText = tx("misc.blocked");
  } else if (cardIsActive) {
    cardNumberText = spaceEveryFourthLetter(creditStore.currentCard?.displayNumber);
  } else {
    cardNumberText = (
      <ModalButton
        buttonLabel={cardManufactured ? tx("misc.notActive") : tx("card.manufacturingPending")}
        modalLabel={tx("card.activateCard")}
        account={currentAccount}
        labelClassName={styles.link}
        disabledClassName={styles.disabledLink}
        disabled={!cardManufactured}
        modalTitle={contentStore.currentCardTemplatePage?.activateCardModalTitle}
        modalContent={contentStore.currentCardTemplatePage?.activateCardModalContent}
      />
    );
  }

  if (!cardTemplate) return null;

  const renderContent = () => {
    return (
      <section>
        <div className={styles.templateHeader}>
          {!uiStore.isMobile && (
            <div
              className={styles.image}
              style={{
                backgroundImage: `url(${cardTemplate.image?.url})`,
              }}
            />
          )}
          <div className={styles.templateContent}>
            <div className={styles.availableBalance}>
              <div>
                <span className={styles.templateTitle}>{cardTemplate.availableCredit}</span>
                <h3>{toLocaleString(isClosing ? 0 : currentAccount.availableBalance, currency, locale)}</h3>
              </div>
              {uiStore.isMobile && (
                <div
                  className={styles.image}
                  style={{
                    backgroundImage: `url(${cardTemplate.image?.url})`,
                  }}
                />
              )}
            </div>

            <div className={styles.flexBetween}>
              <div>
                <span className={styles.templateTitle}>{cardTemplate.usedCredit}</span>
                <h4>{toLocaleString(usedCredit, currency, locale)}</h4>
              </div>
              <div>
                <span className={styles.templateTitle}>{cardTemplate.creditAmount}</span>
                <h4>{toLocaleString(currentAccount.creditLimit, currency, locale)}</h4>
              </div>
            </div>
            <div className={styles.progressBar}>
              <div
                style={{
                  width: `${usedCreditProgress}%`,
                }}
              />
            </div>
          </div>
        </div>
      </section>
    );
  };

  const renderBlocked = (blockedEmptyState: IEmptyState) => {
    return (
      <section>
        <div className={[accordionStyle.wrapper, accordionStyle.open].join(" ")}>
          <div className={accordionStyle.header}>{blockedEmptyState.header}</div>
          <div className={accordionStyle.content} style={{ maxHeight: 10000 }}>
            <div className={accordionStyle.verticalPadding}>
              <EmptyState data={blockedEmptyState} />
            </div>
          </div>
        </div>
      </section>
    );
  };

  const renderModal = (modalContent?: IContentAreaItem[]) => {
    const modal: IModal = {
      content: modalContent || [],
    };

    uiStore.setModal(modal);
  };

  return (
    <>
      <Helmet>
        <title>{`${currentAccount.name} - Santander`}</title>
      </Helmet>
      <div className={classes.join(" ")}>
        <section className={styles.headerWrapper}>
          <div>
            <h1
              style={{
                display: "inline-flex",
              }}
            >
              {getAccountDisplayName(creditStore.currentAccount)}
            </h1>
            {contentStore.isSetAccountNameEnabled && (
              <Button
                className={styles.boxWrapper}
                onClick={() => {
                  renderModal(cardTemplate?.setAccountNameModalContent);
                }}
              >
                <div className={styles.box}>
                  <img src={editIcon} alt="edit-icon" />
                </div>
              </Button>
            )}
            <p>
              <span className={styles.bold}>{tx("misc.accountNumber")}:</span>
              {` ${currentAccount.displayNumber || currentAccount.accountNumber}`}
            </p>
            {currentCard && (
              <p>
                <span className={styles.bold}>{tx("card.cardNumber")}:</span>
                &nbsp;
                {cardNumberText}
              </p>
            )}
            {currentAccount && (
              <p>
                <span className={styles.bold}>{tx("deposit.productType").concat(":")}</span>
                {` ${currentAccount.nameIB}`}
              </p>
            )}
          </div>
        </section>
        {!isBlocked && renderContent()}
        {isBlocked && accountBlockedState && renderBlocked(accountBlockedState)}
        {isClosing &&
          closingContent?.map((infoBlock, i) => (
            <BasicInfoBlock
              data={infoBlock}
              key={infoBlock.contentLink.guidValue}
              nextBlockType={infoBlock.contentType[i + 1]}
              noBorder={i < closingContent.length - 1}
              noBottomPadding={i < closingContent.length - 1}
            />
          ))}
        {!isBlocked && <ContentArea page={cardTemplate} />}
      </div>
    </>
  );
});

export default withRoot(CardPage);
