import { CardStatus, IEngagements } from "../../../../models/Engagements";

export const mockCreditAccounts: IEngagements = {
  creditAccounts: [
    {
      availableBalance: 0.0,
      authorizedBalance: 0.0,
      coApplicantName: undefined,
      hasDirectDebit: false,
      paymentAccount: "5562-1734",
      hasInsurance: true,
      applicationReceived: true,
      insurances: undefined,
      maximumInterestBearingAmount: 0.0,
      cards: [],
      atmFee: "35 Kr",
      bankTransferFee: undefined,
      currencyExchangeFee: undefined,
      displayNumber: "2-3151256593",
      accountNumber: "23151256593",
      canOrderCard: true,
      accountType: "Credit",
      bookedBalance: -6844.0,
      accountInterest: 19.95,
      creditLimit: 7000.0,
      chainName: "53",
      chainNumber: 53,
      storeNumber: "0971051",
      invoiceFee: 30.0,
      nameIB: "Mitt kort trygga köp",
      name: "Mitt kort trygga köp",
    },
    {
      availableBalance: 306.91,
      authorizedBalance: 1.0,
      coApplicantName: undefined,
      hasDirectDebit: false,
      paymentAccount: "5562-1734",
      hasInsurance: false,
      applicationReceived: true,
      insurances: undefined,
      maximumInterestBearingAmount: 0.0,
      cards: [],
      atmFee: "40 Kr",
      bankTransferFee: undefined,
      currencyExchangeFee: undefined,
      displayNumber: "2-3125380791",
      accountNumber: "23125380791",
      canOrderCard: true,
      accountType: "Credit",
      bookedBalance: -14692.09,
      accountInterest: 16.05,
      creditLimit: 15000.0,
      chainName: "Mediamarkt",
      chainNumber: 809,
      storeNumber: "0957985",
      invoiceFee: 30.0,
      nameIB: "Butikskredit",
      name: "Mediamarkt",
    },
    {
      availableBalance: 32000.0,
      authorizedBalance: 0.0,
      coApplicantName: undefined,
      hasDirectDebit: false,
      paymentAccount: "5562-1734",
      hasInsurance: false,
      applicationReceived: true,
      insurances: undefined,
      maximumInterestBearingAmount: 0.0,
      cards: [
        {
          accountNumber: "93530002743298",
          cardId: "353000705864010",
          annualFee: 0.0,
          displayNumber: "420857XXXXXX7057",
          status: CardStatus.Inactive,
          isActive: false,
          isManufactured: true,
          isVirtual: false,
          cardTypeName: undefined,
          expireDate: new Date("2025-11-01T00:00:00+01:00"),
          embossingDate: undefined,
          embossingName: undefined,
          insuranceType: undefined,
          isSecureCodeRegistered: false,
        },
      ],
      atmFee: undefined,
      bankTransferFee: undefined,
      currencyExchangeFee: undefined,
      displayNumber: "93530002743298",
      accountNumber: "93530002743298",
      canOrderCard: true,
      accountType: "Credit",
      bookedBalance: 0.0,
      accountInterest: 0.0,
      creditLimit: 32000.0,
      chainName: "136000000",
      chainNumber: 136000000,
      storeNumber: "136001001",
      invoiceFee: 0.0,
      nameIB: "Mitt kort",
      name: "Mitt kort",
    },
    {
      availableBalance: 32000.0,
      authorizedBalance: 0.0,
      coApplicantName: undefined,
      hasDirectDebit: false,
      paymentAccount: "5562-1734",
      hasInsurance: false,
      applicationReceived: true,
      insurances: undefined,
      maximumInterestBearingAmount: 0.0,
      cards: [
        {
          accountNumber: "93530002743297",
          cardId: "353000705864012",
          annualFee: 0.0,
          displayNumber: "420857XXXXXX7052",
          status: CardStatus.Inactive,
          isActive: false,
          isManufactured: false,
          isVirtual: false,
          cardTypeName: undefined,
          expireDate: new Date("2025-11-01T00:00:00+01:00"),
          embossingDate: undefined,
          embossingName: undefined,
          insuranceType: undefined,
          isSecureCodeRegistered: false,
        },
      ],
      atmFee: undefined,
      bankTransferFee: undefined,
      currencyExchangeFee: undefined,
      displayNumber: "93530002743297",
      accountNumber: "93530002743297",
      canOrderCard: true,
      accountType: "Credit",
      bookedBalance: 0.0,
      accountInterest: 0.0,
      creditLimit: 32000.0,
      chainName: "136000000",
      chainNumber: 136000000,
      storeNumber: "136001001",
      invoiceFee: 0.0,
      nameIB: "Mitt kort (Ej tillverkad)",
      name: "Mitt kort (Ej tillverkad)",
    },
  ],
  error: { message: undefined },
};
