import { ApisauceInstance, create } from "apisauce";
import { AxiosAdapter, AxiosInstance } from "axios";
import MockAdapter from "axios-mock-adapter";
import { Store } from "../../stores";
import { IConfig } from "../models/Config";
import { Environment, PendingApplicationState } from "../models/Content/Enums";
import {
  IUpdateApplicationServiceRequest,
  IUpdateApplicationServiceResponse,
  IAddTopUpRequest,
  IAddTopUpResponse,
  IOfferProjectionRequest,
  IOfferProjectionResponse,
  IOffersResponse,
  IPendingApplication,
  IPendingApplicationsResponse,
  IUpdateApplicationRequest,
  IUpdateApplicationResponse,
} from "../models/Offer/Offer";
import {
  mockAddTopUpSuccess,
  mockPendingApplication,
  mockPendingApplications,
  mockUpdateApplicationSuccess,
} from "./mock/offers";
import { mockOffers } from "./mock/offers/offers";
import { mockPfmlProjection, mockPpilProjection } from "./mock/offers/projections";

export class OffersApi {
  rootStore: Store;

  client?: ApisauceInstance;

  mock?: MockAdapter;

  mockAdapter?: AxiosAdapter;

  actualAdapter?: AxiosAdapter;

  mockApplicationState = PendingApplicationState.Scoring;

  constructor(rootStore: Store) {
    this.rootStore = rootStore;
  }

  init = (config: IConfig | undefined) => {
    if (config) {
      const { platformStore } = this.rootStore;
      const platformSpecificHeaders = platformStore?.getPlatformSpecificHeaders();

      const headers: { [key: string]: string | number } = {
        "Content-Type": "application/json",
        "api-version": "1.0",
        "x-content-type-options": "nosniff",
        "Ocp-Apim-Subscription-Key": config.OCP_APIM_SUBSCRIPTION_KEY,
      };

      if (platformSpecificHeaders) Object.assign(headers, platformSpecificHeaders);
      this.client = create({
        baseURL: config.OFFERS_URL,
        headers,
        timeout: 60000,
      });

      if (config.OCTOPUS_ENV !== Environment.Production) this.setupMockAdapter();
    }
  };

  getHeaders = (headers?: any) => ({
    headers: {
      ...headers,
      Authorization: `Bearer ${this.rootStore.securePersistentStore.accessToken}`,
    },
  });

  getOffers = async () => this.client?.get<IOffersResponse>("", { v: "1.0" }, this.getHeaders());

  consumeOffer = async (offerId: string) => {
    return this.client?.post<string>(`${offerId}/consume`, { v: "1.0" }, this.getHeaders({}));
  };

  acceptLimitIncrease = async (offerId: string, appliedAmount: number) => {
    return this.client?.put<string>(
      `${offerId}/acceptLimitIncrease?appliedAmount=${appliedAmount}`,
      { v: "1.0" },
      this.getHeaders({})
    );
  };

  addTopUp = async (offerId: string, addTopUpRequest: IAddTopUpRequest) => {
    return this.client?.post<IAddTopUpResponse>(`${offerId}/addTopUp`, addTopUpRequest, this.getHeaders({}));
  };

  createOfferProjection = async (
    offerId: string,
    accountNumber: string,
    offerProjectionRequest: IOfferProjectionRequest
  ) => {
    return this.client?.post<IOfferProjectionResponse>(
      `${offerId}/projections/${accountNumber}`,
      offerProjectionRequest,
      this.getHeaders({})
    );
  };

  getApplication = async (applicationId: string) => {
    return this.client?.get<IPendingApplication>(`applications/${applicationId}`, { v: "1.0" }, this.getHeaders({}));
  };

  updateApplicationService = async (
    applicationId: string,
    updateApplicationServiceRequest: IUpdateApplicationServiceRequest
  ) => {
    return this.client?.post<IUpdateApplicationServiceResponse>(
      `applications/${applicationId}/services`,
      updateApplicationServiceRequest,
      this.getHeaders({})
    );
  };

  updateApplication = async (applicationId: string, updateApplicationRequest: IUpdateApplicationRequest) => {
    return this.client?.put<IUpdateApplicationResponse>(
      `applications/${applicationId}`,
      updateApplicationRequest,
      this.getHeaders({})
    );
  };

  getPendingApplications = async () => {
    return this.client?.get<IPendingApplicationsResponse>(`applications`, { v: "1.0" }, this.getHeaders({}));
  };

  setupMockAdapter = () => {
    const setNextApplicationState = () => {
      switch (this.mockApplicationState) {
        case PendingApplicationState.Scoring:
          this.mockApplicationState = PendingApplicationState.PendingAccept;
          break;
        case PendingApplicationState.PendingAccept:
        case PendingApplicationState.DownSellPendingAccept:
          this.mockApplicationState = PendingApplicationState.Accepted;
          break;
        case PendingApplicationState.Accepted:
          this.mockApplicationState = PendingApplicationState.ReadyForSigning;
          break;
        case PendingApplicationState.ReadyForSigning:
          this.mockApplicationState = PendingApplicationState.Completed;
          break;
        default:
          break;
      }
    };

    const idRegexSegment = "[a-zA-Z0-9-]*";

    this.mock = new MockAdapter(this.client?.axiosInstance as AxiosInstance, {
      delayResponse: process.env.NODE_ENV === "test" ? 0 : 2000,
    });

    this.mockAdapter = this.client?.axiosInstance.defaults.adapter;

    // Defaults to real apis
    this.mock.restore();

    this.actualAdapter = this.client?.axiosInstance.defaults.adapter;

    this.mock.onGet("").reply(200, mockOffers);

    this.mock.onGet("applications").reply(200, mockPendingApplications);

    this.mock.onGet(new RegExp(`applications/${idRegexSegment}`)).reply(200, mockPendingApplication);

    this.mock.onGet(new RegExp(`applications/${idRegexSegment}`)).reply(() => {
      setNextApplicationState();
      return [
        200,
        {
          ...mockPendingApplication,
          applicationState: this.mockApplicationState,
          signUrl:
            this.mockApplicationState === PendingApplicationState.ReadyForSigning
              ? "https://www.santanderconsumer.se/"
              : undefined,
        },
      ];
    });

    this.mock.onPost(new RegExp(`applications/${idRegexSegment}/services`)).reply(201);

    this.mock.onPost("1/consume").reply(204);

    this.mock.onPost("2/consume").reply(204);

    this.mock.onPut("3/acceptLimitIncrease?appliedAmount=5000").reply(204);

    this.mock.onPost("4/consume").reply(204);

    this.mock.onPost("6/addTopUp").reply(200, mockAddTopUpSuccess);

    this.mock.onPut(new RegExp(`applications/${idRegexSegment}`)).reply(200, mockUpdateApplicationSuccess);

    this.mock.onPost("5/projections/4257557").reply(200, mockPpilProjection);

    this.mock.onPost("7/projections/4257557").reply(200, mockPfmlProjection);

    this.mock.onPost("7/consume").reply(204);
  };

  setMock = (isMock: boolean) => {
    if (this.client) {
      if (isMock) {
        this.client.axiosInstance.defaults.adapter = this.mockAdapter;
      } else {
        this.client.axiosInstance.defaults.adapter = this.actualAdapter;
      }
    }
  };
}
