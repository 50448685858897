import { TransferStore } from "stores";
import { BankAccountControlFlowState } from "../models/Content/Enums";
import { KreditzEvent, KreditzRawEvent } from "libs/models/BankAccountControl/KreditzEvent";

export const displayInitialLoading = (currentState: BankAccountControlFlowState) =>
  currentState === BankAccountControlFlowState.FetchingIFrameData ||
  currentState === BankAccountControlFlowState.IFrameDataLoaded;

export const displayKreditzInControlStep = (currentState: BankAccountControlFlowState) =>
  currentState === BankAccountControlFlowState.IFrameDataLoaded ||
  currentState === BankAccountControlFlowState.KreditzInControl;

export const displayPollingResultStep = (currentState: BankAccountControlFlowState) =>
  currentState === BankAccountControlFlowState.PollingResult;

export const displayErrorStep = (currentState: BankAccountControlFlowState) =>
  currentState === BankAccountControlFlowState.Error;

export const displayFinalStep = (currentState: BankAccountControlFlowState) =>
  currentState === BankAccountControlFlowState.Final;

export const handleBankAccountControlEvent = (data: string, transferStore: TransferStore): string => {
  const rawData: KreditzRawEvent = JSON.parse(data);
  const kreditzEvent: KreditzEvent = {
    eventType: rawData.message,
    status: rawData.status,
    caseId: rawData.case_id,
  };
  transferStore.handleKreditzEvent(kreditzEvent);

  return rawData.message;
};
