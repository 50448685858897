import {
  IBankAccountControlResponse,
  ITransferAccountsForCaseResponse,
  ITransferAccountsResponse,
} from "../../../models/Transfer/Transfer";

export const mockKreditz: IBankAccountControlResponse = {
  source:
    "https://sandbox-vista.kreditz.com/connect_bank?locale=sw&client_id=167&case_id=01000000-1b19-5aca-d650-08dbfbe9967b",
  origin: "https://sandbox-vista.kreditz.com",
  caseId: "",
};

export const mockVerifiedAccount: ITransferAccountsForCaseResponse = {
  account: {
    clearingNumber: "3300",
    accountNumber: "8501110000",
    displayName: "Kreditz Verified",
    bank: "Nordea Personkonto",
    isAccountVerified: true,
  },
};

export const mockTransferAccounts: ITransferAccountsResponse = {
  accounts: [
    {
      clearingNumber: "2200",
      accountNumber: "8508902102300",
      displayName: "Verwendet",
      bank: "Nordea Personkonto",
      isAccountValid: true,
      isAccountVerified: false,
    },
    {
      clearingNumber: "4108",
      accountNumber: "1645261233",
      displayName: "Erwartet",
      bank: "Nordea",
      isAccountValid: true,
      isAccountVerified: false,
    },
    {
      clearingNumber: "4400",
      accountNumber: "890123100660",
      displayName: "Verlassen",
      bank: "Nordea Personkonto",
      isAccountValid: true,
      isAccountVerified: false,
    },
    {
      clearingNumber: "4107",
      accountNumber: "1644162",
      displayName: "Mammas konto",
      bank: "Nordea",
      isAccountValid: true,
      isAccountVerified: false,
    },
    {
      clearingNumber: "4107",
      accountNumber: "1241163",
      displayName: "Spender",
      bank: "Nordea",
      isAccountValid: true,
      isAccountVerified: true,
    },
    {
      clearingNumber: "88888",
      accountNumber: "1234567",
      displayName: "Felaktigt konto",
      bank: "Swedbank",
      isAccountValid: true,
      isAccountVerified: true,
    },
    {
      clearingNumber: "4109",
      accountNumber: "1242172",
      displayName: "Leiter",
      bank: "Nordea",
      isAccountValid: true,
      isAccountVerified: true,
    },
    {
      clearingNumber: "882382",
      accountNumber: "12312390",
      displayName: "Vorsitzende",
      bank: "Swedbank",
      isAccountValid: true,
      isAccountVerified: true,
    },
  ],
};
export const mockTransferPostAccounts: ITransferAccountsResponse = {
  accounts: [
    {
      clearingNumber: "3300",
      accountNumber: "1234567890",
      displayName: "3300 1234567890",
      bank: "Nytillagt konto",
      isAccountVerified: false,
    },
  ],
};
