import { tx } from "../i18n";
import { UrlType } from "../models/Content/Enums";

export const boolToString = (value: boolean) => (value ? tx("misc.yes") : tx("misc.no"));

export const base64ToBlob = (base64: string, mimeType?: string) => {
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i += 1) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const type = mimeType || "application/pdf";

  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type });
  return blob;
};

export const isBase64File = (value: string | undefined) => value?.match(/data:([a-zA-Z/]*);base64,([^\"]*)/g);

const extractFileNameWithExtension = (url: string) => {
  return url
    .substr(1 + url.lastIndexOf("/"))
    .split("?")[0]
    .split("#")[0];
};

export const getUrlType = (url: string): UrlType => {
  if (url.indexOf("mailto:") === 0) {
    return UrlType.MailTo;
  }
  if (url.indexOf("tel:") === 0) {
    return UrlType.Tel;
  }

  const fileNameWithExtension = extractFileNameWithExtension(url);

  if (fileNameWithExtension.indexOf(".") > -1) {
    return UrlType.File;
  }

  if (url.indexOf("/") === 0) {
    return UrlType.Internal;
  }

  return UrlType.External;
};

export const parseNumber = (value: string) => {
  // eslint-disable-next-line no-irregular-whitespace
  return parseFloat(value.replace(/ /g, "").replace(",", "."));
};

export const bytesToSize = (bytes: number) => {
  const sizes = ["bytes", "kB", "MB"];

  if (bytes === 0) return "n/a";
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  if (i === 0) return `${bytes} ${sizes[i]})`;
  return `${(bytes / 1024 ** i).toFixed(0)} ${sizes[i]}`;
};

export const getFileSizeFromUrl = (url: string) => {
  let fileSize = "";
  const http = new XMLHttpRequest();
  http.open("HEAD", url, false);

  http.send(null);

  if (http.status === 200) {
    const contentLength = http.getResponseHeader("content-length");
    fileSize = contentLength ? bytesToSize(parseInt(contentLength)) : "";
  }

  return fileSize;
};

export const isUrl = (str: string) => {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  );
  return !!pattern.test(str);
};

export const createAutoDownloadTempLink = (fileName: string, objectUrl: string, autoDownload: boolean) => {
  const tempLink = document.createElement("a");
  tempLink.style.display = "none";
  tempLink.href = objectUrl;

  if (autoDownload) {
    tempLink.setAttribute("download", fileName);
  } else {
    tempLink.setAttribute("target", "_blank");
  }

  document.body.appendChild(tempLink);
  tempLink.click();
  setTimeout(() => {
    document.body.removeChild(tempLink);
    URL.revokeObjectURL(objectUrl);
  }, 200);
};
