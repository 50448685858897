/* eslint-disable max-len */
import dayjs from "dayjs";
import {
  IComplianceStatus,
  IComplianceDefinition,
  ComplianceStatus,
  IComplianceSigningStatus,
  ComplianceSigningStatus,
  ComplianceConditionValueType,
  ComplianceConditionOperator,
  ComplianceFieldType,
  IUploadAttachmentsResponse,
} from "../../../models/CustomerCompliance/Compliance";

export const mockComplianceStatuses: IComplianceStatus = {
  status: ComplianceStatus.InProgress,
  complianceDueDate: dayjs().add(3, "day").toDate(),
  completedQuestions: 0,
  outstaningQuestions: 2,
  bookmarkedAnswerId: undefined,
};

export const mockComplianceSignatures: IComplianceSigningStatus = {
  signatureLink: "https://google.com/",
  status: ComplianceSigningStatus.Signable,
};

export const mockUploadAttachments: IUploadAttachmentsResponse = {
  attachments: [{ fileId: "322f4ef7-a62e-46d7-aaa0-fd4f7c195063", fileName: "Test.png" }],
};

export const mockComplianceDefinitions: IComplianceDefinition[] = [
  {
    title: "Sparkonto",
    answerId: "MjcwNzg1M18w",
    questions: [
      {
        id: "TWpjd056ZzFNMTh3X19VSFZ5Y0c5elpWOVBabDlCWTJOdmRXNTBYMFJsY0c5emFYVENwTUtrd3FRd3dxVENwRlJ5ZFdYQ3BNS2s=",
        type: ComplianceFieldType.DropDownList,
        editable: true,
        answers: [],
        questionText: "Vad är syftet med kontot?",
        questionHelpText: undefined,
        options: [
          {
            id: "Short_Term_Saving",
            text: "Korttidsparande",
          },
          {
            id: "Long_Term_Saving",
            text: "Långtidssparande",
          },
          {
            id: "Private_Consumption",
            text: "Privat konsumtion",
          },
          {
            id: "Other",
            text: "Annat",
          },
        ],
        conditions: [],
        children: undefined,
      },
      {
        id: "TWpjd056ZzFNMTh3X19VSFZ5Y0c5elpWOVBabDlCWTJOdmRXNTBYMFJsY0c5emFYVENwTUtrd3FRd3dxVENwRlJ5ZFdYQ3BNS2ssdsadsafdasf=",
        type: ComplianceFieldType.FileUploader,
        editable: true,
        answers: [],
        questionText: "Ladda upp filer",
        questionHelpText:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        options: [],
        conditions: [],
        children: undefined,
      },
      {
        id: "TWpjd056ZzFNMTh3X181MmU2ODMwMS02MWU4LTQwZGUtYjUzYS0wNmUyYjRjZTMxZTU=",
        type: ComplianceFieldType.Conditional,
        editable: false,
        answers: undefined,
        questionText: undefined,
        questionHelpText: undefined,
        options: undefined,
        conditions: [
          {
            valueType: ComplianceConditionValueType.Option,
            operator: ComplianceConditionOperator.Equals,
            questionId:
              "TWpjd056ZzFNMTh3X19VSFZ5Y0c5elpWOVBabDlCWTJOdmRXNTBYMFJsY0c5emFYVENwTUtrd3FRd3dxVENwRlJ5ZFdYQ3BNS2s=",
            value: "Other",
          },
        ],
        children: [
          {
            id: "TWpjd056ZzFNMTh3X19VSFZ5Y0c5elpWOVBabDlCWTJOdmRXNTBYMGxtWDA5MGFHVnlYMFJsY0c5emFYVENwTUtrd3FRd3dxVENwRlJ5ZFdYQ3BNS2s=",
            type: ComplianceFieldType.TextArea,
            editable: true,
            answers: [],
            questionText:
              "Vänligen specificera syftet med kontot. Notera att om du ej detaljerar ditt svar kommer vi i ett senare skede kontakta dig för att inhämta informationen.",
            questionHelpText: undefined,
            options: undefined,
            conditions: [],
            children: undefined,
          },
        ],
      },
      {
        id: "TWpjd056ZzFNMTh3X19VMmw2WlY5UFpsOVVlRzVmU1c1ZlVHVnlUVzl1ZEdoZlJHVndiM05wZE1La3dxVENwRERDcE1La1ZISjFaY0trd3FRPQ==",
        type: ComplianceFieldType.DropDownList,
        editable: true,
        answers: [],
        questionText: "Hur mycket kommer du att sätta in totalt per månad?",
        questionHelpText: undefined,
        options: [
          {
            id: "0–20000_SEK",
            text: "0 - 20 000 SEK",
          },
          {
            id: "20001–50000_SEK",
            text: "20 001 - 50 000 SEK",
          },
          {
            id: "50001–100000_SEK",
            text: "50 001 - 100 000 SEK",
          },
          {
            id: "100001–200000_SEK",
            text: "100 001 - 200 000 SEK",
          },
          {
            id: "More_Than_200000_SEK",
            text: "Mer än 200 000 SEK",
          },
        ],
        conditions: [],
        children: undefined,
      },
      {
        id: "TWpjd056ZzFNMTh3X19VMmw2WlY5UFpsOVVlRzVmVDNWMFgxQmxjazF2Ym5Sb1gwUmxjRzl6YVhUQ3BNS2t3cVF3d3FUQ3BGUnlkV1hDcE1Law==",
        type: ComplianceFieldType.DropDownList,
        editable: true,
        answers: [],
        questionText: "Hur mycket kommer du ta ut totalt per månad?",
        questionHelpText: undefined,
        options: [
          {
            id: "0–20000_SEK",
            text: "0 - 20 000 SEK",
          },
          {
            id: "20001–50000_SEK",
            text: "20 001 - 50 000 SEK",
          },
          {
            id: "50001–100000_SEK",
            text: "50 001 - 100 000 SEK",
          },
          {
            id: "100001–200000_SEK",
            text: "100 001 - 200 000 SEK",
          },
          {
            id: "More_Than_200000_SEK",
            text: "Mer än 200 000 SEK",
          },
        ],
        conditions: [],
        children: undefined,
      },
      {
        id: "TWpjd056ZzFNMTh3X19VbVZuZFd4aGNtbDBlVjlQWmw5VWVHNWZTVzVmVUdWeVRXOXVkR2hmUkdWd2IzTnBkTUtrd3FUQ3BERENwTUtrVkhKMVpjS2t3cVE9",
        type: ComplianceFieldType.DropDownList,
        editable: true,
        answers: [],
        questionText: "Hur ofta kommer du göra insättningar per månad?",
        questionHelpText: undefined,
        options: [
          {
            id: "0-1",
            text: "Mindre än en gång i månaden",
          },
          {
            id: "1-5",
            text: "1-5 gånger i månaden",
          },
          {
            id: "More_Than_ 5",
            text: "Mer än 5 gånger per månad",
          },
        ],
        conditions: [],
        children: undefined,
      },
      {
        id: "TWpjd056ZzFNMTh3X19VbVZuZFd4aGNtbDBlVjlQWmw5VWVHNWZUM1YwWDFCbGNrMXZiblJvWDBSbGNHOXphWFRDcE1La3dxUXd3cVRDcEZSeWRXWENwTUtr",
        type: ComplianceFieldType.DropDownList,
        editable: true,
        answers: [],
        questionText: "Hur ofta kommer du göra uttag per månad?",
        questionHelpText: undefined,
        options: [
          {
            id: "0-1",
            text: "Mindre än en gång i månaden",
          },
          {
            id: "1-5",
            text: "1-5 gånger i månaden",
          },
          {
            id: "More_Than_ 5",
            text: "Mer än 5 gånger per månad",
          },
        ],
        conditions: [],
        children: undefined,
      },
      {
        id: "TWpjd056ZzFNMTh3X19SblZ1WkY5VGIzVnlZMlZmUkdWd2IzTnBkTUtrd3FUQ3BERENwTUtrVkhKMVpjS2t3cVE9",
        type: ComplianceFieldType.DropDownList,
        editable: true,
        answers: [],
        questionText: "Var kommer pengarna som du sparar hos oss huvudsakligen ifrån?",
        questionHelpText:
          'I enlighet med lagen om åtgärder mot penningtvätt och finansiering av terrorism, behöver Santander Consumer Bank information om varifrån pengarna som används i ditt sparande huvudsakligen kommer. Som beskrivet i tillvalslistan är det vanligast att man använder sin lön eller pension för att betala för lånet eller produkten man har hos banken. Om inget av alternativen avser de pengar som används för att betala för din produkt hos banken, kan du välja "annat" och specificera ytterligare i kommentarsfältet.',
        options: [
          {
            id: "Salary",
            text: "Lön",
          },
          {
            id: "Pension",
            text: "Pension",
          },
          {
            id: "Savings",
            text: "Sparande",
          },
          {
            id: "Social_Benefits",
            text: "Bidrag",
          },
          {
            id: "StudyLoan_Stipends",
            text: "Studiestöd",
          },
          {
            id: "Income_From_CommercialActivity_Including_Rent",
            text: "Intäkter från näringsverksamhet inklusive hyresintäkter",
          },
          {
            id: "Profit_From_SaleOfRealEstate",
            text: "Vinst från försäljning av fastighet",
          },
          {
            id: "Profit_From_Movables_Car_Boat_Etc",
            text: "Vinst från försäljning av lös egendom (bil, båt, etc.)",
          },
          {
            id: "Inheritance_Or_Gifts",
            text: "Arv/gåvor",
          },
          {
            id: "Yield_From_Investment",
            text: "Vinst från investeringar",
          },
          {
            id: "Dividend_Or_Yield_From_CommercialActivity",
            text: "Aktieutdelning eller liknande vinst från näringsverksamhet",
          },
          {
            id: "Compensation",
            text: "Annan typ av ersättning (t.ex. från försäkring, avgångsvederlag eller liknande)",
          },
          {
            id: "Profit_From_Lottery_Gambling",
            text: "Vinster från spel/lotterier",
          },
          {
            id: "Other",
            text: "Annat",
          },
        ],
        conditions: [],
        children: undefined,
      },
      {
        id: "TWpjd056ZzFNMTh3X19kYzkxOTRiNi0yZWM0LTQyMmYtYmQxNS01ZTViNWE0ZDIxYWM=",
        type: ComplianceFieldType.Conditional,
        editable: false,
        answers: undefined,
        questionText: undefined,
        questionHelpText: undefined,
        options: undefined,
        conditions: [
          {
            valueType: ComplianceConditionValueType.Option,
            operator: ComplianceConditionOperator.Equals,
            questionId: "TWpjd056ZzFNMTh3X19SblZ1WkY5VGIzVnlZMlZmUkdWd2IzTnBkTUtrd3FUQ3BERENwTUtrVkhKMVpjS2t3cVE9",
            value: "Other",
          },
        ],
        children: [
          {
            id: "TWpjd056ZzFNMTh3X19SblZ1WkY5VGIzVnlZMlZmU1daZlQzUm9aWEpmUkdWd2IzTnBkTUtrd3FUQ3BERENwTUtrVkhKMVpjS2t3cVE9",
            type: ComplianceFieldType.TextArea,
            editable: true,
            answers: [],
            questionText:
              "Vänligen ange varifrån pengarna för sparandet huvudsakligen kommer. Notera att om du ej detaljerar ditt svar kommer vi i ett senare skede kontakta dig för att inhämta informationen.",
            questionHelpText: undefined,
            options: undefined,
            conditions: [],
            children: undefined,
          },
        ],
      },
      {
        id: "TWpjd056ZzFNMTh3X18zZTM3NjljNy1mOWI3LTQxYzctODVjMy1lMGI2NGRjNWU2ODE=",
        type: ComplianceFieldType.paragraph,
        editable: false,
        answers: [],
        questionText:
          "Genom att skicka in det här frågeformuläret bekräftar du härmed att all information du har lämnat är fullständig och korrekt. Vänligen notera att du inte kan gå tillbaka när du väl skickat in frågeformuläret.",
        questionHelpText: undefined,
        options: undefined,
        conditions: [],
        children: undefined,
      },
    ],
    accountId: "100005229529",
  },
  {
    title: "Kundformulär",
    answerId: "MjcwNzkxM18w",
    questions: [
      {
        id: "TWpjd056a3hNMTh3X182MWU3NjNhYi1iY2EzLTQ5OTQtOTI1ZC0zYmY2Mjk4MzM2NTM=",
        type: ComplianceFieldType.paragraph,
        editable: false,
        answers: [],
        questionText:
          "I den första delen av detta avsnitt kommer dina personuppgifter att vara förifyllda och låsta. Detta är information som vi har fått från offentliga register. Om du anser att denna information är inkorrekt har du möjlighet att specificera detta längre ner i avsnittet.",
        questionHelpText: undefined,
        options: undefined,
        conditions: [],
        children: undefined,
      },
      {
        id: "TWpjd056a3hNMTh3X19kNGU1ZjU3Ni0yZGMzLTRmMWItOWQyMy1iY2ViNzc2NjEzNzQ=",
        type: ComplianceFieldType.paragraph,
        editable: false,
        answers: [],
        questionText:
          "Om det finns uppgifter som behöver ändras i den låsta delen, ber vi dig vänligen att uppdatera de relevanta uppgifterna direkt hos Skatteverket/ Folkbokföringen. Mer information finner du på Skatteverkets hemsida: \nhttps://www.skatteverket.se/privat/folkbokforing.4.18e1b10334ebe8bc800039.html",
        questionHelpText: undefined,
        options: undefined,
        conditions: [],
        children: undefined,
      },
      {
        id: "TWpjd056a3hNMTh3X19kN2M2NTUwMy01YWUwLTRiOGYtYWJhMi1hZjBhNTBmNDY2MDA=",
        type: ComplianceFieldType.h3,
        editable: false,
        answers: [],
        questionText: "Kundinformation",
        questionHelpText: undefined,
        options: undefined,
        conditions: [],
        children: undefined,
      },
      {
        id: "TWpjd056a3hNMTh3X19SbWx5YzNRdVRtRnRaY0trVkU5ZlFrVmZSRVZVUlZKTlNVNUZSTUtrd3FRd3dxVENwTUtrd3FRPQ==",
        type: ComplianceFieldType.TextField,
        editable: false,
        answers: [
          {
            id: "First.Name",
            text: "Åpdpfpä",
          },
        ],
        questionText: "Förnamn",
        questionHelpText: undefined,
        options: undefined,
        conditions: [],
        children: undefined,
      },
      {
        id: "TWpjd056a3hNMTh3X19UR0Z6ZEM1T1lXMWx3cVJVVDE5Q1JWOUVSVlJGVWsxSlRrVkV3cVRDcEREQ3BNS2t3cVRDcEE9PQ==",
        type: ComplianceFieldType.TextField,
        editable: false,
        answers: [
          {
            id: "Last.Name",
            text: "Adup Ywwlekhl",
          },
        ],
        questionText: "Efternamn",
        questionHelpText: undefined,
        options: undefined,
        conditions: [],
        children: undefined,
      },
      {
        id: "TWpjd056a3hNMTh3X19VMU5Pd3FSVVQxOUNSVjlFUlZSRlVrMUpUa1ZFd3FUQ3BERENwTUtrd3FUQ3BBPT0=",
        type: ComplianceFieldType.TextField,
        editable: false,
        answers: [
          {
            id: "SSN",
            text: "199007013569",
          },
        ],
        questionText: "Personnummer",
        questionHelpText: undefined,
        options: undefined,
        conditions: [],
        children: undefined,
      },
      {
        id: "TWpjd056a3hNMTh3X19UR2x1WlY4eFgwRmtaSEpsYzNQQ3BGUlBYMEpGWDBSRlZFVlNUVWxPUlVUQ3BNS2tNTUtrd3FUQ3BNS2s=",
        type: ComplianceFieldType.TextField,
        editable: false,
        answers: [],
        questionText: "Adress",
        questionHelpText: undefined,
        options: undefined,
        conditions: [],
        children: undefined,
      },
      {
        id: "TWpjd056a3hNMTh3X19RM1Z6ZEc5dFpYSXVXa2xRd3FSVVQxOUNSVjlFUlZSRlVrMUpUa1ZFd3FUQ3BERENwTUtrd3FUQ3BBPT0=",
        type: ComplianceFieldType.TextField,
        editable: false,
        answers: [],
        questionText: "Postnummer",
        questionHelpText: undefined,
        options: undefined,
        conditions: [],
        children: undefined,
      },
      {
        id: "TWpjd056a3hNMTh3X19RM1Z6ZEc5dFpYSXVRMmwwZWNLa1ZFOWZRa1ZmUkVWVVJWSk5TVTVGUk1La3dxUXd3cVRDcE1La3dxUT0=",
        type: ComplianceFieldType.TextField,
        editable: false,
        answers: [],
        questionText: "Postort",
        questionHelpText: undefined,
        options: undefined,
        conditions: [],
        children: undefined,
      },
      {
        id: "TWpjd056a3hNMTh3X19RV1JrY21WemMxOWpiM1Z1ZEhKNXdxUlVUMTlDUlY5RVJWUkZVazFKVGtWRXdxVENwRERDcE1La3dxVENwQT09",
        type: ComplianceFieldType.DropDownList,
        editable: false,
        answers: [
          {
            id: "SWE",
            text: "Sverige",
          },
        ],
        questionText: "Bosättningsland",
        questionHelpText: undefined,
        options: undefined,
        conditions: [],
        children: undefined,
      },
      {
        id: "TWpjd056a3hNMTh3X19TVzVtYjNKdFlYUnBiMjVmTEd4dlkydGxaRjlqYUdGdVoyVno=",
        type: ComplianceFieldType.DropDownListYesNo,
        editable: true,
        answers: [],
        questionText: "Är informationen i ovan sektion korrekt?",
        questionHelpText: undefined,
        options: [
          {
            id: "True",
            text: "Ja",
          },
          {
            id: "False",
            text: "Nej",
          },
        ],
        conditions: [],
        children: undefined,
      },
      {
        id: "TWpjd056a3hNMTh3X19iOGY1OGRkMC0xZmI5LTRhN2ItOGU5MC02OTNmMGVlYmI2MGY=",
        type: ComplianceFieldType.Conditional,
        editable: false,
        answers: undefined,
        questionText: undefined,
        questionHelpText: undefined,
        options: undefined,
        conditions: [
          {
            valueType: ComplianceConditionValueType.Bool,
            operator: ComplianceConditionOperator.Equals,
            questionId: "TWpjd056a3hNMTh3X19TVzVtYjNKdFlYUnBiMjVmTEd4dlkydGxaRjlqYUdGdVoyVno=",
            value: "False",
          },
        ],
        children: [
          {
            id: "TWpjd056a3hNMTh3X19VR3hsWVhObFgzTndaV05wWm5rPQ==",
            type: ComplianceFieldType.TextArea,
            editable: true,
            answers: [],
            questionText:
              "Vänligen detaljera ditt svar nedan. Notera att om du ej utvecklar ditt svar  kommer vi i ett senare skede kontakta dig för att inhämta informationen.",
            questionHelpText: undefined,
            options: undefined,
            conditions: [],
            children: undefined,
          },
        ],
      },
      {
        id: "TWpjd056a3hNMTh3X19RMmwwYVhwbGJuTm9hWEE9",
        type: ComplianceFieldType.DropDownListMulti,
        editable: true,
        answers: [],
        questionText: "Vänligen ange samtliga medborgarskap du har",
        questionHelpText: undefined,
        options: [
          {
            id: "SWE",
            text: "Sverige",
          },
          {
            id: "AFG",
            text: "Afghanistan",
          },
          {
            id: "ALB",
            text: "Albanien",
          },
          {
            id: "DZA",
            text: "Algeriet",
          },
          {
            id: "VIR",
            text: "Amerikanska Jungfruöarna",
          },
          {
            id: "ASM",
            text: "Amerikanska Samoa",
          },
          {
            id: "AND",
            text: "Andorra",
          },
          {
            id: "AGO",
            text: "Angola",
          },
          {
            id: "AIA",
            text: "Anguilla",
          },
          {
            id: "ATA",
            text: "Antarktis",
          },
          {
            id: "ATG",
            text: "Antigua och Barbuda",
          },
          {
            id: "ARG",
            text: "Argentina",
          },
          {
            id: "ARM",
            text: "Armenien",
          },
          {
            id: "ABW",
            text: "Aruba",
          },
          {
            id: "AUS",
            text: "Australien",
          },
          {
            id: "AZE",
            text: "Azerbajdzjan",
          },
          {
            id: "BHS",
            text: "Bahamas",
          },
          {
            id: "BHR",
            text: "Bahrain",
          },
          {
            id: "BGD",
            text: "Bangladesh",
          },
          {
            id: "BRB",
            text: "Barbados",
          },
          {
            id: "BLR",
            text: "Belarus",
          },
          {
            id: "BEL",
            text: "Belgien",
          },
          {
            id: "BLZ",
            text: "Belize",
          },
          {
            id: "BEN",
            text: "Benin",
          },
          {
            id: "BMU",
            text: "Bermuda",
          },
          {
            id: "BTN",
            text: "Bhutan",
          },
          {
            id: "BOL",
            text: "Bolivia",
          },
          {
            id: "BES",
            text: "Bonaire, Sint Eustatius och Saba",
          },
          {
            id: "BIH",
            text: "Bosnien och Hercegovina",
          },
          {
            id: "BWA",
            text: "Botswana",
          },
          {
            id: "BVT",
            text: "Bouvetön",
          },
          {
            id: "BRA",
            text: "Brasilien",
          },
          {
            id: "IOT",
            text: "Brittiska territoriet i Indiska oceanen",
          },
          {
            id: "BRN",
            text: "Brunei Darussalam",
          },
          {
            id: "BGR",
            text: "Bulgarien",
          },
          {
            id: "BFA",
            text: "Burkina Faso",
          },
          {
            id: "BDI",
            text: "Burundi",
          },
          {
            id: "CYM",
            text: "Caymanöarna",
          },
          {
            id: "CAF",
            text: "Centralafrikanska Republiken (CAR)",
          },
          {
            id: "CHL",
            text: "Chile",
          },
          {
            id: "COL",
            text: "Colombia",
          },
          {
            id: "COK",
            text: "Cooköarna",
          },
          {
            id: "CRI",
            text: "Costa Rica",
          },
          {
            id: "CUW",
            text: "Curaçao",
          },
          {
            id: "CYP",
            text: "Cypern",
          },
          {
            id: "DNK",
            text: "Danmark",
          },
          {
            id: "COD",
            text: "Demokratiska republiken Kongo",
          },
          {
            id: "DJI",
            text: "Djibouti",
          },
          {
            id: "DMA",
            text: "Dominica",
          },
          {
            id: "DOM",
            text: "Dominikanska republiken",
          },
          {
            id: "ECU",
            text: "Ecuador",
          },
          {
            id: "EGY",
            text: "Egypten",
          },
          {
            id: "GNQ",
            text: "Ekvatorialguinea",
          },
          {
            id: "CIV",
            text: "Elfenbenskusten",
          },
          {
            id: "SLV",
            text: "El Salvador",
          },
          {
            id: "ERI",
            text: "Eritrea",
          },
          {
            id: "EST",
            text: "Estland",
          },
          {
            id: "ETH",
            text: "Etiopien",
          },
          {
            id: "FLK",
            text: "Falklandsöarna (Malvinerna)",
          },
          {
            id: "FJI",
            text: "Fiji",
          },
          {
            id: "PHL",
            text: "Filippinerna",
          },
          {
            id: "FIN",
            text: "Finland",
          },
          {
            id: "FRA",
            text: "Frankrike",
          },
          {
            id: "GUF",
            text: "Franska Guyana",
          },
          {
            id: "PYF",
            text: "Franska Polynesien",
          },
          {
            id: "ATF",
            text: "Franska sydterritorierna (Franska syd- och Antarktisterritorierna)",
          },
          {
            id: "FRO",
            text: "Färöarna",
          },
          {
            id: "ARE",
            text: "Förenade Arabemiraten",
          },
          {
            id: "UMI",
            text: "Förenta staternas mindre öar i Oceanien och Västindien",
          },
          {
            id: "GAB",
            text: "Gabon",
          },
          {
            id: "GMB",
            text: "Gambia",
          },
          {
            id: "GEO",
            text: "Georgien",
          },
          {
            id: "GHA",
            text: "Ghana",
          },
          {
            id: "GIB",
            text: "Gibraltar",
          },
          {
            id: "GRC",
            text: "Grekland",
          },
          {
            id: "GRD",
            text: "Grenada",
          },
          {
            id: "GRL",
            text: "Grönland",
          },
          {
            id: "GLP",
            text: "Guadeloupe",
          },
          {
            id: "GUM",
            text: "Guam",
          },
          {
            id: "GTM",
            text: "Guatemala",
          },
          {
            id: "GGY",
            text: "Guernsey",
          },
          {
            id: "GIN",
            text: "Guinea",
          },
          {
            id: "GNB",
            text: "Guinea-Bissau",
          },
          {
            id: "GUY",
            text: "Guyana",
          },
          {
            id: "HTI",
            text: "Haiti",
          },
          {
            id: "HMD",
            text: "Heard- och McDonaldöarna",
          },
          {
            id: "HND",
            text: "Honduras",
          },
          {
            id: "HKG",
            text: "Hong Kong",
          },
          {
            id: "IND",
            text: "Indien",
          },
          {
            id: "IDN",
            text: "Indonesien",
          },
          {
            id: "ISL",
            text: "Island",
          },
          {
            id: "IRQ",
            text: "Irak",
          },
          {
            id: "IRN",
            text: "Iran (Islamiska republiken Iran)",
          },
          {
            id: "IRL",
            text: "Irland",
          },
          {
            id: "IMN",
            text: "Isle of Man",
          },
          {
            id: "ISR",
            text: "Israel",
          },
          {
            id: "ITA",
            text: "Italien",
          },
          {
            id: "JAM",
            text: "Jamaica",
          },
          {
            id: "JPN",
            text: "Japan",
          },
          {
            id: "JOR",
            text: "Jordanien",
          },
          {
            id: "CXR",
            text: "Julön",
          },
          {
            id: "VGB",
            text: "Jungfruöarna (Storbritannien)",
          },
          {
            id: "CMR",
            text: "Kamerun",
          },
          {
            id: "KHM",
            text: "Kambodja",
          },
          {
            id: "CAN",
            text: "Kanada",
          },
          {
            id: "JEY",
            text: "Kanalöarna, Jersey",
          },
          {
            id: "XAA",
            text: "Kanarieöarna",
          },
          {
            id: "CPV",
            text: "Kap Verde",
          },
          {
            id: "KAZ",
            text: "Kazakstan",
          },
          {
            id: "KEN",
            text: "Kenya",
          },
          {
            id: "CHN",
            text: "Kina",
          },
          {
            id: "KGZ",
            text: "Kirgizistan",
          },
          {
            id: "KIR",
            text: "Kiribati",
          },
          {
            id: "CCK",
            text: "Kokosöarna",
          },
          {
            id: "COM",
            text: "Komorerna",
          },
          {
            id: "COG",
            text: "Kongo",
          },
          {
            id: "XKX",
            text: "Kosovo",
          },
          {
            id: "HRV",
            text: "Kroatien",
          },
          {
            id: "CUB",
            text: "Kuba",
          },
          {
            id: "KWT",
            text: "Kuwait",
          },
          {
            id: "LAO",
            text: "Laos",
          },
          {
            id: "LVA",
            text: "Lettland",
          },
          {
            id: "LSO",
            text: "Lesotho",
          },
          {
            id: "LBN",
            text: "Libanon",
          },
          {
            id: "LBR",
            text: "Liberia",
          },
          {
            id: "LBY",
            text: "Libyen",
          },
          {
            id: "LIE",
            text: "Liechtenstein",
          },
          {
            id: "LTU",
            text: "Litauen",
          },
          {
            id: "LUX",
            text: "Luxemburg",
          },
          {
            id: "MAC",
            text: "Macao",
          },
          {
            id: "MDG",
            text: "Madagaskar",
          },
          {
            id: "MKD",
            text: "Makedonien (Republiken Makedonien)",
          },
          {
            id: "MWI",
            text: "Malawi",
          },
          {
            id: "MYS",
            text: "Malaysia",
          },
          {
            id: "MDV",
            text: "Maldiverna",
          },
          {
            id: "MLI",
            text: "Mali",
          },
          {
            id: "MLT",
            text: "Malta",
          },
          {
            id: "MAR",
            text: "Marocko",
          },
          {
            id: "MHL",
            text: "Marshallöarna",
          },
          {
            id: "MTQ",
            text: "Martinique",
          },
          {
            id: "MRT",
            text: "Mauretanien",
          },
          {
            id: "MUS",
            text: "Mauritius",
          },
          {
            id: "MYT",
            text: "Mayotte",
          },
          {
            id: "MEX",
            text: "Mexiko",
          },
          {
            id: "FSM",
            text: "Mikronesiska federationen",
          },
          {
            id: "MDA",
            text: "Moldavien",
          },
          {
            id: "MCO",
            text: "Monaco",
          },
          {
            id: "MNG",
            text: "Mongoliet",
          },
          {
            id: "MNE",
            text: "Montenegro",
          },
          {
            id: "MSR",
            text: "Montserrat",
          },
          {
            id: "MOZ",
            text: "Moçambique",
          },
          {
            id: "MMR",
            text: "Myanmar",
          },
          {
            id: "NAM",
            text: "Namibia",
          },
          {
            id: "NRU",
            text: "Nauru",
          },
          {
            id: "NLD",
            text: "Nederländerna",
          },
          {
            id: "ANT",
            text: "Nederländska Antillerna",
          },
          {
            id: "NPL",
            text: "Nepal",
          },
          {
            id: "NIC",
            text: "Nicaragua",
          },
          {
            id: "NER",
            text: "Niger",
          },
          {
            id: "NGA",
            text: "Nigeria",
          },
          {
            id: "NIU",
            text: "Niue",
          },
          {
            id: "PRK",
            text: "Nordkorea (Demokratiska Folkrepubliken Korea)",
          },
          {
            id: "MNP",
            text: "Nordmarianerna",
          },
          {
            id: "NFK",
            text: "Norfolkön",
          },
          {
            id: "NOR",
            text: "Norge",
          },
          {
            id: "NCL",
            text: "Nya Kaledonien",
          },
          {
            id: "NZL",
            text: "Nya Zeeland",
          },
          {
            id: "OMN",
            text: "Oman",
          },
          {
            id: "PAK",
            text: "Pakistan",
          },
          {
            id: "PLW",
            text: "Palau",
          },
          {
            id: "PSE",
            text: "Palestina",
          },
          {
            id: "PAN",
            text: "Panama",
          },
          {
            id: "PNG",
            text: "Papua Nya Guinea",
          },
          {
            id: "PRY",
            text: "Paraguay",
          },
          {
            id: "PER",
            text: "Peru",
          },
          {
            id: "PCN",
            text: "Pitcairnöarna",
          },
          {
            id: "POL",
            text: "Polen",
          },
          {
            id: "PRT",
            text: "Portugal",
          },
          {
            id: "PRI",
            text: "Puerto Rico",
          },
          {
            id: "QAT",
            text: "Qatar",
          },
          {
            id: "REU",
            text: "Réunion",
          },
          {
            id: "ROU",
            text: "Rumänien",
          },
          {
            id: "RUS",
            text: "Ryssland",
          },
          {
            id: "RWA",
            text: "Rwanda",
          },
          {
            id: "BLM",
            text: "Saint-Barthélemy",
          },
          {
            id: "KNA",
            text: "Saint Kitts och Nevis",
          },
          {
            id: "LCA",
            text: "Saint Lucia",
          },
          {
            id: "MAF",
            text: "Saint Martin",
          },
          {
            id: "SPM",
            text: "Saint-Pierre och Miquelon",
          },
          {
            id: "VCT",
            text: "Saint Vincent och Grenadinerna",
          },
          {
            id: "SLB",
            text: "Salomonöarna",
          },
          {
            id: "WSM",
            text: "Samoa",
          },
          {
            id: "SHN",
            text: "Sankta Helena, Ascension och Tristan da Cunha",
          },
          {
            id: "SMR",
            text: "San Marino",
          },
          {
            id: "SAU",
            text: "Saudiarabien",
          },
          {
            id: "SEN",
            text: "Senegal",
          },
          {
            id: "SRB",
            text: "Serbien",
          },
          {
            id: "SYC",
            text: "Seychellerna",
          },
          {
            id: "CHE",
            text: "Schweiz",
          },
          {
            id: "SLE",
            text: "Sierra Leone",
          },
          {
            id: "SGP",
            text: "Singapore",
          },
          {
            id: "SVK",
            text: "Slovakien",
          },
          {
            id: "SVN",
            text: "Slovenien",
          },
          {
            id: "SOM",
            text: "Somalia",
          },
          {
            id: "ESP",
            text: "Spanien",
          },
          {
            id: "LKA",
            text: "Sri Lanka",
          },
          {
            id: "GBR",
            text: "Storbritannien",
          },
          {
            id: "SDN",
            text: "Sudan",
          },
          {
            id: "SUR",
            text: "Surinam",
          },
          {
            id: "SJM",
            text: "Svalbard och Jan Mayen",
          },
          {
            id: "SWZ",
            text: "Swaziland",
          },
          {
            id: "ZAF",
            text: "Sydafrika",
          },
          {
            id: "SGS",
            text: "Sydgeorgien och Sydsandwichöarna",
          },
          {
            id: "KOR",
            text: "Sydkorea (Republiken Korea)",
          },
          {
            id: "SSD",
            text: "Sydsudan",
          },
          {
            id: "SYR",
            text: "Syrien",
          },
          {
            id: "STP",
            text: "São Tomé och Príncipe",
          },
          {
            id: "TWN",
            text: "Taiwan (Republiken Kina)",
          },
          {
            id: "TJK",
            text: "Tadzjikistan",
          },
          {
            id: "TZA",
            text: "Tanzania",
          },
          {
            id: "TCD",
            text: "Tchad",
          },
          {
            id: "CZE",
            text: "Tjeckien",
          },
          {
            id: "THA",
            text: "Thailand",
          },
          {
            id: "TGO",
            text: "Togo",
          },
          {
            id: "TKL",
            text: "Tokelau",
          },
          {
            id: "TON",
            text: "Tonga",
          },
          {
            id: "TTO",
            text: "Trinidad och Tobago",
          },
          {
            id: "TUN",
            text: "Tunisien",
          },
          {
            id: "TUR",
            text: "Turkiet",
          },
          {
            id: "TKM",
            text: "Turkmenistan",
          },
          {
            id: "TCA",
            text: "Turks- och Caicosöarna",
          },
          {
            id: "TUV",
            text: "Tuvalu",
          },
          {
            id: "DEU",
            text: "Tyskland",
          },
          {
            id: "UGA",
            text: "Uganda",
          },
          {
            id: "UKR",
            text: "Ukraina",
          },
          {
            id: "HUN",
            text: "Ungern",
          },
          {
            id: "URY",
            text: "Uruguay",
          },
          {
            id: "USA",
            text: "USA",
          },
          {
            id: "UZB",
            text: "Uzbekistan",
          },
          {
            id: "VUT",
            text: "Vanuatu",
          },
          {
            id: "VAT",
            text: "Vatikanstaten",
          },
          {
            id: "VEN",
            text: "Venezuela (Bolivarianska republiken Venezuela)",
          },
          {
            id: "VNM",
            text: "Vietnam",
          },
          {
            id: "ESH",
            text: "Västsahara",
          },
          {
            id: "WLF",
            text: "Wallis- och Futunaöarna",
          },
          {
            id: "YEM",
            text: "Yemen",
          },
          {
            id: "ZMB",
            text: "Zambia",
          },
          {
            id: "ZWE",
            text: "Zimbabwe",
          },
          {
            id: "AUT",
            text: "Österrike",
          },
          {
            id: "TLS",
            text: "Östtimor (Timor-Leste)",
          },
          {
            id: "ALA",
            text: "Åland",
          },
          {
            id: "UNK",
            text: "Okänd",
          },
        ],
        conditions: [],
        children: undefined,
      },
      {
        id: "TWpjd056a3hNMTh3X19UMk5qZFhCaGRHbHZiZz09",
        type: ComplianceFieldType.DropDownList,
        editable: true,
        answers: [],
        questionText: "Vänligen bekräfta din sysselsättning",
        questionHelpText: undefined,
        options: [
          {
            id: "Permanent_Employment",
            text: "Fastanställning",
          },
          {
            id: "Self_Employed",
            text: "Egen företagare",
          },
          {
            id: "Temporary_Employment",
            text: "Tidsbegränsad anställning/vikariat",
          },
          {
            id: "Pensioner",
            text: "Pensionär",
          },
          {
            id: "Student",
            text: "Student",
          },
          {
            id: "Unemployed",
            text: "Arbetslös",
          },
        ],
        conditions: [],
        children: undefined,
      },
      {
        id: "TWpjd056a3hNMTh3X181ZjZkY2YwMC05ZDQ1LTQ0MDctYjE4NC1kM2M0ZGY4ZjJhZGU=",
        type: ComplianceFieldType.Conditional,
        editable: false,
        answers: undefined,
        questionText: undefined,
        questionHelpText: undefined,
        options: undefined,
        conditions: [
          {
            valueType: ComplianceConditionValueType.Option,
            operator: ComplianceConditionOperator.Equals,
            questionId: "TWpjd056a3hNMTh3X19UMk5qZFhCaGRHbHZiZz09",
            value: "Permanent_Employment",
          },
          {
            valueType: ComplianceConditionValueType.Option,
            operator: ComplianceConditionOperator.Equals,
            questionId: "TWpjd056a3hNMTh3X19UMk5qZFhCaGRHbHZiZz09",
            value: "Temporary_Employment",
          },
        ],
        children: [
          {
            id: "TWpjd056a3hNMTh3X19SVzF3Ykc5NVpYSmZibUZ0WlE9PQ==",
            type: ComplianceFieldType.TextField,
            editable: true,
            answers: [],
            questionText: "Vänligen bekräfta namnet på din arbetsgivare",
            questionHelpText: undefined,
            options: undefined,
            conditions: [],
            children: undefined,
          },
        ],
      },
      {
        id: "TWpjd056a3hNMTh3X181MDc4NDdlOC1jZDFkLTRlNWMtYjkzNi1hYzdkNTQ0OWQ5MDY=",
        type: ComplianceFieldType.Conditional,
        editable: false,
        answers: undefined,
        questionText: undefined,
        questionHelpText: undefined,
        options: undefined,
        conditions: [
          {
            valueType: ComplianceConditionValueType.Option,
            operator: ComplianceConditionOperator.Equals,
            questionId: "TWpjd056a3hNMTh3X19UMk5qZFhCaGRHbHZiZz09",
            value: "Self_Employed",
          },
        ],
        children: [
          {
            id: "TWpjd056a3hNMTh3X19VMlZzWmw5RmJYQnNiM2xsWkY5dVlXMWw=",
            type: ComplianceFieldType.TextField,
            editable: true,
            answers: [],
            questionText: "Vänligen ange namnet på företag du driver",
            questionHelpText: undefined,
            options: undefined,
            conditions: [],
            children: undefined,
          },
        ],
      },
      {
        id: "TWpjd056a3hNMTh3X19UVzl1ZEdoc2VWOU9aWFJmU1c1amIyMWw=",
        type: ComplianceFieldType.DropDownList,
        editable: true,
        answers: [],
        questionText: "Vänligen ange din månatliga nettoinkomst",
        questionHelpText: undefined,
        options: [
          {
            id: "0-20000_SEK",
            text: "0 - 20 000 SEK",
          },
          {
            id: "20001-50000_SEK",
            text: "20 000 - 50 000 SEK",
          },
          {
            id: "50001-100000_SEK",
            text: "50 000 - 100 000 SEK",
          },
          {
            id: "More_Than_100000_SEK",
            text: "Mer än 100 000 SEK",
          },
        ],
        conditions: [],
        children: undefined,
      },
      {
        id: "TWpjd056a3hNMTh3X180YzM2OWEwNi05Y2JiLTRmMzYtYWQxYS02MjA3M2JiNzZiNWI=",
        type: ComplianceFieldType.h3,
        editable: false,
        answers: [],
        questionText: "Person i politiskt utsatt ställning (PEP)",
        questionHelpText: undefined,
        options: undefined,
        conditions: [],
        children: undefined,
      },
      {
        id: "TWpjd056a3hNMTh3X19TWE5mVUVWUVgxSkRRUT09",
        type: ComplianceFieldType.DropDownList,
        editable: true,
        answers: [],
        questionText:
          'Är du, eller har du varit, en person i politiskt utsatt ställning (PEP) eller är du nära familjemedlem eller känd medarbetare till en sådan person? Vänligen klicka på "i"-ikonen för mer information.',
        questionHelpText:
          "Person i politiskt utsatt ställning (PEP) är en fysisk person som har eller har haft en viktig offentlig funktion i en stat eller i en internationell organisation.",
        options: [
          {
            id: "Yes",
            text: "Ja",
          },
          {
            id: "No",
            text: "Nej",
          },
        ],
        conditions: [],
        children: undefined,
      },
      {
        id: "TWpjd056a3hNMTh3X19mYTMzYzBmMC1hNjlmLTRhZmEtYWQ5NS05Zjk2YWFjZjBiNmY=",
        type: ComplianceFieldType.Conditional,
        editable: false,
        answers: undefined,
        questionText: undefined,
        questionHelpText: undefined,
        options: undefined,
        conditions: [
          {
            valueType: ComplianceConditionValueType.Option,
            operator: ComplianceConditionOperator.Equals,
            questionId: "TWpjd056a3hNMTh3X19TWE5mVUVWUVgxSkRRUT09",
            value: "Yes",
          },
        ],
        children: [
          {
            id: "TWpjd056a3hNMTh3X19VRVZRWDFKMWJHVmZNUT09",
            type: ComplianceFieldType.DropDownList,
            editable: true,
            answers: [],
            questionText: "Vänligen bekräfta vem personen i politiskt utsatt ställning är",
            questionHelpText:
              "Med familjemedlem till en person i politiskt utsatt ställning avses:\n1) Make, \n2) Registrerad partner, \n3) Sambo, \n4) Barn och deras makar, registrerade partner eller sambor samt,\n5) Föräldrar. Med känd medarbetare till en person i politiskt utsatt ställning avses: 1. fysisk person som, enligt vad som är känt eller finns anledning att förmoda, gemensamt med en person i politiskt utsatt ställning är verklig huvudman till en juridisk person eller juridisk konstruktion eller som på annat sätt har eller har haft nära förbindelser med en person i politiskt utsatt ställning, och\n2. fysisk person som ensam är verklig huvudman till en juridisk person eller juridisk konstruktion som, enligt vad som är känt eller finns anledning att förmoda, egentligen har upprättats till förmån för en person i politiskt utsatt ställning.\n\nMed nära förbindelser i andra stycket 1 avses nära affärsförbindelser och andra förbindelser som kan medföra att den kända medarbetaren kan förknippas med förhöjd risk för penningtvätt eller finansiering av terrorism.",
            options: [
              {
                id: "Myself",
                text: "Jag själv",
              },
              {
                id: "Family_Member",
                text: "Nära familjemedlem",
              },
              {
                id: "Known_associate",
                text: "Känd medarbetare",
              },
            ],
            conditions: [],
            children: undefined,
          },
          {
            id: "TWpjd056a3hNMTh3X19jNTNmZGFjMy0yYzEyLTQ2ODAtODEwMS0yN2YzOWExZmYxMmM=",
            type: ComplianceFieldType.Conditional,
            editable: false,
            answers: undefined,
            questionText: undefined,
            questionHelpText: undefined,
            options: undefined,
            conditions: [
              {
                valueType: ComplianceConditionValueType.Option,
                operator: ComplianceConditionOperator.Equals,
                questionId: "TWpjd056a3hNMTh3X19VRVZRWDFKMWJHVmZNUT09",
                value: "Myself",
              },
            ],
            children: [
              {
                id: "TWpjd056a3hNMTh3X19VRVZRWDFCdmMybDBhVzl1WDAxNWMyVnNaZz09",
                type: ComplianceFieldType.DropDownList,
                editable: true,
                answers: [],
                questionText: "Vänligen ange positionen som gör dig till PEP",
                questionHelpText: undefined,
                options: [
                  {
                    id: "Head_of_State",
                    text: "Stats- eller regeringschefer, ministrar samt vice och biträdande ministrar",
                  },
                  {
                    id: "Member_of_Parliament",
                    text: "Parlamentsledamöter och ledamöter av liknande lagstiftande organ",
                  },
                  {
                    id: "Member_of_Political_Party",
                    text: "Ledamöter i styrelsen för politiska partier",
                  },
                  {
                    id: "Supreme_Court",
                    text: "Domare i Högsta domstol, konstitutionell domstol eller andra rättsliga organ på hög nivå vilkas beslut endast undantagsvis kan överklagas",
                  },
                  {
                    id: "Member_of_Central_Bank",
                    text: "Högre tjänstemän vid revisionsmyndigheter och ledamöter i centralbankers styrande organ",
                  },
                  {
                    id: "Swedish_Ambassador",
                    text: "Ambassadörer, beskickningschefer samt höga officerare i försvarsmakten",
                  },
                  {
                    id: "Member_of_supervision_body",
                    text: "Personer som ingår i statsägda företags förvaltnings-, lednings- eller kontrollorgan",
                  },
                ],
                conditions: [],
                children: undefined,
              },
              {
                id: "TWpjd056a3hNMTh3X19VRVZRWDJaMWJtUnpYMjE1YzJWc1pnPT0=",
                type: ComplianceFieldType.DropDownListYesNo,
                editable: true,
                answers: [],
                questionText:
                  "Kommer medel från potentiella intäkter/tillgångar som erhållits från rollen som PEP att ingå i denna kundrelation?",
                questionHelpText:
                  "Vi vill förstå ifall de intäkter/tillgångar som erhållits från din position som PEP kommer att användas i affärsförbindelsen med Santander Consumer Bank. Detta för att få en bättre förståelse av medlens ursprung vilket är i enlighet med kraven som framgår i Lag om åtgärder mot penningtvätt och finansiering av terrorism.",
                options: [
                  {
                    id: "True",
                    text: "Ja",
                  },
                  {
                    id: "False",
                    text: "Nej",
                  },
                ],
                conditions: [],
                children: undefined,
              },
              {
                id: "TWpjd056a3hNMTh3X18wODk3MWUzYi0zNjRkLTQ4YTgtYTY3Mi1kZjUyZDE1ODFhNGE=",
                type: ComplianceFieldType.Conditional,
                editable: false,
                answers: undefined,
                questionText: undefined,
                questionHelpText: undefined,
                options: undefined,
                conditions: [
                  {
                    valueType: ComplianceConditionValueType.Bool,
                    operator: ComplianceConditionOperator.Equals,
                    questionId: "TWpjd056a3hNMTh3X19VRVZRWDJaMWJtUnpYMjE1YzJWc1pnPT0=",
                    value: "True",
                  },
                ],
                children: [
                  {
                    id: "TWpjd056a3hNMTh3X19VR3hsWVhObFgzTndaV05wWm5sZk1RPT0=",
                    type: ComplianceFieldType.TextArea,
                    editable: true,
                    answers: [],
                    questionText:
                      "Vänligen detaljera ditt svar nedan. Notera att om du ej utvecklar ditt svar kommer vi i ett senare skede kontakta dig för att inhämta informationen.",
                    questionHelpText: undefined,
                    options: undefined,
                    conditions: [],
                    children: undefined,
                  },
                ],
              },
              {
                id: "TWpjd056a3hNMTh3X184YWE1YWM4OC02MDkyLTRjNDgtOTY0YS1iNDg1MTg2Yzg4ZTg=",
                type: ComplianceFieldType.paragraph,
                editable: false,
                answers: [],
                questionText:
                  'Genom att trycka på "Nästa" bekräftar du härmed att all information du lämnat är korrekt och fullständig. Vänligen notera att du inte kan gå tillbaka när du klickat på "Nästa".',
                questionHelpText: undefined,
                options: undefined,
                conditions: [],
                children: undefined,
              },
            ],
          },
          {
            id: "TWpjd056a3hNMTh3X19kNmZhN2I0OC03ZjU2LTQ0OTEtOGRkNS1hODhhOTBlNmM2OWM=",
            type: ComplianceFieldType.Conditional,
            editable: false,
            answers: undefined,
            questionText: undefined,
            questionHelpText: undefined,
            options: undefined,
            conditions: [
              {
                valueType: ComplianceConditionValueType.Option,
                operator: ComplianceConditionOperator.Equals,
                questionId: "TWpjd056a3hNMTh3X19VRVZRWDFKMWJHVmZNUT09",
                value: "Family_Member",
              },
            ],
            children: [
              {
                id: "TWpjd056a3hNMTh3X19lY2Y2Y2IzOC1kOWIwLTQxOWMtYTUxOS0xZDhmZDg4ZTkyZWM=",
                type: ComplianceFieldType.paragraph,
                editable: false,
                answers: [],
                questionText:
                  "Vi vill förstå relationen mellan dig och din nära relation. Detta för att få en inblick i vilken utsträckning den familjemedlemmen kommer att påverka din kundrelation med Santander. I detta avsnitt kommer vi därför be dig fylla i uppgifter om din relation till den person som har angetts som PEP, förutom att lämna din familjemedlems personuppgifter. ",
                questionHelpText: undefined,
                options: undefined,
                conditions: [],
                children: undefined,
              },
              {
                id: "TWpjd056a3hNMTh3X19VRVZRWDJOc2IzTmxYM0psYkdGMGFYWmxYM0psYkdGMGFXOXVYekU9",
                type: ComplianceFieldType.DropDownList,
                editable: true,
                answers: [],
                questionText: "Vänligen ange relationen mellan dig och din nära familjemedlem",
                questionHelpText: undefined,
                options: [
                  {
                    id: "Parents",
                    text: "Föräldrar",
                  },
                  {
                    id: "Partner",
                    text: "Make, registrerad partner, sambo",
                  },
                  {
                    id: "Children",
                    text: "Barn",
                  },
                  {
                    id: "Children_Partner",
                    text: "Barns makar, registrerade partner eller sambos",
                  },
                ],
                conditions: [],
                children: undefined,
              },
              {
                id: "TWpjd056a3hNMTh3X19VRVZRWDFCdmMybDBhVzl1WDBOc2IzTmxYMUpsYkdGMGFYWmw=",
                type: ComplianceFieldType.DropDownList,
                editable: true,
                answers: [],
                questionText: "Vänligen ange positionen som gör din nära familjemedlem till PEP",
                questionHelpText: undefined,
                options: [
                  {
                    id: "Head_of_State",
                    text: "Stats- eller regeringschefer, ministrar samt vice och biträdande ministrar",
                  },
                  {
                    id: "Member_of_Parliament",
                    text: "Parlamentsledamöter och ledamöter av liknande lagstiftande organ",
                  },
                  {
                    id: "Member_of_Political_Party",
                    text: "Ledamöter i styrelsen för politiska partier",
                  },
                  {
                    id: "Supreme_Court",
                    text: "Domare i Högsta domstol, konstitutionell domstol eller andra rättsliga organ på hög nivå vilkas beslut endast undantagsvis kan överklagas",
                  },
                  {
                    id: "Member_of_Central_Bank",
                    text: "Högre tjänstemän vid revisionsmyndigheter och ledamöter i centralbankers styrande organ",
                  },
                  {
                    id: "Swedish_Ambassador",
                    text: "Ambassadörer, beskickningschefer samt höga officerare i försvarsmakten",
                  },
                  {
                    id: "Member_of_supervision_body",
                    text: "Personer som ingår i statsägda företags förvaltnings-, lednings- eller kontrollorgan",
                  },
                ],
                conditions: [],
                children: undefined,
              },
              {
                id: "TWpjd056a3hNMTh3X19RMjkxYm5SeWVWOWtaV1pwYm1Wa1gxQkZVRjlPUmc9PQ==",
                type: ComplianceFieldType.DropDownList,
                editable: true,
                answers: [],
                questionText: "Vänligen bekräfta i vilket land din nära familjemedlem definieras som PEP",
                questionHelpText: undefined,
                options: [
                  {
                    id: "SWE",
                    text: "Sverige",
                  },
                  {
                    id: "AFG",
                    text: "Afghanistan",
                  },
                  {
                    id: "ALB",
                    text: "Albanien",
                  },
                  {
                    id: "DZA",
                    text: "Algeriet",
                  },
                  {
                    id: "VIR",
                    text: "Amerikanska Jungfruöarna",
                  },
                  {
                    id: "ASM",
                    text: "Amerikanska Samoa",
                  },
                  {
                    id: "AND",
                    text: "Andorra",
                  },
                  {
                    id: "AGO",
                    text: "Angola",
                  },
                  {
                    id: "AIA",
                    text: "Anguilla",
                  },
                  {
                    id: "ATA",
                    text: "Antarktis",
                  },
                  {
                    id: "ATG",
                    text: "Antigua och Barbuda",
                  },
                  {
                    id: "ARG",
                    text: "Argentina",
                  },
                  {
                    id: "ARM",
                    text: "Armenien",
                  },
                  {
                    id: "ABW",
                    text: "Aruba",
                  },
                  {
                    id: "AUS",
                    text: "Australien",
                  },
                  {
                    id: "AZE",
                    text: "Azerbajdzjan",
                  },
                  {
                    id: "BHS",
                    text: "Bahamas",
                  },
                  {
                    id: "BHR",
                    text: "Bahrain",
                  },
                  {
                    id: "BGD",
                    text: "Bangladesh",
                  },
                  {
                    id: "BRB",
                    text: "Barbados",
                  },
                  {
                    id: "BLR",
                    text: "Belarus",
                  },
                  {
                    id: "BEL",
                    text: "Belgien",
                  },
                  {
                    id: "BLZ",
                    text: "Belize",
                  },
                  {
                    id: "BEN",
                    text: "Benin",
                  },
                  {
                    id: "BMU",
                    text: "Bermuda",
                  },
                  {
                    id: "BTN",
                    text: "Bhutan",
                  },
                  {
                    id: "BOL",
                    text: "Bolivia",
                  },
                  {
                    id: "BES",
                    text: "Bonaire, Sint Eustatius och Saba",
                  },
                  {
                    id: "BIH",
                    text: "Bosnien och Hercegovina",
                  },
                  {
                    id: "BWA",
                    text: "Botswana",
                  },
                  {
                    id: "BVT",
                    text: "Bouvetön",
                  },
                  {
                    id: "BRA",
                    text: "Brasilien",
                  },
                  {
                    id: "IOT",
                    text: "Brittiska territoriet i Indiska oceanen",
                  },
                  {
                    id: "BRN",
                    text: "Brunei Darussalam",
                  },
                  {
                    id: "BGR",
                    text: "Bulgarien",
                  },
                  {
                    id: "BFA",
                    text: "Burkina Faso",
                  },
                  {
                    id: "BDI",
                    text: "Burundi",
                  },
                  {
                    id: "CYM",
                    text: "Caymanöarna",
                  },
                  {
                    id: "CAF",
                    text: "Centralafrikanska Republiken (CAR)",
                  },
                  {
                    id: "CHL",
                    text: "Chile",
                  },
                  {
                    id: "COL",
                    text: "Colombia",
                  },
                  {
                    id: "COK",
                    text: "Cooköarna",
                  },
                  {
                    id: "CRI",
                    text: "Costa Rica",
                  },
                  {
                    id: "CUW",
                    text: "Curaçao",
                  },
                  {
                    id: "CYP",
                    text: "Cypern",
                  },
                  {
                    id: "DNK",
                    text: "Danmark",
                  },
                  {
                    id: "COD",
                    text: "Demokratiska republiken Kongo",
                  },
                  {
                    id: "DJI",
                    text: "Djibouti",
                  },
                  {
                    id: "DMA",
                    text: "Dominica",
                  },
                  {
                    id: "DOM",
                    text: "Dominikanska republiken",
                  },
                  {
                    id: "ECU",
                    text: "Ecuador",
                  },
                  {
                    id: "EGY",
                    text: "Egypten",
                  },
                  {
                    id: "GNQ",
                    text: "Ekvatorialguinea",
                  },
                  {
                    id: "CIV",
                    text: "Elfenbenskusten",
                  },
                  {
                    id: "SLV",
                    text: "El Salvador",
                  },
                  {
                    id: "ERI",
                    text: "Eritrea",
                  },
                  {
                    id: "EST",
                    text: "Estland",
                  },
                  {
                    id: "ETH",
                    text: "Etiopien",
                  },
                  {
                    id: "FLK",
                    text: "Falklandsöarna (Malvinerna)",
                  },
                  {
                    id: "FJI",
                    text: "Fiji",
                  },
                  {
                    id: "PHL",
                    text: "Filippinerna",
                  },
                  {
                    id: "FIN",
                    text: "Finland",
                  },
                  {
                    id: "FRA",
                    text: "Frankrike",
                  },
                  {
                    id: "GUF",
                    text: "Franska Guyana",
                  },
                  {
                    id: "PYF",
                    text: "Franska Polynesien",
                  },
                  {
                    id: "ATF",
                    text: "Franska sydterritorierna (Franska syd- och Antarktisterritorierna)",
                  },
                  {
                    id: "FRO",
                    text: "Färöarna",
                  },
                  {
                    id: "ARE",
                    text: "Förenade Arabemiraten",
                  },
                  {
                    id: "UMI",
                    text: "Förenta staternas mindre öar i Oceanien och Västindien",
                  },
                  {
                    id: "GAB",
                    text: "Gabon",
                  },
                  {
                    id: "GMB",
                    text: "Gambia",
                  },
                  {
                    id: "GEO",
                    text: "Georgien",
                  },
                  {
                    id: "GHA",
                    text: "Ghana",
                  },
                  {
                    id: "GIB",
                    text: "Gibraltar",
                  },
                  {
                    id: "GRC",
                    text: "Grekland",
                  },
                  {
                    id: "GRD",
                    text: "Grenada",
                  },
                  {
                    id: "GRL",
                    text: "Grönland",
                  },
                  {
                    id: "GLP",
                    text: "Guadeloupe",
                  },
                  {
                    id: "GUM",
                    text: "Guam",
                  },
                  {
                    id: "GTM",
                    text: "Guatemala",
                  },
                  {
                    id: "GGY",
                    text: "Guernsey",
                  },
                  {
                    id: "GIN",
                    text: "Guinea",
                  },
                  {
                    id: "GNB",
                    text: "Guinea-Bissau",
                  },
                  {
                    id: "GUY",
                    text: "Guyana",
                  },
                  {
                    id: "HTI",
                    text: "Haiti",
                  },
                  {
                    id: "HMD",
                    text: "Heard- och McDonaldöarna",
                  },
                  {
                    id: "HND",
                    text: "Honduras",
                  },
                  {
                    id: "HKG",
                    text: "Hong Kong",
                  },
                  {
                    id: "IND",
                    text: "Indien",
                  },
                  {
                    id: "IDN",
                    text: "Indonesien",
                  },
                  {
                    id: "ISL",
                    text: "Island",
                  },
                  {
                    id: "IRQ",
                    text: "Irak",
                  },
                  {
                    id: "IRN",
                    text: "Iran (Islamiska republiken Iran)",
                  },
                  {
                    id: "IRL",
                    text: "Irland",
                  },
                  {
                    id: "IMN",
                    text: "Isle of Man",
                  },
                  {
                    id: "ISR",
                    text: "Israel",
                  },
                  {
                    id: "ITA",
                    text: "Italien",
                  },
                  {
                    id: "JAM",
                    text: "Jamaica",
                  },
                  {
                    id: "JPN",
                    text: "Japan",
                  },
                  {
                    id: "JOR",
                    text: "Jordanien",
                  },
                  {
                    id: "CXR",
                    text: "Julön",
                  },
                  {
                    id: "VGB",
                    text: "Jungfruöarna (Storbritannien)",
                  },
                  {
                    id: "CMR",
                    text: "Kamerun",
                  },
                  {
                    id: "KHM",
                    text: "Kambodja",
                  },
                  {
                    id: "CAN",
                    text: "Kanada",
                  },
                  {
                    id: "JEY",
                    text: "Kanalöarna, Jersey",
                  },
                  {
                    id: "XAA",
                    text: "Kanarieöarna",
                  },
                  {
                    id: "CPV",
                    text: "Kap Verde",
                  },
                  {
                    id: "KAZ",
                    text: "Kazakstan",
                  },
                  {
                    id: "KEN",
                    text: "Kenya",
                  },
                  {
                    id: "CHN",
                    text: "Kina",
                  },
                  {
                    id: "KGZ",
                    text: "Kirgizistan",
                  },
                  {
                    id: "KIR",
                    text: "Kiribati",
                  },
                  {
                    id: "CCK",
                    text: "Kokosöarna",
                  },
                  {
                    id: "COM",
                    text: "Komorerna",
                  },
                  {
                    id: "COG",
                    text: "Kongo",
                  },
                  {
                    id: "XKX",
                    text: "Kosovo",
                  },
                  {
                    id: "HRV",
                    text: "Kroatien",
                  },
                  {
                    id: "CUB",
                    text: "Kuba",
                  },
                  {
                    id: "KWT",
                    text: "Kuwait",
                  },
                  {
                    id: "LAO",
                    text: "Laos",
                  },
                  {
                    id: "LVA",
                    text: "Lettland",
                  },
                  {
                    id: "LSO",
                    text: "Lesotho",
                  },
                  {
                    id: "LBN",
                    text: "Libanon",
                  },
                  {
                    id: "LBR",
                    text: "Liberia",
                  },
                  {
                    id: "LBY",
                    text: "Libyen",
                  },
                  {
                    id: "LIE",
                    text: "Liechtenstein",
                  },
                  {
                    id: "LTU",
                    text: "Litauen",
                  },
                  {
                    id: "LUX",
                    text: "Luxemburg",
                  },
                  {
                    id: "MAC",
                    text: "Macao",
                  },
                  {
                    id: "MDG",
                    text: "Madagaskar",
                  },
                  {
                    id: "MKD",
                    text: "Makedonien (Republiken Makedonien)",
                  },
                  {
                    id: "MWI",
                    text: "Malawi",
                  },
                  {
                    id: "MYS",
                    text: "Malaysia",
                  },
                  {
                    id: "MDV",
                    text: "Maldiverna",
                  },
                  {
                    id: "MLI",
                    text: "Mali",
                  },
                  {
                    id: "MLT",
                    text: "Malta",
                  },
                  {
                    id: "MAR",
                    text: "Marocko",
                  },
                  {
                    id: "MHL",
                    text: "Marshallöarna",
                  },
                  {
                    id: "MTQ",
                    text: "Martinique",
                  },
                  {
                    id: "MRT",
                    text: "Mauretanien",
                  },
                  {
                    id: "MUS",
                    text: "Mauritius",
                  },
                  {
                    id: "MYT",
                    text: "Mayotte",
                  },
                  {
                    id: "MEX",
                    text: "Mexiko",
                  },
                  {
                    id: "FSM",
                    text: "Mikronesiska federationen",
                  },
                  {
                    id: "MDA",
                    text: "Moldavien",
                  },
                  {
                    id: "MCO",
                    text: "Monaco",
                  },
                  {
                    id: "MNG",
                    text: "Mongoliet",
                  },
                  {
                    id: "MNE",
                    text: "Montenegro",
                  },
                  {
                    id: "MSR",
                    text: "Montserrat",
                  },
                  {
                    id: "MOZ",
                    text: "Moçambique",
                  },
                  {
                    id: "MMR",
                    text: "Myanmar",
                  },
                  {
                    id: "NAM",
                    text: "Namibia",
                  },
                  {
                    id: "NRU",
                    text: "Nauru",
                  },
                  {
                    id: "NLD",
                    text: "Nederländerna",
                  },
                  {
                    id: "ANT",
                    text: "Nederländska Antillerna",
                  },
                  {
                    id: "NPL",
                    text: "Nepal",
                  },
                  {
                    id: "NIC",
                    text: "Nicaragua",
                  },
                  {
                    id: "NER",
                    text: "Niger",
                  },
                  {
                    id: "NGA",
                    text: "Nigeria",
                  },
                  {
                    id: "NIU",
                    text: "Niue",
                  },
                  {
                    id: "PRK",
                    text: "Nordkorea (Demokratiska Folkrepubliken Korea)",
                  },
                  {
                    id: "MNP",
                    text: "Nordmarianerna",
                  },
                  {
                    id: "NFK",
                    text: "Norfolkön",
                  },
                  {
                    id: "NOR",
                    text: "Norge",
                  },
                  {
                    id: "NCL",
                    text: "Nya Kaledonien",
                  },
                  {
                    id: "NZL",
                    text: "Nya Zeeland",
                  },
                  {
                    id: "OMN",
                    text: "Oman",
                  },
                  {
                    id: "PAK",
                    text: "Pakistan",
                  },
                  {
                    id: "PLW",
                    text: "Palau",
                  },
                  {
                    id: "PSE",
                    text: "Palestina",
                  },
                  {
                    id: "PAN",
                    text: "Panama",
                  },
                  {
                    id: "PNG",
                    text: "Papua Nya Guinea",
                  },
                  {
                    id: "PRY",
                    text: "Paraguay",
                  },
                  {
                    id: "PER",
                    text: "Peru",
                  },
                  {
                    id: "PCN",
                    text: "Pitcairnöarna",
                  },
                  {
                    id: "POL",
                    text: "Polen",
                  },
                  {
                    id: "PRT",
                    text: "Portugal",
                  },
                  {
                    id: "PRI",
                    text: "Puerto Rico",
                  },
                  {
                    id: "QAT",
                    text: "Qatar",
                  },
                  {
                    id: "REU",
                    text: "Réunion",
                  },
                  {
                    id: "ROU",
                    text: "Rumänien",
                  },
                  {
                    id: "RUS",
                    text: "Ryssland",
                  },
                  {
                    id: "RWA",
                    text: "Rwanda",
                  },
                  {
                    id: "BLM",
                    text: "Saint-Barthélemy",
                  },
                  {
                    id: "KNA",
                    text: "Saint Kitts och Nevis",
                  },
                  {
                    id: "LCA",
                    text: "Saint Lucia",
                  },
                  {
                    id: "MAF",
                    text: "Saint Martin",
                  },
                  {
                    id: "SPM",
                    text: "Saint-Pierre och Miquelon",
                  },
                  {
                    id: "VCT",
                    text: "Saint Vincent och Grenadinerna",
                  },
                  {
                    id: "SLB",
                    text: "Salomonöarna",
                  },
                  {
                    id: "WSM",
                    text: "Samoa",
                  },
                  {
                    id: "SHN",
                    text: "Sankta Helena, Ascension och Tristan da Cunha",
                  },
                  {
                    id: "SMR",
                    text: "San Marino",
                  },
                  {
                    id: "SAU",
                    text: "Saudiarabien",
                  },
                  {
                    id: "SEN",
                    text: "Senegal",
                  },
                  {
                    id: "SRB",
                    text: "Serbien",
                  },
                  {
                    id: "SYC",
                    text: "Seychellerna",
                  },
                  {
                    id: "CHE",
                    text: "Schweiz",
                  },
                  {
                    id: "SLE",
                    text: "Sierra Leone",
                  },
                  {
                    id: "SGP",
                    text: "Singapore",
                  },
                  {
                    id: "SVK",
                    text: "Slovakien",
                  },
                  {
                    id: "SVN",
                    text: "Slovenien",
                  },
                  {
                    id: "SOM",
                    text: "Somalia",
                  },
                  {
                    id: "ESP",
                    text: "Spanien",
                  },
                  {
                    id: "LKA",
                    text: "Sri Lanka",
                  },
                  {
                    id: "GBR",
                    text: "Storbritannien",
                  },
                  {
                    id: "SDN",
                    text: "Sudan",
                  },
                  {
                    id: "SUR",
                    text: "Surinam",
                  },
                  {
                    id: "SJM",
                    text: "Svalbard och Jan Mayen",
                  },
                  {
                    id: "SWZ",
                    text: "Swaziland",
                  },
                  {
                    id: "ZAF",
                    text: "Sydafrika",
                  },
                  {
                    id: "SGS",
                    text: "Sydgeorgien och Sydsandwichöarna",
                  },
                  {
                    id: "KOR",
                    text: "Sydkorea (Republiken Korea)",
                  },
                  {
                    id: "SSD",
                    text: "Sydsudan",
                  },
                  {
                    id: "SYR",
                    text: "Syrien",
                  },
                  {
                    id: "STP",
                    text: "São Tomé och Príncipe",
                  },
                  {
                    id: "TWN",
                    text: "Taiwan (Republiken Kina)",
                  },
                  {
                    id: "TJK",
                    text: "Tadzjikistan",
                  },
                  {
                    id: "TZA",
                    text: "Tanzania",
                  },
                  {
                    id: "TCD",
                    text: "Tchad",
                  },
                  {
                    id: "CZE",
                    text: "Tjeckien",
                  },
                  {
                    id: "THA",
                    text: "Thailand",
                  },
                  {
                    id: "TGO",
                    text: "Togo",
                  },
                  {
                    id: "TKL",
                    text: "Tokelau",
                  },
                  {
                    id: "TON",
                    text: "Tonga",
                  },
                  {
                    id: "TTO",
                    text: "Trinidad och Tobago",
                  },
                  {
                    id: "TUN",
                    text: "Tunisien",
                  },
                  {
                    id: "TUR",
                    text: "Turkiet",
                  },
                  {
                    id: "TKM",
                    text: "Turkmenistan",
                  },
                  {
                    id: "TCA",
                    text: "Turks- och Caicosöarna",
                  },
                  {
                    id: "TUV",
                    text: "Tuvalu",
                  },
                  {
                    id: "DEU",
                    text: "Tyskland",
                  },
                  {
                    id: "UGA",
                    text: "Uganda",
                  },
                  {
                    id: "UKR",
                    text: "Ukraina",
                  },
                  {
                    id: "HUN",
                    text: "Ungern",
                  },
                  {
                    id: "URY",
                    text: "Uruguay",
                  },
                  {
                    id: "USA",
                    text: "USA",
                  },
                  {
                    id: "UZB",
                    text: "Uzbekistan",
                  },
                  {
                    id: "VUT",
                    text: "Vanuatu",
                  },
                  {
                    id: "VAT",
                    text: "Vatikanstaten",
                  },
                  {
                    id: "VEN",
                    text: "Venezuela (Bolivarianska republiken Venezuela)",
                  },
                  {
                    id: "VNM",
                    text: "Vietnam",
                  },
                  {
                    id: "ESH",
                    text: "Västsahara",
                  },
                  {
                    id: "WLF",
                    text: "Wallis- och Futunaöarna",
                  },
                  {
                    id: "YEM",
                    text: "Yemen",
                  },
                  {
                    id: "ZMB",
                    text: "Zambia",
                  },
                  {
                    id: "ZWE",
                    text: "Zimbabwe",
                  },
                  {
                    id: "AUT",
                    text: "Österrike",
                  },
                  {
                    id: "TLS",
                    text: "Östtimor (Timor-Leste)",
                  },
                  {
                    id: "ALA",
                    text: "Åland",
                  },
                  {
                    id: "UNK",
                    text: "Okänd",
                  },
                ],
                conditions: [],
                children: undefined,
              },
              {
                id: "TWpjd056a3hNMTh3X18zNDhlNDRmNS05ZmU2LTRmMGQtODg3Ni0yOTI0YTZjN2EzMzU=",
                type: ComplianceFieldType.h5,
                editable: false,
                answers: [],
                questionText: "Vänligen fyll i din nära familjemedlems personuppgifter",
                questionHelpText: undefined,
                options: undefined,
                conditions: [],
                children: undefined,
              },
              {
                id: "TWpjd056a3hNMTh3X19SbWx5YzNSZmJtRnRaVjlTUTBFPQ==",
                type: ComplianceFieldType.TextField,
                editable: true,
                answers: [],
                questionText: "Förnamn",
                questionHelpText: undefined,
                options: undefined,
                conditions: [],
                children: undefined,
              },
              {
                id: "TWpjd056a3hNMTh3X19UR0Z6ZEY5dVlXMWxYMUpEUVE9PQ==",
                type: ComplianceFieldType.TextField,
                editable: true,
                answers: [],
                questionText: "Efternamn",
                questionHelpText: undefined,
                options: undefined,
                conditions: [],
                children: undefined,
              },
              {
                id: "TWpjd056a3hNMTh3X19VMU5PWDBSUFFnPT0=",
                type: ComplianceFieldType.TextField,
                editable: true,
                answers: [],
                questionText: "Födelsedatum",
                questionHelpText: undefined,
                options: undefined,
                conditions: [],
                children: undefined,
              },
              {
                id: "TWpjd056a3hNMTh3X19RV1JrY21WemMxOVNRMEU9",
                type: ComplianceFieldType.TextField,
                editable: true,
                answers: [],
                questionText: "Adress",
                questionHelpText: undefined,
                options: undefined,
                conditions: [],
                children: undefined,
              },
              {
                id: "TWpjd056a3hNMTh3X19Xa2xRWDFKRFFRPT0=",
                type: ComplianceFieldType.TextField,
                editable: true,
                answers: [],
                questionText: "Postnummer",
                questionHelpText: undefined,
                options: undefined,
                conditions: [],
                children: undefined,
              },
              {
                id: "TWpjd056a3hNMTh3X19RMmwwZVY5U1EwRT0=",
                type: ComplianceFieldType.TextField,
                editable: true,
                answers: [],
                questionText: "Postort",
                questionHelpText: undefined,
                options: undefined,
                conditions: [],
                children: undefined,
              },
              {
                id: "TWpjd056a3hNMTh3X19VSEpwYldGeWVWOWhaR1J5WlhOelgxSkRRUT09",
                type: ComplianceFieldType.DropDownList,
                editable: true,
                answers: [],
                questionText: "Bosättningsland",
                questionHelpText: undefined,
                options: [
                  {
                    id: "SWE",
                    text: "Sverige",
                  },
                  {
                    id: "AFG",
                    text: "Afghanistan",
                  },
                  {
                    id: "ALB",
                    text: "Albanien",
                  },
                  {
                    id: "DZA",
                    text: "Algeriet",
                  },
                  {
                    id: "VIR",
                    text: "Amerikanska Jungfruöarna",
                  },
                  {
                    id: "ASM",
                    text: "Amerikanska Samoa",
                  },
                  {
                    id: "AND",
                    text: "Andorra",
                  },
                  {
                    id: "AGO",
                    text: "Angola",
                  },
                  {
                    id: "AIA",
                    text: "Anguilla",
                  },
                  {
                    id: "ATA",
                    text: "Antarktis",
                  },
                  {
                    id: "ATG",
                    text: "Antigua och Barbuda",
                  },
                  {
                    id: "ARG",
                    text: "Argentina",
                  },
                  {
                    id: "ARM",
                    text: "Armenien",
                  },
                  {
                    id: "ABW",
                    text: "Aruba",
                  },
                  {
                    id: "AUS",
                    text: "Australien",
                  },
                  {
                    id: "AZE",
                    text: "Azerbajdzjan",
                  },
                  {
                    id: "BHS",
                    text: "Bahamas",
                  },
                  {
                    id: "BHR",
                    text: "Bahrain",
                  },
                  {
                    id: "BGD",
                    text: "Bangladesh",
                  },
                  {
                    id: "BRB",
                    text: "Barbados",
                  },
                  {
                    id: "BLR",
                    text: "Belarus",
                  },
                  {
                    id: "BEL",
                    text: "Belgien",
                  },
                  {
                    id: "BLZ",
                    text: "Belize",
                  },
                  {
                    id: "BEN",
                    text: "Benin",
                  },
                  {
                    id: "BMU",
                    text: "Bermuda",
                  },
                  {
                    id: "BTN",
                    text: "Bhutan",
                  },
                  {
                    id: "BOL",
                    text: "Bolivia",
                  },
                  {
                    id: "BES",
                    text: "Bonaire, Sint Eustatius och Saba",
                  },
                  {
                    id: "BIH",
                    text: "Bosnien och Hercegovina",
                  },
                  {
                    id: "BWA",
                    text: "Botswana",
                  },
                  {
                    id: "BVT",
                    text: "Bouvetön",
                  },
                  {
                    id: "BRA",
                    text: "Brasilien",
                  },
                  {
                    id: "IOT",
                    text: "Brittiska territoriet i Indiska oceanen",
                  },
                  {
                    id: "BRN",
                    text: "Brunei Darussalam",
                  },
                  {
                    id: "BGR",
                    text: "Bulgarien",
                  },
                  {
                    id: "BFA",
                    text: "Burkina Faso",
                  },
                  {
                    id: "BDI",
                    text: "Burundi",
                  },
                  {
                    id: "CYM",
                    text: "Caymanöarna",
                  },
                  {
                    id: "CAF",
                    text: "Centralafrikanska Republiken (CAR)",
                  },
                  {
                    id: "CHL",
                    text: "Chile",
                  },
                  {
                    id: "COL",
                    text: "Colombia",
                  },
                  {
                    id: "COK",
                    text: "Cooköarna",
                  },
                  {
                    id: "CRI",
                    text: "Costa Rica",
                  },
                  {
                    id: "CUW",
                    text: "Curaçao",
                  },
                  {
                    id: "CYP",
                    text: "Cypern",
                  },
                  {
                    id: "DNK",
                    text: "Danmark",
                  },
                  {
                    id: "COD",
                    text: "Demokratiska republiken Kongo",
                  },
                  {
                    id: "DJI",
                    text: "Djibouti",
                  },
                  {
                    id: "DMA",
                    text: "Dominica",
                  },
                  {
                    id: "DOM",
                    text: "Dominikanska republiken",
                  },
                  {
                    id: "ECU",
                    text: "Ecuador",
                  },
                  {
                    id: "EGY",
                    text: "Egypten",
                  },
                  {
                    id: "GNQ",
                    text: "Ekvatorialguinea",
                  },
                  {
                    id: "CIV",
                    text: "Elfenbenskusten",
                  },
                  {
                    id: "SLV",
                    text: "El Salvador",
                  },
                  {
                    id: "ERI",
                    text: "Eritrea",
                  },
                  {
                    id: "EST",
                    text: "Estland",
                  },
                  {
                    id: "ETH",
                    text: "Etiopien",
                  },
                  {
                    id: "FLK",
                    text: "Falklandsöarna (Malvinerna)",
                  },
                  {
                    id: "FJI",
                    text: "Fiji",
                  },
                  {
                    id: "PHL",
                    text: "Filippinerna",
                  },
                  {
                    id: "FIN",
                    text: "Finland",
                  },
                  {
                    id: "FRA",
                    text: "Frankrike",
                  },
                  {
                    id: "GUF",
                    text: "Franska Guyana",
                  },
                  {
                    id: "PYF",
                    text: "Franska Polynesien",
                  },
                  {
                    id: "ATF",
                    text: "Franska sydterritorierna (Franska syd- och Antarktisterritorierna)",
                  },
                  {
                    id: "FRO",
                    text: "Färöarna",
                  },
                  {
                    id: "ARE",
                    text: "Förenade Arabemiraten",
                  },
                  {
                    id: "UMI",
                    text: "Förenta staternas mindre öar i Oceanien och Västindien",
                  },
                  {
                    id: "GAB",
                    text: "Gabon",
                  },
                  {
                    id: "GMB",
                    text: "Gambia",
                  },
                  {
                    id: "GEO",
                    text: "Georgien",
                  },
                  {
                    id: "GHA",
                    text: "Ghana",
                  },
                  {
                    id: "GIB",
                    text: "Gibraltar",
                  },
                  {
                    id: "GRC",
                    text: "Grekland",
                  },
                  {
                    id: "GRD",
                    text: "Grenada",
                  },
                  {
                    id: "GRL",
                    text: "Grönland",
                  },
                  {
                    id: "GLP",
                    text: "Guadeloupe",
                  },
                  {
                    id: "GUM",
                    text: "Guam",
                  },
                  {
                    id: "GTM",
                    text: "Guatemala",
                  },
                  {
                    id: "GGY",
                    text: "Guernsey",
                  },
                  {
                    id: "GIN",
                    text: "Guinea",
                  },
                  {
                    id: "GNB",
                    text: "Guinea-Bissau",
                  },
                  {
                    id: "GUY",
                    text: "Guyana",
                  },
                  {
                    id: "HTI",
                    text: "Haiti",
                  },
                  {
                    id: "HMD",
                    text: "Heard- och McDonaldöarna",
                  },
                  {
                    id: "HND",
                    text: "Honduras",
                  },
                  {
                    id: "HKG",
                    text: "Hong Kong",
                  },
                  {
                    id: "IND",
                    text: "Indien",
                  },
                  {
                    id: "IDN",
                    text: "Indonesien",
                  },
                  {
                    id: "ISL",
                    text: "Island",
                  },
                  {
                    id: "IRQ",
                    text: "Irak",
                  },
                  {
                    id: "IRN",
                    text: "Iran (Islamiska republiken Iran)",
                  },
                  {
                    id: "IRL",
                    text: "Irland",
                  },
                  {
                    id: "IMN",
                    text: "Isle of Man",
                  },
                  {
                    id: "ISR",
                    text: "Israel",
                  },
                  {
                    id: "ITA",
                    text: "Italien",
                  },
                  {
                    id: "JAM",
                    text: "Jamaica",
                  },
                  {
                    id: "JPN",
                    text: "Japan",
                  },
                  {
                    id: "JOR",
                    text: "Jordanien",
                  },
                  {
                    id: "CXR",
                    text: "Julön",
                  },
                  {
                    id: "VGB",
                    text: "Jungfruöarna (Storbritannien)",
                  },
                  {
                    id: "CMR",
                    text: "Kamerun",
                  },
                  {
                    id: "KHM",
                    text: "Kambodja",
                  },
                  {
                    id: "CAN",
                    text: "Kanada",
                  },
                  {
                    id: "JEY",
                    text: "Kanalöarna, Jersey",
                  },
                  {
                    id: "XAA",
                    text: "Kanarieöarna",
                  },
                  {
                    id: "CPV",
                    text: "Kap Verde",
                  },
                  {
                    id: "KAZ",
                    text: "Kazakstan",
                  },
                  {
                    id: "KEN",
                    text: "Kenya",
                  },
                  {
                    id: "CHN",
                    text: "Kina",
                  },
                  {
                    id: "KGZ",
                    text: "Kirgizistan",
                  },
                  {
                    id: "KIR",
                    text: "Kiribati",
                  },
                  {
                    id: "CCK",
                    text: "Kokosöarna",
                  },
                  {
                    id: "COM",
                    text: "Komorerna",
                  },
                  {
                    id: "COG",
                    text: "Kongo",
                  },
                  {
                    id: "XKX",
                    text: "Kosovo",
                  },
                  {
                    id: "HRV",
                    text: "Kroatien",
                  },
                  {
                    id: "CUB",
                    text: "Kuba",
                  },
                  {
                    id: "KWT",
                    text: "Kuwait",
                  },
                  {
                    id: "LAO",
                    text: "Laos",
                  },
                  {
                    id: "LVA",
                    text: "Lettland",
                  },
                  {
                    id: "LSO",
                    text: "Lesotho",
                  },
                  {
                    id: "LBN",
                    text: "Libanon",
                  },
                  {
                    id: "LBR",
                    text: "Liberia",
                  },
                  {
                    id: "LBY",
                    text: "Libyen",
                  },
                  {
                    id: "LIE",
                    text: "Liechtenstein",
                  },
                  {
                    id: "LTU",
                    text: "Litauen",
                  },
                  {
                    id: "LUX",
                    text: "Luxemburg",
                  },
                  {
                    id: "MAC",
                    text: "Macao",
                  },
                  {
                    id: "MDG",
                    text: "Madagaskar",
                  },
                  {
                    id: "MKD",
                    text: "Makedonien (Republiken Makedonien)",
                  },
                  {
                    id: "MWI",
                    text: "Malawi",
                  },
                  {
                    id: "MYS",
                    text: "Malaysia",
                  },
                  {
                    id: "MDV",
                    text: "Maldiverna",
                  },
                  {
                    id: "MLI",
                    text: "Mali",
                  },
                  {
                    id: "MLT",
                    text: "Malta",
                  },
                  {
                    id: "MAR",
                    text: "Marocko",
                  },
                  {
                    id: "MHL",
                    text: "Marshallöarna",
                  },
                  {
                    id: "MTQ",
                    text: "Martinique",
                  },
                  {
                    id: "MRT",
                    text: "Mauretanien",
                  },
                  {
                    id: "MUS",
                    text: "Mauritius",
                  },
                  {
                    id: "MYT",
                    text: "Mayotte",
                  },
                  {
                    id: "MEX",
                    text: "Mexiko",
                  },
                  {
                    id: "FSM",
                    text: "Mikronesiska federationen",
                  },
                  {
                    id: "MDA",
                    text: "Moldavien",
                  },
                  {
                    id: "MCO",
                    text: "Monaco",
                  },
                  {
                    id: "MNG",
                    text: "Mongoliet",
                  },
                  {
                    id: "MNE",
                    text: "Montenegro",
                  },
                  {
                    id: "MSR",
                    text: "Montserrat",
                  },
                  {
                    id: "MOZ",
                    text: "Moçambique",
                  },
                  {
                    id: "MMR",
                    text: "Myanmar",
                  },
                  {
                    id: "NAM",
                    text: "Namibia",
                  },
                  {
                    id: "NRU",
                    text: "Nauru",
                  },
                  {
                    id: "NLD",
                    text: "Nederländerna",
                  },
                  {
                    id: "ANT",
                    text: "Nederländska Antillerna",
                  },
                  {
                    id: "NPL",
                    text: "Nepal",
                  },
                  {
                    id: "NIC",
                    text: "Nicaragua",
                  },
                  {
                    id: "NER",
                    text: "Niger",
                  },
                  {
                    id: "NGA",
                    text: "Nigeria",
                  },
                  {
                    id: "NIU",
                    text: "Niue",
                  },
                  {
                    id: "PRK",
                    text: "Nordkorea (Demokratiska Folkrepubliken Korea)",
                  },
                  {
                    id: "MNP",
                    text: "Nordmarianerna",
                  },
                  {
                    id: "NFK",
                    text: "Norfolkön",
                  },
                  {
                    id: "NOR",
                    text: "Norge",
                  },
                  {
                    id: "NCL",
                    text: "Nya Kaledonien",
                  },
                  {
                    id: "NZL",
                    text: "Nya Zeeland",
                  },
                  {
                    id: "OMN",
                    text: "Oman",
                  },
                  {
                    id: "PAK",
                    text: "Pakistan",
                  },
                  {
                    id: "PLW",
                    text: "Palau",
                  },
                  {
                    id: "PSE",
                    text: "Palestina",
                  },
                  {
                    id: "PAN",
                    text: "Panama",
                  },
                  {
                    id: "PNG",
                    text: "Papua Nya Guinea",
                  },
                  {
                    id: "PRY",
                    text: "Paraguay",
                  },
                  {
                    id: "PER",
                    text: "Peru",
                  },
                  {
                    id: "PCN",
                    text: "Pitcairnöarna",
                  },
                  {
                    id: "POL",
                    text: "Polen",
                  },
                  {
                    id: "PRT",
                    text: "Portugal",
                  },
                  {
                    id: "PRI",
                    text: "Puerto Rico",
                  },
                  {
                    id: "QAT",
                    text: "Qatar",
                  },
                  {
                    id: "REU",
                    text: "Réunion",
                  },
                  {
                    id: "ROU",
                    text: "Rumänien",
                  },
                  {
                    id: "RUS",
                    text: "Ryssland",
                  },
                  {
                    id: "RWA",
                    text: "Rwanda",
                  },
                  {
                    id: "BLM",
                    text: "Saint-Barthélemy",
                  },
                  {
                    id: "KNA",
                    text: "Saint Kitts och Nevis",
                  },
                  {
                    id: "LCA",
                    text: "Saint Lucia",
                  },
                  {
                    id: "MAF",
                    text: "Saint Martin",
                  },
                  {
                    id: "SPM",
                    text: "Saint-Pierre och Miquelon",
                  },
                  {
                    id: "VCT",
                    text: "Saint Vincent och Grenadinerna",
                  },
                  {
                    id: "SLB",
                    text: "Salomonöarna",
                  },
                  {
                    id: "WSM",
                    text: "Samoa",
                  },
                  {
                    id: "SHN",
                    text: "Sankta Helena, Ascension och Tristan da Cunha",
                  },
                  {
                    id: "SMR",
                    text: "San Marino",
                  },
                  {
                    id: "SAU",
                    text: "Saudiarabien",
                  },
                  {
                    id: "SEN",
                    text: "Senegal",
                  },
                  {
                    id: "SRB",
                    text: "Serbien",
                  },
                  {
                    id: "SYC",
                    text: "Seychellerna",
                  },
                  {
                    id: "CHE",
                    text: "Schweiz",
                  },
                  {
                    id: "SLE",
                    text: "Sierra Leone",
                  },
                  {
                    id: "SGP",
                    text: "Singapore",
                  },
                  {
                    id: "SVK",
                    text: "Slovakien",
                  },
                  {
                    id: "SVN",
                    text: "Slovenien",
                  },
                  {
                    id: "SOM",
                    text: "Somalia",
                  },
                  {
                    id: "ESP",
                    text: "Spanien",
                  },
                  {
                    id: "LKA",
                    text: "Sri Lanka",
                  },
                  {
                    id: "GBR",
                    text: "Storbritannien",
                  },
                  {
                    id: "SDN",
                    text: "Sudan",
                  },
                  {
                    id: "SUR",
                    text: "Surinam",
                  },
                  {
                    id: "SJM",
                    text: "Svalbard och Jan Mayen",
                  },
                  {
                    id: "SWZ",
                    text: "Swaziland",
                  },
                  {
                    id: "ZAF",
                    text: "Sydafrika",
                  },
                  {
                    id: "SGS",
                    text: "Sydgeorgien och Sydsandwichöarna",
                  },
                  {
                    id: "KOR",
                    text: "Sydkorea (Republiken Korea)",
                  },
                  {
                    id: "SSD",
                    text: "Sydsudan",
                  },
                  {
                    id: "SYR",
                    text: "Syrien",
                  },
                  {
                    id: "STP",
                    text: "São Tomé och Príncipe",
                  },
                  {
                    id: "TWN",
                    text: "Taiwan (Republiken Kina)",
                  },
                  {
                    id: "TJK",
                    text: "Tadzjikistan",
                  },
                  {
                    id: "TZA",
                    text: "Tanzania",
                  },
                  {
                    id: "TCD",
                    text: "Tchad",
                  },
                  {
                    id: "CZE",
                    text: "Tjeckien",
                  },
                  {
                    id: "THA",
                    text: "Thailand",
                  },
                  {
                    id: "TGO",
                    text: "Togo",
                  },
                  {
                    id: "TKL",
                    text: "Tokelau",
                  },
                  {
                    id: "TON",
                    text: "Tonga",
                  },
                  {
                    id: "TTO",
                    text: "Trinidad och Tobago",
                  },
                  {
                    id: "TUN",
                    text: "Tunisien",
                  },
                  {
                    id: "TUR",
                    text: "Turkiet",
                  },
                  {
                    id: "TKM",
                    text: "Turkmenistan",
                  },
                  {
                    id: "TCA",
                    text: "Turks- och Caicosöarna",
                  },
                  {
                    id: "TUV",
                    text: "Tuvalu",
                  },
                  {
                    id: "DEU",
                    text: "Tyskland",
                  },
                  {
                    id: "UGA",
                    text: "Uganda",
                  },
                  {
                    id: "UKR",
                    text: "Ukraina",
                  },
                  {
                    id: "HUN",
                    text: "Ungern",
                  },
                  {
                    id: "URY",
                    text: "Uruguay",
                  },
                  {
                    id: "USA",
                    text: "USA",
                  },
                  {
                    id: "UZB",
                    text: "Uzbekistan",
                  },
                  {
                    id: "VUT",
                    text: "Vanuatu",
                  },
                  {
                    id: "VAT",
                    text: "Vatikanstaten",
                  },
                  {
                    id: "VEN",
                    text: "Venezuela (Bolivarianska republiken Venezuela)",
                  },
                  {
                    id: "VNM",
                    text: "Vietnam",
                  },
                  {
                    id: "ESH",
                    text: "Västsahara",
                  },
                  {
                    id: "WLF",
                    text: "Wallis- och Futunaöarna",
                  },
                  {
                    id: "YEM",
                    text: "Yemen",
                  },
                  {
                    id: "ZMB",
                    text: "Zambia",
                  },
                  {
                    id: "ZWE",
                    text: "Zimbabwe",
                  },
                  {
                    id: "AUT",
                    text: "Österrike",
                  },
                  {
                    id: "TLS",
                    text: "Östtimor (Timor-Leste)",
                  },
                  {
                    id: "ALA",
                    text: "Åland",
                  },
                  {
                    id: "UNK",
                    text: "Okänd",
                  },
                ],
                conditions: [],
                children: undefined,
              },
              {
                id: "TWpjd056a3hNMTh3X19RMmwwYVhwbGJuTm9hWEJmVWtOQg==",
                type: ComplianceFieldType.DropDownList,
                editable: true,
                answers: [],
                questionText: "Vänligen ange samtliga medborgarskap som din nära familjemedlem har",
                questionHelpText: undefined,
                options: [
                  {
                    id: "SWE",
                    text: "Sverige",
                  },
                  {
                    id: "AFG",
                    text: "Afghanistan",
                  },
                  {
                    id: "ALB",
                    text: "Albanien",
                  },
                  {
                    id: "DZA",
                    text: "Algeriet",
                  },
                  {
                    id: "VIR",
                    text: "Amerikanska Jungfruöarna",
                  },
                  {
                    id: "ASM",
                    text: "Amerikanska Samoa",
                  },
                  {
                    id: "AND",
                    text: "Andorra",
                  },
                  {
                    id: "AGO",
                    text: "Angola",
                  },
                  {
                    id: "AIA",
                    text: "Anguilla",
                  },
                  {
                    id: "ATA",
                    text: "Antarktis",
                  },
                  {
                    id: "ATG",
                    text: "Antigua och Barbuda",
                  },
                  {
                    id: "ARG",
                    text: "Argentina",
                  },
                  {
                    id: "ARM",
                    text: "Armenien",
                  },
                  {
                    id: "ABW",
                    text: "Aruba",
                  },
                  {
                    id: "AUS",
                    text: "Australien",
                  },
                  {
                    id: "AZE",
                    text: "Azerbajdzjan",
                  },
                  {
                    id: "BHS",
                    text: "Bahamas",
                  },
                  {
                    id: "BHR",
                    text: "Bahrain",
                  },
                  {
                    id: "BGD",
                    text: "Bangladesh",
                  },
                  {
                    id: "BRB",
                    text: "Barbados",
                  },
                  {
                    id: "BLR",
                    text: "Belarus",
                  },
                  {
                    id: "BEL",
                    text: "Belgien",
                  },
                  {
                    id: "BLZ",
                    text: "Belize",
                  },
                  {
                    id: "BEN",
                    text: "Benin",
                  },
                  {
                    id: "BMU",
                    text: "Bermuda",
                  },
                  {
                    id: "BTN",
                    text: "Bhutan",
                  },
                  {
                    id: "BOL",
                    text: "Bolivia",
                  },
                  {
                    id: "BES",
                    text: "Bonaire, Sint Eustatius och Saba",
                  },
                  {
                    id: "BIH",
                    text: "Bosnien och Hercegovina",
                  },
                  {
                    id: "BWA",
                    text: "Botswana",
                  },
                  {
                    id: "BVT",
                    text: "Bouvetön",
                  },
                  {
                    id: "BRA",
                    text: "Brasilien",
                  },
                  {
                    id: "IOT",
                    text: "Brittiska territoriet i Indiska oceanen",
                  },
                  {
                    id: "BRN",
                    text: "Brunei Darussalam",
                  },
                  {
                    id: "BGR",
                    text: "Bulgarien",
                  },
                  {
                    id: "BFA",
                    text: "Burkina Faso",
                  },
                  {
                    id: "BDI",
                    text: "Burundi",
                  },
                  {
                    id: "CYM",
                    text: "Caymanöarna",
                  },
                  {
                    id: "CAF",
                    text: "Centralafrikanska Republiken (CAR)",
                  },
                  {
                    id: "CHL",
                    text: "Chile",
                  },
                  {
                    id: "COL",
                    text: "Colombia",
                  },
                  {
                    id: "COK",
                    text: "Cooköarna",
                  },
                  {
                    id: "CRI",
                    text: "Costa Rica",
                  },
                  {
                    id: "CUW",
                    text: "Curaçao",
                  },
                  {
                    id: "CYP",
                    text: "Cypern",
                  },
                  {
                    id: "DNK",
                    text: "Danmark",
                  },
                  {
                    id: "COD",
                    text: "Demokratiska republiken Kongo",
                  },
                  {
                    id: "DJI",
                    text: "Djibouti",
                  },
                  {
                    id: "DMA",
                    text: "Dominica",
                  },
                  {
                    id: "DOM",
                    text: "Dominikanska republiken",
                  },
                  {
                    id: "ECU",
                    text: "Ecuador",
                  },
                  {
                    id: "EGY",
                    text: "Egypten",
                  },
                  {
                    id: "GNQ",
                    text: "Ekvatorialguinea",
                  },
                  {
                    id: "CIV",
                    text: "Elfenbenskusten",
                  },
                  {
                    id: "SLV",
                    text: "El Salvador",
                  },
                  {
                    id: "ERI",
                    text: "Eritrea",
                  },
                  {
                    id: "EST",
                    text: "Estland",
                  },
                  {
                    id: "ETH",
                    text: "Etiopien",
                  },
                  {
                    id: "FLK",
                    text: "Falklandsöarna (Malvinerna)",
                  },
                  {
                    id: "FJI",
                    text: "Fiji",
                  },
                  {
                    id: "PHL",
                    text: "Filippinerna",
                  },
                  {
                    id: "FIN",
                    text: "Finland",
                  },
                  {
                    id: "FRA",
                    text: "Frankrike",
                  },
                  {
                    id: "GUF",
                    text: "Franska Guyana",
                  },
                  {
                    id: "PYF",
                    text: "Franska Polynesien",
                  },
                  {
                    id: "ATF",
                    text: "Franska sydterritorierna (Franska syd- och Antarktisterritorierna)",
                  },
                  {
                    id: "FRO",
                    text: "Färöarna",
                  },
                  {
                    id: "ARE",
                    text: "Förenade Arabemiraten",
                  },
                  {
                    id: "UMI",
                    text: "Förenta staternas mindre öar i Oceanien och Västindien",
                  },
                  {
                    id: "GAB",
                    text: "Gabon",
                  },
                  {
                    id: "GMB",
                    text: "Gambia",
                  },
                  {
                    id: "GEO",
                    text: "Georgien",
                  },
                  {
                    id: "GHA",
                    text: "Ghana",
                  },
                  {
                    id: "GIB",
                    text: "Gibraltar",
                  },
                  {
                    id: "GRC",
                    text: "Grekland",
                  },
                  {
                    id: "GRD",
                    text: "Grenada",
                  },
                  {
                    id: "GRL",
                    text: "Grönland",
                  },
                  {
                    id: "GLP",
                    text: "Guadeloupe",
                  },
                  {
                    id: "GUM",
                    text: "Guam",
                  },
                  {
                    id: "GTM",
                    text: "Guatemala",
                  },
                  {
                    id: "GGY",
                    text: "Guernsey",
                  },
                  {
                    id: "GIN",
                    text: "Guinea",
                  },
                  {
                    id: "GNB",
                    text: "Guinea-Bissau",
                  },
                  {
                    id: "GUY",
                    text: "Guyana",
                  },
                  {
                    id: "HTI",
                    text: "Haiti",
                  },
                  {
                    id: "HMD",
                    text: "Heard- och McDonaldöarna",
                  },
                  {
                    id: "HND",
                    text: "Honduras",
                  },
                  {
                    id: "HKG",
                    text: "Hong Kong",
                  },
                  {
                    id: "IND",
                    text: "Indien",
                  },
                  {
                    id: "IDN",
                    text: "Indonesien",
                  },
                  {
                    id: "ISL",
                    text: "Island",
                  },
                  {
                    id: "IRQ",
                    text: "Irak",
                  },
                  {
                    id: "IRN",
                    text: "Iran (Islamiska republiken Iran)",
                  },
                  {
                    id: "IRL",
                    text: "Irland",
                  },
                  {
                    id: "IMN",
                    text: "Isle of Man",
                  },
                  {
                    id: "ISR",
                    text: "Israel",
                  },
                  {
                    id: "ITA",
                    text: "Italien",
                  },
                  {
                    id: "JAM",
                    text: "Jamaica",
                  },
                  {
                    id: "JPN",
                    text: "Japan",
                  },
                  {
                    id: "JOR",
                    text: "Jordanien",
                  },
                  {
                    id: "CXR",
                    text: "Julön",
                  },
                  {
                    id: "VGB",
                    text: "Jungfruöarna (Storbritannien)",
                  },
                  {
                    id: "CMR",
                    text: "Kamerun",
                  },
                  {
                    id: "KHM",
                    text: "Kambodja",
                  },
                  {
                    id: "CAN",
                    text: "Kanada",
                  },
                  {
                    id: "JEY",
                    text: "Kanalöarna, Jersey",
                  },
                  {
                    id: "XAA",
                    text: "Kanarieöarna",
                  },
                  {
                    id: "CPV",
                    text: "Kap Verde",
                  },
                  {
                    id: "KAZ",
                    text: "Kazakstan",
                  },
                  {
                    id: "KEN",
                    text: "Kenya",
                  },
                  {
                    id: "CHN",
                    text: "Kina",
                  },
                  {
                    id: "KGZ",
                    text: "Kirgizistan",
                  },
                  {
                    id: "KIR",
                    text: "Kiribati",
                  },
                  {
                    id: "CCK",
                    text: "Kokosöarna",
                  },
                  {
                    id: "COM",
                    text: "Komorerna",
                  },
                  {
                    id: "COG",
                    text: "Kongo",
                  },
                  {
                    id: "XKX",
                    text: "Kosovo",
                  },
                  {
                    id: "HRV",
                    text: "Kroatien",
                  },
                  {
                    id: "CUB",
                    text: "Kuba",
                  },
                  {
                    id: "KWT",
                    text: "Kuwait",
                  },
                  {
                    id: "LAO",
                    text: "Laos",
                  },
                  {
                    id: "LVA",
                    text: "Lettland",
                  },
                  {
                    id: "LSO",
                    text: "Lesotho",
                  },
                  {
                    id: "LBN",
                    text: "Libanon",
                  },
                  {
                    id: "LBR",
                    text: "Liberia",
                  },
                  {
                    id: "LBY",
                    text: "Libyen",
                  },
                  {
                    id: "LIE",
                    text: "Liechtenstein",
                  },
                  {
                    id: "LTU",
                    text: "Litauen",
                  },
                  {
                    id: "LUX",
                    text: "Luxemburg",
                  },
                  {
                    id: "MAC",
                    text: "Macao",
                  },
                  {
                    id: "MDG",
                    text: "Madagaskar",
                  },
                  {
                    id: "MKD",
                    text: "Makedonien (Republiken Makedonien)",
                  },
                  {
                    id: "MWI",
                    text: "Malawi",
                  },
                  {
                    id: "MYS",
                    text: "Malaysia",
                  },
                  {
                    id: "MDV",
                    text: "Maldiverna",
                  },
                  {
                    id: "MLI",
                    text: "Mali",
                  },
                  {
                    id: "MLT",
                    text: "Malta",
                  },
                  {
                    id: "MAR",
                    text: "Marocko",
                  },
                  {
                    id: "MHL",
                    text: "Marshallöarna",
                  },
                  {
                    id: "MTQ",
                    text: "Martinique",
                  },
                  {
                    id: "MRT",
                    text: "Mauretanien",
                  },
                  {
                    id: "MUS",
                    text: "Mauritius",
                  },
                  {
                    id: "MYT",
                    text: "Mayotte",
                  },
                  {
                    id: "MEX",
                    text: "Mexiko",
                  },
                  {
                    id: "FSM",
                    text: "Mikronesiska federationen",
                  },
                  {
                    id: "MDA",
                    text: "Moldavien",
                  },
                  {
                    id: "MCO",
                    text: "Monaco",
                  },
                  {
                    id: "MNG",
                    text: "Mongoliet",
                  },
                  {
                    id: "MNE",
                    text: "Montenegro",
                  },
                  {
                    id: "MSR",
                    text: "Montserrat",
                  },
                  {
                    id: "MOZ",
                    text: "Moçambique",
                  },
                  {
                    id: "MMR",
                    text: "Myanmar",
                  },
                  {
                    id: "NAM",
                    text: "Namibia",
                  },
                  {
                    id: "NRU",
                    text: "Nauru",
                  },
                  {
                    id: "NLD",
                    text: "Nederländerna",
                  },
                  {
                    id: "ANT",
                    text: "Nederländska Antillerna",
                  },
                  {
                    id: "NPL",
                    text: "Nepal",
                  },
                  {
                    id: "NIC",
                    text: "Nicaragua",
                  },
                  {
                    id: "NER",
                    text: "Niger",
                  },
                  {
                    id: "NGA",
                    text: "Nigeria",
                  },
                  {
                    id: "NIU",
                    text: "Niue",
                  },
                  {
                    id: "PRK",
                    text: "Nordkorea (Demokratiska Folkrepubliken Korea)",
                  },
                  {
                    id: "MNP",
                    text: "Nordmarianerna",
                  },
                  {
                    id: "NFK",
                    text: "Norfolkön",
                  },
                  {
                    id: "NOR",
                    text: "Norge",
                  },
                  {
                    id: "NCL",
                    text: "Nya Kaledonien",
                  },
                  {
                    id: "NZL",
                    text: "Nya Zeeland",
                  },
                  {
                    id: "OMN",
                    text: "Oman",
                  },
                  {
                    id: "PAK",
                    text: "Pakistan",
                  },
                  {
                    id: "PLW",
                    text: "Palau",
                  },
                  {
                    id: "PSE",
                    text: "Palestina",
                  },
                  {
                    id: "PAN",
                    text: "Panama",
                  },
                  {
                    id: "PNG",
                    text: "Papua Nya Guinea",
                  },
                  {
                    id: "PRY",
                    text: "Paraguay",
                  },
                  {
                    id: "PER",
                    text: "Peru",
                  },
                  {
                    id: "PCN",
                    text: "Pitcairnöarna",
                  },
                  {
                    id: "POL",
                    text: "Polen",
                  },
                  {
                    id: "PRT",
                    text: "Portugal",
                  },
                  {
                    id: "PRI",
                    text: "Puerto Rico",
                  },
                  {
                    id: "QAT",
                    text: "Qatar",
                  },
                  {
                    id: "REU",
                    text: "Réunion",
                  },
                  {
                    id: "ROU",
                    text: "Rumänien",
                  },
                  {
                    id: "RUS",
                    text: "Ryssland",
                  },
                  {
                    id: "RWA",
                    text: "Rwanda",
                  },
                  {
                    id: "BLM",
                    text: "Saint-Barthélemy",
                  },
                  {
                    id: "KNA",
                    text: "Saint Kitts och Nevis",
                  },
                  {
                    id: "LCA",
                    text: "Saint Lucia",
                  },
                  {
                    id: "MAF",
                    text: "Saint Martin",
                  },
                  {
                    id: "SPM",
                    text: "Saint-Pierre och Miquelon",
                  },
                  {
                    id: "VCT",
                    text: "Saint Vincent och Grenadinerna",
                  },
                  {
                    id: "SLB",
                    text: "Salomonöarna",
                  },
                  {
                    id: "WSM",
                    text: "Samoa",
                  },
                  {
                    id: "SHN",
                    text: "Sankta Helena, Ascension och Tristan da Cunha",
                  },
                  {
                    id: "SMR",
                    text: "San Marino",
                  },
                  {
                    id: "SAU",
                    text: "Saudiarabien",
                  },
                  {
                    id: "SEN",
                    text: "Senegal",
                  },
                  {
                    id: "SRB",
                    text: "Serbien",
                  },
                  {
                    id: "SYC",
                    text: "Seychellerna",
                  },
                  {
                    id: "CHE",
                    text: "Schweiz",
                  },
                  {
                    id: "SLE",
                    text: "Sierra Leone",
                  },
                  {
                    id: "SGP",
                    text: "Singapore",
                  },
                  {
                    id: "SVK",
                    text: "Slovakien",
                  },
                  {
                    id: "SVN",
                    text: "Slovenien",
                  },
                  {
                    id: "SOM",
                    text: "Somalia",
                  },
                  {
                    id: "ESP",
                    text: "Spanien",
                  },
                  {
                    id: "LKA",
                    text: "Sri Lanka",
                  },
                  {
                    id: "GBR",
                    text: "Storbritannien",
                  },
                  {
                    id: "SDN",
                    text: "Sudan",
                  },
                  {
                    id: "SUR",
                    text: "Surinam",
                  },
                  {
                    id: "SJM",
                    text: "Svalbard och Jan Mayen",
                  },
                  {
                    id: "SWZ",
                    text: "Swaziland",
                  },
                  {
                    id: "ZAF",
                    text: "Sydafrika",
                  },
                  {
                    id: "SGS",
                    text: "Sydgeorgien och Sydsandwichöarna",
                  },
                  {
                    id: "KOR",
                    text: "Sydkorea (Republiken Korea)",
                  },
                  {
                    id: "SSD",
                    text: "Sydsudan",
                  },
                  {
                    id: "SYR",
                    text: "Syrien",
                  },
                  {
                    id: "STP",
                    text: "São Tomé och Príncipe",
                  },
                  {
                    id: "TWN",
                    text: "Taiwan (Republiken Kina)",
                  },
                  {
                    id: "TJK",
                    text: "Tadzjikistan",
                  },
                  {
                    id: "TZA",
                    text: "Tanzania",
                  },
                  {
                    id: "TCD",
                    text: "Tchad",
                  },
                  {
                    id: "CZE",
                    text: "Tjeckien",
                  },
                  {
                    id: "THA",
                    text: "Thailand",
                  },
                  {
                    id: "TGO",
                    text: "Togo",
                  },
                  {
                    id: "TKL",
                    text: "Tokelau",
                  },
                  {
                    id: "TON",
                    text: "Tonga",
                  },
                  {
                    id: "TTO",
                    text: "Trinidad och Tobago",
                  },
                  {
                    id: "TUN",
                    text: "Tunisien",
                  },
                  {
                    id: "TUR",
                    text: "Turkiet",
                  },
                  {
                    id: "TKM",
                    text: "Turkmenistan",
                  },
                  {
                    id: "TCA",
                    text: "Turks- och Caicosöarna",
                  },
                  {
                    id: "TUV",
                    text: "Tuvalu",
                  },
                  {
                    id: "DEU",
                    text: "Tyskland",
                  },
                  {
                    id: "UGA",
                    text: "Uganda",
                  },
                  {
                    id: "UKR",
                    text: "Ukraina",
                  },
                  {
                    id: "HUN",
                    text: "Ungern",
                  },
                  {
                    id: "URY",
                    text: "Uruguay",
                  },
                  {
                    id: "USA",
                    text: "USA",
                  },
                  {
                    id: "UZB",
                    text: "Uzbekistan",
                  },
                  {
                    id: "VUT",
                    text: "Vanuatu",
                  },
                  {
                    id: "VAT",
                    text: "Vatikanstaten",
                  },
                  {
                    id: "VEN",
                    text: "Venezuela (Bolivarianska republiken Venezuela)",
                  },
                  {
                    id: "VNM",
                    text: "Vietnam",
                  },
                  {
                    id: "ESH",
                    text: "Västsahara",
                  },
                  {
                    id: "WLF",
                    text: "Wallis- och Futunaöarna",
                  },
                  {
                    id: "YEM",
                    text: "Yemen",
                  },
                  {
                    id: "ZMB",
                    text: "Zambia",
                  },
                  {
                    id: "ZWE",
                    text: "Zimbabwe",
                  },
                  {
                    id: "AUT",
                    text: "Österrike",
                  },
                  {
                    id: "TLS",
                    text: "Östtimor (Timor-Leste)",
                  },
                  {
                    id: "ALA",
                    text: "Åland",
                  },
                  {
                    id: "UNK",
                    text: "Okänd",
                  },
                ],
                conditions: [],
                children: undefined,
              },
              {
                id: "TWpjd056a3hNMTh3X19VRVZRWDJaMWJtUnpYMk5zYjNObFgzSmxiR0YwYVhabA==",
                type: ComplianceFieldType.DropDownListYesNo,
                editable: true,
                answers: [],
                questionText:
                  "Kommer medel från potentiella intäkter/tillgångar som erhållits från rollen som PEP att ingå i denna kundrelation?",
                questionHelpText:
                  "Anledningen till att vi ber om denna information är för att kunna få en bättre förståelse om din familjemedlem som är anses vara en person i politisk utsatt ställning planerar att hjälpa till med avbetalningen av lånet/krediten eller sätta in pengar på ditt sparkonto som du har hos Santander. Om så är fallet så vill vi få en bättre förståelse hur pengarna används samt dess ursprung. Detta är i enlighet med kraven som framgår i Lag om åtgärder mot penningtvätt och finansiering av terrorism.",
                options: [
                  {
                    id: "True",
                    text: "Ja",
                  },
                  {
                    id: "False",
                    text: "Nej",
                  },
                ],
                conditions: [],
                children: undefined,
              },
              {
                id: "TWpjd056a3hNMTh3X181ZTlkZTIyOC1lNzk0LTRjOTktYTk5MS1hZmY2MDYyYzhhOTQ=",
                type: ComplianceFieldType.Conditional,
                editable: false,
                answers: undefined,
                questionText: undefined,
                questionHelpText: undefined,
                options: undefined,
                conditions: [
                  {
                    valueType: ComplianceConditionValueType.Bool,
                    operator: ComplianceConditionOperator.Equals,
                    questionId: "TWpjd056a3hNMTh3X19VRVZRWDJaMWJtUnpYMk5zYjNObFgzSmxiR0YwYVhabA==",
                    value: "True",
                  },
                ],
                children: [
                  {
                    id: "TWpjd056a3hNMTh3X19VR3hsWVhObFgzTndaV05wWm5sZk13PT0=",
                    type: ComplianceFieldType.TextArea,
                    editable: true,
                    answers: [],
                    questionText:
                      "Vänligen detaljera ditt svar nedan. Notera att om du ej utvecklar ditt svar  kommer vi i ett senare skede kontakta dig för att inhämta informationen.",
                    questionHelpText: undefined,
                    options: undefined,
                    conditions: [],
                    children: undefined,
                  },
                ],
              },
              {
                id: "TWpjd056a3hNMTh3X184YjFjNzQwZi0yZGNkLTRmYmQtYjRkMy1iMDA0OGIxYzYzYzg=",
                type: ComplianceFieldType.paragraph,
                editable: false,
                answers: [],
                questionText:
                  'Genom att trycka på "Nästa" bekräftar du härmed att all information du lämnat är korrekt och fullständig. Vänligen notera att du inte kan gå tillbaka när du klickat på "Nästa".',
                questionHelpText: undefined,
                options: undefined,
                conditions: [],
                children: undefined,
              },
            ],
          },
          {
            id: "TWpjd056a3hNMTh3X18zZjU2ZDE5OS00YWY5LTQ1MTAtOTdkNy1mYWE3N2JkM2U2ZGQ=",
            type: ComplianceFieldType.Conditional,
            editable: false,
            answers: undefined,
            questionText: undefined,
            questionHelpText: undefined,
            options: undefined,
            conditions: [
              {
                valueType: ComplianceConditionValueType.Option,
                operator: ComplianceConditionOperator.Equals,
                questionId: "TWpjd056a3hNMTh3X19VRVZRWDFKMWJHVmZNUT09",
                value: "Known_associate",
              },
            ],
            children: [
              {
                id: "TWpjd056a3hNMTh3X19VRVZRWDFCdmMybDBhVzl1WDB0dWIzZHVYMEZ6YzI5amFXRjBaUT09",
                type: ComplianceFieldType.DropDownList,
                editable: true,
                answers: [],
                questionText: "Vänligen ange positionen som gör din nära medarbetare till PEP",
                questionHelpText: undefined,
                options: [
                  {
                    id: "Head_of_State",
                    text: "Stats- eller regeringschefer, ministrar samt vice och biträdande ministrar",
                  },
                  {
                    id: "Member_of_Parliament",
                    text: "Parlamentsledamöter och ledamöter av liknande lagstiftande organ",
                  },
                  {
                    id: "Member_of_Political_Party",
                    text: "Ledamöter i styrelsen för politiska partier",
                  },
                  {
                    id: "Supreme_Court",
                    text: "Domare i Högsta domstol, konstitutionell domstol eller andra rättsliga organ på hög nivå vilkas beslut endast undantagsvis kan överklagas",
                  },
                  {
                    id: "Member_of_Central_Bank",
                    text: "Högre tjänstemän vid revisionsmyndigheter och ledamöter i centralbankers styrande organ",
                  },
                  {
                    id: "Swedish_Ambassador",
                    text: "Ambassadörer, beskickningschefer samt höga officerare i försvarsmakten",
                  },
                  {
                    id: "Member_of_supervision_body",
                    text: "Personer som ingår i statsägda företags förvaltnings-, lednings- eller kontrollorgan",
                  },
                ],
                conditions: [],
                children: undefined,
              },
              {
                id: "TWpjd056a3hNMTh3X19RMjkxYm5SeWVWOWtaV1pwYm1Wa1gxQkZVRjlPVFE9PQ==",
                type: ComplianceFieldType.DropDownList,
                editable: true,
                answers: [],
                questionText: "Vänligen bekräfta i vilket land din nära medarbetare definieras som PEP",
                questionHelpText: undefined,
                options: [
                  {
                    id: "SWE",
                    text: "Sverige",
                  },
                  {
                    id: "AFG",
                    text: "Afghanistan",
                  },
                  {
                    id: "ALB",
                    text: "Albanien",
                  },
                  {
                    id: "DZA",
                    text: "Algeriet",
                  },
                  {
                    id: "VIR",
                    text: "Amerikanska Jungfruöarna",
                  },
                  {
                    id: "ASM",
                    text: "Amerikanska Samoa",
                  },
                  {
                    id: "AND",
                    text: "Andorra",
                  },
                  {
                    id: "AGO",
                    text: "Angola",
                  },
                  {
                    id: "AIA",
                    text: "Anguilla",
                  },
                  {
                    id: "ATA",
                    text: "Antarktis",
                  },
                  {
                    id: "ATG",
                    text: "Antigua och Barbuda",
                  },
                  {
                    id: "ARG",
                    text: "Argentina",
                  },
                  {
                    id: "ARM",
                    text: "Armenien",
                  },
                  {
                    id: "ABW",
                    text: "Aruba",
                  },
                  {
                    id: "AUS",
                    text: "Australien",
                  },
                  {
                    id: "AZE",
                    text: "Azerbajdzjan",
                  },
                  {
                    id: "BHS",
                    text: "Bahamas",
                  },
                  {
                    id: "BHR",
                    text: "Bahrain",
                  },
                  {
                    id: "BGD",
                    text: "Bangladesh",
                  },
                  {
                    id: "BRB",
                    text: "Barbados",
                  },
                  {
                    id: "BLR",
                    text: "Belarus",
                  },
                  {
                    id: "BEL",
                    text: "Belgien",
                  },
                  {
                    id: "BLZ",
                    text: "Belize",
                  },
                  {
                    id: "BEN",
                    text: "Benin",
                  },
                  {
                    id: "BMU",
                    text: "Bermuda",
                  },
                  {
                    id: "BTN",
                    text: "Bhutan",
                  },
                  {
                    id: "BOL",
                    text: "Bolivia",
                  },
                  {
                    id: "BES",
                    text: "Bonaire, Sint Eustatius och Saba",
                  },
                  {
                    id: "BIH",
                    text: "Bosnien och Hercegovina",
                  },
                  {
                    id: "BWA",
                    text: "Botswana",
                  },
                  {
                    id: "BVT",
                    text: "Bouvetön",
                  },
                  {
                    id: "BRA",
                    text: "Brasilien",
                  },
                  {
                    id: "IOT",
                    text: "Brittiska territoriet i Indiska oceanen",
                  },
                  {
                    id: "BRN",
                    text: "Brunei Darussalam",
                  },
                  {
                    id: "BGR",
                    text: "Bulgarien",
                  },
                  {
                    id: "BFA",
                    text: "Burkina Faso",
                  },
                  {
                    id: "BDI",
                    text: "Burundi",
                  },
                  {
                    id: "CYM",
                    text: "Caymanöarna",
                  },
                  {
                    id: "CAF",
                    text: "Centralafrikanska Republiken (CAR)",
                  },
                  {
                    id: "CHL",
                    text: "Chile",
                  },
                  {
                    id: "COL",
                    text: "Colombia",
                  },
                  {
                    id: "COK",
                    text: "Cooköarna",
                  },
                  {
                    id: "CRI",
                    text: "Costa Rica",
                  },
                  {
                    id: "CUW",
                    text: "Curaçao",
                  },
                  {
                    id: "CYP",
                    text: "Cypern",
                  },
                  {
                    id: "DNK",
                    text: "Danmark",
                  },
                  {
                    id: "COD",
                    text: "Demokratiska republiken Kongo",
                  },
                  {
                    id: "DJI",
                    text: "Djibouti",
                  },
                  {
                    id: "DMA",
                    text: "Dominica",
                  },
                  {
                    id: "DOM",
                    text: "Dominikanska republiken",
                  },
                  {
                    id: "ECU",
                    text: "Ecuador",
                  },
                  {
                    id: "EGY",
                    text: "Egypten",
                  },
                  {
                    id: "GNQ",
                    text: "Ekvatorialguinea",
                  },
                  {
                    id: "CIV",
                    text: "Elfenbenskusten",
                  },
                  {
                    id: "SLV",
                    text: "El Salvador",
                  },
                  {
                    id: "ERI",
                    text: "Eritrea",
                  },
                  {
                    id: "EST",
                    text: "Estland",
                  },
                  {
                    id: "ETH",
                    text: "Etiopien",
                  },
                  {
                    id: "FLK",
                    text: "Falklandsöarna (Malvinerna)",
                  },
                  {
                    id: "FJI",
                    text: "Fiji",
                  },
                  {
                    id: "PHL",
                    text: "Filippinerna",
                  },
                  {
                    id: "FIN",
                    text: "Finland",
                  },
                  {
                    id: "FRA",
                    text: "Frankrike",
                  },
                  {
                    id: "GUF",
                    text: "Franska Guyana",
                  },
                  {
                    id: "PYF",
                    text: "Franska Polynesien",
                  },
                  {
                    id: "ATF",
                    text: "Franska sydterritorierna (Franska syd- och Antarktisterritorierna)",
                  },
                  {
                    id: "FRO",
                    text: "Färöarna",
                  },
                  {
                    id: "ARE",
                    text: "Förenade Arabemiraten",
                  },
                  {
                    id: "UMI",
                    text: "Förenta staternas mindre öar i Oceanien och Västindien",
                  },
                  {
                    id: "GAB",
                    text: "Gabon",
                  },
                  {
                    id: "GMB",
                    text: "Gambia",
                  },
                  {
                    id: "GEO",
                    text: "Georgien",
                  },
                  {
                    id: "GHA",
                    text: "Ghana",
                  },
                  {
                    id: "GIB",
                    text: "Gibraltar",
                  },
                  {
                    id: "GRC",
                    text: "Grekland",
                  },
                  {
                    id: "GRD",
                    text: "Grenada",
                  },
                  {
                    id: "GRL",
                    text: "Grönland",
                  },
                  {
                    id: "GLP",
                    text: "Guadeloupe",
                  },
                  {
                    id: "GUM",
                    text: "Guam",
                  },
                  {
                    id: "GTM",
                    text: "Guatemala",
                  },
                  {
                    id: "GGY",
                    text: "Guernsey",
                  },
                  {
                    id: "GIN",
                    text: "Guinea",
                  },
                  {
                    id: "GNB",
                    text: "Guinea-Bissau",
                  },
                  {
                    id: "GUY",
                    text: "Guyana",
                  },
                  {
                    id: "HTI",
                    text: "Haiti",
                  },
                  {
                    id: "HMD",
                    text: "Heard- och McDonaldöarna",
                  },
                  {
                    id: "HND",
                    text: "Honduras",
                  },
                  {
                    id: "HKG",
                    text: "Hong Kong",
                  },
                  {
                    id: "IND",
                    text: "Indien",
                  },
                  {
                    id: "IDN",
                    text: "Indonesien",
                  },
                  {
                    id: "ISL",
                    text: "Island",
                  },
                  {
                    id: "IRQ",
                    text: "Irak",
                  },
                  {
                    id: "IRN",
                    text: "Iran (Islamiska republiken Iran)",
                  },
                  {
                    id: "IRL",
                    text: "Irland",
                  },
                  {
                    id: "IMN",
                    text: "Isle of Man",
                  },
                  {
                    id: "ISR",
                    text: "Israel",
                  },
                  {
                    id: "ITA",
                    text: "Italien",
                  },
                  {
                    id: "JAM",
                    text: "Jamaica",
                  },
                  {
                    id: "JPN",
                    text: "Japan",
                  },
                  {
                    id: "JOR",
                    text: "Jordanien",
                  },
                  {
                    id: "CXR",
                    text: "Julön",
                  },
                  {
                    id: "VGB",
                    text: "Jungfruöarna (Storbritannien)",
                  },
                  {
                    id: "CMR",
                    text: "Kamerun",
                  },
                  {
                    id: "KHM",
                    text: "Kambodja",
                  },
                  {
                    id: "CAN",
                    text: "Kanada",
                  },
                  {
                    id: "JEY",
                    text: "Kanalöarna, Jersey",
                  },
                  {
                    id: "XAA",
                    text: "Kanarieöarna",
                  },
                  {
                    id: "CPV",
                    text: "Kap Verde",
                  },
                  {
                    id: "KAZ",
                    text: "Kazakstan",
                  },
                  {
                    id: "KEN",
                    text: "Kenya",
                  },
                  {
                    id: "CHN",
                    text: "Kina",
                  },
                  {
                    id: "KGZ",
                    text: "Kirgizistan",
                  },
                  {
                    id: "KIR",
                    text: "Kiribati",
                  },
                  {
                    id: "CCK",
                    text: "Kokosöarna",
                  },
                  {
                    id: "COM",
                    text: "Komorerna",
                  },
                  {
                    id: "COG",
                    text: "Kongo",
                  },
                  {
                    id: "XKX",
                    text: "Kosovo",
                  },
                  {
                    id: "HRV",
                    text: "Kroatien",
                  },
                  {
                    id: "CUB",
                    text: "Kuba",
                  },
                  {
                    id: "KWT",
                    text: "Kuwait",
                  },
                  {
                    id: "LAO",
                    text: "Laos",
                  },
                  {
                    id: "LVA",
                    text: "Lettland",
                  },
                  {
                    id: "LSO",
                    text: "Lesotho",
                  },
                  {
                    id: "LBN",
                    text: "Libanon",
                  },
                  {
                    id: "LBR",
                    text: "Liberia",
                  },
                  {
                    id: "LBY",
                    text: "Libyen",
                  },
                  {
                    id: "LIE",
                    text: "Liechtenstein",
                  },
                  {
                    id: "LTU",
                    text: "Litauen",
                  },
                  {
                    id: "LUX",
                    text: "Luxemburg",
                  },
                  {
                    id: "MAC",
                    text: "Macao",
                  },
                  {
                    id: "MDG",
                    text: "Madagaskar",
                  },
                  {
                    id: "MKD",
                    text: "Makedonien (Republiken Makedonien)",
                  },
                  {
                    id: "MWI",
                    text: "Malawi",
                  },
                  {
                    id: "MYS",
                    text: "Malaysia",
                  },
                  {
                    id: "MDV",
                    text: "Maldiverna",
                  },
                  {
                    id: "MLI",
                    text: "Mali",
                  },
                  {
                    id: "MLT",
                    text: "Malta",
                  },
                  {
                    id: "MAR",
                    text: "Marocko",
                  },
                  {
                    id: "MHL",
                    text: "Marshallöarna",
                  },
                  {
                    id: "MTQ",
                    text: "Martinique",
                  },
                  {
                    id: "MRT",
                    text: "Mauretanien",
                  },
                  {
                    id: "MUS",
                    text: "Mauritius",
                  },
                  {
                    id: "MYT",
                    text: "Mayotte",
                  },
                  {
                    id: "MEX",
                    text: "Mexiko",
                  },
                  {
                    id: "FSM",
                    text: "Mikronesiska federationen",
                  },
                  {
                    id: "MDA",
                    text: "Moldavien",
                  },
                  {
                    id: "MCO",
                    text: "Monaco",
                  },
                  {
                    id: "MNG",
                    text: "Mongoliet",
                  },
                  {
                    id: "MNE",
                    text: "Montenegro",
                  },
                  {
                    id: "MSR",
                    text: "Montserrat",
                  },
                  {
                    id: "MOZ",
                    text: "Moçambique",
                  },
                  {
                    id: "MMR",
                    text: "Myanmar",
                  },
                  {
                    id: "NAM",
                    text: "Namibia",
                  },
                  {
                    id: "NRU",
                    text: "Nauru",
                  },
                  {
                    id: "NLD",
                    text: "Nederländerna",
                  },
                  {
                    id: "ANT",
                    text: "Nederländska Antillerna",
                  },
                  {
                    id: "NPL",
                    text: "Nepal",
                  },
                  {
                    id: "NIC",
                    text: "Nicaragua",
                  },
                  {
                    id: "NER",
                    text: "Niger",
                  },
                  {
                    id: "NGA",
                    text: "Nigeria",
                  },
                  {
                    id: "NIU",
                    text: "Niue",
                  },
                  {
                    id: "PRK",
                    text: "Nordkorea (Demokratiska Folkrepubliken Korea)",
                  },
                  {
                    id: "MNP",
                    text: "Nordmarianerna",
                  },
                  {
                    id: "NFK",
                    text: "Norfolkön",
                  },
                  {
                    id: "NOR",
                    text: "Norge",
                  },
                  {
                    id: "NCL",
                    text: "Nya Kaledonien",
                  },
                  {
                    id: "NZL",
                    text: "Nya Zeeland",
                  },
                  {
                    id: "OMN",
                    text: "Oman",
                  },
                  {
                    id: "PAK",
                    text: "Pakistan",
                  },
                  {
                    id: "PLW",
                    text: "Palau",
                  },
                  {
                    id: "PSE",
                    text: "Palestina",
                  },
                  {
                    id: "PAN",
                    text: "Panama",
                  },
                  {
                    id: "PNG",
                    text: "Papua Nya Guinea",
                  },
                  {
                    id: "PRY",
                    text: "Paraguay",
                  },
                  {
                    id: "PER",
                    text: "Peru",
                  },
                  {
                    id: "PCN",
                    text: "Pitcairnöarna",
                  },
                  {
                    id: "POL",
                    text: "Polen",
                  },
                  {
                    id: "PRT",
                    text: "Portugal",
                  },
                  {
                    id: "PRI",
                    text: "Puerto Rico",
                  },
                  {
                    id: "QAT",
                    text: "Qatar",
                  },
                  {
                    id: "REU",
                    text: "Réunion",
                  },
                  {
                    id: "ROU",
                    text: "Rumänien",
                  },
                  {
                    id: "RUS",
                    text: "Ryssland",
                  },
                  {
                    id: "RWA",
                    text: "Rwanda",
                  },
                  {
                    id: "BLM",
                    text: "Saint-Barthélemy",
                  },
                  {
                    id: "KNA",
                    text: "Saint Kitts och Nevis",
                  },
                  {
                    id: "LCA",
                    text: "Saint Lucia",
                  },
                  {
                    id: "MAF",
                    text: "Saint Martin",
                  },
                  {
                    id: "SPM",
                    text: "Saint-Pierre och Miquelon",
                  },
                  {
                    id: "VCT",
                    text: "Saint Vincent och Grenadinerna",
                  },
                  {
                    id: "SLB",
                    text: "Salomonöarna",
                  },
                  {
                    id: "WSM",
                    text: "Samoa",
                  },
                  {
                    id: "SHN",
                    text: "Sankta Helena, Ascension och Tristan da Cunha",
                  },
                  {
                    id: "SMR",
                    text: "San Marino",
                  },
                  {
                    id: "SAU",
                    text: "Saudiarabien",
                  },
                  {
                    id: "SEN",
                    text: "Senegal",
                  },
                  {
                    id: "SRB",
                    text: "Serbien",
                  },
                  {
                    id: "SYC",
                    text: "Seychellerna",
                  },
                  {
                    id: "CHE",
                    text: "Schweiz",
                  },
                  {
                    id: "SLE",
                    text: "Sierra Leone",
                  },
                  {
                    id: "SGP",
                    text: "Singapore",
                  },
                  {
                    id: "SVK",
                    text: "Slovakien",
                  },
                  {
                    id: "SVN",
                    text: "Slovenien",
                  },
                  {
                    id: "SOM",
                    text: "Somalia",
                  },
                  {
                    id: "ESP",
                    text: "Spanien",
                  },
                  {
                    id: "LKA",
                    text: "Sri Lanka",
                  },
                  {
                    id: "GBR",
                    text: "Storbritannien",
                  },
                  {
                    id: "SDN",
                    text: "Sudan",
                  },
                  {
                    id: "SUR",
                    text: "Surinam",
                  },
                  {
                    id: "SJM",
                    text: "Svalbard och Jan Mayen",
                  },
                  {
                    id: "SWZ",
                    text: "Swaziland",
                  },
                  {
                    id: "ZAF",
                    text: "Sydafrika",
                  },
                  {
                    id: "SGS",
                    text: "Sydgeorgien och Sydsandwichöarna",
                  },
                  {
                    id: "KOR",
                    text: "Sydkorea (Republiken Korea)",
                  },
                  {
                    id: "SSD",
                    text: "Sydsudan",
                  },
                  {
                    id: "SYR",
                    text: "Syrien",
                  },
                  {
                    id: "STP",
                    text: "São Tomé och Príncipe",
                  },
                  {
                    id: "TWN",
                    text: "Taiwan (Republiken Kina)",
                  },
                  {
                    id: "TJK",
                    text: "Tadzjikistan",
                  },
                  {
                    id: "TZA",
                    text: "Tanzania",
                  },
                  {
                    id: "TCD",
                    text: "Tchad",
                  },
                  {
                    id: "CZE",
                    text: "Tjeckien",
                  },
                  {
                    id: "THA",
                    text: "Thailand",
                  },
                  {
                    id: "TGO",
                    text: "Togo",
                  },
                  {
                    id: "TKL",
                    text: "Tokelau",
                  },
                  {
                    id: "TON",
                    text: "Tonga",
                  },
                  {
                    id: "TTO",
                    text: "Trinidad och Tobago",
                  },
                  {
                    id: "TUN",
                    text: "Tunisien",
                  },
                  {
                    id: "TUR",
                    text: "Turkiet",
                  },
                  {
                    id: "TKM",
                    text: "Turkmenistan",
                  },
                  {
                    id: "TCA",
                    text: "Turks- och Caicosöarna",
                  },
                  {
                    id: "TUV",
                    text: "Tuvalu",
                  },
                  {
                    id: "DEU",
                    text: "Tyskland",
                  },
                  {
                    id: "UGA",
                    text: "Uganda",
                  },
                  {
                    id: "UKR",
                    text: "Ukraina",
                  },
                  {
                    id: "HUN",
                    text: "Ungern",
                  },
                  {
                    id: "URY",
                    text: "Uruguay",
                  },
                  {
                    id: "USA",
                    text: "USA",
                  },
                  {
                    id: "UZB",
                    text: "Uzbekistan",
                  },
                  {
                    id: "VUT",
                    text: "Vanuatu",
                  },
                  {
                    id: "VAT",
                    text: "Vatikanstaten",
                  },
                  {
                    id: "VEN",
                    text: "Venezuela (Bolivarianska republiken Venezuela)",
                  },
                  {
                    id: "VNM",
                    text: "Vietnam",
                  },
                  {
                    id: "ESH",
                    text: "Västsahara",
                  },
                  {
                    id: "WLF",
                    text: "Wallis- och Futunaöarna",
                  },
                  {
                    id: "YEM",
                    text: "Yemen",
                  },
                  {
                    id: "ZMB",
                    text: "Zambia",
                  },
                  {
                    id: "ZWE",
                    text: "Zimbabwe",
                  },
                  {
                    id: "AUT",
                    text: "Österrike",
                  },
                  {
                    id: "TLS",
                    text: "Östtimor (Timor-Leste)",
                  },
                  {
                    id: "ALA",
                    text: "Åland",
                  },
                  {
                    id: "UNK",
                    text: "Okänd",
                  },
                ],
                conditions: [],
                children: undefined,
              },
              {
                id: "TWpjd056a3hNMTh3X19VRVZRWDJaMWJtUnpYMk5zYjNObFgyRnpjMjlqYVdGMFpRPT0=",
                type: ComplianceFieldType.DropDownListYesNo,
                editable: true,
                answers: [],
                questionText:
                  "Kommer medel från potentiella intäkter/tillgångar som erhållits från rollen som PEP att ingå i denna kundrelation?",
                questionHelpText:
                  "Anledningen till att vi ber om denna information är för att kunna få en bättre förståelse om din kända medarbetare som är anses vara en person i politisk utsatt ställning planerar att hjälpa till med avbetalningen av lånet/krediten eller sätta in pengar på ditt sparkonto som du har hos Santander. Om så är fallet så vill vi få en bättre förståelse hur pengarna används samt dess ursprung. Detta är i enlighet med kraven som framgår i Lag om åtgärder mot penningtvätt och finansiering av terrorism.",
                options: [
                  {
                    id: "True",
                    text: "Ja",
                  },
                  {
                    id: "False",
                    text: "Nej",
                  },
                ],
                conditions: [],
                children: undefined,
              },
              {
                id: "TWpjd056a3hNMTh3X183NGZhNjU1Mi1lNzFlLTQzMDMtYjJmNi0yNzg4YzAzNmU3ZjY=",
                type: ComplianceFieldType.Conditional,
                editable: false,
                answers: undefined,
                questionText: undefined,
                questionHelpText: undefined,
                options: undefined,
                conditions: [
                  {
                    valueType: ComplianceConditionValueType.Bool,
                    operator: ComplianceConditionOperator.Equals,
                    questionId: "TWpjd056a3hNMTh3X19VRVZRWDJaMWJtUnpYMk5zYjNObFgyRnpjMjlqYVdGMFpRPT0=",
                    value: "True",
                  },
                ],
                children: [
                  {
                    id: "TWpjd056a3hNMTh3X19VR3hsWVhObFgzTndaV05wWm5sZk5RPT0=",
                    type: ComplianceFieldType.TextArea,
                    editable: true,
                    answers: [],
                    questionText:
                      "Vänligen detaljera ditt svar nedan. Notera att om du ej utvecklar ditt svar kommer vi i ett senare skede kontakta dig för att inhämta informationen.",
                    questionHelpText: undefined,
                    options: undefined,
                    conditions: [],
                    children: undefined,
                  },
                ],
              },
              {
                id: "TWpjd056a3hNMTh3X19kNzZkYjkyNi1jNGRiLTRlYjUtOTBiNi0wZTQ1MjYwZWNiNGI=",
                type: ComplianceFieldType.paragraph,
                editable: false,
                answers: [],
                questionText:
                  'Genom att trycka på "Nästa" bekräftar du härmed att all information du lämnat är korrekt och fullständig. Vänligen notera att du inte kan gå tillbaka när du klickat på "Nästa".',
                questionHelpText: undefined,
                options: undefined,
                conditions: [],
                children: undefined,
              },
            ],
          },
        ],
      },
      {
        id: "TWpjd056a3hNMTh3X19jMDRlZDMzMi1lYTUzLTRiZDQtOTRlNS1jOGU0ZTY0MzI1N2Y=",
        type: ComplianceFieldType.Conditional,
        editable: false,
        answers: undefined,
        questionText: undefined,
        questionHelpText: undefined,
        options: undefined,
        conditions: [
          {
            valueType: ComplianceConditionValueType.Option,
            operator: ComplianceConditionOperator.Equals,
            questionId: "TWpjd056a3hNMTh3X19TWE5mVUVWUVgxSkRRUT09",
            value: "No",
          },
        ],
        children: [
          {
            id: "TWpjd056a3hNMTh3X19lNTk0MjhhMi1mYzIyLTRjMmQtOGZhMy0yZTI0YTczYTkwZTc=",
            type: ComplianceFieldType.paragraph,
            editable: false,
            answers: [],
            questionText:
              'Genom att trycka på "Nästa" bekräftar du härmed att all information du lämnat är korrekt och fullständig. Vänligen notera att du inte kan gå tillbaka när du klickat på "Nästa".',
            questionHelpText: undefined,
            options: undefined,
            conditions: [],
            children: undefined,
          },
        ],
      },
    ],
    accountId: undefined,
  },
];
