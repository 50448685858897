import React from "react";
import { observer } from "mobx-react";
import { ILoanTopUpPage } from "netbank-shared/src/libs/models/Content/Page";
import { useStores } from "netbank-shared/src/hooks";
import { ITopUpApplicantForm } from "netbank-shared/src/libs/models/Offer/Offer";
import { tx } from "netbank-shared/src/libs/i18n";
import offerStyles from "~views/pages/Offers/Offers.scss";
import rootStyles from "~views/pages/Root.scss";
import styles from "../LoanTopUpFlow.scss";
import { HtmlContent } from "~views/shared/HtmlContent/HtmlContent";
import { Button } from "~views/shared";
import { TrackingAction, TrackingCategory } from "netbank-shared/src/libs/models/Tracking";
import { toLocaleString } from "netbank-shared/src/libs/utils";
import { ApplicantInformation, IApplicantInformationLabelsProps } from "~views/shared/Applicant/ApplicantInformation";
import { usePushInteraction } from "~hooks";
import { PendingApplicationState } from "netbank-shared/src/libs/models/Content/Enums";

export interface LoanTopUpApplicantInformationStepProps {
  data: ILoanTopUpPage;
  setNextTopUpStep: () => void;
  setPreviousTopUpStep: () => void;
}

export const LoanTopUpApplicantInformationStep = observer(
  ({ data, setNextTopUpStep, setPreviousTopUpStep }: LoanTopUpApplicantInformationStepProps) => {
    const { offerStore, uiStore } = useStores();

    const { locale, currency } = uiStore;

    usePushInteraction({
      eventCategory: TrackingCategory.ProductLoan,
      eventAction: TrackingAction.LoanRaise2Information,
      eventPrefix: toLocaleString(offerStore.totalLoanAmount, currency, locale, 0),
      eventValue: offerStore.totalLoanAmount,
    });

    const { applicantDetailsStep, sharedContent } = data;

    if (!offerStore.currentAccount) return null;

    const { hasCoApplicant, coApplicantName } = offerStore.currentAccount;

    const isCoApplicantApproval =
      offerStore.currentPendingApplication?.applicationState === PendingApplicationState.CoParticipantApproval;

    const applicantLabels: IApplicantInformationLabelsProps = {
      purposeOfLoanLabel: sharedContent.purposeOfLoanLabel,
      customPurposeOfLoanPlaceholder: sharedContent.customPurposeOfLoanPlaceholder,
    };

    return (
      <div>
        {!isCoApplicantApproval && (
          <>
            <HtmlContent
              html={`<h1>${applicantDetailsStep.mainApplicantHeader || tx("topUp.mainApplicantHeader")}</h1>`}
            />
            {applicantDetailsStep.mainApplicantText && (
              <HtmlContent className={styles.topText} html={applicantDetailsStep.mainApplicantText} />
            )}
            <ApplicantInformation
              data={applicantLabels}
              applicant={offerStore.mainTopupApplicantData}
              setApplicant={(applicant: ITopUpApplicantForm) => offerStore.setMainApplicantData(applicant)}
              showValidationErrors={offerStore.showTopUpValidationErrors}
              isMainApplicant
            />
          </>
        )}

        {((hasCoApplicant && coApplicantName) || isCoApplicantApproval) && (
          <>
            <h2>{applicantDetailsStep.coApplicantHeader || tx("topUp.coApplicantHeader")}</h2>
            <p className={rootStyles.bold}>{coApplicantName}</p>
            {applicantDetailsStep.coApplicantText && (
              <HtmlContent className={styles.topText} html={applicantDetailsStep.coApplicantText} />
            )}
            <ApplicantInformation
              data={applicantLabels}
              applicant={offerStore.coTopupApplicantData}
              setApplicant={(applicant: ITopUpApplicantForm) => offerStore.setCoApplicantData(applicant)}
              showValidationErrors={offerStore.showTopUpValidationErrors}
            />
          </>
        )}

        <div>
          <div className={offerStyles.submit}>
            {offerStore.currentOffer && (
              <Button
                title={applicantDetailsStep.previousButtonText || tx("misc.back")}
                onClick={setPreviousTopUpStep}
                borderColor="black"
                bordered
                large
              />
            )}
            <Button
              title={applicantDetailsStep.continueButtonText || tx("misc.continue")}
              color="red"
              onClick={() => {
                const isValid = offerStore.validateApplicantsInformation();

                if (isValid) {
                  setNextTopUpStep();
                }
              }}
              large
            />
          </div>
        </div>
      </div>
    );
  }
);
