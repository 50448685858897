import React from "react";
import { observer } from "mobx-react";
import { ILoanTopUpPage } from "netbank-shared/src/libs/models/Content/Page";
import { tx } from "netbank-shared/src/libs/i18n";
import offerStyles from "~views/pages/Offers/Offers.scss";
import { Button } from "~views/shared";
import { HtmlContent } from "~views/shared/HtmlContent/HtmlContent";
import { TrackingAction, TrackingCategory } from "netbank-shared/src/libs/models/Tracking";
import { usePushInteraction } from "~hooks";
import { useStores } from "netbank-shared/src/hooks";
import styles from "../LoanTopUpFlow.scss";
import { FaqBlock } from "~views/shared/ContentArea/Blocks/FaqBlock";
import { LinkItemCollectionBlock } from "~views/shared/ContentArea/Blocks/LinkItemCollectionBlock";
import { DividerBlock } from "~views/shared/ContentArea/Blocks/DividerBlock";
import { IGenericContentLink } from "netbank-shared/src/libs/models/Content/ContentLink";
import { IFaq } from "netbank-shared/src/libs/models/Content/Faq";
import { ILinkItemCollection } from "netbank-shared/src/libs/models/Content/LinkItem";
import { IDivider } from "netbank-shared/src/libs/models/Content/Divider";
import { UspBlock } from "~views/shared/ContentArea/Blocks/UspBlock";
import { Checkbox } from "~views/shared/Checkbox/Checkbox";

export interface LoanTopUpInsuranceStepProps {
  data: ILoanTopUpPage;
  setNextTopUpStep: () => void;
  setPreviousTopUpStep: () => void;
}

export const LoanTopUpInsuranceStep = observer(
  ({ data, setNextTopUpStep, setPreviousTopUpStep }: LoanTopUpInsuranceStepProps) => {
    const { offerStore, uiStore } = useStores();
    const { isMobile } = uiStore;

    usePushInteraction({
      eventCategory: TrackingCategory.ProductLoan,
      eventAction: TrackingAction.LoanRaise3Insurance,
    });

    const {
      payoutSelectionStep,
      loanInsuranceHeader,
      loanInsuranceText,
      loanInsuranceUspList,
      loanInsuranceUspBackgroundColor,
      loanInsuranceUspBackgroundImage,
      loanInsuranceUspBackgroundImageMobile,
      loanInsuranceFaq,
      loanInsuranceTermsText,
    } = data;

    const loanInsuranceUspBackground = isMobile
      ? loanInsuranceUspBackgroundImageMobile
      : loanInsuranceUspBackgroundImage;

    const renderLoanInsuranceFaqs = () => {
      if (!loanInsuranceFaq || loanInsuranceFaq.length === 0) {
        return null;
      }

      return (
        <div className={styles.loanInsuranceFaqs}>
          {loanInsuranceFaq.map((item) => {
            switch (item.contentType?.[1]) {
              case "FaqBlock":
                return <FaqBlock data={item as IGenericContentLink<IFaq>} accordion />;
              case "LinkItemCollectionBlock":
                return <LinkItemCollectionBlock data={item as IGenericContentLink<ILinkItemCollection>} accordion />;
              case "DividerBlock":
                return <DividerBlock data={item as IGenericContentLink<IDivider>} accordion />;
              default:
                return null;
            }
          })}
        </div>
      );
    };

    const setNextStep = async () => {
      await offerStore.addTopUpInsuranceService(setNextTopUpStep);
    };

    return (
      <div>
        <HtmlContent html={`<h1>${loanInsuranceHeader || tx("topUp.loanInsuranceHeader")}</h1>`} />
        {loanInsuranceText && <HtmlContent html={loanInsuranceText} />}

        {loanInsuranceUspList && loanInsuranceUspList.length > 0 && (
          <div className={styles.insuranceUsp}>
            <div
              className={styles.insuranceUspList}
              style={{
                backgroundColor: loanInsuranceUspBackgroundColor,
              }}
            >
              {loanInsuranceUspBackground && (
                <img
                  className={styles.insuranceBgImage}
                  src={loanInsuranceUspBackground.url}
                  alt={loanInsuranceUspBackground.altText}
                />
              )}
              {loanInsuranceUspList.map((usp) => {
                if (!usp.content || !usp.icon) return null;
                return <UspBlock key={usp.contentLink.guidValue} icon={usp.icon} content={usp.content} />;
              })}
            </div>
          </div>
        )}
        {renderLoanInsuranceFaqs()}
        <Checkbox
          label={loanInsuranceTermsText}
          checked={offerStore.topUpInsuranceAccepted}
          disabled={offerStore.addingTopUpInsurance}
          onChange={(e) => {
            offerStore.topUpInsuranceAccepted = e.target.checked;
          }}
        />

        <div className={offerStyles.submit}>
          <Button
            title={payoutSelectionStep.previousButtonText || tx("misc.cancel")}
            onClick={setPreviousTopUpStep}
            borderColor="black"
            disabled={offerStore.addingTopUpInsurance}
            bordered
            large
          />
          <Button
            title={payoutSelectionStep.continueButtonText || tx("misc.continue")}
            color="red"
            onClick={setNextStep}
            loading={offerStore.addingTopUpInsurance}
            large
          />
        </div>
      </div>
    );
  }
);
