import React, { useMemo } from "react";
import { IThread } from "netbank-shared/src/libs/models/Message/Threads";
import { tx } from "netbank-shared/src/libs/i18n";
import { useStores } from "netbank-shared/src/hooks";
import { IMessageInboxPage } from "netbank-shared/src/libs/models/Content/Page";
import { InboxTabs } from "netbank-shared/src/libs/models/Message/Inbox";
import { MessageSender } from "netbank-shared/src/libs/models/Content/Enums";
import { Button } from "~views/shared";
import styles from "./MessageInbox.scss";
import rootStyles from "~views/pages/Root.scss";
import archiveIcon from "~assets/archive.svg";
import mailIcon from "~assets/mail.svg";
import mailOpenIcon from "~assets/mail-open.svg";
import { DynamicLink } from "~views/shared/DynamicLink/DynamicLink";

interface IMessageRowProps {
  data: IThread;
  displayLastSent?: boolean;
}

export const MessageRow = ({ data, displayLastSent }: IMessageRowProps) => {
  const [open, setOpen] = React.useState(false);
  const [archiving, setArchiving] = React.useState(false);
  const { contentStore, messageStore } = useStores();
  const page = contentStore.currentPage as IMessageInboxPage;
  const wrapperClasses = [styles.messageWrapper];
  const messageTitleClasses = [styles.title];
  const dateClasses = [styles.date];
  const actionsClasses = [styles.messageActions];
  const sentTabIsActive = messageStore.activeTab === InboxTabs.sent;

  function createRawMessage(message: string) {
    const element = document.createElement("div");
    element.innerHTML = message.split("\n").join(" ");
    return element.innerText;
  }

  if (open) {
    wrapperClasses.push(styles.open);
    dateClasses.push(styles.hidden);
  }

  if (!data.customerAccessed && !sentTabIsActive) {
    messageTitleClasses.push(styles.unread);
    dateClasses.push(styles.unread);
  }

  if (open && !data.archivedByCustomer) {
    actionsClasses.push(styles.open);
  }

  let createdDate = "";
  let messageBody = "";

  if (displayLastSent) {
    const lastSentIndex = data.messages.findIndex((m) => m.creatorReference !== MessageSender.santander);
    createdDate = data.messages[lastSentIndex]?.created.split("T")[0] || "";
    messageBody = data.messages[lastSentIndex]?.body || "";
  } else {
    createdDate = data.messages[0]?.created.split("T")[0] || "";
    messageBody = data.messages.find((m) => !!m.body)?.body || "";
  }
  const rawMessage = useMemo(() => createRawMessage(messageBody), [messageBody]);

  return (
    <div className={wrapperClasses.join(" ")}>
      <div className={styles.messageRow}>
        <DynamicLink to={`${page.messageInboxPage}?threadId=${data.threadId}`} className={styles.messageLink}>
          {!open && <div className={dateClasses.join(" ")}>{createdDate}</div>}
          <div className={styles.messageContent}>
            <div className={styles.tag}>{tx("misc.message")}</div>
            <div className={messageTitleClasses.join(" ")}>{data.title}</div>
            <div className={styles.message}>{rawMessage}</div>
          </div>
        </DynamicLink>
        <div className={styles.messageOptions}>
          <div className={styles.outsideOptions}>
            {!data.archivedByCustomer && (
              <Button onClick={() => setOpen(!open)} className={styles.expandButton}>
                <div className={rootStyles.ellipsis} />
              </Button>
            )}
            {!sentTabIsActive && (
              <img
                src={!data.customerAccessed ? mailIcon : mailOpenIcon}
                className={styles.mailIcon}
                alt={!data.customerAccessed ? "mail-icon-closed" : "mail-icon-open"}
              />
            )}
          </div>

          <div className={actionsClasses.join(" ")}>
            <div className={styles.archive}>
              {archiving && (
                <div className={styles.spinnerWrapper}>
                  <div className={[rootStyles.loading, rootStyles.spinner].join(" ")} />
                </div>
              )}
              {!archiving && (
                <Button
                  className={styles.archiveButton}
                  onClick={async () => {
                    setArchiving(true);
                    await messageStore.archive(data.threadId);
                    await messageStore.fetchThreads(false);
                  }}
                >
                  <div className={styles.iconLabelWrapper}>
                    <img className={styles.archiveIcon} src={archiveIcon} alt="archive-icon" />
                    <span>{tx("message.toArchive")}</span>
                  </div>
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
