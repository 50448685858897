import { Dayjs } from "dayjs";
import { ICreditAccount, IDepositAccount, IEngagementTransaction, ILeaseAccount, ILoanAccount } from ".";

export interface IEngagements {
  creditAccounts?: ICreditAccount[];
  depositAccounts?: IDepositAccount[];
  leaseAccounts?: ILeaseAccount[];
  securedLoanAccounts?: ILoanAccount[];
  privateLoanAccounts?: ILoanAccount[];
  securedLoanAccountsError: IEngagementsError;
  privateLoanAccountsError: IEngagementsError;
  creditAccountsError: IEngagementsError;
  depositAccountsError: IEngagementsError;
  leaseAccountsError: IEngagementsError;
}

export interface ICreditEngagements {
  creditAccounts?: ICreditAccount[];
  error: IEngagementsError;
}

export interface ISingleCreditEngagement {
  creditAccount?: ICreditAccount;
}

export interface IDepositEngagements {
  depositAccounts?: IDepositAccount[];
  error: IEngagementsError;
}

export interface ILeaseEngagements {
  leaseAccounts?: ILeaseAccount[];
  error: IEngagementsError;
}
export interface ILoanEngagements {
  loanAccounts?: ILoanAccount[];
  error: IEngagementsError;
}

export interface IEngagementsError {
  message?: string;
}

export interface IAccountTransactions {
  transactions: IEngagementTransaction[];
  nextToken?: string;
}

export interface ICard {
  cardId: string;
  accountNumber?: string;
  annualFee?: number;
  displayNumber?: string;
  status?: CardStatus;
  isVirtual?: boolean;
  isActive: boolean | null;
  isManufactured?: boolean;
  cardTypeName?: string;
  expireDate?: Date;
  embossingDate?: Date;
  embossingName?: string;
  insuranceType?: IInsuranceType;
  isSecureCodeRegistered?: boolean;
}

export enum OrderCardReason {
  Lost = 0,
  Stolen = 1,
  Order = 2,
  Swallowed = 3,
}

export enum BlockCardReason {
  Other = 0,
  Lost = 1,
  Stolen = 2,
}

export enum CardStatus {
  Active = "Active", // 1
  Inactive = "Inactive", // 2
  Blocked = "Blocked", // 3
  SoftBlocked = "SoftBlocked", // 4
}

export interface IInsuranceType {
  insuranceTypeText?: string;
  hasExtendedWarranty?: boolean;
  hasCarRentalInsurance?: boolean;
  hasIdTheftInsurance?: boolean;
  hasTravelInsurance?: boolean;
  purchaseInsurance?: string;
}

export interface IVendorAddress {
  street1: string;
  street2: string;
  street3: string;
  city: string;
  postalCode: string;
  country: string;
  type: string;
}

export interface IVendor {
  addresses?: IVendorAddress[];
  id: string;
  customerNumber?: number;
  name?: string;
  contactName?: string;
  phones?: string[];
  mobiles?: string[];
  eMails?: string[];
}

export interface IEngagementsObject {
  id: number;
  objectId?: string;
  make?: string;
  model?: string;
  serial?: string;
  year?: number;
}

export interface IEvryToken {
  wctx: string;
  wresult: string;
  url: string;
  validTo: Dayjs;
}
