import { observable, action, makeObservable, computed } from "mobx";
import dayjs from "dayjs";
import { ApiErrorResponse, ApiOkResponse } from "apisauce";
import { EngagementsApi, SantanderAuthApi, ChatApi } from "../libs/api";
import { base64ToBlob, createAutoDownloadTempLink, keysToCamel } from "../libs/utils";
import { IGetTokenResponse } from "../libs/models/Auth";
import {
  CustomerContactInfoField,
  IEngagementsCustomer,
  UpdateCustomerInfoStatus,
} from "../libs/models/Customer/EngagementsCustomer";
import { IDataPortabilityDocument, IEvryToken } from "../libs/models/Engagements";
import { Store } from "./Store";
import { IContentAreaItem } from "../libs/models/Content/ContentAreaItem";
import { CustomerPersistentStore } from "./CustomerPersistentStore";
import { EmailValidator, PhoneValidator } from "../libs/validators";
import { BankIdSignStore } from "./BankIdSignStore";

export class CustomerStore {
  authApi: SantanderAuthApi;

  chatApi: ChatApi;

  engagementsApi: EngagementsApi;

  rootStore: Store;

  customerPersistentStore: CustomerPersistentStore;

  bankIdSignStore: BankIdSignStore;

  constructor(
    authApi: SantanderAuthApi,
    engagementsApi: EngagementsApi,
    chatApi: ChatApi,
    rootStore: Store,
    customerPersistentStore: CustomerPersistentStore,
    bankIdSignStore: BankIdSignStore
  ) {
    this.authApi = authApi;
    this.engagementsApi = engagementsApi;
    this.chatApi = chatApi;
    this.rootStore = rootStore;
    this.customerPersistentStore = customerPersistentStore;
    this.bankIdSignStore = bankIdSignStore;
    makeObservable(this);
  }

  @observable
  currentCustomer?: IEngagementsCustomer | null = undefined;

  @observable
  unreadMessages: number = 0;

  @observable
  unpaidInvoices: number = 0;

  @observable
  evryCardToken?: IEvryToken;

  @observable
  evryPinToken?: IEvryToken;

  @observable
  loadingEvryPinIframe?: boolean;

  @observable
  loadingEvryCardIframe?: boolean;

  @observable
  loginOnSameDevice: boolean = false;

  @observable
  loginResubmitForm: () => void = () => { }

  @observable
  fetchingDataPortabilityDocument?: boolean;

  @observable
  loadingGiosg = false;

  @observable
  isGiosgInitialized = false;

  @observable
  giosgVisitorId = "";

  @observable
  giosgRooms: string[] = [];

  @observable
  isUpdatingContactInfo = false;

  @observable
  hasUpdateEmailError?: boolean = false;

  @observable
  hasUpdatePhoneNumberError?: boolean = false;

  @observable
  wasEmailEdited?: boolean = false;

  @observable
  wasPhoneNumberEdited?: boolean = false;

  @observable
  fetchingAccessToken?: boolean = false;

  setCurrentCustomer = (customer: IEngagementsCustomer) => {
    this.currentCustomer = customer;
  };

  @computed
  get hasParties(): boolean {
    return (this.currentCustomer?.parties && this.currentCustomer.parties.length > 0) || false;
  }

  hasAnyUpdateCustomerInfoError = () => {
    return (
      (this.hasUpdateEmailError && this.wasEmailEdited) ||
      (this.hasUpdatePhoneNumberError && this.wasPhoneNumberEdited)
    );
  };

  @action
  resetUpdateCustomerInfoAndErrors = (email: string, phone: string) => {
    this.hasUpdateEmailError = false;
    this.hasUpdatePhoneNumberError = false;
    this.wasEmailEdited = false;
    this.wasPhoneNumberEdited = false;
    if (this.currentCustomer != null) {
      this.currentCustomer.email = email;
      this.currentCustomer.phone = phone;
    }
  };

  @action
  updateContactInfo = async () => {
    if (!this.currentCustomer) return false;

    this.isUpdatingContactInfo = true;

    const { email, phone } = this.currentCustomer;

    this.hasUpdatePhoneNumberError = !PhoneValidator(phone || "", {}).valid;
    this.hasUpdateEmailError = !EmailValidator(email || "", {}).valid;

    if (this.hasAnyUpdateCustomerInfoError()) {
      this.isUpdatingContactInfo = false;
      return UpdateCustomerInfoStatus.ValidationError;
    }

    const updatedData = {
      mobileNumber: this.currentCustomer.mobile,
      phoneNumber: this.currentCustomer.phone,
      email: this.currentCustomer.email,
    };

    const response = await this.engagementsApi.updateContactInfo(updatedData);
    this.isUpdatingContactInfo = false;

    if (response?.ok) {
      this.getCustomer(true);
      return UpdateCustomerInfoStatus.UpdateSuccessfull;
    }

    return UpdateCustomerInfoStatus.UpdateFailed;
  };

  @action
  updateCustomerContactInfo = (field: CustomerContactInfoField, value: string) => {
    if (!this.currentCustomer) return;
    switch (field) {
      case CustomerContactInfoField.Email:
        this.wasEmailEdited = true;
        this.hasUpdateEmailError = false;
        this.currentCustomer.email = value;
        break;
      case CustomerContactInfoField.Phone:
        this.wasPhoneNumberEdited = true;
        this.hasUpdatePhoneNumberError = false;
        this.currentCustomer.phone = value;
        break;
      default:
    }
  };

  @action
  isOnlyCorporateCustomer = () => {
    return (
      this.rootStore.allEngagements?.length === 0 &&
      this.hasParties &&
      this.currentCustomer?.isPartnerCustomer === false
    );
  };

  @action
  getCustomer = async (refresh: boolean = false) => {
    if (this.currentCustomer && refresh === false) return this.currentCustomer;
    const response = await this.engagementsApi.getCustomer(refresh);
    if (response?.ok && response.data?.customer) {
      this.currentCustomer = response.data.customer;

      const { customerId } = response.data.customer;

      if (!this.rootStore.customerPersistentStore.isHydrated) {
        await this.rootStore.customerPersistentStore.hydrate(customerId);
      }
      this.rootStore.globalPersistentStore.customerId = customerId;
      const { customer } = response.data;
      return customer;
    }
    this.currentCustomer = null;
    return null;
  };

  getEngagements = async () => this.rootStore.getEngagements();

  getCurrentParty = () => {
    const { currentOrganizationNumber } = this.customerPersistentStore;

    if (!currentOrganizationNumber) return undefined;

    return this.currentCustomer?.parties?.find((party) => party.identityNumber === currentOrganizationNumber);
  };

  getShowPinToken = async () => {
    if (this.loadingEvryPinIframe) return;
    this.loadingEvryPinIframe = true;
    const response = await this.engagementsApi.getShowPinToken();
    if (response?.ok && response.data) {
      this.evryPinToken = response.data;
      this.evryPinToken.validTo = dayjs().add(20, "minutes");
    }
    this.loadingEvryPinIframe = false;

    // Todo - handle error response according to design.
  };

  getShowCardToken = async () => {
    if (this.loadingEvryCardIframe) return;
    this.loadingEvryCardIframe = true;
    const response = await this.engagementsApi.getShowCardToken();
    if (response?.ok && response.data) {
      this.evryCardToken = response.data;
      this.evryCardToken.validTo = dayjs().add(20, "minutes");
    }
    this.loadingEvryCardIframe = false;

    // Todo - handle error response according to design.
  };

  handleLoginFailed = (timeout: boolean = false) => {
    this.resetLoginState();
    if (timeout) {
      this.rootStore.setBankIdTimeoutError(this.loginResubmitForm);
    }
    else {
      this.rootStore.setBankIdGeneralError(this.loginResubmitForm)
    }
  }

  handleCollectSuccess = async (successCallback: () => void) => {
    const loginNonce = this.bankIdSignStore.nonce;
    this.bankIdSignStore.clear();
    this.fetchingAccessToken = true;
    const tokenResponse = await this.authApi.getToken(loginNonce);
    if (this.handleTokenResponse(tokenResponse)) {
      successCallback();
    }
    else {
      this.handleLoginFailed(false)
    }
  }

  login = async (
    resubmitForm: () => void,
    successCallback: () => void) => {
    this.loginResubmitForm = resubmitForm;

    if (this.loginOnSameDevice) {
      this.bankIdSignStore.onAutoStartTokenFetched = (token) => this.rootStore.openBankId(token);
    }

    this.bankIdSignStore.onQrCodeRefreshFailed = () => this.handleLoginFailed(false);
    this.bankIdSignStore.onCollectFailed = (reason) => this.handleLoginFailed(reason === "Timeout")

    this.bankIdSignStore.onCollectSuccess = async () => {
      await this.handleCollectSuccess(successCallback);
    }

    const returnUrl = this.loginOnSameDevice ? this.rootStore.bankIdRedirectUrl() : "";

    const result = await this.bankIdSignStore.startLogin(returnUrl, this.loginOnSameDevice);

    if (!result.initialized) {
      this.handleLoginFailed(false);
    }
  };

  redirectFromDifferentBrowserLogin = async (
    nonce: string,
    startState: string,
    resubmitForm: () => void,
    successCallback: () => void) => {
    this.loginResubmitForm = resubmitForm;


    if (this.bankIdSignStore.nonce === "" && this.bankIdSignStore.bankIdStartState === "") {
      this.bankIdSignStore.bankIdStartState = startState;
      this.bankIdSignStore.nonce = nonce

      if (await this.bankIdSignStore.onePassCollect()) {
        await this.handleCollectSuccess(successCallback)
      }
      else {
        this.handleLoginFailed(false);
      }
    }
  }


  getToken = async () => {
    this.fetchingAccessToken = true;
    const tokenResponse = await this.authApi.getToken(this.bankIdSignStore.nonce);
    return this.handleTokenResponse(tokenResponse, this.loginResubmitForm);
  };

  cancelLogin = async () => {
    this.bankIdSignStore.clear();
    this.fetchingAccessToken = false;
  };

  refreshToken = async () => {
    const { refreshToken } = this.rootStore.securePersistentStore;
    if (!refreshToken) return;

    const tokenResponse = await this.authApi.refreshToken(refreshToken);

    this.handleTokenResponse(tokenResponse);
  };

  handleTokenResponse = (
    tokenResponse: ApiErrorResponse<IGetTokenResponse> | ApiOkResponse<IGetTokenResponse> | undefined,
    resubmitForm?: () => void
  ): boolean => {
    if (tokenResponse?.ok && tokenResponse.data) {
      const data = keysToCamel(tokenResponse.data) as IGetTokenResponse;
      if (data?.accessToken) {
        this.rootStore.securePersistentStore.setAccessToken(data.accessToken);
      }

      if (data?.refreshToken) {
        this.rootStore.securePersistentStore.setRefreshToken(data.refreshToken);
      }

      if (data?.expiresIn && data?.refreshToken) {
        const expiresInMs = (data.expiresIn - 60) * 1000;
        this.rootStore.securePersistentStore.setExpiresIn(expiresInMs);
        this.rootStore.securePersistentStore.setTokenTimestamp(dayjs().toString());
      }
      return true;
    }

    this.resetLoginState();
    if (resubmitForm) {
      this.rootStore.setBankIdGeneralError(resubmitForm);
    }
    return false;
  };

  resetLoginState = () => {
    this.bankIdSignStore.clear();
    this.fetchingAccessToken = false;
    this.rootStore.removeBankIdHash();
  };

  customerCanActOn = (blockType?: string) => this.rootStore.customerCanActOn(blockType);

  canActOnSelfServiceContent = (content: IContentAreaItem): boolean => {
    let res = true;
    if (this.customerCanActOn(content?.contentType?.[1])) {
      if (content?.content) {
        content.content.forEach((el: IContentAreaItem) => {
          res = res && this.canActOnSelfServiceContent(el);
        });
      } else {
        res = true;
      }
    } else {
      res = false;
    }
    return res;
  };

  getDataPortabilityDocument = async (): Promise<IDataPortabilityDocument | null> => {
    this.fetchingDataPortabilityDocument = true;
    const response = await this.engagementsApi.getDataPortabilityDocument();
    if (response?.ok && response.data) {
      const { content, name, mimeType } = response.data;
      if (content && name && mimeType) {
        const blob = base64ToBlob(content, mimeType);
        const objectUrl = URL.createObjectURL(blob);
        createAutoDownloadTempLink(name, objectUrl, true);
        this.fetchingDataPortabilityDocument = false;
        return response.data;
      }
    }

    this.fetchingDataPortabilityDocument = false;
    return null;
  };

  getDataPortabilityDocumentData = async (): Promise<IDataPortabilityDocument | null> => {
    this.fetchingDataPortabilityDocument = true;
    const response = await this.engagementsApi.getDataPortabilityDocument();
    if (response?.ok && response.data?.content && response.data?.name) {
      this.fetchingDataPortabilityDocument = false;
      return response.data;
    }
    this.fetchingDataPortabilityDocument = false;
    return null;
  };

  @action
  initializeGiosg = async (visitorId: string, rooms: string[]) => {
    if (this.loadingGiosg) return;
    this.loadingGiosg = true;
    await this.chatApi.initiateChat(visitorId, rooms);
    this.loadingGiosg = false;
    this.giosgVisitorId = visitorId;
    this.giosgRooms = rooms;
    this.setIsGiosgInitialized(true);
  };

  @action
  resendGiosgData = async (visitorId: string, rooms: string[]) => {
    if (this.loadingGiosg) return;
    this.loadingGiosg = true;
    await this.chatApi.initiateChat(visitorId, rooms);
    this.loadingGiosg = false;
  }

  @action
  setIsGiosgInitialized = (value: boolean) => {
    this.isGiosgInitialized = value;
  };

  @action
  resetEvryTokens = () => {
    this.evryCardToken = undefined;
    this.evryPinToken = undefined;
  };

  @action
  resetStore = () => {
    this.currentCustomer = undefined;
    this.evryCardToken = undefined;
    this.evryPinToken = undefined;
    this.loadingEvryPinIframe = false;
    this.loadingEvryCardIframe = false;
    this.loginOnSameDevice = false;
    this.loginResubmitForm = () => { };
    this.fetchingDataPortabilityDocument = false;
    this.loadingGiosg = false;
    this.isGiosgInitialized = false;
    this.giosgVisitorId = "";
    this.giosgRooms = [];
    this.wasEmailEdited = false;
    this.wasPhoneNumberEdited = false;
    this.hasUpdateEmailError = false;
    this.hasUpdatePhoneNumberError = false;
    this.fetchingAccessToken = false;
  };
}