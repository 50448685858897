import { IAttachmentResponse } from "../Attachment/Attachment";

export interface IThreads {
  threads: IThread[];
}

export interface IThread {
  threadId: string;
  created: string;
  archivedByCustomer: boolean;
  customerAccessed: boolean;
  title: string;
  status: string;
  messages: IMessage[];
}

export interface IMessage {
  messageId: string;
  threadId: string;
  creatorReference: string;
  created: string;
  body: string;
  attachmentId: string;
  attachments?: IAttachmentResponse[];
}

export enum ThreadStatus {
  closed = "Closed",
}
