import React from "react";
import { IContentAreaItem } from "netbank-shared/src/libs/models/Content/ContentAreaItem";
import { IModal } from "netbank-shared/src/libs/models/Content/Modal";
import { ICreditAccount } from "netbank-shared/src/libs/models/Engagements";
import { useStores } from "netbank-shared/src/hooks";

interface IModalButtonProps {
  buttonLabel: string;
  modalLabel: string;
  account: ICreditAccount;
  labelClassName: string;
  disabledClassName?: string;
  modalTitle?: string;
  modalContent?: IContentAreaItem[];
  disabled?: boolean;
}

export const ModalButton = ({
  buttonLabel,
  modalLabel,
  account,
  labelClassName,
  modalTitle,
  modalContent,
  disabled,
  disabledClassName,
}: IModalButtonProps) => {
  const { uiStore } = useStores();
  if (!modalContent) return null;

  if (!account.canOrderCard) {
    return <span>{buttonLabel}</span>;
  }

  const modal: IModal = {
    title: modalTitle || "",
    content: modalContent || [],
  };

  return (
    <span>
      {`${buttonLabel} -`}

      <span
        className={[labelClassName, disabled && disabledClassName].join(" ").trim()}
        role="button"
        aria-label={modalLabel}
        tabIndex={0}
        onClick={() => uiStore.setModal(modal)}
      >
        {modalLabel}
      </span>
    </span>
  );
};
