import { observer } from "mobx-react";
import React from "react";
import { useStores } from "netbank-shared/src/hooks";
import { IEmptyState } from "netbank-shared/src/libs/models/Content/EmptyState";
import { isProperty } from "netbank-shared/src/libs/models/Content/Property";
import { Button } from "..";
import { DynamicLink } from "../DynamicLink/DynamicLink";
import { HtmlContent } from "../HtmlContent/HtmlContent";
import styles from "./EmptyState.scss";
import { TrackingAction, TrackingCategory } from "netbank-shared/src/libs/models/Tracking";
import { usePushInteraction } from "~hooks";

interface IEmptyStateProps {
  data: IEmptyState;
  wrapperClassName?: string;
  closeAction?: () => void;
  returnLink?: string;
  trackingAction?: TrackingAction;
  trackingCategory?: TrackingCategory;
  trackingPrefix?: string;
}

export const EmptyState = observer(
  ({
    data,
    wrapperClassName,
    closeAction,
    returnLink,
    trackingAction,
    trackingCategory,
    trackingPrefix,
  }: IEmptyStateProps) => {
    if (!data) return null;

    const { emptyStateImageMobile, emptyStateImage, header, textContent, returnLabel, closeLabel } = data;
    const { uiStore } = useStores();
    const { isMobile } = uiStore;

    usePushInteraction({
      eventCategory: trackingCategory,
      eventAction: trackingAction,
      eventPrefix: trackingPrefix,
      requirement: !!trackingCategory && !!trackingAction,
    });

    const image = isMobile ? emptyStateImageMobile : emptyStateImage;
    const imageSource = isProperty(image) ? image?.value?.url : image?.url;
    const imageAlt = isProperty(image) ? image.value.altText || image.value.name : image?.altText || image?.name;

    const title = isProperty(header) ? header?.value : header;
    const content = isProperty(textContent) ? textContent?.value : textContent;
    const returnLinkLabel = isProperty(returnLabel) ? returnLabel?.value : returnLabel;
    const closeButtonLabel = isProperty(closeLabel) ? closeLabel?.value : closeLabel;
    const wrapperStyle = [styles.wrapper, wrapperClassName];

    return (
      <div className={wrapperStyle.join(" ")}>
        <div className={styles.imagesWrapper}>
          <img className={styles.image} src={imageSource} alt={imageAlt} />
        </div>
        <div className={styles.textWrapper}>
          {title && (
            <div className={styles.titleWrapper}>
              <h4 dangerouslySetInnerHTML={{ __html: title }} />
            </div>
          )}
          {content && (
            <div className={styles.bodyWrapper}>
              <HtmlContent html={content} />
            </div>
          )}
        </div>
        {(closeAction || returnLink) && (
          <div className={styles.returnWrapper}>
            {closeAction && closeButtonLabel && (
              <Button onClick={closeAction} className={styles.return}>
                <span>{closeButtonLabel}</span>
              </Button>
            )}
            {returnLink && returnLinkLabel && (
              <DynamicLink to={returnLink} className={styles.return}>
                <span>{returnLinkLabel}</span>
              </DynamicLink>
            )}
          </div>
        )}
      </div>
    );
  }
);
