import React, { useEffect, useState } from "react";
import { initStore, Store } from "netbank-shared/src/stores/Store";
import { StoreContext } from "netbank-shared/src/contexts/storesContext";
import { PageLoader } from "~views/shared";
import { OutdatedBrowser } from "~views/pages/OutdatedBrowser/OutdatedBrowser";
import { getConfig } from "../config/config";
import { WebCommonService } from "./WebCommonService";
import WebPlatformStore from "./WebPlatformStore";
import { useNavigate } from "react-router-dom";

export const withStore = (Comp: any) => {
  const ComponentWithStore = () => {
    const [store, setStore] = useState<Store>();
    const navigate = useNavigate();

    useEffect(() => {
      const mountStore = async () => {
        const config = await getConfig();
        const s = initStore(localStorage, localStorage, new WebCommonService(navigate), new WebPlatformStore());

        const mountedStore = await s.onMount(config, window, navigate);
        setStore(mountedStore);
      };

      mountStore();
    }, []);

    if (!store?.config) {
      return null;
    }

    return (
      <StoreContext.Provider value={store}>
        {store.uiStore.hasOutdatedBrowser ? (
          <OutdatedBrowser />
        ) : (
          <>
            <PageLoader />
            <Comp store={store} />
          </>
        )}
      </StoreContext.Provider>
    );
  };

  return ComponentWithStore;
};
