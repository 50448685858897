import styles from "./Login.scss";
import * as React from "react";
import { IParty } from "netbank-shared/src/libs/models/Customer/EngagementsCustomer";
import chevronRight from "~assets/chevron-right.svg";
import chevronRightBlue from "~assets/chevron-right-blue.svg";

interface IProfilePicker {
  parties: IParty[];
  textContent: { header: string; subheader: string; privateProfileLabel: string; organizationProfileLabel: string };
  setProfile: (isOrganization: boolean, organizationNumber: string) => void;
}

export const ProfilePicker = ({ parties, textContent, setProfile }: IProfilePicker) => {
  const { header, subheader, organizationProfileLabel, privateProfileLabel } = textContent;

  return (
    <div className={styles.profilePickerWrapper}>
      <div className={styles.header}>
        <h2>{header}</h2>
      </div>
      <p className={styles.subHeader}>{subheader}</p>
      <div className={styles.profiles}>
        {parties.map((party) => {
          return (
            <button
              key={`${party.identityNumber}-${party.displayName}`}
              type="button"
              onClick={() => setProfile(party.isOrganization, party.identityNumber)}
            >
              <div className={styles.text}>
                <h3>{party.displayName}</h3>
                <p>{party.displayIdentityNumber}</p>
                <p>{party.isOrganization ? organizationProfileLabel : privateProfileLabel}</p>
              </div>
              <img className={styles.chevron} src={chevronRight} alt="icon" />
              <img className={`${styles.chevron} ${styles.blue}`} src={chevronRightBlue} alt="icon" />
            </button>
          );
        })}
      </div>
    </div>
  );
};
