import { observer } from "mobx-react";
import { IBasicInfoBlock } from "netbank-shared/src/libs/models/Content/BasicInfoBlock";
import { IGenericContentLink } from "netbank-shared/src/libs/models/Content/ContentLink";
import { NotificationLevel } from "netbank-shared/src/libs/models/Content/Notification";
import React from "react";
import errorIcon from "../../../../../static/assets/error.svg";
import importantIcon from "../../../../../static/assets/important.svg";
import informationIcon from "../../../../../static/assets/information.svg";
import rootStyles from "../../../pages/Root.scss";
import { HtmlContent } from "../../HtmlContent/HtmlContent";
import notificationsStyles from "../../Notifications/Notifications.scss";
import styles from "./BasicInfoBlock.scss";

interface IBasicInfoBlockProps {
  key: string;
  data: IGenericContentLink<IBasicInfoBlock>;
  nextBlockType?: string;
  accordion?: boolean;
  noBorder?: boolean;
  noBottomPadding?: boolean;
  noTopPadding?: boolean;
  inModal?: boolean;
}

export const BasicInfoBlock = observer(
  ({ data, accordion, noBorder, noBottomPadding, noTopPadding, inModal }: IBasicInfoBlockProps) => {
    const { level, showIcon, header, textArea } = data;
    const classes = [
      styles.wrapper,
      notificationsStyles.notification,
      notificationsStyles.noMargin,
      rootStyles.NoHorizontalPadding,
      styles.fullWidthNotification,
    ];

    let icon;
    switch (level) {
      case NotificationLevel.information:
        classes.push(notificationsStyles.information);
        icon = informationIcon;
        break;
      case NotificationLevel.important:
        classes.push(notificationsStyles.important);
        icon = importantIcon;
        break;
      case NotificationLevel.error:
        classes.push(notificationsStyles.error);
        icon = errorIcon;
        break;
      default:
        classes.push(notificationsStyles.information);
        break;
    }

    if (!textArea) return null;

    if (accordion) {
      classes.push(styles.accordion, rootStyles.noBorder);
    }

    if (noBorder) {
      classes.push(rootStyles.noBorder);
    }

    if (noBottomPadding) {
      classes.push(rootStyles.noBottomPadding);
    }

    if (noTopPadding) {
      classes.push(rootStyles.noTopPadding);
    }

    if (inModal) {
      classes.push(styles.inModal);
    }

    return (
      <section className={classes.join(" ")}>
        <div className={notificationsStyles.wrapper}>
          {showIcon && !!icon && <img className={notificationsStyles.icon} src={icon} alt={level} />}
          <div className={notificationsStyles.contentWrapper}>
            {!!header && <h2 className={notificationsStyles.header}>{header}</h2>}
            <HtmlContent className={styles.container} html={textArea} />
          </div>
        </div>
      </section>
    );
  }
);
