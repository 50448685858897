import React from "react";
import { observer } from "mobx-react";
import styles from "./Applicant.scss";
import rootStyles from "~views/pages/Root.scss";
import { ITopUpApplicantForm } from "netbank-shared/src/libs/models/Offer/Offer";
import { tx } from "netbank-shared/src/libs/i18n";
import { citizenshipsList } from "netbank-shared/src/libs/models/Citizenships";
import { RadioButtons } from "..";
import { Checkbox } from "../Checkbox/Checkbox";
import errorIcon from "~assets/error.svg";
import { useStores } from "netbank-shared/src/hooks";
import { MultiSelect } from "../MultiSelect/MultiSelect";
import { toJS } from "mobx";

export interface IApplicantEBAInformationLabelsProps {
  pepScreenTips: string;
  pepLabel: string;
  pepInformation: string;
  isPersonallyApplyingLabel: string;
  isPersonallyApplyingCheckboxText: string;
  isPersonallyApplyingErrorText: string;
}

interface IApplicantEBAInformationProps {
  data: IApplicantEBAInformationLabelsProps;
  applicant: ITopUpApplicantForm;
  applicantType: "main-applicant" | "co-applicant";
  setApplicant: (applicant: ITopUpApplicantForm) => void;
  showValidationErrors: boolean;
}

export const ApplicantEBAInformation = observer(
  ({ data, applicant, applicantType, setApplicant, showValidationErrors }: IApplicantEBAInformationProps) => {
    const { uiStore } = useStores();
    const { isMobile } = uiStore;

    const pepClasses = [styles.inputWrapper];

    if (data.pepScreenTips) {
      pepClasses.push(styles.fullWidth);
    }

    const setRadioButtonValue = (value: boolean | undefined) => {
      if (value === undefined) return "";
      return value ? "yes" : "no";
    };

    const missingCitizenshipsError =
      showValidationErrors && applicant.citizenships?.value.length === 0 && tx("error.required");
    const tooManyCitizenshipsError =
      showValidationErrors && applicant.citizenships?.value.length > 5 && tx("topUp.tooManyCitizenshipsError");

    return (
      <div className={styles.wrapper}>
        <div className={[styles.inputWrapper, styles.fullWidth].join(" ")}>
          <MultiSelect
            label={tx("topUp.citizenShipsLabel")}
            placeholder={tx("misc.chooseCitizenshipPlaceholder")}
            onChange={(value) => {
              setApplicant({
                ...applicant,
                citizenships: {
                  value,
                  isValid: value && value.length > 0 && value.length <= 5,
                },
              });
            }}
            value={toJS(applicant.citizenships.value)}
            options={citizenshipsList}
            error={missingCitizenshipsError || tooManyCitizenshipsError || undefined}
            showMenuOnFocus
          />
        </div>
        <div className={pepClasses.join(" ")}>
          <RadioButtons
            label={data.pepLabel || tx("topUp.pepLabel")}
            infoPopover={{
              content: data.pepInformation,
              popoverTitle: data.pepLabel || tx("topUp.pepLabel"),
            }}
            topText={data.pepScreenTips}
            list={[
              {
                label: tx("misc.yes"),
                value: "yes",
                index: 0,
              },
              {
                label: tx("misc.no"),
                value: "no",
                index: 1,
              },
            ]}
            value={setRadioButtonValue(applicant.isPep.value)}
            onChange={(item) => {
              const value = item.value === "yes";
              setApplicant({
                ...applicant,
                isPep: { value, isValid: true },
              });
            }}
            error={showValidationErrors && !applicant.isPep.isValid ? tx("error.required") : undefined}
          />
        </div>
        <div style={{ marginBottom: isMobile ? 24 : 35 }}>
          <span className={rootStyles.label}>
            {data.isPersonallyApplyingLabel || tx("topUp.isPersonallyApplyingLabel")}
          </span>
          <Checkbox
            label={data.isPersonallyApplyingCheckboxText || tx("topUp.isPersonallyApplyingCheckboxText")}
            checkboxId={`${applicantType}-applying-personally`}
            checked={applicant.isApplyingPersonally.value}
            onChange={() => {
              setApplicant({
                ...applicant,
                isApplyingPersonally: {
                  value: !applicant.isApplyingPersonally.value,
                  isValid: applicant.isApplyingPersonally.value === true,
                },
              });
            }}
          />
          {showValidationErrors && !applicant.isApplyingPersonally.value && (
            <div className={rootStyles.error}>
              <img src={errorIcon} alt="error-icon" />
              <span>{data.isPersonallyApplyingErrorText || tx("topUp.isPersonallyApplyingErrorText")}</span>
            </div>
          )}
        </div>
      </div>
    );
  }
);
