import { observer } from "mobx-react";
import React from "react";
import { useStores } from "netbank-shared/src/hooks";
import { IEmptyState } from "netbank-shared/src/libs/models/Content/EmptyState";
import { INewMessagePage } from "netbank-shared/src/libs/models/Content/Page";
import { EmptyState } from "~views/shared";
import accordionStyles from "../../shared/ContentArea/Blocks/AccordionBlock.scss";
import styles from "./MessageEmptyState.scss";
import newMessageStyles from "./NewMessage.scss";

interface IMessageEmptyStateProps {
  data: IEmptyState;
}

export const MessageEmptyState = observer(({ data }: IMessageEmptyStateProps) => {
  const { contentStore, uiStore } = useStores();
  const page = contentStore.currentPage as INewMessagePage;
  const currentUrl = contentStore.currentPage?.url;
  const returnUrl =
    page.newMessagePage && currentUrl?.match(page.newMessagePage) ? page.newMessagePage : page.messageInboxPage;
  const wrapperStyles = [];
  if (uiStore.isMobile) {
    wrapperStyles.push(newMessageStyles.newMessageWrapper, styles.mobileWrapper);
  }

  const contentStyles = [accordionStyles.content, accordionStyles.verticalPadding];

  return (
    <section className={wrapperStyles.join(" ")}>
      <div className={newMessageStyles.newMessageWrapper}>
        <div className={contentStyles.join(" ")}>
          <EmptyState data={data} wrapperClassName={styles.emptyState} returnLink={returnUrl} />
        </div>
      </div>
    </section>
  );
});
