import React, { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import TagManager from "react-gtm-module";
import { BrowserRouter } from "react-router-dom";
import { configure } from "mobx";
import { RouterWrapper } from "./RouterWrapper";
import { getConfig } from "../config/config";
import { IConfig } from "netbank-shared/src/libs/models/Config";
import { observer } from "mobx-react";

configure({ useProxies: "never", enforceActions: "never" });

export const App = () => {
  const [config, setConfig] = useState<IConfig | undefined>(undefined);
  const initGtm = async (gtmId: string | undefined) => {
    if (gtmId && gtmId.indexOf("#") === -1) {
      TagManager.initialize({ gtmId });
    }
  };

  useEffect(() => {
    const initConfig = async () => {
      if (!config) {
        const response = await getConfig();
        setConfig(response);
        initGtm(response?.GTM_ID);
      }
    };

    initConfig();
  }, []);

  if (!config) return null;

  return (
    <BrowserRouter>
      <RouterWrapper />
    </BrowserRouter>
  );
};

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<App />);
