import { Button } from "~views/shared/Button/Button";
import styles from "../BankAccountControlFlow.scss";
import React from "react";

interface KreditzLegalInformationStepProps {
  title: string;
  body: string;
  continueLabel: string;
  handleContinue: () => void;
}

export const BankAccountControlLegalInformationStep = ({
  title,
  body,
  continueLabel,
  handleContinue,
}: KreditzLegalInformationStepProps) => {
  return (
    <div className={styles.legalInformationContainer}>
      <div className={styles.contentContainer}>
        <div className={styles.header}>{title}</div>
        <div className={styles.bodyContainer} dangerouslySetInnerHTML={{ __html: body }} />
      </div>

      <div className={styles.buttonContainer}>
        <Button title={continueLabel} color="red" bordered fullWidth onClick={handleContinue} />
      </div>
    </div>
  );
};
