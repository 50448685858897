import styles from "../BankAccountControlFlow.scss";
import React from "react";
import { Lottie } from "~views/shared/Lottie/Lottie";
import loadingAnimationData from "~assets/lottie/loading.json";
import { observer } from "mobx-react";

export interface BankAccountControlPollingResultStepProps {
  waitingForResultHeader: string;
  waitingForResultBody: string;
}

export const BankAccountControlPollingResultStep = observer(
  ({ waitingForResultHeader, waitingForResultBody }: BankAccountControlPollingResultStepProps) => {
    return (
      <div className={styles.pollingResultContainer}>
        <Lottie
          options={{
            animationData: loadingAnimationData,
          }}
          height={100}
          width={100}
        />
        <div className={styles.textContainer}>
          <h1>{waitingForResultHeader}</h1>
          <span className={styles.description}>{waitingForResultBody}</span>
        </div>
      </div>
    );
  }
);
