import { observer } from "mobx-react";
import { useLogoAnimation, useStores } from "netbank-shared/src/hooks";
import { NotificationLevel } from "netbank-shared/src/libs/models/Content/Notification";
import { IProductRouteParams } from "netbank-shared/src/libs/models/Routing";
import { getDaysLeftUntil, templateString, toLocaleString } from "netbank-shared/src/libs/utils";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, InfoText } from "~views/shared";
import { ContentArea } from "~views/shared/ContentArea/ContentArea";
import { NotFoundPage } from "../NotFound/NotFound";
import styles from "../Page.scss";
import { withRoot } from "../Root";
import { ProductPageSkeleton } from "../Skeletons/ProductPageSkeleton";
import { tx } from "netbank-shared/src/libs/i18n";
import { IDepositAccount } from "netbank-shared/src/libs/models/Engagements";
import editIcon from "~assets/pencil-outline.svg";
import { IContentAreaItem } from "netbank-shared/src/libs/models/Content/ContentAreaItem";
import { IModal } from "netbank-shared/src/libs/models/Content/Modal";
import { getAccountDisplayName } from "netbank-shared/src/libs/utils/accountName";
import { AccountStatus } from "netbank-shared/src/libs/models/Engagements/Account";
import { HtmlContent } from "~views/shared/HtmlContent/HtmlContent";
import depositStyles from "./Deposit.scss";

export interface DepositPageProps {
  location: Location;
}

const renderCloseDate = (currentAccount: IDepositAccount, closedDateTitle?: string) => {
  const { closeDate } = currentAccount;
  if (currentAccount.accountStatus !== AccountStatus.AccountClosed || !closeDate) return undefined;

  const date = new Date(closeDate).toLocaleDateString(tx("routing.locale"));
  const title = closedDateTitle || tx("deposit.closedDateLabel");

  return (
    <p>
      <span className={styles.bold}>{title}: </span>
      {date}
    </p>
  );
};

const renderClosedAccountInfo = (currentAccount: IDepositAccount, closedAccountInfo?: string) => {
  const { accountStatus } = currentAccount;

  if (!currentAccount || accountStatus !== AccountStatus.AccountClosed) return undefined;
  const text = closedAccountInfo || tx("deposit.closedAccountInfo");
  return <HtmlContent html={text} disableTemplating className={`${depositStyles.closedDepositNotification}`} />;
};

const renderFTDDate = (currentAccount: IDepositAccount, maturityDateTitle?: string) => {
  const { maturityDate } = currentAccount;

  if (!currentAccount || !maturityDate || currentAccount.accountStatus === AccountStatus.AccountClosed)
    return undefined;

  const date = new Date(maturityDate).toLocaleDateString(tx("routing.locale"));
  const title = maturityDateTitle || tx("deposit.FTDMaturityDateTitle");

  return (
    <p>
      <span className={styles.bold}>{title}: </span>
      {date}
    </p>
  );
};

const renderFTDInformation = (
  currentAccount: IDepositAccount,
  maturityDateText?: string,
  activationDateText?: string,
  activationDaysLeftText?: string,
  lastActivationDayText?: string
) => {
  const { activationDate, maturityDate, isActivationPeriodExpired } = currentAccount;

  if (
    !currentAccount ||
    !activationDate ||
    !maturityDate ||
    currentAccount.accountStatus === AccountStatus.AccountClosed
  )
    return undefined;

  const level = isActivationPeriodExpired ? NotificationLevel.important : NotificationLevel.information;

  const daysLeftUntilActivation = getDaysLeftUntil(activationDate);
  const daysLeftUntilActivationText = templateString(
    daysLeftUntilActivation > 1
      ? activationDaysLeftText || tx("deposit.FTDActivationDaysLeftText")
      : lastActivationDayText || tx("deposit.FTDLastActivationDayText"),
    {
      activationDate: activationDate ? new Date(activationDate).toLocaleDateString(tx("routing.locale")) : "",
      maturityDate: maturityDate ? new Date(maturityDate).toLocaleDateString(tx("routing.locale")) : "",
      activationDaysLeft: daysLeftUntilActivation,
    }
  );

  const text = templateString(
    isActivationPeriodExpired
      ? maturityDateText || tx("deposit.FTDDepositPageMaturityDateTemplateStringText")
      : activationDateText || tx("deposit.FTDDepositPageActivationDateTemplateStringText"),
    {
      maturityDate: new Date(maturityDate).toLocaleDateString(tx("routing.locale")),
      activationDate: new Date(activationDate).toLocaleDateString(tx("routing.locale")),
      activationDaysLeft: daysLeftUntilActivation,
      activationDaysLeftText: daysLeftUntilActivationText,
    }
  );

  return (
    <section>
      <InfoText text={text} level={level} />
    </section>
  );
};

const DepositPage = observer(({ location }: DepositPageProps) => {
  const { depositId } = useParams<IProductRouteParams>();
  const { contentStore, depositStore, uiStore } = useStores();
  const { currency, locale } = uiStore;
  const { currentAccount } = depositStore;

  const depositTemplate = contentStore.currentDepositTemplatePage;

  useEffect(() => {
    const fetchTemplate = async () => {
      depositStore.currentAccountNumber = depositId;
      await contentStore.getDepositTemplate();
    };
    fetchTemplate();
  }, [location]);

  useLogoAnimation([uiStore.logoAnimationLoaded, depositStore.depositAccounts]);

  useEffect(() => {
    if (depositId) {
      depositStore.setCurrentAccount(depositId);
    }
  }, [currentAccount, depositStore.depositAccounts, depositId, contentStore.depositTemplatePages]);

  if (uiStore.loading) return null;

  if (contentStore.fetchingTemplatePage) {
    return <ProductPageSkeleton />;
  }

  if (!currentAccount) {
    return <NotFoundPage />;
  }

  const renderModal = (modalContent?: IContentAreaItem[]) => {
    const modal: IModal = {
      content: modalContent || [],
    };

    uiStore.setModal(modal);
  };

  const isClosing = !!currentAccount.closesAt;

  return (
    <div className={styles.wrapper}>
      <section className={styles.headerWrapper}>
        {renderClosedAccountInfo(currentAccount, depositTemplate?.closedAccountInfo)}
        <div>
          <h1
            style={{
              display: "inline-flex",
            }}
          >
            {getAccountDisplayName(depositStore.currentAccount)}
          </h1>
          {contentStore.isSetAccountNameEnabled && currentAccount.accountStatus !== AccountStatus.AccountClosed && (
            <Button
              className={styles.boxWrapper}
              onClick={() => {
                renderModal(depositTemplate?.setAccountNameModalContent);
              }}
            >
              <div className={styles.box}>
                <img src={editIcon} alt="edit-icon" />
              </div>
            </Button>
          )}
          <p>
            <span className={styles.bold}>{depositTemplate?.subHeading || "N°:"}</span>
            {` ${currentAccount.displayNumber}`}
          </p>
          <p>
            <span className={styles.bold}>{tx("deposit.productType").concat(":")}</span>
            {` ${currentAccount.nameIB}`}
          </p>
          {renderCloseDate(currentAccount, depositTemplate?.closedDateLabel)}
          {renderFTDDate(currentAccount, depositTemplate?.maturityDateTitle)}
        </div>
      </section>
      <section>
        <div className={styles.templateHeader}>
          {!uiStore.isTablet && (
            <div
              className={styles.image}
              style={{
                backgroundImage: `url(${depositTemplate?.image?.url})`,
              }}
            />
          )}
          <div className={styles.templateContent}>
            <div className={styles.flexBetween}>
              <div className={styles.availableBalance}>
                <div className={styles.templateTitleContainer}>
                  <span className={styles.templateTitle}>
                    {depositTemplate?.availableAmount || tx("misc.availableAmount")}
                  </span>
                  <h3>{toLocaleString(isClosing ? 0 : currentAccount.availableBalance, currency, locale)}</h3>
                </div>
              </div>
              <div className={styles.interestBearingBalance}>
                <div className={[styles.templateTitleContainer, styles.alignRight].join(" ")}>
                  <span className={styles.templateTitle}>{depositTemplate?.bookedBalance || tx("misc.balance")}</span>
                  <h3>{toLocaleString(isClosing ? 0 : currentAccount.bookedBalance, currency, locale)}</h3>
                </div>
              </div>
            </div>
            <div className={styles.flexBetween}>
              <div className={styles.templateTitleContainer}>
                <span className={styles.templateTitle}>{depositTemplate?.interest || tx("misc.interest")}</span>
                <h4>{`${toLocaleString(currentAccount.accountInterest || 0, undefined, locale, 2)} %`}</h4>
              </div>
              <div className={[styles.templateTitleContainer, styles.alignRight].join(" ")}>
                <span className={styles.templateTitle}>
                  {depositTemplate?.accruedInterest || tx("deposit.accruedInterest")}
                </span>
                <h4>{toLocaleString(currentAccount.accumulatedInterest || 0, currency, locale)}</h4>
              </div>
            </div>
          </div>
        </div>
      </section>
      {currentAccount.closesAt &&
        depositTemplate?.closingAccountInfoContent &&
        currentAccount.accountStatus !== AccountStatus.AccountClosed && (
          <section>
            <InfoText text={depositTemplate?.closingAccountInfoContent} level={NotificationLevel.information} />
          </section>
        )}
      {renderFTDInformation(currentAccount, depositTemplate?.maturityDateText, depositTemplate?.activationDateText)}
      <ContentArea page={depositTemplate} />
    </div>
  );
});

export default withRoot(DepositPage);
