import * as React from "react";
import { CSSProperties } from "react";
import { HtmlContent } from "../HtmlContent/HtmlContent";
import styles from "./Checkbox.scss";
import rootStyles from "~views/pages/Root.scss";
import errorIcon from "~assets/error.svg";

type ICheckboxProps = {
  label?: string;
  checkboxId?: string;
  loading?: boolean;
  wrapperStyle?: CSSProperties;
  error?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const Checkbox = ({
  label,
  checkboxId,
  disabled,
  wrapperStyle,
  onChange,
  error,
  ...props
}: ICheckboxProps): JSX.Element => {
  const id = checkboxId ?? label?.toLowerCase().replace(/ /g, "-");
  return (
    <div className={styles.wrapper} style={{ ...wrapperStyle }}>
      <label htmlFor={`checkbox-${id}`} className={styles.checkboxWrapper}>
        <input
          id={`checkbox-${id}`}
          type="checkbox"
          onChange={(e) => {
            if (disabled) return;
            if (onChange) onChange(e);
          }}
          {...props}
        />
        <span className={styles.checkmark} />
        {label && <HtmlContent className={styles.label} html={label} />}
      </label>
      {error && (
        <div className={rootStyles.error}>
          <img src={errorIcon} alt="error-icon" />
          <span>{error}</span>
        </div>
      )}
    </div>
  );
};
