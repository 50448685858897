import React from "react";
import { observer } from "mobx-react";
import styles from "../AccordionNewTransferBlock.scss";
import { useStores } from "netbank-shared/src/hooks";
import { tx } from "netbank-shared/src/libs/i18n";
import { Button, Lottie } from "~views/shared";
import { BankIdSignStatus, Colors } from "netbank-shared/src/libs/models/Content/Enums";
import loadingAnimationData from "~assets/lottie/loading.json";
import bankIdLogo from "~assets/bankId-logo.svg";
import { ImageQrBlock } from "~views/pages/Login/ImageQrBlock";

interface IPendingNewTransferProps {
  header?: string;
  text?: string;
  cancelAction?: () => void;
}

export const PendingNewTransfer = observer(({ header, text, cancelAction }: IPendingNewTransferProps) => {
  const { transferStore, bankIdSignStore } = useStores();
  return (
    <div>
      <div className={styles.pendingNewTransfer}>
        {header && <span className={styles.label}>{header}</span>}
        {text && (
          <div
            dangerouslySetInnerHTML={{
              __html: text || "",
            }}
          />
        )}
        {(bankIdSignStore.status === BankIdSignStatus.Polling &&
          bankIdSignStore.qrCode !== "")
          ? <div className={styles.qrCodeContainer}>
            <ImageQrBlock token={bankIdSignStore.qrCode} />
          </div>
          : <Lottie
            options={{
              animationData: loadingAnimationData,
            }}
            height={100}
            width={100}
          />
        }
      </div>
      <div className={styles.pendingTransferBottomWrapper}>
        <img src={bankIdLogo} alt="BankId logo" />
        <div className={styles.cancelButton}>
          <Button
            color={Colors.White}
            onClick={() => (cancelAction ? cancelAction() : transferStore.cancelTransaction())}
          >
            <span className={styles.buttonLabel}>{tx("misc.cancel")}</span>
          </Button>
        </div>
      </div>
    </div>
  );
});
