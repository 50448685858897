const EPS = 0.0000001;
const DECAY = 0.08333333;
const STEP = 0.00001;

const finalCredit = (
  payment: number,
  initialCredit: number,
  interestRate: number,
  periods: number,
  yearlyFee: number
): number => {
  let previousCredit = initialCredit;
  let credit = 0;
  let fee = 0;

  for (let period = 1; period <= periods; period += 1) {
    const interest = period === 1 ? 0 : previousCredit * interestRate * DECAY;
    fee = period === 1 ? yearlyFee : 0;
    credit = previousCredit + interest - payment + fee;
    previousCredit = credit;
  }
  return credit;
};

const dFinalCredit = (
  payment: number,
  initialCredit: number,
  interestRate: number,
  periods: number,
  yearlyFee: number
) => {
  const f = (x: number): number => finalCredit(x, initialCredit, interestRate, periods, yearlyFee);
  return (f(payment + EPS) - f(payment - EPS)) / (EPS * 2);
};

const leEvalRate = (
  interestRate: number,
  term: number,
  payment: number,
  present: number,
  residual: number,
  due: number
) => {
  let dTemp1 = 0;
  let dTemp2 = 0;
  let dTemp3 = 0;
  if (interestRate === 0) {
    return present + payment * term + residual;
  }

  dTemp3 = interestRate + 1;
  dTemp1 = dTemp3 ** term;
  if (due !== 0) dTemp2 = 1 + interestRate;
  else dTemp2 = 1;

  return present * dTemp1 + (payment * dTemp2 * (dTemp1 - 1)) / interestRate + residual;
};

const calculatePayment = (
  initialCredit: number,
  interestRate: number,
  periods: number,
  yearlyFee: number,
  guess = 0.1
) => {
  let x2 = guess;
  let x1;
  x1 = x2;

  const f = (payment: number) => finalCredit(payment, initialCredit, interestRate, periods, yearlyFee);
  const df = (payment: number) => dFinalCredit(payment, initialCredit, interestRate, periods, yearlyFee);

  let i = 0;
  while (Math.abs(f(x2)) > EPS) {
    x2 = x1 - f(x1) / df(x1);
    x1 = x2;

    i += 1;

    if (i === 100) return 0;
  }
  return x2;
};

const rate = (term: number, payment: number, present: number, residual = 0, due = 0, guess = 0.1) => {
  let dTemp = 0;
  let dRate0 = 0;
  let dRate1 = 0;
  let dY0 = 0;
  let dY1 = 0;
  let i = 0;
  if (term <= 0) return null;

  dRate0 = guess;
  dY0 = leEvalRate(dRate0, term, payment, present, residual, due);
  if (dY0 > 0) dRate1 = dRate0 / 2;
  else dRate1 = dRate0 * 2;
  dY1 = leEvalRate(dRate1, term, payment, present, residual, due);
  for (i = 0; i <= 39; i += 1) {
    if (dY1 === dY0) {
      if (dRate1 > dRate0) dRate0 -= STEP;
      else dRate0 -= STEP * -1;
      dY0 = leEvalRate(dRate0, term, payment, present, residual, due);
      if (dY1 === dY0) return null;
    }
    dRate0 = dRate1 - ((dRate1 - dRate0) * dY1) / (dY1 - dY0);
    dY0 = leEvalRate(dRate0, term, payment, present, residual, due);
    if (Math.abs(dY0) < EPS) return dRate0;
    dTemp = dY0;
    dY0 = dY1;
    dY1 = dTemp;
    dTemp = dRate0;
    dRate0 = dRate1;
    dRate1 = dTemp;
  }
  return null;
};

const fv = (interestRate: number, months: number, pmt: number, present: number) => {
  const type = 0;
  const pow = (1 + interestRate) ** months;
  let futureValue = 0;
  if (interestRate > 0) {
    futureValue = (pmt * (1 + interestRate * type) * (1 - pow)) / interestRate - present * pow;
  } else {
    futureValue = -1 * (present + pmt * months);
  }
  return futureValue;
};

export const calculateMonthlyCost = (
  initialCredit: number,
  interestRate: number,
  periods: number,
  yearlyFee: number
) => {
  let x2 = 0.1;
  let x1 = x2;
  const f = (payment: number) => finalCredit(payment, initialCredit, interestRate, periods, yearlyFee);
  const df = (payment: number) => dFinalCredit(payment, initialCredit, interestRate, periods, yearlyFee);

  let i = 0;

  while (Math.abs(f(x2)) > EPS) {
    x2 = x1 - f(x1) / df(x1);
    x1 = x2;
    i += 1;

    if (i === 100) return 0;
  }

  return x2;
};

export const calculateEffectiveInterest = (creditLimit: number, nominalRate: number): number => {
  const periods = 12;
  const fee = 195;
  const credit = creditLimit;
  const payment = calculatePayment(credit, nominalRate, periods, fee);
  const effectiveRate = rate(periods, payment, -credit, 0);
  if (!effectiveRate) return 0;
  const futureValue = fv(effectiveRate, 12, 0, -100) - 100;

  return futureValue;
};
