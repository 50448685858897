import React from "react";
import { observer } from "mobx-react";
import holdingHands from "~assets/holding-hands.svg";
import personOnComputer from "~assets/person-on-computer.svg";
import handOnFooter from "~assets/hand-on-footer.svg";
import bicycle from "~assets/person-on-bicycle.svg";
import styles from "./Footer.scss";
import { useStores } from "netbank-shared/src/hooks";
import { ISocialMediaItem } from "netbank-shared/src/libs/models/Content/SocialMediaItem";
import { IContentLink, IGenericContentLink } from "netbank-shared/src/libs/models/Content/ContentLink";
import { ILinkItemCollection, ILink } from "netbank-shared/src/libs/models/Content/LinkItem";
import { LinkItemCollection } from "../LinkItemCollection/LinkItemCollection";
import { DynamicLink } from "../DynamicLink/DynamicLink";
import { HtmlContent } from "../HtmlContent/HtmlContent";
import { TrackingAction, TrackingCategory } from "netbank-shared/src/libs/models/Tracking";
import { tx } from "netbank-shared/src/libs/i18n";

const RenderSocialMediaItem = (items: IGenericContentLink<ISocialMediaItem>[]) => {
  if (items?.length <= 0) return null;

  return (
    <div className={styles.socialMedia}>
      {items?.length > 0 &&
        items?.map((media: IGenericContentLink<ISocialMediaItem>) => {
          const link = media?.linkItem?.[0]?.link?.[0] || media?.link?.[0];
          const trackingCategory = media?.linkItem?.[0].trackingCategory;
          const trackingAction = media?.linkItem?.[0].trackingAction;
          return (
            <DynamicLink
              key={media?.contentLink?.guidValue}
              to={link?.href}
              target={link?.target}
              aria-label={link?.title || link?.text}
              trackingCategory={trackingCategory}
              trackingAction={trackingAction}
            >
              <img src={media?.icon?.url} alt={link?.title || link?.text} />
            </DynamicLink>
          );
        })}
    </div>
  );
};

const RenderCustomerServiceContent = (content: IContentLink[], links: ILink[]) => {
  if (!content || !links?.at(0)) return null;

  const [{ href, target, title }] = links;

  return (
    <div className={styles.customerService}>
      {content?.map((el: any) => (
        <HtmlContent key={el?.contentLink?.guidValue} html={el?.textArea} />
      ))}
      {links?.length > 0 && (
        <DynamicLink
          to={href || `/${tx("routing.lang")}`}
          target={target}
          className={styles.contactButton}
          trackingCategory={TrackingCategory.Footer}
          trackingAction={TrackingAction.FaqCTA}
        >
          {title}
        </DynamicLink>
      )}
    </div>
  );
};

const RenderFooterLinkColumns = (footerLinkColumns: IGenericContentLink<ILinkItemCollection>[]) => {
  if (!(footerLinkColumns?.length > 0)) return null;
  return (
    <div className={styles.columnsWrapper}>
      {footerLinkColumns?.map((column, i) => (
        <LinkItemCollection
          key={i}
          linkItemCollection={column}
          wrapperStyles={styles.columnWrapper}
          contentWrapperStyles={styles.contentWrapper}
          linksWrapperStyles={styles.column}
          titleWrapperStyles={styles.columnTitle}
        />
      ))}
    </div>
  );
};

const RenderDisclaimerLinks = (disclaimerLinks: ILink[]) => {
  if (disclaimerLinks?.length <= 0) return null;
  return (
    <div className={styles.policy}>
      {disclaimerLinks?.map((link, index) => (
        <DynamicLink
          key={index}
          to={link?.href || `/${tx("routing.lang")}`}
          target={link?.target}
          aria-label={link?.text}
        >
          {link?.title}
        </DynamicLink>
      ))}
    </div>
  );
};

export const Footer = observer(() => {
  const { contentStore } = useStores();
  const footer = contentStore.currentPage;

  const wrapperClasses = [styles.wrapper];
  const upperFooterClasses = [styles.upperFooterWrapper];
  const lowerFooterClasses = [styles.lowerFooterWrapper];

  if (footer === undefined) return null;

  const customerServiceContent = footer?.customerServiceContent;
  const customerServiceButton: ILink[] | undefined = footer?.customerServiceLink
    ? footer.customerServiceLink?.map((item) => item.link?.[0])
    : footer?.customerServiceButton;

  const disclaimerLinks = footer?.disclaimerLinkItems
    ? footer.disclaimerLinkItems?.map((item) => item.link?.[0])
    : footer?.disclaimerLinks;
  const footerLinkColumns = footer?.footerLinkColumns;
  const companyInformation = footer?.companyInformation;
  const socialMediaLinks = footer?.socialMediaLinks;

  if (footer?.backgroundColor) {
    wrapperClasses.push(styles[footer.backgroundColor]);
    upperFooterClasses.push(styles[footer.backgroundColor]);
    lowerFooterClasses.push(styles[footer.backgroundColor]);
  }

  return (
    <footer className={wrapperClasses.join(" ")}>
      <div className={upperFooterClasses.join(" ")}>
        <div className={styles.upperFooter}>
          {RenderCustomerServiceContent(customerServiceContent, customerServiceButton)}

          {footerLinkColumns?.length < 3 ? (
            <img className={styles.desktopLgIllustration} src={personOnComputer} alt="" />
          ) : (
            <img className={styles.desktopLg3ColIllustration} src={handOnFooter} alt="" />
          )}
          <img className={styles.desktopMdIllustration} src={holdingHands} alt="" />
          {RenderFooterLinkColumns(footerLinkColumns)}
        </div>
      </div>
      <div className={lowerFooterClasses.join(" ")}>
        <div className={styles.lowerFooter}>
          <div className={styles.organizationWrapper}>
            {companyInformation?.length > 0 && <div className={styles.organization}>{companyInformation}</div>}
            {RenderDisclaimerLinks(disclaimerLinks)}
          </div>
          <img className={styles.mobileIllustration} src={bicycle} alt="" />
          {RenderSocialMediaItem(socialMediaLinks)}
        </div>
      </div>
    </footer>
  );
});
